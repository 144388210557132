import { BookType } from '@models/price-book-models';
import { type LineItemDiscount, type LineItemRebate } from '@models/pricing-models';
import { type Entity } from '../entity';
import { QuickBooksSynchronizationStatus } from '../quick-books';
import { type CustomData, type OptionalTextStateResource, type RequiredTextStateResource } from '../resource';
import { type Signature } from '../signature';
import { type TagEntityInformation } from '@models/tag-models';

export enum CompletionStatus {
  Incomplete = 0,
  Complete = 1,
  OutstandingProposal = 2,
}

export enum BalanceType {
  Empty = 1,
  Open = 2,
  Closed = 3,
}

export enum HappyCall {
  NotMade = 0,
  Made = 1,
  Avoided = 2,
}

export enum WorkOrderType {
  Full = 1,
  Sales = 2,
}

export interface WorkOrderInformation extends OptionalTextStateResource {
  localId: Id;
  customerId: Id;
  callId: Id | null;
  technicianId: Id | null;
  completed: boolean;
  followUpNotes: string | null;
  emailSent: boolean;
  processed: boolean;
  processedDate: Date | null;
  receiptSent: boolean;
  receiptSentDate: Date | null;
  taxItemId: Id | null;
  salesTaxPercentage: Fraction | null;
  completionStatus: CompletionStatus;
  technicianSignature: Signature | null;
  customerSignature: Signature | null;
  callDepartmentTypeId: Id | null;
  quickBooksStatus: QuickBooksSynchronizationStatus;
  happyCall: HappyCall;
  taxOverride: Money | null;
  siteId: Id | null;
  rateTypeId: Id; // Not allowed to change
  propertyTypeId: Id; // Allowed to change on work orders without a call
  type: WorkOrderType;
  salesProposalId: Id | null;
  paymentTokenLink: string | null;
  createdDate: Date;
  contractorId: Id;
  customData?: CustomData;
  tagEntityInformations: TagEntityInformation[] | null;
}

export interface WorkOrderInformationForList {
  data: WorkOrderInformation;
  siteStreetAddress: string | null;
  invoiceSent: boolean;
  agreementSold: boolean;
  hasBeenDebriefed: boolean;
  callWorkOrderTypeId: Id | null;
  arrived: Date | null;
  customer: Entity;
  balanceType: BalanceType;
  callCustomData?: CustomData;
  workOrderCustomData?: CustomData;
}

export interface WorkOrderDiscountInformation extends RequiredTextStateResource, LineItemDiscount {
  workOrderId: Id;
  afterTax: boolean;
  amount: Money | null;
  percentage: Fraction | null;
  callDepartmentTypeId: Id | null;
}

export interface WorkOrderRebateInformation extends RequiredTextStateResource, LineItemRebate {
  workOrderId: Id;
  afterTax: boolean;
  amount: Money;
  callDepartmentTypeId: Id | null;
}

/*
export interface WorkOrderSearchResult extends Resource {
    balance: Money;
    entity: Entity;
    phone: Phone;
    address: Address;
} */

export interface WorkOrderForList {
  workOrderId: Id;
  localId: Id;
  processed: boolean;
  customer: string;
  technicianId: Id;
  callWorkOrderTypeId: Id | null;
  callReasonId: Id | null;
  arrived: Date | null;
  departed: Date | null;
  receiptSent: boolean;
  customerCompany: string | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  quickbooksStatus: QuickBooksSynchronizationStatus;
  completed: boolean;
}

export function getHappyCall(happyCall: HappyCall): { url: string; text: string } | null {
  switch (happyCall) {
    case HappyCall.NotMade:
      return null;
    case HappyCall.Made:
      return { url: '/assets/images/happy-call-made.svg', text: 'Happy Call Made' };
    case HappyCall.Avoided:
      return { url: '/assets/images/happy-call-avoided.svg', text: 'Happy Call Avoided' };
  }
}

export function getBalanceType(balanceType: BalanceType): { url: string; text: string } | null {
  switch (balanceType) {
    case BalanceType.Empty:
      return null;
    case BalanceType.Open:
      return { url: '/assets/images/balance-open.svg', text: 'Balance Due' };
    case BalanceType.Closed:
      return { url: '/assets/images/balance-closed.svg', text: 'Paid in Full' };
  }
}

export const createEmpty = (): WorkOrderInformation => {
  return {
    id: 0,
    localId: 0,
    customerId: 0,
    callId: null,
    technicianId: null,
    completed: false,
    followUpNotes: null,
    emailSent: false,
    processed: false,
    processedDate: null,
    receiptSent: false,
    receiptSentDate: null,
    taxItemId: null,
    salesTaxPercentage: null,
    completionStatus: CompletionStatus.Incomplete,
    technicianSignature: null,
    customerSignature: null,
    callDepartmentTypeId: null,
    quickBooksStatus: QuickBooksSynchronizationStatus.None,
    happyCall: HappyCall.NotMade,
    taxOverride: null,
    siteId: null,
    rateTypeId: 1,
    propertyTypeId: 1,
    type: WorkOrderType.Full,
    salesProposalId: null,
    paymentTokenLink: null,
    createdDate: new Date(),
    contractorId: 0,
    tagEntityInformations: null,
  };
};

export const createEmptyWorkOrderDiscount = (): WorkOrderDiscountInformation => {
  return {
    id: 0,
    text: '',
    workOrderId: 0,
    afterTax: false,
    amount: 0,
    percentage: null,
    callDepartmentTypeId: null,
  };
};

export const createEmptyWorkOrderRebate = (): WorkOrderRebateInformation => {
  return {
    id: 0,
    text: '',
    workOrderId: 0,
    afterTax: false,
    amount: 0,
    callDepartmentTypeId: null,
  };
};

export function getMatchinBookType(workOrderType: WorkOrderType): BookType | null {
  switch (workOrderType) {
    case WorkOrderType.Full:
      return BookType.Service;
    case WorkOrderType.Sales:
      return BookType.Sales;
  }

  return null;
}
