<wm-rw2 [name]="name()" [formControl]="control" [mode]="mode()">
  @if (helperTemplate2(); as helperTemplate2) {
    <ng-template #helperTemplate let-control>
      <ng-container [ngTemplateOutlet]="helperTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    </ng-template>
  }
  <ng-template #writeTemplate let-control>
    @if (writeTemplate2(); as writeTemplate2) {
      <ng-container [ngTemplateOutlet]="writeTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    } @else {
      <wm-input-text
        #t
        [type]="type()"
        [helper]="helper()"
        [placeholder]="placeholder()"
        [formControl]="control"
        [required]="required()"
        [spellCheck]="spellCheck()"
        [maxLength]="maxLength()"
        [autoComplete]="autoComplete()"
        [useCorrectTyping]="true"
        (init)="defaultInput = t"
      />
    }
  </ng-template>
  <ng-template #readTemplate let-control>
    @if (readTemplate2(); as readTemplate2) {
      <ng-container [ngTemplateOutlet]="readTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    } @else {
      <span class="template">{{ control.value }}</span>
    }
  </ng-template>
</wm-rw2>
