import { type TagEntityInformation } from '@models/tag-models';
import { type OptionalTextStateResource, type CustomData } from '../resource';
import { type AgreementSiteSystemInformation } from './agreement-site-system-information';
import { type WorkOrderAgreementInformation } from './work-order-agreement-information';
import { type WorkOrderDetailInformation } from './work-order-detail-information';

export type SiteSystem = SiteSystemInformation | MiscellaneousSiteSystem;

export interface MiscellaneousSiteSystem {
  id: 0;
  miscellaneous: true;
}

export interface SiteSystemInformation extends OptionalTextStateResource {
  localId: Id;
  siteId: Id;
  note: string | null;
  indoor: SiteSystemUnitInformation;
  outdoor: SiteSystemUnitInformation;
  warrantyStatus: string | null;
  systemTypeId: Id | null;
  systemTypeOther: string | null;
  customData?: CustomData;
  tagEntityInformations: TagEntityInformation[] | null;
}

export interface SiteSystemUnitInformation {
  mfgYear: number | null;
  brand: string | null;
  model: string | null;
  serialNumber: string | null;
  location: string | null;
}

export interface SiteSystemInformationForList {
  data: SiteSystemInformation;
  filters: number;
  belts: number;
  filterSizes: string[];
}

export interface SiteSystemBehavior {
  wordings: Record<string, SiteSystemWording>;
}

export interface SiteSystemWording {
  singular: string;
  plural: string;
  withIndefiniteArticle: string;
}

export const defaultSiteSystemWording: SiteSystemWording = {
  singular: 'Equipment',
  plural: 'Equipment',
  withIndefiniteArticle: 'a piece of equipment',
};

export const isMiscellaneousSiteSystem = (siteSystem: SiteSystem): siteSystem is MiscellaneousSiteSystem =>
  (siteSystem as MiscellaneousSiteSystem)?.miscellaneous;

export const findSiteSystemIdByWorkOrderAgreement = (
  workOrderAgreement: WorkOrderAgreementInformation,
  agreementSiteSystems: AgreementSiteSystemInformation[]
): Id | null => {
  return (
    workOrderAgreement.siteSystemId ??
    agreementSiteSystems.find(m => m.id === workOrderAgreement.agreementSiteSystemId)?.siteSystemId ??
    null
  );
};

export const hasWorkOrderDetailsOrAgreements = (
  siteSystem: SiteSystem,
  workOrderDetails: WorkOrderDetailInformation[],
  workOrderAgreements: WorkOrderAgreementInformation[],
  agreementSiteSystems: AgreementSiteSystemInformation[]
): boolean =>
  workOrderDetails
    .filter(x => !x.hidden)
    .map(x => x.siteSystemId)
    // fallback 0 to null so we can catch the MiscellaneousSiteSystem
    .includes(siteSystem.id || null) ||
  (!isMiscellaneousSiteSystem(siteSystem) &&
    workOrderAgreements
      .filter(x => !x.hidden)
      .map(x => findSiteSystemIdByWorkOrderAgreement(x, agreementSiteSystems))
      .includes(siteSystem.id));

export function createEmptySiteSystemUnitInformation(): SiteSystemUnitInformation {
  return {
    mfgYear: null,
    brand: null,
    model: null,
    serialNumber: null,
    location: null,
  };
}

export function createEmpty(): SiteSystemInformation {
  return {
    id: 0,
    localId: 0,
    siteId: 0,
    note: null,
    indoor: createEmptySiteSystemUnitInformation(),
    outdoor: createEmptySiteSystemUnitInformation(),
    warrantyStatus: null,
    systemTypeId: null,
    systemTypeOther: null,
    hidden: false,
    tagEntityInformations: [],
  };
}
