import { CommonModule } from '@angular/common';
import { Component, effect, forwardRef, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { RwDropdown2Component } from '@controls/rw-dropdown/rw-dropdown2.component';
import { RwTag2Component } from '@controls/rw-tag/rw-tag2.component';
import { Mode } from '@models/form';
import { type RequiredTextResource1 } from '@models/resource';
import { DynamicFilterService2 } from '@services/dynamic-filter.service';
import { combineLatest, concatMap, filter } from 'rxjs';

// Angular 18
@Component({
  selector: 'wm-filter-dynamic-dropdown',
  template: `@if (multiselect()) {
      <wm-rw-tag2
        [name]="label()"
        [options]="options()"
        [formControl]="control"
        [mode]="Mode.Write"
        [closeOnSelection]="true"
        [optgroups]="optgroups()"
        [optgroupField]="optgroupField()"
      />
    } @else {
      <wm-rw-dropdown2
        [name]="label()"
        [options]="options()"
        dropdownParent="body"
        [formControl]="control"
        [mode]="Mode.Write"
        [clearable]="clearable()"
        [optgroups]="optgroups()"
        [optgroupField]="optgroupField()"
      />
    }`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDynamicDropdownComponent),
      multi: true,
    },
  ],
  imports: [ReactiveFormsModule, RwDropdown2Component, RwTag2Component, CommonModule],
  standalone: true,
})
export class FilterDynamicDropdownComponent<T> extends ElementBaseWithFormControl<T | T[] | null> {
  readonly Mode = Mode;

  readonly dynamicFilterService = inject(DynamicFilterService2);

  readonly filterId = input<string>('');
  readonly label = input<string>();
  readonly code = input<string>(); // required
  readonly name = input<string>(); // required
  readonly options = input.required<RequiredTextResource1<T>[]>();
  readonly clearable = input(false);
  readonly multiselect = input(false);
  readonly optgroupField = input<string | undefined>(undefined);
  readonly optgroups = input<{ value: string; label: string }[] | undefined>(undefined);

  readonly valueChanges = toSignal(this.control.valueChanges);
  readonly serviceChanges = toSignal(
    combineLatest([toObservable(this.code), toObservable(this.name)]).pipe(
      filter(([code, name]) => !!code && !!name),
      concatMap(([code, name]) => this.dynamicFilterService.changes<T | T[] | null>(this.filterId(), code ?? '', name ?? ''))
    ),
    { initialValue: null }
  );

  _ = effect(() => {
    this.dynamicFilterService.patch(this.filterId(), this.code() ?? '', this.name() ?? '', this.valueChanges());
  });

  __ = effect(
    () => {
      this.control.patchValue(this.serviceChanges());
    },
    { allowSignalWrites: true }
  );

  constructor() {
    super(() => null);
  }
}

