<div class="wm-card2">
  <div class="wm-description">
    @for (availableColumn of data.availableColumns; track availableColumn.id) {
      <div>
        <wm-checkbox
          [ngModel]="result()[availableColumn.id]"
          [disabled]="disabled()[availableColumn.id]"
          (ngModelChange)="changeResult(availableColumn.id, $event)"
          >{{ availableColumn.text }}</wm-checkbox
        >
      </div>
    }
  </div>
</div>
