import { type RequiredTextResource } from './resource';

export interface TagInformation extends RequiredTextResource {
  entityType: TagEntityType;
}

export interface TagEntityInformation extends RequiredTextResource {
  entityType: TagEntityType;
  entityId: Id;
}

export interface TagEntityForEntityInformation extends TagEntityInformation {
  entityName: string;
  entityIds: Id[];
}

export enum TagEntityType {
  Customer = 1,
  Site = 2,
  WorkOrder = 5,
  Call = 9,
  SiteSystem = 10,
  SalesProposal = 11,
  Duty = 12,
  AgreementSiteSystem = 13,
}

export const getTagEntityTypeName = (tagEntityType: TagEntityType) => {
  switch (tagEntityType) {
    case TagEntityType.Customer:
      return 'Customer';
    case TagEntityType.Site:
      return 'Site';
    case TagEntityType.WorkOrder:
      return 'Work Order';
    case TagEntityType.Call:
      return 'Appointment';
    case TagEntityType.SiteSystem:
      return 'Equipment';
    case TagEntityType.SalesProposal:
      return 'Sales Proposal';
    case TagEntityType.Duty:
      return 'Task';
    case TagEntityType.AgreementSiteSystem:
      return 'Agreement';
    default:
      return '';
  }
};

