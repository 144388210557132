<div class="wm-card2">
  <wm-rw-dropdown2
    name="Ownership / Residency Status"
    [required]="true"
    [options]="ownershipOptions"
    [(ngModel)]="ownership"
    (ngModelChange)="internalModelChange()"
    [mode]="Mode.Write"
  />
  <wm-rw-dropdown2
    name="Property Type"
    [required]="true"
    dropdownParent="body"
    [options]="propertyTypeOptions"
    [(ngModel)]="propertyType"
    (ngModelChange)="internalModelChange()"
    [mode]="Mode.Write"
  />
  <wm-rw-money2
    name="Mortgage / Rent Payment"
    [(ngModel)]="monthlyMortgagePayment"
    (ngModelChange)="internalModelChange()"
    [mode]="Mode.Write"
  />
</div>
