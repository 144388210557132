import { type Creatable, type Mutable, type Updatable } from '@models/mutable';
import {
  type BookType,
  type PartItemInformation,
  type PriceBookCategoryInformation,
  type PriceBookInformation,
  type ServiceRepairInformation,
} from '@models/price-book-models';
import { createReducer, on, type Action } from '@ngrx/store';
import { type SaveState } from '@services/save-store/save.actions';
import { flattenResource, indexBy } from '@utility/array';
import { clone } from '@utility/object';
import { newGuid } from '@utility/string';
import * as PriceBookDetailActions from './price-book-detail.actions';

const defaultFilter = { text: null, propertyTypeId: 1, rateTypeId: 1, agreementId: null };
const defaultEntities = {
  priceBooks: null,
  priceBookCategories: null,
  priceBookCategoryPartItemIds: null,
  priceBookCategoryServiceRepairIds: null,
  partItems: null,
  serviceRepairs: null,
} as ANY as EntitiesState;

export type PriceBookInformationState = Record<string, PriceBookInformation>;
export type PriceBookCategoryInformationState = Record<string, PriceBookCategoryInformation & Mutable>;
export type PriceBookCategoryPartItemState = Record<string, Updatable>;
export type PartItemInformationState = Record<string, PartItemInformation & Creatable & Updatable>;
export type PriceBookCategoryServiceRepairState = Record<string, Updatable>;
export type ServiceRepairInformationState = Record<string, ServiceRepairInformation & Creatable & Updatable>;

export interface PriceBookDetailGroupData {
  priceBookId?: Id;
  bookType?: BookType;
  filter: PriceBookDetailFilter;
  selections: { serviceRepairId: Id; partItemIds: Id[] }[] | null;
  partItemIdSelections: Id[] | null;

  // The entities are modified on the page, but displayed on the dialog.
  entities: EntitiesState;
}

export type PriceBookDetailGroupState = Record<string, PriceBookDetailGroupData>;
export interface Counters {
  partItems: number;
  serviceRepairs: number;
  priceBookCategories: number;
}

export interface PriceBookDetailFilter {
  text: string;
  propertyTypeId: Id;
  rateTypeId: Id;
  agreementId: Id | null;
}

export interface EntitiesState {
  priceBooks: PriceBookInformationState;
  priceBookCategories: PriceBookCategoryInformationState; // Unflatten!
  priceBookCategoryPartItemIds: PriceBookCategoryPartItemState;
  priceBookCategoryServiceRepairIds: PriceBookCategoryServiceRepairState;
  partItems: PartItemInformationState;
  serviceRepairs: ServiceRepairInformationState;
}

// We need 2 price book details to show at the same time.
// However, only 1 can be edited.
// The other price book can be filtered, so we will use a "group" for keeping that data
export interface PriceBookDetailState extends SaveState {
  edit: boolean;
  counters: Counters;
  entitiesSnapshot: EntitiesState | null;
  group: PriceBookDetailGroupState;
}

const initialState: PriceBookDetailState = {
  group: {},
  edit: false,
  counters: {
    partItems: 0,
    serviceRepairs: 0,
    priceBookCategories: 0,
  },
  entitiesSnapshot: null,
  _submitCounter: 0,
  _error: null,
  _cacheCorrelationId: null as ANY,
  _correlationId: null as ANY,
};

function loadReducer<T extends { id: Id }>(arg: { data: T[] }): Record<string, T> {
  return indexBy(arg.data, 'id');
}

function addReducer<T extends { id: Id }>(
  newId: Id,
  arg: { data: Partial<T> },
  order: number,
  withGuid: boolean
): Record<number, Partial<T> & Creatable> {
  let newObj: Partial<T> & { guid?: string } = { ...arg.data };
  if (withGuid) {
    newObj = { ...newObj, guid: newGuid() };
  }

  return {
    [newId]: { ...newObj, ...{ id: newId, order, created: true } },
  };
}

function updateReducer<T extends { id: Id }>(
  stateResource: Record<number, T & Mutable>,
  arg: { id: Id; patch: Partial<T> }
): Record<number, T & Mutable & Updatable> {
  const obj = { ...stateResource[arg.id], ...arg.patch, updated: true };

  return {
    [arg.id]: obj,
  };
}

function entityReducer(
  state: PriceBookDetailState,
  group: string,
  key: keyof PriceBookDetailGroupData['entities'],
  value: any
): PriceBookDetailState {
  const obj: Record<string, unknown> = {};
  obj[key] = value;
  return {
    ...state,
    group: {
      ...state.group,
      [group]: {
        ...state.group[group],
        entities: {
          ...state.group[group].entities,
          ...obj,
        },
      },
    },
  };
}

// Start: priceBookDetailReducer
const priceBookDetailReducer = createReducer(
  initialState,
  on(PriceBookDetailActions.clear, (_state, _action) => {
    return clone(initialState);
  }),
  on(PriceBookDetailActions.loadPriceBookData, (state, action) => {
    const flattenPriceBookCategories = flattenResource(clone(action.priceBookCategories), true);

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          selections: [],
          partItemIdSelections: [],
          entities: {
            ...defaultEntities,
            priceBooks: loadReducer({ data: action.priceBooks }),
            priceBookCategoryPartItemIds: indexBy(
              flattenPriceBookCategories.map(m => ({
                id: m.id,
                updated: false,
              })),
              'id'
            ),
            priceBookCategoryServiceRepairIds: indexBy(
              flattenPriceBookCategories.map(m => ({
                id: m.id,
                updated: false,
              })),
              'id'
            ),
            priceBookCategories: loadReducer({ data: flattenPriceBookCategories }) as PriceBookCategoryInformationState,
            serviceRepairs: loadReducer({ data: action.serviceRepairs }) as ServiceRepairInformationState,
            partItems: loadReducer({ data: action.partItems }) as PartItemInformationState,
          },
        },
      },
    };
  }),
  on(PriceBookDetailActions.selectPriceBook, (state, action) => ({
    ...state,
    group: {
      ...state.group,
      [action.group]: {
        ...state.group[action.group],
        priceBookId: action.id,
      },
    },
  })),
  on(PriceBookDetailActions.changeFilter, (state, action) => ({
    ...state,
    group: {
      ...state.group,
      [action.group]: {
        ...state.group[action.group],
        filter: {
          ...defaultFilter,
          ...state.group[action.group]?.filter,
          ...action.data,
        },
      },
    },
  })),
  on(PriceBookDetailActions.addCategory, (state, action) => {
    // We search how many items are in the parentCategory to get the order.
    const count = Object.values(state.group[action.group].entities.priceBookCategories).filter(
      m => m.priceBookId === action.data.priceBookId && m.parentPriceBookCategoryId === action.data.parentPriceBookCategoryId
    ).length;
    const counters = {
      ...state.counters,
      priceBookCategories: state.counters.priceBookCategories - 1,
    };
    const addition = addReducer(
      counters.priceBookCategories,
      {
        data: {
          parentPriceBookCategoryId: null,
          serviceRepairIds: [],
          partItemIds: [],
          ...action.data,
        },
      },
      count,
      true
    );
    const additionId = counters.priceBookCategories;
    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            priceBookCategoryPartItemIds: {
              ...state.group[action.group].entities.priceBookCategoryPartItemIds,
              [additionId]: {
                updated: false,
              },
            },
            priceBookCategoryServiceRepairIds: {
              ...state.group[action.group].entities.priceBookCategoryServiceRepairIds,
              [additionId]: {
                updated: false,
              },
            },
            priceBookCategories: {
              ...state.group[action.group].entities.priceBookCategories,
              ...(addition as PriceBookCategoryInformationState),
            },
          },
        },
      },
      counters,
    };
  }),
  on(PriceBookDetailActions.updateCategory, (state, action) =>
    entityReducer(state, action.group, 'priceBookCategories', {
      ...state.group[action.group].entities.priceBookCategories,
      ...updateReducer(state.group[action.group].entities.priceBookCategories, action),
    })
  ),
  on(PriceBookDetailActions.deleteCategory, (state, action) => {
    // Too many things are changing.
    const entities = clone(state.group[action.group].entities);

    function recursiveDelete(id: Id): void {
      delete entities.priceBookCategoryPartItemIds[id];
      delete entities.priceBookCategoryServiceRepairIds[id];

      const children = Object.values(entities.priceBookCategories).filter(m => m.parentPriceBookCategoryId === id);
      children.forEach(child => {
        recursiveDelete(child.id);
      });
      if (id <= 0) {
        delete entities.priceBookCategories[id];
      } else {
        entities.priceBookCategories[id].deleted = true;
      }
    }

    function deleteUnwantedItems(): void {
      const priceBookCategories = Object.values(entities.priceBookCategories);
      const serviceRepairKeys = Object.keys(entities.serviceRepairs)
        .map(m => +m)
        .filter(m => m <= 0);

      for (const key of serviceRepairKeys) {
        const found = priceBookCategories.some(m => m.serviceRepairIds.some(n => n.id === key));
        if (!found) {
          delete entities.serviceRepairs[key];
        }
      }

      const partItemKeys = Object.keys(entities.partItems)
        .map(m => +m)
        .filter(m => m <= 0);

      for (const key of partItemKeys) {
        const found = priceBookCategories.some(m => m.partItemIds.some(n => n.id === key));
        if (!found) {
          delete entities.partItems[key];
        }
      }
    }

    recursiveDelete(action.id);
    deleteUnwantedItems();

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities,
        },
      },
    };
  }),
  on(PriceBookDetailActions.addServiceRepair, (state, action) => {
    const counters = {
      ...state.counters,
      serviceRepairs: state.counters.serviceRepairs - 1,
    };

    let serviceRepairId = action.data.id as ANY;
    if (serviceRepairId <= 0) {
      serviceRepairId = counters.serviceRepairs;
    }

    const count = state.group[action.group].entities.priceBookCategories[action.categoryId].serviceRepairIds.length;
    const addObj = {
      [serviceRepairId]: { ...action.data, id: serviceRepairId, created: true },
    };

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            priceBookCategories: {
              ...state.group[action.group].entities.priceBookCategories,
              [action.categoryId]: {
                ...state.group[action.group].entities.priceBookCategories[action.categoryId],
                serviceRepairIds: state.group[action.group].entities.priceBookCategories[action.categoryId].serviceRepairIds
                  .filter(m => m.id !== serviceRepairId)
                  .concat({ id: serviceRepairId, order: count }),
              },
            },
            priceBookCategoryServiceRepairIds: {
              ...state.group[action.group].entities.priceBookCategoryServiceRepairIds,
              [action.categoryId]: {
                updated: true,
              },
            },
            serviceRepairs: {
              ...state.group[action.group].entities.serviceRepairs,
              ...addObj,
            },
          },
        },
      },
      counters,
    };
  }),

  on(PriceBookDetailActions.changeServiceRepairOrder, (state, action) => {
    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            priceBookCategories: {
              ...state.group[action.group].entities.priceBookCategories,
              [action.categoryId]: {
                ...state.group[action.group].entities.priceBookCategories[action.categoryId],
                serviceRepairIds: state.group[action.group].entities.priceBookCategories[action.categoryId].serviceRepairIds
                  .filter(m => m.id !== action.id)
                  .concat({ id: action.id, order: action.order }),
              },
            },
            priceBookCategoryServiceRepairIds: {
              ...state.group[action.group].entities.priceBookCategoryServiceRepairIds,
              [action.categoryId]: {
                updated: true,
              },
            },
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.changePartItemOrder, (state, action) => {
    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            priceBookCategories: {
              ...state.group[action.group].entities.priceBookCategories,
              [action.categoryId]: {
                ...state.group[action.group].entities.priceBookCategories[action.categoryId],
                partItemIds: state.group[action.group].entities.priceBookCategories[action.categoryId].partItemIds
                  .filter(m => m.id !== action.id)
                  .concat({ id: action.id, order: action.order }),
              },
            },
            priceBookCategoryPartItemIds: {
              ...state.group[action.group].entities.priceBookCategoryPartItemIds,
              [action.categoryId]: {
                updated: true,
              },
            },
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.addPartItem, (state, action) => {
    const counters = {
      ...state.counters,
      partItems: state.counters.partItems - 1,
    };

    let partItemId = action.data.id || 0;
    let addObj = {};
    const count = state.group[action.group].entities.priceBookCategories[action.categoryId].partItemIds.length;
    if (partItemId <= 0) {
      partItemId = counters.partItems;

      addObj = {
        [partItemId]: { ...action.data, id: partItemId, code: 'Code', created: true },
      };
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            priceBookCategories: {
              ...state.group[action.group].entities.priceBookCategories,
              [action.categoryId]: {
                ...state.group[action.group].entities.priceBookCategories[action.categoryId],
                partItemIds: state.group[action.group].entities.priceBookCategories[action.categoryId].partItemIds
                  .filter(m => m.id !== partItemId)
                  .concat({ id: partItemId, order: count }),
              },
            },
            priceBookCategoryPartItemIds: {
              ...state.group[action.group].entities.priceBookCategoryPartItemIds,
              [action.categoryId]: {
                updated: true,
              },
            },
            partItems: {
              ...state.group[action.group].entities.partItems,
              ...addObj,
            },
          },
        },
      },
      counters,
    };
  }),

  on(PriceBookDetailActions.updateServiceRepair, (state, action) =>
    entityReducer(state, action.group, 'serviceRepairs', {
      ...state.group[action.group].entities.serviceRepairs,
      ...updateReducer(state.group[action.group].entities.serviceRepairs, action),
    })
  ),
  on(PriceBookDetailActions.updatePartItem, (state, action) =>
    entityReducer(state, action.group, 'partItems', {
      ...state.group[action.group].entities.partItems,
      ...updateReducer(state.group[action.group].entities.partItems, action),
    })
  ),

  on(PriceBookDetailActions.deleteServiceRepair, (state, action) => {
    // If we don't have it anywhere in serviceRepairIds and it is a negative number we will remove it from the serviceRepairs
    const stillUsedSomewhere = Object.values(state.group[action.group].entities.priceBookCategories)
      .flat()
      .some(m => m.id !== action.categoryId && m.serviceRepairIds.some(n => n.id === action.id));

    const serviceRepairs = {
      ...state.group[action.group].entities.serviceRepairs,
      [action.id]: {
        ...state.group[action.group].entities.serviceRepairs[action.id],

        // We set this to false if we are not using this service repair anywhere else.
        // Otherwise the user might have modified the service repair to something invalid
        // Then we won't be able to see it again.
        updated: stillUsedSomewhere ? state.group[action.group].entities.serviceRepairs[action.id].updated : false,
      },
    };
    if (action.id <= 0) {
      if (
        !Object.values(state.group[action.group].entities.priceBookCategories).some(
          m => m.id !== action.categoryId && m.serviceRepairIds.some(n => n.id === action.id)
        )
      ) {
        delete (serviceRepairs as ANY)[action.id];
      }
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            serviceRepairs,
            priceBookCategories: {
              ...state.group[action.group].entities.priceBookCategories,
              [action.categoryId]: {
                ...state.group[action.group].entities.priceBookCategories[action.categoryId],
                serviceRepairIds: state.group[action.group].entities.priceBookCategories[action.categoryId].serviceRepairIds.filter(
                  m => m.id !== action.id
                ),
              },
            },
            priceBookCategoryServiceRepairIds: {
              ...state.group[action.group].entities.priceBookCategoryServiceRepairIds,
              [action.categoryId]: {
                updated: true,
              },
            },
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.deletePartItem, (state, action) => {
    // If we don't have it anywhere in partItemIds and it is a negative number we will remove it from the partItems
    const partItems = { ...state.group[action.group].entities.partItems };
    if (action.id <= 0) {
      if (
        !Object.values(state.group[action.group].entities.priceBookCategories).some(
          m => m.id !== action.categoryId && m.partItemIds.some(n => n.id === action.id)
        )
      ) {
        delete partItems[action.id];
      }
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            partItems,
            priceBookCategories: {
              ...state.group[action.group].entities.priceBookCategories,
              [action.categoryId]: {
                ...state.group[action.group].entities.priceBookCategories[action.categoryId],
                partItemIds: state.group[action.group].entities.priceBookCategories[action.categoryId].partItemIds.filter(
                  m => m.id !== action.id
                ),
              },
            },
            priceBookCategoryPartItemIds: {
              ...state.group[action.group].entities.priceBookCategoryPartItemIds,
              [action.categoryId]: {
                updated: true,
              },
            },
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.saveSuccess, (state, action) => {
    // We don't replace the partItemIds or serviceRepairIds
    const newPriceBookCategories = { ...state.group[action.group].entities.priceBookCategories };
    const priceBookCategoriesArray = Object.values(newPriceBookCategories);
    const newPriceBookCategoryPartItemIds = { ...state.group[action.group].entities.priceBookCategoryPartItemIds };
    const newPriceBookCategoryServiceRepairIds = { ...state.group[action.group].entities.priceBookCategoryServiceRepairIds };
    for (const newCategory of flattenResource(action.data, true)) {
      if (newCategory.guid) {
        const existingCategory = priceBookCategoriesArray.find(n => n.guid === newCategory.guid) as ANY;

        // This if should always be true if we use the site properly.
        if (newPriceBookCategories[existingCategory.id]) {
          newPriceBookCategories[newCategory.id] = {
            ...newCategory,
            partItemIds: newPriceBookCategories[existingCategory.id].partItemIds,
            serviceRepairIds: newPriceBookCategories[existingCategory.id].serviceRepairIds,
          };
          delete newPriceBookCategories[existingCategory.id];

          newPriceBookCategoryPartItemIds[newCategory.id] = newPriceBookCategoryPartItemIds[existingCategory.id];
          delete newPriceBookCategoryPartItemIds[existingCategory.id];

          newPriceBookCategoryServiceRepairIds[newCategory.id] = newPriceBookCategoryServiceRepairIds[existingCategory.id];
          delete newPriceBookCategoryServiceRepairIds[existingCategory.id];
        }
      } else {
        const existingCategory = newPriceBookCategories[newCategory.id];
        if (existingCategory) {
          newPriceBookCategories[newCategory.id] = {
            ...newCategory,
            partItemIds: existingCategory.partItemIds,
            serviceRepairIds: existingCategory.serviceRepairIds,
          };
        }
      }
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            priceBookCategories: newPriceBookCategories,
            priceBookCategoryPartItemIds: newPriceBookCategoryPartItemIds,
            priceBookCategoryServiceRepairIds: newPriceBookCategoryServiceRepairIds,
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.saveServiceRepairSuccess, (state, action) => {
    const newServiceRepairs = { ...state.group[action.group].entities.serviceRepairs };
    newServiceRepairs[action.newId] = {
      ...newServiceRepairs[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newServiceRepairs[action.oldId];
    }

    const newPriceBookCategories = clone(state.group[action.group].entities.priceBookCategories);
    for (const newPriceBookCategory of Object.values(newPriceBookCategories)) {
      const pos = newPriceBookCategory.serviceRepairIds.findIndex(n => n.id === action.oldId);
      if (pos >= 0) {
        newPriceBookCategory.serviceRepairIds.splice(pos, 1, {
          id: action.newId,
          order: newPriceBookCategory.serviceRepairIds[pos].order,
        });
      }
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            serviceRepairs: newServiceRepairs,
            priceBookCategories: newPriceBookCategories,
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.savePartItemSuccess, (state, action) => {
    const newPartItems = { ...state.group[action.group].entities.partItems };
    newPartItems[action.newId] = {
      ...newPartItems[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newPartItems[action.oldId];
    }

    const newPriceBookCategories = clone(state.group[action.group].entities.priceBookCategories);
    for (const newPriceBookCategory of Object.values(newPriceBookCategories)) {
      const pos = newPriceBookCategory.partItemIds.findIndex(n => n.id === action.oldId);
      if (pos >= 0) {
        newPriceBookCategory.partItemIds.splice(pos, 1, { id: action.newId, order: newPriceBookCategory.partItemIds[pos].order });
      }
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            partItems: newPartItems,
            priceBookCategories: newPriceBookCategories,
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.savePartItemEmptySuccess, (state, action) => {
    const newPartItems = { ...state.group[action.group].entities.partItems };
    delete newPartItems[action.id];

    const newPriceBookCategories = clone(state.group[action.group].entities.priceBookCategories);
    for (const newPriceBookCategory of Object.values(newPriceBookCategories)) {
      const pos = newPriceBookCategory.partItemIds.findIndex(n => n.id === action.id);
      if (pos >= 0) {
        newPriceBookCategory.partItemIds.splice(pos, 1);
      }
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            partItems: newPartItems,
            priceBookCategories: newPriceBookCategories,
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.setSelections, (state, action) => {
    if (action.selections) {
      // We try to keep the order in which they were entered.
      const newObjects = action.selections.filter(
        m =>
          !state.group[action.group]?.selections?.find(
            n => n.serviceRepairId === m.serviceRepairId && arrayEquals(n.partItemIds, m.partItemIds)
          )
      );
      const availableObjects =
        state.group[action.group]?.selections?.filter(m =>
          action.selections.find(n => n.serviceRepairId === m.serviceRepairId && arrayEquals(n.partItemIds, m.partItemIds))
        ) || [];
      availableObjects.push(...newObjects);
      return {
        ...state,
        group: {
          ...state.group,
          [action.group]: {
            ...state.group[action.group],
            selections: clone(availableObjects),
          },
        },
      };
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          selections: [],
        },
      },
    };
  }),

  on(PriceBookDetailActions.setPartItemIdsSelections, (state, action) => {
    if (action.selections) {
      // We try to keep the order in which they were entered.
      let newSelections = [];
      const cloneActionSelections = action.selections.slice(0);

      const p = state.group[action.group]?.partItemIdSelections;
      if (p) {
        for (const partItemId of p) {
          const pos = cloneActionSelections.indexOf(partItemId);
          if (pos >= 0) {
            newSelections.push(partItemId);
            cloneActionSelections.splice(pos, 1);
          }
        }
      }

      // Add the rest
      newSelections = newSelections.concat(cloneActionSelections);

      return {
        ...state,
        group: {
          ...state.group,
          [action.group]: {
            ...state.group[action.group],
            partItemIdSelections: newSelections,
          },
        },
      };
    }

    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          partItemIdSelections: [],
        },
      },
    };
  }),

  // We have to mark the updated to false when we are about to send the request
  // otherwise, if we have 4 serviceRepair requests going out, but the one that changes the serviceRepairId from -1 to 1 (gets an id)
  // goes first, then all the other service repair will trigger this savePriceBookCategoryServiceRepair in the effects
  // By saying we are not updated anymore, it will prevent sending multiple.
  on(
    PriceBookDetailActions.savePriceBookCategoryServiceRepair,
    PriceBookDetailActions.savePriceBookCategoryServiceRepairSuccess,
    (state, action) => {
      return {
        ...state,
        group: {
          ...state.group,
          [action.group]: {
            ...state.group[action.group],
            entities: {
              ...state.group[action.group].entities,
              priceBookCategoryServiceRepairIds: {
                ...state.group[action.group].entities.priceBookCategoryServiceRepairIds,
                [action.id]: {
                  updated: false,
                },
              },
            },
          },
        },
      };
    }
  ),

  // Same as above, check the comment on ID why we set the updated to false on "non-success"
  on(PriceBookDetailActions.savePriceBookCategoryPartItem, PriceBookDetailActions.savePriceBookCategoryPartItemSuccess, (state, action) => {
    return {
      ...state,
      group: {
        ...state.group,
        [action.group]: {
          ...state.group[action.group],
          entities: {
            ...state.group[action.group].entities,
            priceBookCategoryPartItemIds: {
              ...state.group[action.group].entities.priceBookCategoryPartItemIds,
              [action.id]: {
                updated: false,
              },
            },
          },
        },
      },
    };
  }),

  on(PriceBookDetailActions.editMode, (state, action) => ({
    ...state,
    entitiesSnapshot: clone(state.group[action.group].entities),
    edit: true,
  })),
  on(PriceBookDetailActions.cancel, (state, action) => ({
    ...state,
    group: {
      ...state.group,
      [action.group]: {
        ...state.group[action.group],
        entities: state.entitiesSnapshot,
      },
    } as ANY,
    entitiesSnapshot: null,
    edit: false,
  }))
);

function arrayEquals(array1: number[], array2: number[]): boolean {
  return (
    array1.length === array2.length &&
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    array1.sort().every(function (value, index) {
      // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
      return value === array2.sort()[index];
    })
  );
}

export function reducer(state: PriceBookDetailState | undefined, action: Action) {
  return priceBookDetailReducer(state, action);
}
