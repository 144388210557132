import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ButtonComponent } from '@controls/button/button.component';
import { PagerComponent } from '@controls/pager/pager.component';
import { TableActionComponent } from '@controls/table-action/table-action.component';
import { TableComponent } from '@controls/table/table.component';
import { ColumnDynamicComponent } from '@dialogviews/filters/column-dynamic.component';
import { FilterDynamicComponent } from '@dialogviews/filters/filter-dynamic.component';
import { SegmentDynamicComponent } from '@dialogviews/filters/segment-dynamic.component';
import { DIALOG_SERVICE_IMPL, DialogType, buttons } from '@models/dialog';
import { type TableColumnDefinition } from '@models/filter-models';
import { type SelfClickableResource } from '@models/resource';
import { defaultFilterTableData, type FilterTable, type FilterTable2 } from '@services/pager.service';
import { normalizeArray } from '@utility/array';
import { filter, switchMap } from 'rxjs';

export interface NoResult {
  url?: string;
  text?: string;
  createText?: string;
}

// Angular 18
@Component({
  selector: 'wm-table-dynamic',
  templateUrl: 'table-dynamic.component.html',
  styleUrls: ['table-dynamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TableComponent,
    PagerComponent,
    FilterDynamicComponent,
    ColumnDynamicComponent,
    TableActionComponent,
    SegmentDynamicComponent,
  ],
})
export class TableDynamicComponent<T> {
  readonly normalizeArray = normalizeArray;

  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);

  /** @deprecated */
  readonly tableDeprecated = input<FilterTable<T>>(); // .required
  readonly table = input<FilterTable2<T>>(); // .required

  readonly asPageTitle = input(false);
  readonly printPage = input<string>();
  readonly helpPage = input<string>();
  readonly helpSection = input<string>();

  /** @deprecated */
  readonly columns = input<TableColumnDefinition<T>[]>();
  /** @deprecated */
  readonly isLoading = input(false);

  /** @deprecated */
  readonly tableData = toSignal(
    toObservable(this.tableDeprecated).pipe(
      filter(x => !!x),
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-non-null-assertion
      switchMap(x => x!.data$)
    ),
    {
      initialValue: defaultFilterTableData<T>(),
    }
  );

  /** @deprecated */
  readonly betaFeatureOn = input(false);

  readonly disablePadding = input(false);
  readonly includeColgroup = input(true);
  readonly isFixedLayout = input(false);
  readonly rowSelection = input(false);
  readonly disableAllRowSelection = input(false);
  readonly rowDelete = input(false);
  readonly rowAdd = input(false);
  readonly errors = input(false);
  readonly withFooter = input(false);
  readonly withHeader = input(false);
  readonly withThead = input(true);
  readonly removeText = input('Remove');
  readonly rowSelectionRowSpan = input<(row: T) => number>(() => 1);
  readonly selectionModel = input<Record<number, boolean>>({});
  readonly rowAddText = input<string | undefined>();
  readonly customRowClassFnc = input<((item: T) => string) | undefined>();
  readonly draggable = input(false);
  readonly orderable = input(false);
  readonly showTotalCount = input(false);
  readonly noResult = input<NoResult>();
  readonly shouldIncludeRowSelection = input<(item: T) => boolean>(() => true);
  readonly isRowSelectionDisabled = input<(item: T) => boolean>(() => false);
  readonly shouldIncludeRowDelete = input<(item: T) => boolean>(() => true);
  readonly shouldShowCheckbox = input<(item: T) => boolean>(() => true);
  readonly isRowDeleteDisabled = input<(item: T) => boolean>(() => false);
  readonly getRowTestId = input<(item: T) => string>(() => '');
  readonly idTracking = input<(item: T) => number>(item => (this.isRowResource(item) && item.id) || 0);

  readonly rowAddClick = output<void>();
  readonly rowDeleteClick = output<T>();
  readonly rowUpdated = output<T>();
  readonly create = output<void>();

  readonly tableActions = computed(() => {
    const table = this.table();
    if (table) {
      const exportEntry = table.export
        ? [
            {
              id: 0,
              text: 'Export',
              click: () => {
                table.export?.execute();
              },
            } satisfies SelfClickableResource,
          ]
        : [];
      const deleteSegmentEntry =
        (table.segments?.requested() ?? 0) > 0
          ? [
              {
                id: 1,
                text: 'Delete List',
                click: () => {
                  this.deleteSegment();
                },
              } satisfies SelfClickableResource,
            ]
          : [];

      return [...exportEntry, ...deleteSegmentEntry];
    }

    return [];
  });

  readonly displayColumns = computed(() => {
    const c1 = this.table()?.columns.requested();
    const c2 = this.table()?.columns.available();
    return (
      c1?.filter(function (n) {
        return c2?.includes(n);
      }) ?? []
    );
  });

  private isRowResource(row: any): row is { id: Id } {
    return !!(row as { id?: Id }).id;
  }

  private deleteSegment(): void {
    const dialogRef = this.dialog2.confirm<boolean>({
      confirm: {
        title: 'Delete List?',
        type: DialogType.Warning,
        text: 'Are you sure you want to this delete this current list?',
        buttons: buttons.confirmButtons({
          withCancel: true,
          withDelete: true,
        }),
      },
    });

    dialogRef.afterClosed().subscribe(m => {
      if (m) {
        const table = this.table();
        table?.segments?.remove().subscribe();
      }
    });
  }

  normalizeColumn(
    tableColumnDefinition: TableColumnDefinition<unknown>,
    results: T[]
  ): { id: string; text: string; classes?: string[]; dataTestId?: string }[] {
    if (tableColumnDefinition.getColumns) {
      return tableColumnDefinition.getColumns(results).map(({ id, text, classes, dataTestId }, index) => {
        return {
          id: id ?? `${tableColumnDefinition.id}-${index}`,
          text: text ?? tableColumnDefinition.text,
          classes: classes ?? tableColumnDefinition.classes,
          dataTestId: dataTestId ?? tableColumnDefinition.dataTestId,
        };
      });
    }

    return [tableColumnDefinition];
  }
}
