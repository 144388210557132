import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type ExtendedAgreementInformation } from '@models/agreement';
import { type AgreementSiteSystemInformationForList } from '@models/cards/agreement-site-system-information';
import { type CallInformationForList } from '@models/cards/call-information';
import { type CustomerInformation, type CustomerInformationForList } from '@models/cards/customer-information';
import { type LastVisitNoteInformation } from '@models/cards/last-visit-information';
import { type SiteInformation, type SiteInformationForList } from '@models/cards/site-information';
import { type WorkOrderInformationForList } from '@models/cards/work-order-information';
import { type DutyInformationForList } from '@models/duty-models';
import { type supportedCustomerColumns, type supportedCustomerFilterTypes } from '@models/filter-customer-models';
import {
  type DefaultTableSegment,
  StatusFilter,
  type Filter2,
  type SupportedColumns,
  type SupportedFilters,
  type TableQueryResult,
  type TableSegment,
} from '@models/filter-models';
import { type WorkOrderLoanApplicationInformation } from '@models/loans';
import { type SearchResult, type SortModel } from '@models/result';
import { type SalesProposalInformationForList } from '@models/sales-proposal-models';
import { type FileInformation } from '@models/upload';
import { type ExportToFileResponse } from '@services/pager.service';
import { allowRetries, skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

export interface CustomerSiteSearchResult {
  customers: SearchResult<CustomerInformation>[];
  sites: SearchResult<SiteInformation>[];
}

export type PossibleFilter = SupportedFilters<typeof supportedCustomerFilterTypes>;
export type PossibleColumns = SupportedColumns<typeof supportedCustomerColumns>;

@Injectable()
export class CustomersService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  tableList(filter: Filter2<PossibleFilter, PossibleColumns>): Observable<TableQueryResult<CustomerInformationForList>> {
    return this.http.post<TableQueryResult<CustomerInformationForList>>(this.url.customerTableList, filter, allowRetries());
  }

  exportToFile(filter: Filter2<PossibleFilter, PossibleColumns>): Observable<ExportToFileResponse> {
    return this.http.post<ExportToFileResponse>(this.url.customerExport, filter);
  }

  getTableSegments(): Observable<TableSegment<PossibleFilter, PossibleColumns>[]> {
    return this.http.get<TableSegment<PossibleFilter, PossibleColumns>[]>(this.url.customerGetTableSegments);
  }

  saveTableSegment(tableSegment: TableSegment<PossibleFilter, PossibleColumns>): Observable<Id> {
    return this.http.post<Id>(this.url.customerSaveTableSegment, tableSegment, allowRetries());
  }

  deleteTableSegment(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.customerDeleteTableSegment.replace('$0', id.toString()));
  }

  getDefaultTableSegments(): Observable<DefaultTableSegment<PossibleFilter>[]> {
    return this.http.get<DefaultTableSegment<PossibleFilter>[]>(this.url.customerDefaultTableSegments);
  }

  get(id: Id): Observable<CustomerInformation> {
    return this.http.get<CustomerInformation>(this.url.customerSingle.replace('$0', id.toString()));
  }

  getSites(id: Id): Observable<SiteInformationForList[]> {
    return this.http.get<SiteInformationForList[]>(this.url.customerSites.replace('$0', id.toString()));
  }

  getWorkOrders(id: Id): Observable<WorkOrderInformationForList[]> {
    return this.http.get<WorkOrderInformationForList[]>(this.url.customerWorkOrders.replace('$0', id.toString()));
  }

  getSalesProposal(id: Id): Observable<SalesProposalInformationForList[]> {
    return this.http.get<SalesProposalInformationForList[]>(this.url.customerSalesProposals.replace('$0', id.toString()));
  }

  getCalls(id: Id): Observable<CallInformationForList[]> {
    return this.http.get<CallInformationForList[]>(this.url.customerCalls.replace('$0', id.toString()));
  }

  getDuties(id: Id): Observable<DutyInformationForList[]> {
    return this.http.get<DutyInformationForList[]>(this.url.customerDuties.replace('$0', id.toString()));
  }

  getFiles(id: Id): Observable<FileInformation[]> {
    return this.http.get<FileInformation[]>(this.url.customerListFiles.replace('$0', id.toString()));
  }

  getLoanApplications(id: Id): Observable<WorkOrderLoanApplicationInformation[]> {
    return this.http.get<WorkOrderLoanApplicationInformation[]>(this.url.customerLoanApplications.replace('$0', id.toString()));
  }

  addFile(id: Id, fileId: Id): Observable<void> {
    return this.http.post<void>(this.url.customerAddFile.replace('$0', id.toString()).replace('$1', fileId.toString()), null);
  }

  deleteFile(id: Id, fileId: Id): Observable<void> {
    return this.http.delete<void>(this.url.customerDeleteFile.replace('$0', id.toString()).replace('$1', fileId.toString()));
  }

  deleteFiles(id: Id, fileIds: Id[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: fileIds,
    };

    return this.http.delete<void>(this.url.customerDeleteFiles.replace('$0', id.toString()), options);
  }

  save(customerInformation: CustomerInformation): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(customerInformation, 'quickBooksStatus', 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.customerSave, obj);
  }

  patch(id: Id, obj: Partial<CustomerInformation>): Observable<void> {
    obj = this.http.setEmptyStringToNull(obj);

    return this.http.patch<void>(this.url.customerPatch.replace('$0', id.toString()), obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.customerDelete.replace('$0', id.toString()), skipBubbleError());
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.customerRestore.replace('$0', id.toString()), null);
  }

  search(
    search: string,
    status: StatusFilter = StatusFilter.Visible,
    withHighlight: boolean = false
  ): Observable<CustomerSiteSearchResult> {
    return this.http.post<CustomerSiteSearchResult>(
      this.url.customerSearch,
      {
        search,
        status,
        withHighlight,
      },
      allowRetries()
    );
  }

  searchWithSites(
    search: string,
    sort: SortModel | null = null,
    start: number | null = null,
    status: StatusFilter = StatusFilter.Visible,
    withHighlight: boolean = false
  ): Observable<CustomerSiteSearchResult> {
    return this.http.post<CustomerSiteSearchResult>(
      this.url.customerSearchWithSites,
      {
        search,
        sort,
        start,
        status,
        withHighlight,
      },
      allowRetries()
    );
  }

  getLastVisits(id: Id, skip: number): Observable<LastVisitNoteInformation[]> {
    return this.http.get<LastVisitNoteInformation[]>(
      this.url.customerLastVisits.replace('$0', id.toString()).replace('$1', skip.toString())
    );
  }

  getExtendedAgreements(id: Id): Observable<ExtendedAgreementInformation[]> {
    return this.http.get<ExtendedAgreementInformation[]>(this.url.customerExtendedAgreements.replace('$0', id.toString()));
  }

  getAgreements(id: Id): Observable<AgreementSiteSystemInformationForList[]> {
    return this.http.get<AgreementSiteSystemInformationForList[]>(this.url.customerAgreements.replace('$0', id.toString()));
  }
}

