import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { mergeWith } from 'lodash';
import { type Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { getWindow } from 'ssr-window';

export interface IntercomConfig {
  appId: string;
}

export interface AppInsightConfig {
  apiKey: string;
}

export interface ExternalServiceConfig {
  initializeUrl: string;
  linkUrl: string;
  unlinkUrl: string;
}

export interface SquareConfig {
  appId: string;
  paymentFormUrl: string;
  transactionUrl: string;
}

export interface StripeConfig {
  offline: boolean;
  publishableKey: string;
  dashboardUrl: string;
}

export interface QuickBooksConfig {
  onlineUrl: string;
}

export interface SignalRConfig {
  serverPushUrl: string;
  notificationUrl: string;
}

export interface BingConfig {
  apiKey: string;
}

export interface GoogleConfig {
  apiKey: string;
}

export interface ThreeCConfig {
  cypressLoginUrl?: string;

  appUrl: string;
  apiUrl: string;
  sandboxUrl: string;
  smsShortLinkUrl: string;
  assetsUrl: string;
  authUrl: string;
  intercom: IntercomConfig;
  appInsights: AppInsightConfig;
  externalServices: ExternalServiceConfig;
  square: SquareConfig;
  stripe: StripeConfig;
  quickBooks: QuickBooksConfig;
  signalR: SignalRConfig;
  bing: BingConfig;
  google: GoogleConfig;
  environment: string;
  allowedOrigins: string[];
}

interface ThreeCConfigResponse {
  // For local dev or override
  apiUrl?: string;
  cypressLoginUrl?: string;
  appUrl?: string;
  sandboxUrl?: string;
  servicesUrl?: string;
  allowedOrigins?: string[];
  $sandbox?: any;

  authUrl: string;
  environment: string;
  appHost: string;
  apiHost: string;
  servicesApiHost: string;
  smsShortLinkHost: string;
  sandboxAppHost: string;
  loginPath: string;
  assetsUrl: string;
  intercom: IntercomConfig;
  appInsights: AppInsightConfig;
  externalServices: {
    initializePath: string;
    linkPath: string;
    unlinkPath: string;
  };
  square: SquareConfig;
  stripe: StripeConfig;
  quickBooks: {
    onlinePath: string;
  };
  signalR: {
    serverPushPath: string;
    notificationPath: string;
  };
  bing: BingConfig;
  google: GoogleConfig;
}

const mapConfig = ({ $sandbox, ...config }: ThreeCConfigResponse): ThreeCConfig => {
  // Only used in local
  if (environment.sandbox) {
    config = mergeWith(config, $sandbox ?? {});
  }

  let {
    appHost,
    apiHost,
    servicesApiHost,
    sandboxAppHost,
    smsShortLinkHost,
    appUrl,
    apiUrl,
    sandboxUrl,
    servicesUrl,
    allowedOrigins,
    authUrl,
  } = config;

  appUrl ||= `https://${appHost}`;
  apiUrl ||= `https://${apiHost}`;
  sandboxUrl ||= `https://${sandboxAppHost}`;
  servicesUrl ||= `https://${servicesApiHost}`;

  const smsShortLinkUrl = `https://${smsShortLinkHost}`;

  return {
    apiUrl,
    cypressLoginUrl: config.cypressLoginUrl,
    environment: config.environment,
    authUrl,
    appUrl,
    sandboxUrl,
    smsShortLinkUrl,
    allowedOrigins: [apiUrl, servicesUrl, getWindow().location.origin, ...(allowedOrigins ?? [])],
    assetsUrl: config.assetsUrl,
    intercom: config.intercom,
    appInsights: config.appInsights,
    externalServices: {
      initializeUrl: servicesUrl + config.externalServices.initializePath,
      linkUrl: servicesUrl + config.externalServices.linkPath,
      unlinkUrl: servicesUrl + config.externalServices.unlinkPath,
    },
    square: config.square,
    stripe: config.stripe,
    quickBooks: {
      onlineUrl: servicesUrl + config.quickBooks.onlinePath,
    },
    signalR: {
      serverPushUrl: servicesUrl + config.signalR.serverPushPath,
      notificationUrl: servicesUrl + config.signalR.notificationPath,
    },
    bing: config.bing,
    google: config.google,
  };
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly http = inject(HttpClient);

  config!: ThreeCConfig; // This is loaded very early in Angular.

  private request$: Observable<ThreeCConfig> | null = null;

  loadConfig(): Observable<ThreeCConfig> {
    if (!this.request$) {
      this.request$ = this.http.get<ThreeCConfigResponse>('/assets/config.json').pipe(
        map(mapConfig),
        tap(config => {
          this.config = Object.freeze(config);
        }),
        shareReplay(1)
      );
    }

    return this.request$;
  }
}

