import { type OptionalTextResource, type OptionalTextResourceWithChildren } from '@models/resource';
import { difference as lodashDifference, union as lodashUnion } from 'lodash';

export function groupBy<T>(xs: T[], key: keyof T): Record<string, T[]> {
  return xs.reduce(function (rv: any, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function indexBy<T, K extends keyof T>(array: T[], key: K): Record<string, T>;
export function indexBy<T, K extends keyof T, V>(array: T[], key: K, map?: (obj: T) => V): Record<string, V>;
export function indexBy<T, K extends keyof T, V>(array: T[], key: K, map?: (obj: T) => V): Record<string, T | V> {
  const results: any = {};
  (array || []).forEach(function (object) {
    results[object[key]] = map ? map(object) : object;
  });
  return results;
}

export function pluck<T>(array: T[], key: keyof T): any {
  return array.map(function (object: T) {
    return object[key];
  });
}

export function unique<T>(array: T[]): T[] {
  function onlyUnique(value: T, index: number, self: T[]): boolean {
    return self.indexOf(value) === index;
  }

  return array.filter(onlyUnique);
}

export function uniqueInPlace<T>(arr: T[]): void {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i] === arr[j]) {
        arr.splice(j, 1);
        j--; // Adjust the index after removal
      }
    }
  }
}

export const union = lodashUnion;

export const difference = lodashDifference;

export function swap(json: any): any {
  const ret: any = {};

  for (const key in json) {
    ret[json[key]] = key;
  }

  return ret;
}

export function sortByOrder<T extends { order: number }>(a: T, b: T): number {
  const order1 = a.order ?? 0;
  const order2 = b.order ?? 0;
  if (order1 < order2) {
    return -1;
  } else if (order1 > order2) {
    return 1;
  }

  return 0;
}

// Used when ordering recursive objects. Aka categories and subcategories.
export function sortByOrderBucket<T extends { order: number } & { orderBucket?: number[] }>(a: T, b: T): number {
  if (!a.orderBucket || !b.orderBucket) {
    return sortByOrder(a, b);
  }

  for (let i = 0; i < a.orderBucket.length; i++) {
    if ((a.orderBucket[i] ?? 0) < (b.orderBucket[i] ?? 0)) {
      return -1;
    } else if ((a.orderBucket[i] ?? 0) > (b.orderBucket[i] ?? 0)) {
      return 1;
    }
  }

  if (a.orderBucket.length < b.orderBucket.length) {
    return -1;
  } else if (a.orderBucket.length > b.orderBucket.length) {
    return 1;
  }

  return 0;
}

export function sortByText<T extends { text?: string }>(a: T, b: T): number {
  const aText = a.text ? a.text.toLowerCase() : '';
  const bText = b.text ? b.text.toLowerCase() : '';

  if (aText < bText) {
    return -1;
  } else if (aText > bText) {
    return 1;
  }

  return 0;
}

const isOrderedResource = (resource: { order?: number } | null): resource is { order: number } => {
  return typeof (resource as { order?: number } | null)?.['order'] === 'number';
};

export function sort<U extends { text?: string } | { order?: number }>(obj: U[], sortFnc?: (a: U, b: U) => number): U[] {
  let sortMethod = sortFnc;
  if (!sortMethod && obj && isOrderedResource(obj[0] as { order: number })) {
    sortMethod = sortByOrder as any;
  } else if (!sortMethod) {
    sortMethod = sortByText as any;
  }

  return obj.sort(sortMethod);
}

export function sortWithChildren<T extends OptionalTextResource>(
  m: OptionalTextResourceWithChildren<T>[],
  sortFnc?: (a: T, b: T) => number
): OptionalTextResourceWithChildren<T>[] {
  sort(m);
  for (const item of m) {
    // Typescript is mean... this is valid
    if (item.children) {
      item.children = sort(item.children, sortFnc);
    }
  }

  return m;
}

export function replaceInArray<T extends { id: number }>(target: T[], withNew: T[]): void {
  for (const n of withNew) {
    const index = target.findIndex(m => m.id === n.id);
    if (index >= 0) {
      target.splice(index, 1, n);
    } else {
      target.push(n);
    }
  }
}

export function flattenResource<T extends { id: number; children?: T[] | null }>(objs: T[], deleteChildren: boolean = false): T[] {
  const final: T[] = [];
  function internalFlatten(internalObjs: T[]): void {
    if (internalObjs) {
      for (const obj of internalObjs) {
        final.push(obj);
        if (obj.children) {
          internalFlatten(obj.children);

          if (deleteChildren) {
            delete (obj as Partial<{ children: T[] }>).children;
          }
        }
      }
    }
  }

  internalFlatten(objs);
  return final;
}

export function flattenArray<T>(arr: T[][]): T[] {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flattenArray(toFlatten as any as T[][]) : toFlatten);
  }, []);
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function normalizeArray<T>(value: T | T[]): T[] {
  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}

export function findOrFail<T, S extends T>(array: T[], predicate: (value: T, index: number, obj: T[]) => value is S, message?: string): S;
export function findOrFail<T>(array: T[], predicate: (value: T, index: number, obj: T[]) => unknown, message?: string): T;
export function findOrFail<T>(array: T[], predicate: (value: T, index: number, obj: T[]) => unknown, message?: string): T {
  const result = array.find(predicate);
  if (!result) {
    throw new Error(message ?? "We couldn't find the object in the array.");
  }

  return result;
}
