<button
  wm-button
  type="button"
  class="filter-button secondary"
  (click)="textClick.emit()"
  (focus)="textHasFocus.set(true)"
  (blur)="removeFocusInAMoment()"
  [matIcon]="matIcon()"
  [imageUrl]="imageUrl()"
  [textMaxWidth]="200"
  [title]="text()"
>
  {{ text() }}
</button>

<button
  #b
  class="remove"
  type="button"
  (focus)="removeIsFocused.set(b)"
  (blur)="removeIsFocused.set(undefined)"
  (mouseenter)="removeIsHovered.set(b)"
  (mouseleave)="removeIsHovered.set(undefined)"
  (click)="removeClick.emit()"
>
  <img src="/assets/images/tag-delete.svg" alt="" />
</button>
