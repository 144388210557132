import { Injectable, inject } from '@angular/core';
import { type SettingLoanSettingsViewData } from '@models/cards/setting-view-data';
import { type LoanCompany, type LoanCreate, type LoanRateInformation, type WorkOrderLoanApplicationInformation } from '@models/loans';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { allowRetries, skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class LoansService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  getLoanRates(): Observable<LoanRateInformation[]> {
    return this.applicationCacheService.get<SettingLoanSettingsViewData>(this.url.loanRatesUrl).pipe(map(m => m.loanRates));
  }

  getApplicationsForWorkOrder(workOrderId: Id): Observable<any[]> {
    return this.http.get<any[]>(this.url.loansGetApplicationsForWorkOrder.replace('$0', workOrderId.toString()));
  }

  getApplicationsForCustomer(customerId: Id): Observable<any[]> {
    return this.http.get<any[]>(this.url.loansGetApplicationsForCustomer.replace('$0', customerId.toString()));
  }

  // TODO this does not hit the cache.
  getSettings(): Observable<SettingLoanSettingsViewData> {
    return this.http.get<SettingLoanSettingsViewData>(this.url.loansGetSettings);
  }

  saveSettings(settings: SettingLoanSettingsViewData): Observable<void> {
    return this.http.post<void>(this.url.loansSaveSettings, { ...settings }).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  searchCode(loanCompany: LoanCompany, loanCode: string): Observable<LoanRateInformation> {
    return this.http.post<LoanRateInformation>(
      this.url.loanCodeSearch,
      {
        loanCode,
        loanCompany,
      },
      allowRetries()
    );
  }

  save(createModel: LoanCreate): Observable<WorkOrderLoanApplicationInformation> {
    return this.http.post<WorkOrderLoanApplicationInformation>(this.url.loanCreate, { ...createModel }, skipBubbleError());
  }

  hideWorkOrderLoanApplication(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.loanDeleteWorkOrderApplicationId.replace('$0', id.toString()));
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.LoanRates);
    }
  }
}
