@if (betaFeatureOn()) {
  @if (table(); as table) {
    <wm-table-dynamic
      [betaFeatureOn]="true"
      [table]="table"
      [withFooter]="true"
      [noResult]="noResult"
      [asPageTitle]="asPageTitle()"
      [printPage]="printPage()"
      [helpPage]="helpPage()"
      [helpSection]="helpSection()"
    >
      <div title-projection>
        <ng-content select="[title-projection]" />
      </div>
    </wm-table-dynamic>
  }
} @else {
  @if (tableDeprecated(); as tableDeprecated) {
    <div>
      <wm-table-action
        [class.as-page-title]="asPageTitle()"
        [filterHelper]="tableData().filterHelper"
        [showDeleted]="showDeletedInCog() && hasDeletedEntries()"
        [showDeletedTitle]="(showDeleted() ? 'Hide' : 'Show') + ' Deleted'"
        (showDeletedClick)="showDeleted.set(!showDeleted())"
        [printPage]="printPage()"
        [helpPage]="helpPage()"
        [helpSection]="helpSection()"
      >
        <div class="title">
          <ng-content select="[deprecated-title-projection]" />
        </div>
      </wm-table-action>

      @if (!tableData().pagedResult.count && (tableData().filterHelper.isLoading | async) !== true) {
        <div class="no-entry">
          <div class="robot big">
            <img src="/assets/images/assets/no-agreements.svg" alt="" />
          </div>
          <div class="text">We didn't find any agreements.</div>
        </div>
      } @else {
        <div class="wm-table" [class.with-footer]="tableData().pagedResult.count">
          <div class="loading" *ngIf="tableData().filterHelper.isLoading | async">
            <span class="spinner"></span>
          </div>
          <table [hidden]="!tableData().pagedResult.results.length" wm-table>
            <colgroup>
              <col class="col col-date" />
              <col class="col col-type" />
              <col class="col col-smallest" />
              <ng-container *ngIf="displaySiteSystemData$ | async">
                <col class="col col-location" />
              </ng-container>
              <col *ngIf="hasConnectionType(ConnectionType.Customer)" class="col col-client" />
              <col *ngIf="hasConnectionType(ConnectionType.Site)" class="col col-client" />
              <ng-container *ngIf="displaySiteSystemData$ | async">
                <col *ngIf="hasConnectionType(ConnectionType.Equipment)" class="col col-client" />
              </ng-container>
              <col class="col col-agreement" />
              <col class="col col-remaining" />
              <col class="col col-date" />
              <col *ngIf="showDeleted()" class="col col-status" />
              <col class="col col-visits col-100" />
            </colgroup>
            <thead>
              <tr>
                <th>Next Tune-Up</th>
                <th>Name/Type</th>
                <th>Status</th>
                <ng-container *ngIf="displaySiteSystemData$ | async">
                  <th>Location</th>
                </ng-container>
                <th *ngIf="hasConnectionType(ConnectionType.Customer)">Customer</th>
                <th *ngIf="hasConnectionType(ConnectionType.Site)">Site</th>
                <ng-container *ngIf="displaySiteSystemData$ | async">
                  <th *ngIf="hasConnectionType(ConnectionType.Equipment)">{{ siteSystemBehavior$ | async | siteSystemWording }}</th>
                </ng-container>
                <th>Agreement</th>
                <th>Remaining Tune-Ups</th>
                <th>Expiration</th>
                <th *ngIf="showDeleted()">Status</th>
                <th>Visits</th>
              </tr>
            </thead>
            <tbody>
              @for (line of safeResults(); track line.data.id) {
                <tr [wm-row-select]="line">
                  <td [class.important]="isSetupReady(line.data) || isDeleted(line.data)" data-testid="agreement-name">
                    <a *ngIf="isNavigatable(line.data)" [routerLink]="['/agreements', line.data.id]">
                      <ng-template [ngIf]="isADate(getAgreementLinkName(line))">
                        {{ getAgreementLinkName(line) | date: 'shortDate' }}
                      </ng-template>
                      <ng-template [ngIf]="!isADate(getAgreementLinkName(line))">
                        {{ getAgreementLinkName(line) }}
                      </ng-template>
                    </a>
                    <div *ngIf="!isNavigatable(line.data)">
                      <span>Not Ready.</span>
                      <div>
                        Activate by marking as Processed:
                        <a [routerLink]="['/workorders', line.data.workOrderId]">Go to Work Order</a>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ line.siteSystemType }}
                  </td>
                  <td class="icon-cell">
                    <ul class="icons">
                      <li class="icon-notes" [class.visible]="line.data.notes">
                        <span
                          class="icon-notes"
                          data-id="icon-notes"
                          #notes
                          *ngIf="line.data.notes"
                          (mouseenter)="showHelp(getTruncatedNotes(line.data.notes), notes, true)"
                          (mouseleave)="hideHelp(notes.dataset.id)"
                        >
                          <img src="/assets/images/dashboard-card-notes.svg" alt="" />
                        </span>
                      </li>
                      <li
                        class="icon-unprocessed"
                        data-id="icon-processed"
                        #unprocessed
                        [class.visible]="!line.data.processed"
                        (mouseenter)="showHelp('Unprocessed', unprocessed)"
                        (mouseleave)="hideHelp(unprocessed.dataset.id)"
                      >
                        <img src="/assets/images/status-deleted.svg" alt="" />
                      </li>
                    </ul>
                  </td>
                  <ng-container *ngIf="displaySiteSystemData$ | async">
                    <td>
                      {{ line.locations && line.locations.join(', ') }}
                    </td>
                  </ng-container>
                  <td *ngIf="hasConnectionType(ConnectionType.Customer)">
                    <a [routerLink]="['/customers', line.customerId]">{{ line.customerEntity | entity }}</a>
                  </td>
                  <td *ngIf="hasConnectionType(ConnectionType.Site)">
                    <a [routerLink]="['/customers', line.customerId, 'sites', line.siteId]">
                      <ng-container *ngIf="line.siteAddress | address as address; else noAddress">
                        {{ address }}
                      </ng-container>
                      <ng-template #noAddress>
                        <span class="no-address">No Address</span>
                      </ng-template>
                    </a>
                  </td>
                  <ng-container *ngIf="displaySiteSystemData$ | async">
                    <td *ngIf="hasConnectionType(ConnectionType.Equipment)">
                      <a [routerLink]="['/customers', line.customerId, 'sites', line.siteId, 'equipments', line.data.siteSystemId]"
                        >{{ line.siteSystemType }} #{{ line.siteSystemLocalId }}</a
                      >
                    </td>
                  </ng-container>
                  <td>
                    {{ getAgreementName$(line.data) | async }}
                  </td>
                  <td>
                    <ng-template [ngIf]="getVisitLeft(line) !== null">
                      <span class="status-dot">
                        <img src="{{ getAgreementBadgeUrl(line) }}" alt="" title="{{ getAgreementBadgeTitle(line) }}" />
                      </span>
                      {{ getVisitLeft(line) }} left
                    </ng-template>
                  </td>
                  <td>
                    <div *ngIf="isRecurring$(line) | async">Recurring</div>
                    <div *ngIf="!(isRecurring$(line) | asyncSafe: false)">
                      {{ getEndDate$(line) | async | date: 'shortDate' }}
                      <img
                        *ngIf="line.data.endDate !== null"
                        class="small-icon"
                        src="/assets/images/agreement-edited.svg"
                        alt=""
                        title="The badge expires has been manually changed."
                      />
                    </div>
                    <div class="benefits" *ngIf="line.data.deadDate">Badge Expires: {{ line.data.deadDate | date: 'shortDate' }}</div>
                  </td>
                  <td *ngIf="showDeleted()" [innerHTML]="line.data.hidden | deleted"></td>
                  <td>
                    <span *ngIf="!line.agreementVisits?.length">None</span>
                    <button
                      type="button"
                      class="as-link"
                      *ngIf="!!line.agreementVisits?.length"
                      (click)="$event.stopPropagation(); showingDuties[line.data.id] = !showingDuties[line.data.id]"
                    >
                      <span *ngIf="!showingDuties[line.data.id]"
                        >Show
                        <img class="tiny-icon" src="/assets/images/arrow-show-agrmt.svg" alt="" />
                      </span>
                      <span *ngIf="showingDuties[line.data.id]"
                        >Hide
                        <img class="tiny-icon" src="/assets/images/arrow-hide-agrmt.svg" alt="" />
                      </span>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="showingDuties[line.data.id]">
                  <td class="expanded" [colSpan]="colspan()" class="agreement-visits">
                    <ul>
                      <li *ngFor="let agreementVisit of line.agreementVisits" class="badge">
                        <span class="status">{{ getDutyCompletionText(agreementVisit.status) }}</span>
                        <a [routerLink]="['/tasks', agreementVisit.id]" class="date">
                          <span *ngIf="agreementVisit.dueDate">{{ agreementVisit.dueDate | date: 'shortDate' }} </span>
                          <span *ngIf="!agreementVisit.dueDate">No Due Date</span>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              }
            </tbody>
          </table>

          <div wm-table-footer *ngIf="tableData().pagedResult.results.length">
            <div wm-table-pager class="wm-pager" *ngIf="tableData().showTable">
              <wm-pager
                [showTotalCount]="showTotalCount()"
                [pagedResult]="tableData().pagedResult"
                (currentPageChange)="tableDeprecated.refreshResults($event)"
              ></wm-pager>
            </div>
          </div>
        </div>
      }
    </div>
  }
}

