import { type ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { DIALOG_SERVICE_IMPL } from '@models/dialog';
import { type DialogFilter2Impl } from '@models/dialog-models';
import {
  Direction,
  FilterAction,
  getNormalizedUIConfigs,
  type DynamicFilterContext,
  type DynamicFilterHelper,
  type FancyFilterConfig,
  type FancyFilterImpl,
  type FilterBy,
  type FilterByRule,
  type FilterConfig,
  type FilterInfo,
  type ListOrderBy,
  type ListParameters,
  type PaginatedList,
  type SupportedFilters,
} from '@models/filter-models';
import { allowRetries } from '@utility/angular';
import { notEmpty } from '@utility/array';
import { BehaviorSubject, Subject, combineLatest, of, throwError, type Observable } from 'rxjs';
import { concatMap, filter, first, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { HttpClientService } from './http-client.service';
import { TechnicianSettingsService, type TechnicianSetting } from './live/technician-settings.service';

/** @deprecated */
@Injectable()
export class DynamicFilterService {
  private readonly http = inject(HttpClientService);
  private readonly technicianSettingsService = inject(TechnicianSettingsService);
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);

  register<FILTER_DATA, RESULT_DATA>(
    url: string,
    filterSettingKey: TechnicianSetting | null = null,
    filterOpenKey: TechnicianSetting | null = null
  ): DynamicFilterHelper<FILTER_DATA, RESULT_DATA> {
    const dynamicFilterContext$ = this.restoreFilterContext<FILTER_DATA>(filterSettingKey as ANY, filterOpenKey as ANY).pipe(
      shareReplay(1)
    );

    const requestedOpened$ = new BehaviorSubject<boolean>(null as ANY);
    const cacheBuster$ = new BehaviorSubject<number>(1);
    const defaultParameters$ = new BehaviorSubject<ListParameters<FILTER_DATA> | null>(null);
    const requestedListParameters$ = new BehaviorSubject<ListParameters<FILTER_DATA> | null>(null);
    const listParameters$ = combineLatest([
      defaultParameters$,
      dynamicFilterContext$.pipe(
        map(
          ({ orderBys, filterBy: filterBys }) =>
            ({
              filterBys,
              orderBys,
              pageNumber: 1,
              pageSize: 100,
            }) as ListParameters<FILTER_DATA>
        )
      ),
      requestedListParameters$,
      cacheBuster$,
    ]).pipe(
      map(([o0, o1, o2]) => ({
        ...o0,
        ...o1,
        ...o2,
      }))
    );
    const opened$ = combineLatest([dynamicFilterContext$.pipe(map(m => m.opened)), requestedOpened$]).pipe(
      map(([o1, o2]) => {
        if (o2 !== null) {
          return o2;
        }

        return o1;
      })
    );

    const paginatedList$ = listParameters$
      .pipe(
        switchMap(listParameters => {
          return this.execute(url, listParameters);
        })
      )
      .pipe(shareReplay(1));

    let filterConfig: FilterConfig<FILTER_DATA>;
    const filterAmount$ = listParameters$.pipe(map(listParameters => filterConfig.filterCountFnc((listParameters as ANY).filterBys)));
    const changeParameters = (newListParameters: Partial<ListParameters<FILTER_DATA>>) => {
      const requestedListParameters = requestedListParameters$.getValue();
      let shouldSave = false;
      if (JSON.stringify(requestedListParameters?.filterBys) !== JSON.stringify(newListParameters?.filterBys)) {
        shouldSave = true;
      }

      const newRequestedListParameters = {
        ...requestedListParameters,
        ...newListParameters,
        pageNumber: 1,
      } as ListParameters<FILTER_DATA>;
      requestedListParameters$.next(newRequestedListParameters);

      if (shouldSave) {
        this.saveFilterContext(
          filterSettingKey as ANY,
          filterOpenKey as ANY,
          newRequestedListParameters,
          requestedOpened$.getValue()
        ).subscribe();
      }
    };
    const openDialog = () => {
      listParameters$
        .pipe(first())
        .pipe(concatMap(listParameters => this.showFilterDialog(filterConfig.dialogType, listParameters)))
        .subscribe(newListParameters => {
          if (newListParameters) {
            changeParameters(newListParameters);
          }
        });
    };
    const x = {
      paginatedList$,
      results$: paginatedList$.pipe(map(paginatedList => paginatedList.results)),
      opened$,
      filterAmount$,
      currentListParameters$: listParameters$,
      setDefaultParameters: (listParameters: ListParameters<FILTER_DATA>) => {
        defaultParameters$.next(listParameters);
      },
      changeParameters,
      openDialog,
      clearAllFilters: () => {
        changeParameters({
          filterBys: null,
          pageNumber: 1,
        });
      },
      openChange: (open: boolean) => {
        requestedOpened$.next(open);

        filterAmount$.pipe(first()).subscribe(filterAmount => {
          if (!filterAmount && open) {
            openDialog();
          }
        });

        this.saveFilterContext(filterSettingKey as ANY, filterOpenKey as ANY, null as ANY, requestedOpened$.getValue()).subscribe();
      },
      refresh: () => {
        cacheBuster$.next(cacheBuster$.getValue() + 1);
      },
      setConfig: config => {
        filterConfig = config;
      },
    } as DynamicFilterHelper<FILTER_DATA, RESULT_DATA>;

    return x;
  }

  execute<FILTER_DATA, RESULT_DATA>(url: string, listParameters: ListParameters<FILTER_DATA>): Observable<PaginatedList<RESULT_DATA>> {
    return this.http.post<PaginatedList<RESULT_DATA>>(url, listParameters, allowRetries());
  }

  private saveFilterContext<T>(
    filterSettingKey: TechnicianSetting,
    filterOpenKey: TechnicianSetting,
    listParameters: ListParameters<T>,
    opened: boolean | null
  ): Observable<void> {
    const settings: Dictionary<TechnicianSetting, string> = {};
    if (filterOpenKey && opened !== null) {
      settings[filterOpenKey] = opened ? '1' : '0';
    }

    if (filterSettingKey && listParameters) {
      settings[filterSettingKey] = JSON.stringify({
        filterBys: listParameters.filterBys,
        orderBys: listParameters.orderBys,
      });
    }

    return this.technicianSettingsService.save(settings);
  }

  private showFilterDialog<T>(
    dialogType: ComponentType<DialogFilter2Impl<T>>,
    listParameters: ListParameters<T>
  ): Observable<ListParameters<T>> {
    const dialog = this.dialog2.filter2(dialogType, {
      width: '600px',
      filter: {
        data: {
          listParameters,
        },
      },
    });

    return dialog.afterClosed().pipe(
      map(dialogResult => {
        if (dialogResult?.action === FilterAction.AddFilter) {
          return (dialogResult.result as ANY).listParameters;
        }

        return null;
      })
    );
  }

  private restoreFilterContext<T>(
    filterSettingKey: TechnicianSetting,
    filterOpenKey: TechnicianSetting
  ): Observable<DynamicFilterContext<T>> {
    const requestKeys = [filterSettingKey, filterOpenKey].filter(notEmpty);

    if (requestKeys.length > 0) {
      return this.technicianSettingsService.getMultiple(filterSettingKey, filterOpenKey).pipe(
        map(m => {
          let orderBys: ListOrderBy<T>[] = [];
          let filterBy: FilterBy<T> | null = null;

          if (filterSettingKey && m[filterSettingKey]) {
            const filter = JSON.parse(m[filterSettingKey] as ANY) as FilterInfo[] | { orderBys: ListOrderBy<T>[]; filterBys: FilterBy<T> };
            if (Array.isArray(filter)) {
              filterBy = this.filterInfoToFilterBy(filter);
            } else {
              orderBys = filter.orderBys;
              filterBy = filter.filterBys;
            }
          }

          // We open the filter IF we didn't have a setting and we had some filters.
          // Or simply if it was opened before
          const opened = m[filterOpenKey] === '1' || (m[filterOpenKey] == null && filterBy);
          return {
            filterBy,
            orderBys,
            opened,
          } as DynamicFilterContext<T>;
        })
      );
    }

    return of({
      orderBys: [],
      filterBy: null,
      opened: false,
    } as DynamicFilterContext<T>);
  }

  createListParameters<T>(orderByDescending: keyof T): ListParameters<T> {
    return {
      pageNumber: 1,
      pageSize: 100,
      filterBys: {},
      orderBys: [
        {
          direction: Direction.Desc,
          field: orderByDescending,
        },
      ],
    } as ListParameters<T>;
  }

  private getFilterBy<T>(filter: FilterInfo[] | FilterBy<T>): FilterBy<T> {
    if (this.filterIsFilterBy(filter)) {
      return filter;
    }

    return this.filterInfoToFilterBy(filter);
  }

  private filterInfoToFilterBy<T>(filterInfo: FilterInfo[]): FilterBy<T> {
    return {
      condition: 'and',
      rules: filterInfo.map(filterI => {
        return {
          text: filterI.text,
          field: filterI.id,
          operator: '==',
          value: filterI.value,
        } as FilterByRule<T>;
      }),
    } as FilterBy<T>;
  }

  private filterIsFilterBy<T>(filter: FilterInfo[] | FilterBy<T>): filter is FilterBy<T> {
    return typeof (filter as any).condition === 'string';
  }
}

const notAControlError = new Error('Not a control');

type TableFormControl = FormControl<SupportedFilters<any>[0]>;

export class DynamicFilterService2 {
  private readonly filtersFormArray = new FormArray<TableFormControl>([]);

  private readonly registeredFilters: FancyFilterImpl<unknown>[] = [];
  private isApplying = false;
  private readonly initWith = new BehaviorSubject<{ value: SupportedFilters<any>; isDirty: boolean }>({ value: [], isDirty: false });
  private readonly loaded = new BehaviorSubject(false);
  private readonly destroy$ = new Subject<void>();

  // Gating the valueChanges allows us to prevent the formGroup to emit every time we
  // register a new filter. This way, we know when we are fully loaded and we emit accordingly.
  private readonly readyValueChanges = this.loaded.pipe(
    filter(m => m),
    switchMap(_ => this.filtersFormArray.valueChanges.pipe(startWith(this.filtersFormArray.getRawValue())))
  );

  readonly data = {
    getRawValue: () => this.filtersFormArray.getRawValue(),
    dirty: () => this.filtersFormArray.dirty,
    valueChanges: this.readyValueChanges,
  };

  constructor() {
    this.loaded
      .pipe(
        filter(m => m),
        switchMap(_ => this.initWith),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: initWith => {
          this.filtersFormArray.clear();

          // The previous data was in localStorage, so we double check we have something valid.
          if (initWith.value && Array.isArray(initWith.value)) {
            for (const v of initWith.value) {
              this.getOrAddControl(v.id, v.$type as string);
            }

            this.filtersFormArray.reset(initWith.value);
          }

          if (initWith.isDirty) {
            this.filtersFormArray.markAsDirty();
          }
        },
      });
  }

  /**
   * The garbage collector does not seem terminate the subscription above.
   */
  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Registers a filter with the service.
   */
  async register(filter: FancyFilterImpl<unknown>): Promise<void> {
    await filter.load?.();
    this.registeredFilters.push(filter);
  }

  /**
   * Indicates that we finished loading all the filters.
   */
  fullyLoaded(): void {
    this.loaded.next(true);
  }

  /**
   * Initialize the service with a value.
   *
   * @param value The value to initialize with.
   * @param isDirty Indicates if we are dirty or not.
   */
  init(value: SupportedFilters<any>, isDirty: boolean): void {
    this.initWith.next({ value, isDirty });
  }

  reset(filterCode: SupportedFilters<any>[0]): void {
    const controlIndex = this.filtersFormArray.controls.findIndex(m => m.value.id === filterCode.id);
    if (controlIndex >= 0) {
      this.filtersFormArray.removeAt(controlIndex);
      this.filtersFormArray.markAsDirty();
    }
  }

  patch(id: string, code: string, name: string, value: unknown): void {
    if (value === undefined) {
      return;
    }

    const control = this.getOrAddControl(id, code);
    if (!control) {
      return;
    }

    const config = this.getUiConfig(code, name);
    if (!config) {
      return;
    }

    const hasChanged = this.applyValueIfNecessary(control, config, value);
    if (hasChanged) {
      this.applyAllValues();
    }

    this.filtersFormArray.markAsDirty();
  }

  changes<T>(id: string, code: string, name: string): Observable<T | null> {
    const control = this.getOrAddControl(id, code);
    if (!control) {
      return throwError(() => notAControlError);
    }

    const config = this.getUiConfig<T>(code, name);
    if (!config) {
      return throwError(() => notAControlError);
    }

    let obs = control.valueChanges.pipe(map(value => config.get(value.value)));

    if (control) {
      // The listener needs to have a value, so we provide one.
      obs = obs.pipe(startWith(config.get(control.value.value)));
    }

    return obs.pipe(shareReplay(1));
  }

  private getRegisteredFilter(code: string) {
    const filter = this.registeredFilters.find(m => m.code === code);
    if (!filter) {
      return;
    }

    return filter;
  }

  private getUiConfig<T>(code: string, name: string): FancyFilterConfig<unknown, T> | undefined {
    const filter = this.getRegisteredFilter(code);
    if (!filter) {
      return;
    }

    const configs = getNormalizedUIConfigs(filter);
    const config = configs.find(m => m.ui.inputs.name === name);
    if (!config) {
      return;
    }

    return config as FancyFilterConfig<unknown, T>;
  }

  private applyAllValues(): void {
    if (!this.isApplying) {
      this.isApplying = true;

      for (const registeredFilter of this.registeredFilters) {
        const uiConfigs = getNormalizedUIConfigs(registeredFilter);
        for (const uiConfig of uiConfigs) {
          for (const control of this.filtersFormArray.controls.filter(m => m.value.$type === registeredFilter.code)) {
            const value = uiConfig.get(control.value.value);
            this.applyValueIfNecessary(control, uiConfig, value);
          }
        }
      }

      this.isApplying = false;
    }
  }

  private applyValueIfNecessary(
    control: FormControl<SupportedFilters<any>[0]>,
    config: FancyFilterConfig<unknown, unknown>,
    value: unknown
  ): boolean {
    const previousJsonizedValue = JSON.stringify(control.value.value);
    const newValue = config.set(value, control.value.value) as Record<string, unknown> | null;
    if (newValue !== undefined) {
      const newJsonizedValue = JSON.stringify(newValue);

      if (previousJsonizedValue !== newJsonizedValue) {
        let finalValue = newValue;
        if (newValue) {
          finalValue = {
            $type: control.value.$type,
            ...finalValue,
          };
        }
        control.patchValue({
          ...control.value,
          value: finalValue,
        });
        return true;
      }
    }

    return false;
  }

  private getOrAddControl(id: string, $type: string): FormControl<SupportedFilters<any>[0]> {
    let control = this.filtersFormArray.controls.find(m => m.value.id === id);
    if (!control) {
      control = new FormControl<SupportedFilters<any>[0]>({
        id,
        $type,
        value: null,
      }) as ANY;
      this.filtersFormArray.push(control as ANY);
    }

    return control as ANY;
  }
}
