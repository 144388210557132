import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject, type OnDestroy, type OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { InputMomentPickerComponent } from '@controls/input-moment-picker/input-moment-picker.component';
import { RadioButtonComponent, RadioGroupDirective } from '@controls/radio/radio.component';
import { FILTER_DIALOG_DATA } from '@models/dialog';
import { FilterData } from '@models/filter-models';
import { SharedModule } from '@modules/shared.module';
import { endOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterComponent } from './filter.component';

/** @deprecated */
@Component({
  templateUrl: 'filter-report-leaderboard.component.html',
  styleUrls: ['filter-report-leaderboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, RadioGroupDirective, InputMomentPickerComponent, RadioButtonComponent, CheckboxComponent],
})
export class FilterReportLeaderboardComponent extends FilterComponent implements OnInit, OnDestroy {
  private readonly cd = inject(ChangeDetectorRef);

  private readonly _datePipe: DatePipe;
  dateFormControl = new FormControl();
  private readonly destroy$ = new Subject<void>();

  constructor(@Inject(FILTER_DIALOG_DATA) data: FilterData) {
    super(data);
    this._datePipe = new DatePipe('en-US');
  }

  ngOnInit(): void {
    // Assign the radio button
    if (this.filters) {
      const dateFilter = this.filters.find(m => m.id === 'date');
      if (dateFilter) {
        this.dateFormControl.patchValue(dateFilter.value);
      }
    }

    this.dateFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: number) => {
      if (value >= 1 && value <= 4) {
        this.setValue('date', this.getDateDisplay(value, undefined, undefined), value);
        this.removeValue('dateFrom');
        this.removeValue('dateEnd');
      } else if (value === 5 && (this.dateFrom || this.dateEnd)) {
        this.setValue('date', this.getDateDisplay(value, this.dateFrom, this.dateEnd), 5);
      } else {
        this.removeValue('date');
        this.removeValue('dateFrom');
        this.removeValue('dateEnd');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get dateFrom(): Date | undefined {
    return this.getValue('dateFrom');
  }

  set dateFrom(value: Date | undefined) {
    this.setValue('dateFrom', undefined, value);
    if (value !== undefined) {
      this.dateFormControl.patchValue(5);
    }
  }

  get dateEnd(): Date | undefined {
    return this.getValue('dateEnd');
  }

  set dateEnd(value: Date | undefined) {
    this.setValue('dateEnd', undefined, value ? endOfDay(value) : undefined);
    if (value !== undefined) {
      this.dateFormControl.patchValue(5);
    }
  }

  get compare(): boolean {
    return !!this.getValue('compare');
  }

  set compare(value: boolean) {
    if (value) {
      this.setValue('compare', 'Compare to previous period', value);
    } else {
      this.removeValue('compare');
    }
  }

  getDateDisplay(value: number, dateFrom: Date | undefined, dateEnd: Date | undefined): string {
    switch (value) {
      case 1:
        return 'Last 7 Days';
      case 2:
        return 'Last 30 Days';
      case 3:
        return 'Month to Date';
      case 4:
        return 'Year to Date';
      case 5: {
        let range = '';
        if (dateFrom && dateEnd) {
          range = `from ${this._datePipe.transform(dateFrom, 'shortDate')} to ${this._datePipe.transform(dateEnd, 'shortDate')}`;
        } else if (dateFrom) {
          range = `from ${this._datePipe.transform(dateFrom, 'shortDate')}`;
        } else if (dateEnd) {
          range = `until ${this._datePipe.transform(dateEnd, 'shortDate')}`;
        }

        return `Range: ${range}`;
      }
    }

    return '';
  }

  filtersChanged(): void {
    const date = this.getValue('date');
    if (typeof date === 'number') {
      this.dateFormControl.patchValue(date);
    } else if (date == null) {
      this.dateFormControl.patchValue(0);
    }

    this.cd.markForCheck();
  }
}
