import { Injectable, inject } from '@angular/core';
import { type AgreementSiteSystemInformation } from '@models/cards/agreement-site-system-information';
import { type CallInformation } from '@models/cards/call-information';
import { type CustomerInformation } from '@models/cards/customer-information';
import { type InspectionAnswerInformation } from '@models/cards/inspection-answer-information';
import { type NonStockItemInformation } from '@models/cards/non-stock-item-information';
import {
  type PhotoOrTempFile,
  type RecommendationInformation,
  type RecommendationPhotoInformation,
} from '@models/cards/recommendation-information';
import { type SiteInformation } from '@models/cards/site-information';
import {
  hasWorkOrderDetailsOrAgreements,
  type MiscellaneousSiteSystem,
  type SiteSystem,
  type SiteSystemInformation,
} from '@models/cards/site-system-information';
import { type WorkOrderAgreementInformation } from '@models/cards/work-order-agreement-information';
import { type WorkOrderDetailInformation } from '@models/cards/work-order-detail-information';
import {
  type WorkOrderDiscountInformation,
  type WorkOrderInformation,
  type WorkOrderRebateInformation,
} from '@models/cards/work-order-information';
import { type WorkOrderPaymentInformation } from '@models/cards/work-order-payment-information';
import { type WorkOrderRepairPartInformation } from '@models/cards/work-order-repair-part-information';
import { type WorkOrderSiteSystemInformation } from '@models/cards/work-order-site-system-information';
import { type WorkOrderBaseViewData, type WorkOrderViewData } from '@models/cards/work-order-view-data';
import { type ScheduleEventInformation } from '@models/dashboard-event';
import { type DebriefAnswerInformation } from '@models/debrief-models';
import { type WorkOrderLoanApplicationInformation } from '@models/loans';
import { type TaxItemEntry } from '@models/pricing-models';
import { type SalesProposalInformation, type SalesProposalPackageInformation } from '@models/sales-proposal-models';
import { type TagEntityInformation } from '@models/tag-models';
import { Store, select } from '@ngrx/store';
import { clone } from '@utility/object';
import { combineLatest, type Observable } from 'rxjs';
import { concatMap, filter, first, map } from 'rxjs/operators';
import * as WorkOrderActions from './work-order.actions';
import { type WorkOrderState } from './work-order.reducer';
import * as WorkOrderSelectors from './work-order.selectors';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderStateService {
  private readonly store = inject(Store<WorkOrderState>);

  readonly workOrder$ = this.store.pipe(select(WorkOrderSelectors.workOrder$));
  readonly workOrderLoanApplications$ = this.store.pipe(select(WorkOrderSelectors.workOrderLoanApplicationArray$));
  readonly workOrderDetails$ = this.store.pipe(select(WorkOrderSelectors.workOrderDetailArray$));
  readonly workOrderRepairParts$ = this.store.pipe(select(WorkOrderSelectors.workOrderRepairPartArray$));
  readonly nonStockItems$ = this.store.pipe(select(WorkOrderSelectors.nonStockItemArray$));
  readonly workOrderDiscounts$ = this.store.pipe(select(WorkOrderSelectors.workOrderDiscountArray$));
  readonly workOrderRebates$ = this.store.pipe(select(WorkOrderSelectors.workOrderRebateArray$));
  readonly workOrderAgreements$ = this.store.pipe(select(WorkOrderSelectors.workOrderAgreementArray$));
  readonly workOrderPayments$ = this.store.pipe(select(WorkOrderSelectors.workOrderPaymentArray$));
  readonly salesProposal$ = this.store.pipe(select(WorkOrderSelectors.salesProposal$));
  readonly salesProposalPackage$ = this.store.pipe(select(WorkOrderSelectors.salesProposalPackage$));
  readonly agreementSiteSystems$ = this.store.pipe(select(WorkOrderSelectors.agreementSiteSystemArray$));
  readonly taxItemEntry$ = this.store.pipe(select(WorkOrderSelectors.taxItemEntry$));
  readonly recommendations$ = this.store.pipe(select(WorkOrderSelectors.recommendationArray$));
  readonly recommendationPhotos$ = this.store.pipe(select(WorkOrderSelectors.recommendationPhotoArray$));
  readonly inspectionAnswers$ = this.store.pipe(select(WorkOrderSelectors.inspectionAnswersArray$));
  readonly siteSystems$ = this.store.pipe(select(WorkOrderSelectors.siteSystemsArray$));
  readonly workOrderSiteSystems$ = this.store.pipe(select(WorkOrderSelectors.workOrderSiteSystemsArray$));
  readonly call$ = this.store.pipe(select(WorkOrderSelectors.call$));
  readonly debriefAnswers$ = this.store.pipe(select(WorkOrderSelectors.debriefAnswerArray$));
  readonly tagEntities$ = this.store.pipe(select(WorkOrderSelectors.tagEntityArray$));
  readonly hasVisibleData$ = this.store.pipe(select(WorkOrderSelectors.hasVisibleData$));
  readonly siteInformation$ = this.store.pipe(select(WorkOrderSelectors.siteInformation$));
  readonly customerInformation$ = this.store.pipe(select(WorkOrderSelectors.customerInformation$));
  readonly workOrderBaseViewData$ = this.store.pipe(select(WorkOrderSelectors.workOrderBaseViewData$));
  readonly siteSystemInformations$ = this.store.pipe(select(WorkOrderSelectors.siteSystemInformations$));

  loadData(
    workOrder: WorkOrderInformation,
    workOrderDetails: WorkOrderDetailInformation[],
    workOrderRepairParts: WorkOrderRepairPartInformation[],
    nonStockItems: NonStockItemInformation[],
    workOrderDiscounts: WorkOrderDiscountInformation[],
    workOrderRebates: WorkOrderRebateInformation[],
    workOrderPayments: WorkOrderPaymentInformation[],
    recommendations: RecommendationInformation[],
    recommendationPhotos: RecommendationPhotoInformation[],
    debriefAnswers: DebriefAnswerInformation[],
    inspectionAnswers: InspectionAnswerInformation[],
    siteSystemInformations: SiteSystemInformation[],
    siteSystems: SiteSystem[],
    workOrderSiteSystems: WorkOrderSiteSystemInformation[],
    workOrderLoanApplications: WorkOrderLoanApplicationInformation[],
    workOrderAgreements: WorkOrderAgreementInformation[],
    agreementSiteSystems: AgreementSiteSystemInformation[],
    salesProposal: SalesProposalInformation | null,
    salesProposalPackage: SalesProposalPackageInformation | null,
    tagEntities: TagEntityInformation[],
    call: CallInformation | null,
    siteInformation: SiteInformation | null,
    customerInformation: CustomerInformation,
    workOrderBaseViewData: WorkOrderBaseViewData
  ): void {
    this.store.dispatch(WorkOrderActions.loadCustomerInformation({ data: customerInformation }));
    this.store.dispatch(WorkOrderActions.loadSiteInformation({ data: siteInformation }));
    this.store.dispatch(WorkOrderActions.loadWorkOrder({ data: workOrder }));
    this.store.dispatch(WorkOrderActions.loadDetails({ data: workOrderDetails }));
    this.store.dispatch(WorkOrderActions.loadRepairParts({ data: workOrderRepairParts }));
    this.store.dispatch(WorkOrderActions.loadNonStockItems({ data: nonStockItems }));
    this.store.dispatch(WorkOrderActions.loadDiscounts({ data: workOrderDiscounts }));
    this.store.dispatch(WorkOrderActions.loadRebates({ data: workOrderRebates }));
    this.store.dispatch(WorkOrderActions.loadPayments({ data: workOrderPayments }));
    this.store.dispatch(WorkOrderActions.loadRecommendations({ data: recommendations }));
    this.store.dispatch(WorkOrderActions.loadRecommendationPhotos({ data: recommendationPhotos }));
    this.store.dispatch(WorkOrderActions.loadDebriefAnswers({ data: debriefAnswers }));
    this.store.dispatch(WorkOrderActions.loadInspectionAnswers({ data: inspectionAnswers }));
    this.store.dispatch(WorkOrderActions.loadSiteSystemInformations({ data: siteSystemInformations }));
    this.store.dispatch(WorkOrderActions.loadSiteSystems({ data: siteSystems }));
    this.store.dispatch(WorkOrderActions.loadWorkOrderSiteSystems({ data: workOrderSiteSystems }));
    this.store.dispatch(WorkOrderActions.loadLoanApplications({ data: workOrderLoanApplications }));
    this.store.dispatch(WorkOrderActions.loadAgreements({ data: workOrderAgreements }));
    this.store.dispatch(WorkOrderActions.loadAgreementSiteSystems({ data: agreementSiteSystems }));
    this.store.dispatch(WorkOrderActions.loadSalesProposal({ data: salesProposal }));
    this.store.dispatch(WorkOrderActions.loadSalesProposalPackage({ data: salesProposalPackage }));
    this.store.dispatch(WorkOrderActions.loadTagEntities({ data: tagEntities }));
    this.store.dispatch(WorkOrderActions.loadCall({ data: call }));
    this.store.dispatch(WorkOrderActions.loadWorkOrderBaseViewData({ data: workOrderBaseViewData }));
  }

  loadDataFromViewData(workOrderViewData: WorkOrderViewData): void {
    const siteSystems: SiteSystem[] = [
      ...(workOrderViewData.siteSystemInformations ?? []),
      { id: 0, miscellaneous: true } satisfies MiscellaneousSiteSystem,
    ].filter(x =>
      hasWorkOrderDetailsOrAgreements(
        x,
        workOrderViewData.workOrderDetailInformations,
        workOrderViewData.workOrderAgreementInformations ?? [],
        workOrderViewData.agreementSiteSystemInformations ?? []
      )
    );

    this.loadData(
      workOrderViewData.workOrderInformation,
      workOrderViewData.workOrderDetailInformations,
      workOrderViewData.workOrderRepairPartInformations,
      workOrderViewData.nonStockItemInformations,
      workOrderViewData.workOrderDiscountInformations,
      workOrderViewData.workOrderRebateInformations,
      workOrderViewData.workOrderPaymentInformations,
      workOrderViewData.recommendationInformations,
      workOrderViewData.recommendationInformations?.map(m => m.recommendationPhotos).reduce((a, b) => a.concat(b), []) ?? [],
      workOrderViewData.debriefAnswerInformations,
      workOrderViewData.inspectionAnswerInformations ?? [],
      workOrderViewData.siteSystemInformations ?? [],
      siteSystems,
      workOrderViewData.workOrderSiteSystemInformations ?? [],
      workOrderViewData.workOrderLoanApplicationInformations,
      workOrderViewData.workOrderAgreementInformations ?? [],
      workOrderViewData.agreementSiteSystemInformations ?? [],
      workOrderViewData.salesProposalInformation,
      workOrderViewData.salesProposalPackageInformation,
      workOrderViewData.workOrderInformation.tagEntityInformations ?? [],
      workOrderViewData.callInformation,
      workOrderViewData.siteInformation,
      workOrderViewData.customerInformation,
      workOrderViewData.baseViewData
    );
  }

  loadWorkOrder(workOrderInformation: WorkOrderInformation): void {
    this.store.dispatch(WorkOrderActions.loadWorkOrder({ data: workOrderInformation }));
  }

  loadCustomer(customerInformation: CustomerInformation): void {
    this.store.dispatch(WorkOrderActions.loadCustomerInformation({ data: customerInformation }));
  }

  loadSite(siteInformation: SiteInformation): void {
    this.store.dispatch(WorkOrderActions.loadSiteInformation({ data: siteInformation }));
  }

  loadAgreementSiteSystems(agreementSiteSystems: AgreementSiteSystemInformation[]): void {
    this.store.dispatch(WorkOrderActions.loadAgreementSiteSystems({ data: agreementSiteSystems }));
  }

  loadWorkOrderBaseViewData(workOrderBaseViewData: WorkOrderBaseViewData): void {
    this.store.dispatch(WorkOrderActions.loadWorkOrderBaseViewData({ data: workOrderBaseViewData }));
  }

  loadPaymentData(workOrderPayments: WorkOrderPaymentInformation[]): void {
    this.store.dispatch(WorkOrderActions.loadPayments({ data: workOrderPayments }));
  }

  loadCall(callInformation: CallInformation): void {
    this.store.dispatch(WorkOrderActions.loadCall({ data: callInformation }));
  }

  loadDebriefAnswers(debriefAnswers: DebriefAnswerInformation[]): void {
    this.store.dispatch(WorkOrderActions.loadDebriefAnswers({ data: debriefAnswers }));
  }

  loadTagEntities(tagEntities: TagEntityInformation[]): void {
    this.store.dispatch(WorkOrderActions.loadTagEntities({ data: tagEntities }));
  }

  loadInspectionAnswers(inspectionAnswers: InspectionAnswerInformation[]): void {
    this.store.dispatch(WorkOrderActions.loadInspectionAnswers({ data: inspectionAnswers }));
  }

  loadSiteSystemInformations(siteSystems: SiteSystemInformation[]): void {
    this.store.dispatch(WorkOrderActions.loadSiteSystemInformations({ data: siteSystems }));
  }

  loadSiteSystems(siteSystems: SiteSystemInformation[]): void {
    this.store.dispatch(WorkOrderActions.loadSiteSystems({ data: siteSystems }));
  }

  loadWorkOrderSiteSystems(workOrderSiteSystems: WorkOrderSiteSystemInformation[]): void {
    this.store.dispatch(WorkOrderActions.loadWorkOrderSiteSystems({ data: workOrderSiteSystems }));
  }

  forceCreate(): void {
    this.store.dispatch(WorkOrderActions.forceCreate());
  }

  updateWorkOrder(workOrder: Partial<WorkOrderInformation>): void {
    this.store.dispatch(WorkOrderActions.updateWorkOrder({ patch: workOrder }));
  }

  updateCall(call: Partial<CallInformation>): void {
    // We will delete the event from here.
    const newCall = clone(call);
    delete newCall.event;
    this.store.dispatch(WorkOrderActions.updateCall({ patch: call }));
  }

  updateEvent(event: Partial<ScheduleEventInformation>): void {
    this.store.dispatch(WorkOrderActions.updateEvent({ patch: event }));
  }

  addDebriefAnswer(debriefAnswer: DebriefAnswerInformation): void {
    this.store.dispatch(WorkOrderActions.addDebriefAnswer({ data: debriefAnswer }));
  }

  updateDebriefAnswer(id: Id, debriefAnswer: Partial<DebriefAnswerInformation>): void {
    this.store.dispatch(WorkOrderActions.updateDebriefAnswer({ id, patch: debriefAnswer }));
  }

  removeDebriefAnswer(id: Id): void {
    this.store.dispatch(WorkOrderActions.deleteDebriefAnswer({ id }));
  }

  setTags(tags: TagEntityInformation[]): void {
    this.store.dispatch(WorkOrderActions.setTags({ tags }));
  }

  updateWorkOrderProcess(): Observable<void> {
    this.store.dispatch(WorkOrderActions.updateWorkOrderProcess());
    return this.handleError().pipe(map(_ => undefined));
  }

  addWorkOrderDetail(workOrderDetail: WorkOrderDetailInformation): void {
    this.store.dispatch(WorkOrderActions.addDetail({ data: workOrderDetail }));
  }

  updateWorkOrderDetail(id: Id, workOrderDetail: WorkOrderDetailInformation): void {
    this.store.dispatch(WorkOrderActions.updateDetail({ id, patch: workOrderDetail }));
  }

  removeWorkOrderDetail(id: Id): void {
    this.store.dispatch(WorkOrderActions.deleteDetail({ id }));
  }

  addWorkOrderRepairPart(workOrderRepairPart: WorkOrderRepairPartInformation): void {
    this.store.dispatch(WorkOrderActions.addRepairPart({ data: workOrderRepairPart }));
  }

  updateWorkOrderRepairPart(id: Id, workOrderRepairPart: WorkOrderRepairPartInformation): void {
    this.store.dispatch(WorkOrderActions.updateRepairPart({ id, patch: workOrderRepairPart }));
  }

  removeWorkOrderRepairPart(id: Id): void {
    this.store.dispatch(WorkOrderActions.deleteRepairPart({ id }));
  }

  addNonStockItem(nonStockItem: NonStockItemInformation): void {
    this.store.dispatch(WorkOrderActions.addNonStockItem({ data: nonStockItem }));
  }

  updateNonStockItem(id: Id, nonStockItem: NonStockItemInformation): void {
    this.store.dispatch(WorkOrderActions.updateNonStockItem({ id, patch: nonStockItem }));
  }

  addWorkOrderDiscount(workOrderDiscount: WorkOrderDiscountInformation): void {
    this.store.dispatch(WorkOrderActions.addDiscount({ data: workOrderDiscount }));
  }

  updateWorkOrderDiscount(id: Id, workOrderDiscount: WorkOrderDiscountInformation): void {
    this.store.dispatch(WorkOrderActions.updateDiscount({ id, patch: workOrderDiscount }));
  }

  removeWorkOrderDiscount(id: Id): void {
    this.store.dispatch(WorkOrderActions.deleteDiscount({ id }));
  }

  addWorkOrderRebate(workOrderRebate: WorkOrderRebateInformation): void {
    this.store.dispatch(WorkOrderActions.addRebate({ data: workOrderRebate }));
  }

  updateWorkOrderRebate(id: Id, workOrderRebate: WorkOrderRebateInformation): void {
    this.store.dispatch(WorkOrderActions.updateRebate({ id, patch: workOrderRebate }));
  }

  removeWorkOrderRebate(id: Id): void {
    this.store.dispatch(WorkOrderActions.deleteRebate({ id }));
  }

  addWorkOrderAgreement(workOrderAgreement: WorkOrderAgreementInformation): void {
    this.store.dispatch(WorkOrderActions.addAgreement(workOrderAgreement));
  }

  addSiteSystem(siteSystem: SiteSystem): void {
    this.store.dispatch(WorkOrderActions.addSiteSystem({ data: siteSystem }));
  }

  removeSiteSystem(id: Id | null): void {
    this.store.dispatch(WorkOrderActions.removeSiteSystem({ id }));
  }

  updateSiteSystem(id: Id, siteSystemInformation: SiteSystemInformation): void {
    this.store.dispatch(WorkOrderActions.updateSiteSystem({ id, patch: siteSystemInformation }));
  }

  updateWorkOrderAgreement(id: Id, workOrderAgreement: WorkOrderAgreementInformation): void {
    this.store.dispatch(WorkOrderActions.updateAgreement({ id, patch: workOrderAgreement }));
  }

  removeWorkOrderAgreement(workOrderAgreementId: Id): void {
    this.store.dispatch(WorkOrderActions.deleteAgreement({ id: workOrderAgreementId }));
  }

  addWorkOrderPayment(workOrderPayment: WorkOrderPaymentInformation, isAlreadySavedOnServer: boolean): void {
    this.store.dispatch(WorkOrderActions.addPayment({ data: workOrderPayment, isAlreadySavedOnServer }));
  }

  updateWorkOrderPayment(id: Id, workOrderPayment: WorkOrderPaymentInformation): void {
    this.store.dispatch(WorkOrderActions.updatePayment({ id, patch: workOrderPayment }));
  }

  removeWorkOrderPayment(id: Id): void {
    this.store.dispatch(WorkOrderActions.deletePayment({ id }));
  }

  addRecommendation(recommendation: RecommendationInformation): void {
    this.store.dispatch(WorkOrderActions.addRecommendation({ data: recommendation }));
  }

  updateRecommendation(id: Id, recommendation: RecommendationInformation): void {
    this.store.dispatch(WorkOrderActions.updateRecommendation({ id, patch: recommendation }));
  }

  removeRecommendation(id: Id): void {
    this.store.dispatch(WorkOrderActions.deleteRecommendation({ id }));
  }

  updateRecommendationPhoto(id: Id, recommendationPhoto: PhotoOrTempFile): void {
    this.store.dispatch(WorkOrderActions.updateRecommendationPhoto({ id, patch: recommendationPhoto }));
  }

  removeRecommendationPhoto(id: Id): void {
    this.store.dispatch(WorkOrderActions.deleteRecommendationPhoto({ id }));
  }

  addRecommendationPhoto(id: Id, guid: string, recommendationId: Id): void {
    this.store.dispatch(WorkOrderActions.addRecommendationPhoto({ data: { id, guid, recommendationId } }));
  }

  updateSalesProposal(salesProposal: Partial<SalesProposalInformation>): void {
    this.store.dispatch(WorkOrderActions.updateSalesProposal({ patch: salesProposal }));
  }

  updateSalesProposalPackage(salesProposalPackage: Partial<SalesProposalPackageInformation>): void {
    this.store.dispatch(WorkOrderActions.updateSalesProposalPackage({ patch: salesProposalPackage }));
  }

  addWorkOrderLoanApplication(workOrderLoanApplication: WorkOrderLoanApplicationInformation): void {
    // This is quite custom, we do not change the id here.
    this.store.dispatch(WorkOrderActions.addLoanApplication({ data: workOrderLoanApplication }));
  }

  updateWorkOrderLoanApplication(id: Id, workOrderLoanApplication: WorkOrderLoanApplicationInformation): void {
    this.store.dispatch(WorkOrderActions.updateLoanApplication({ id, patch: workOrderLoanApplication }));
  }

  removeWorkOrderLoanApplication(id: Id): void {
    this.store.dispatch(WorkOrderActions.deleteLoanApplication({ id }));
  }

  updateTax(taxItemEntry: TaxItemEntry): void {
    this.store.dispatch(WorkOrderActions.updateTax({ taxItemEntry }));
  }

  editMode(): void {
    this.store.dispatch(WorkOrderActions.editMode());
  }

  cancel(): void {
    this.store.dispatch(WorkOrderActions.cancel());
  }

  save(customerId: Id, siteId: Id | null): Observable<Id> {
    this.store.dispatch(WorkOrderActions.saveWorkOrder({ customerId, siteId }));

    return this.handleError().pipe(map(workOrderState => workOrderState.entities.workOrder.id));
  }

  reset(): void {
    this.store.dispatch(WorkOrderActions.reset());
  }

  resetWorkOrderOnly(): void {
    this.store.dispatch(WorkOrderActions.loadSiteSystems({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadAgreementSiteSystems({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadAgreements({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadDetails({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadRepairParts({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadLoanApplications({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadNonStockItems({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadDiscounts({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadRebates({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadPayments({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadRecommendationPhotos({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadRecommendations({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadDebriefAnswers({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadTagEntities({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadInspectionAnswers({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadWorkOrderSiteSystems({ data: [] }));
    this.store.dispatch(WorkOrderActions.loadSalesProposal({ data: null }));
    this.store.dispatch(WorkOrderActions.loadSalesProposalPackage({ data: null }));
  }

  private handleError(): Observable<WorkOrderState> {
    const x = this.store
      .select(WorkOrderSelectors.cacheCorrelationId$)
      .pipe(first())
      .pipe(
        concatMap(correlationId =>
          this.store.select(WorkOrderSelectors.saveCorrelationId$).pipe(
            filter(id => !!id && id === correlationId),
            first()
          )
        )
      );

    return combineLatest([
      x,
      this.store.pipe(select(WorkOrderSelectors.error$)),
      this.store.pipe(select(WorkOrderSelectors.workOrderStateSelector)),
    ])
      .pipe(first())
      .pipe(
        map(([_, error, workOrderState]) => {
          if (error) {
            throw error;
          }

          return workOrderState;
        })
      );
  }
}
