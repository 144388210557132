import { type Component, type ComponentRef, EventEmitter, Injectable, type Type } from '@angular/core';

export interface RendererData {
  type: Type<Component>;
  callback: (c: ComponentRef<Component>) => void;
}

@Injectable()
export class RendererService {
  private readonly rendererDatas: RendererData[] = [];
  addTypeEmitter = new EventEmitter<RendererData>();
  removeTypeEmitter = new EventEmitter<ComponentRef<Component>>();

  public async addType<T /* Component */>(type: Type<T>): Promise<ComponentRef<T>> {
    return await new Promise<ComponentRef<any>>(resolve => {
      const rendererData = {
        type,
        callback: (c: ComponentRef<any>) => {
          resolve(c);
        },
      } as ANY;
      this.rendererDatas.push(rendererData);
      this.addTypeEmitter.emit(rendererData);
    });
  }

  public removeType<T /* Component */>(componentRef: ComponentRef<T>) {
    this.removeTypeEmitter.emit(componentRef as ANY);
  }
}
