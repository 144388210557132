import { DatePipe } from '@angular/common';
import {
  Directive,
  Injectable,
  Injector,
  computed,
  effect,
  inject,
  signal,
  untracked,
  type Signal,
  type Type,
  type WritableSignal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FilterAgreementSiteSystemComponent } from '@dialogviews/filters/filter-agreement-site-system.component';
import { FilterDutyComponent } from '@dialogviews/filters/filter-duty.component';
import { FilterExportComponent } from '@dialogviews/filters/filter-export.component';
import { FilterReplenishmentComponent } from '@dialogviews/filters/filter-replenishment.component';
import { FilterReportDateComponent } from '@dialogviews/filters/filter-report-date.component';
import { FilterReportLeaderboardComponent } from '@dialogviews/filters/filter-report-leaderboard.component';
import { FilterReportComponent } from '@dialogviews/filters/filter-report.component';
import { FilterSalesProposalComponent } from '@dialogviews/filters/filter-sales-proposal.component';
import { FilterWorkOrderComponent } from '@dialogviews/filters/filter-work-order.component';
import { type FilterComponent as Filter2Component } from '@dialogviews/filters/filter.component';
import { DIALOG_SERVICE_IMPL, DialogType, buttons } from '@models/dialog';
import { DeveloperError } from '@models/error-models';
import {
  FilterAction,
  type DefaultTableSegment,
  type FancyFilterImpl,
  type Filter2,
  type FilterData,
  type FilterInfo,
  type FilterResult,
  type OrderBySettings,
  type PagedResult,
  type SupportedColumns,
  type SupportedFilters,
  type TableColumnDefinition,
  type TableQueryResult,
  type TableSegment,
} from '@models/filter-models';
import { type UserToken } from '@models/user';
import { notEmpty } from '@utility/array';
import { distinctUntilChangedByJson } from '@utility/observable';
import { newGuid } from '@utility/string';
import { endOfMonth, startOfMonth } from 'date-fns';
import { BehaviorSubject, EMPTY, Subject, combineLatest, from, of, type Observable } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  defaultIfEmpty,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
  toArray,
} from 'rxjs/operators';
import { FilterComponent } from '../dialogs/filter.component';
import { AuthService } from '../services/auth.service';
import { TechnicianSetting, TechnicianSettingsService } from '../services/live/technician-settings.service';
import { CacheService } from './cache.service';
import { DynamicFilterService2 } from './dynamic-filter.service';
import { HttpClientService } from './http-client.service';
import { WindowRefService } from './window-ref.service';
import { FeaturesService } from './live/features.service';
export { TechnicianSetting, type FilterInfo, type OrderBySettings };

const DEFAULT_TAKE = 50;

@Directive()
export class FilterDialogTypeDirective extends FilterComponent {}

export interface FilterContext {
  take?: number;
  skip?: Observable<number>;

  /** @deprecated */
  filters?: Observable<FilterInfo[]>;
  orders?: Observable<OrderBySettings[]>;

  /** @deprecated */
  open?: Observable<boolean>;

  /** @deprecated */
  defaultFilters?: FilterInfo[];

  /** @deprecated */
  defaultOrders?: OrderBySettings[];

  /** @deprecated */
  filterDialog?: Type<Filter2Component>;

  filterSettingKey?: TechnicianSetting;
  /** @deprecated */
  filterOpenKey?: TechnicianSetting;
}

interface InternalFilterContext extends FilterContext {
  filtersSubject: BehaviorSubject<FilterInfo[]>;
  ordersSubject: BehaviorSubject<OrderBySettings[]>;
  openSubject: BehaviorSubject<boolean>;
  skipSubject: BehaviorSubject<number>;
}

/** @deprecated */
interface FilterContextDefaults {
  filters?: FilterInfo[];
  dialog?: Type<Filter2Component>;
  orders?: OrderBySettings[];
  openKey?: TechnicianSetting;
}

/** @deprecated */
function getDefaults(filterSettingKey: TechnicianSetting, userToken: UserToken | undefined): FilterContextDefaults {
  let filters: FilterInfo[] | undefined;
  let dialog: Type<Filter2Component> | undefined;
  let orders: OrderBySettings[] | undefined;
  let openKey: TechnicianSetting | undefined;
  let applyReportFilter = false;
  switch (filterSettingKey) {
    case TechnicianSetting.WEB_FILTER_REPORT_LEADERBOARD:
      dialog = FilterReportLeaderboardComponent;
      openKey = TechnicianSetting.WEB_FILTER_OPEN_REPORT_LEADERBOARD;
      applyReportFilter = true;
      break;
    case TechnicianSetting.WEB_FILTER_REPORT_OVERVIEW:
      dialog = FilterReportDateComponent;
      openKey = TechnicianSetting.WEB_FILTER_OPEN_REPORT_OVERVIEW;
      applyReportFilter = true;
      break;
    case TechnicianSetting.WEB_FILTER_DUTY:
      filters = [
        {
          id: 'status',
          text: 'Active',
          value: 1,
        },
      ];
      if (userToken) {
        filters.push({
          id: 'assignee',
          text: 'Assigned to Me',
          value: userToken.token.technicianId,
        });
      }

      orders = [
        {
          by: 'Status',
          descending: false,
        },
        {
          by: 'DueDate',
          descending: false,
        },
      ];
      dialog = FilterDutyComponent;
      openKey = TechnicianSetting.WEB_FILTER_OPEN_DUTY;
      break;
    case TechnicianSetting.WEB_FILTER_AGREEMENT_SITE_SYSTEM:
      dialog = FilterAgreementSiteSystemComponent;
      orders = [
        {
          by: 'NextVisitIsNull',
          descending: false,
        },
        {
          by: 'NextVisit',
          descending: false,
        },
      ];
      break;
    case TechnicianSetting.WEB_FILTER_EXPORT:
      orders = [
        {
          by: 'ExportId',
          descending: true,
        },
      ];
      dialog = FilterExportComponent;
      openKey = TechnicianSetting.WEB_FILTER_OPEN_EXPORT;
      break;
    case TechnicianSetting.WEB_FILTER_WORK_ORDER:
      orders = [
        {
          by: 'WorkOrderId',
          descending: true,
        },
      ];
      dialog = FilterWorkOrderComponent;
      openKey = TechnicianSetting.WEB_FILTER_OPEN_WORK_ORDER;
      break;
    case TechnicianSetting.WEB_FILTER_SALES_PROPOSAL:
      orders = [
        {
          by: 'SalesProposalId',
          descending: true,
        },
      ];
      dialog = FilterSalesProposalComponent;
      openKey = TechnicianSetting.WEB_FILTER_OPEN_SALES_PROPOSAL;
      break;
    case TechnicianSetting.WEB_FILTER_REPORT_PERFORMANCE:
      openKey = TechnicianSetting.WEB_FILTER_OPEN_REPORT_PERFORMANCE;
      applyReportFilter = true;
      break;
    case TechnicianSetting.WEB_FILTER_REPORT_INCENTIVE:
      openKey = TechnicianSetting.WEB_FILTER_OPEN_REPORT_INCENTIVE;
      applyReportFilter = true;
      break;
    case TechnicianSetting.WEB_FILTER_REPORT_DETAIL:
      openKey = TechnicianSetting.WEB_FILTER_OPEN_REPORT_DETAIL;
      applyReportFilter = true;
      break;
    case TechnicianSetting.WEB_FILTER_REPLENISHMENT:
      orders = [
        {
          by: 'ReplenishmentId',
          descending: true,
        },
      ];
      dialog = FilterReplenishmentComponent;
      openKey = TechnicianSetting.WEB_FILTER_OPEN_REPLENISHMENT;
      break;
  }

  if (applyReportFilter) {
    const som = startOfMonth(new Date());
    const eom = endOfMonth(new Date());
    const datePipe = new DatePipe('en-US');
    filters = filters || [
      { id: 'dateFrom', text: '', value: som },
      { id: 'dateEnd', text: '', value: eom },
      {
        id: 'date',
        text: `from ${datePipe.transform(som, 'shortDate')} to ${datePipe.transform(eom, 'shortDate')}`,
        value: 1,
      },
    ];
    dialog = dialog || FilterReportComponent;
  }

  filters = filters || [];
  orders = orders || [];

  return { filters, dialog, orders, openKey };
}

export interface FilterHelper<TResult> {
  context: FilterContext | null;
  resultsChanged: Observable<PagedResult<TResult>>;
  isLoading: Observable<boolean>;

  dynamicFilterService?: DynamicFilterService2;
  filters?: FancyFilterImpl<unknown>[];
  availableColumns?: TableColumnDefinition<unknown>[];
  columns?: TableColumnDefinition<unknown>[];
  segments?: TableSegment<any, any>[];
  currentSegmentId?: Id | null;

  /** @deprecated */
  openDialog: () => void;
  clearAllFilters: () => void;
  /** @deprecated */
  openChange: (open: boolean) => void;
  refreshResults: (pageNumber?: number) => void;
  /** @deprecated */
  destroy: () => void;
}

/** @deprecated */
export interface Filter0<T extends Record<string, unknown> | undefined> {
  take?: number;
  skip?: number;
  filters?: T;
  orders?: OrderBySettings[];
}

/** @deprecated */
export type FilterRegister0<T, U extends Record<string, unknown> | undefined> = (filter: Filter0<U>) => Observable<PagedResult<T>>;

export type FilterRegister<TResult, TFilters extends SupportedFilters<any> | undefined, TColumns extends string[]> = (
  filter: Filter2<TFilters, TColumns>
) => Observable<TableQueryResult<TResult>>;

export type GetSegmentRegister<TFilters extends SupportedFilters<any> | undefined, TColumns extends string[]> = () => Observable<
  TableSegment<TFilters, TColumns>[]
>;

export type GetDefaultRegister<TFilters extends SupportedFilters<any> | undefined> = () => Observable<DefaultTableSegment<TFilters>[]>;

export type SaveSegmentRegister<TFilters extends SupportedFilters<any> | undefined, TColumns extends string[]> = (
  segment: TableSegment<TFilters, TColumns>
) => Observable<Id>;

export type DeleteSegmentRegister = (id: Id) => Observable<void>;

export interface ExportToFileResponse {
  readUrl: string;
  fileName: string;
}

export type ExportToFileRegister<TFilters extends SupportedFilters<any> | undefined, TColumns extends string[]> = (
  filter: Filter2<TFilters, TColumns>
) => Observable<ExportToFileResponse>;

/** @deprecated */
export interface FilterTableData<T> {
  filterHelper: FilterHelper<T>;
  pagedResult: PagedResult<T>;
  showTable: boolean;
}

export interface FilterTable2<
  TResult = unknown,
  TFilterTypes extends readonly Type<FancyFilterImpl<unknown>>[] = readonly any[],
  TColumnTypes extends readonly Type<TableColumnDefinition<unknown>>[] = readonly any[],
> {
  columns: {
    /**
     * Indicates which column can be chosen on the UI.
     */
    available: Signal<TableColumnDefinition<unknown>[]>;

    /**
     * Indicates which column are supposed to be displayed to the UI.
     */
    requested: Signal<TableColumnDefinition<unknown>[]>;

    /**
     * Requests another set of columns.
     *
     * @param columns
     */
    change: (columns: string[]) => void;
  };

  segments?: {
    /**
     * Returns the available segments.
     */

    available: Signal<TableSegment<SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>[] | undefined>;

    /**
     * Indicates which segment is currently in used.
     */
    requested: Signal<Id | null>;

    /**
     * Indicates if the current segment is dirty.
     */
    isDirty: Signal<boolean>;

    /**
     * Saves the segment to the server.
     *
     * @param segmentId The segment id or null if we are saving a new segment.
     * @param segmentName The segment name.
     */
    save: (segmentId: Id | null, segmentName: string) => Observable<Id>;

    /**
     * Requests to change the segment.
     *
     * @param segmentId The segment id or null
     */
    change: (segmentId: Id | null) => void;

    /**
     * Deletes the current segment.
     */
    remove: () => Observable<void>;
  };

  results: {
    /**
     * Has the data from the server.
     */
    pagedResult: Signal<TableQueryResult<TResult>>;

    /**
     * Indicates if we are loading.
     */
    isLoading: Signal<boolean>;

    /**
     * Triggers that we want to refresh the result.
     * The pageNubmer starts at 1.
     *
     * @param pageNumber Page number; starts at 1.
     */
    refresh: (pageNumber?: number) => void;
  };

  filters?: {
    available: FancyFilterImpl<unknown>[];
    requestedCodes: Signal<SupportedFilters<any>>;
    service: DynamicFilterService2;
    requested: Signal<SupportedFilters<TFilterTypes>>;
    change: (codes: SupportedFilters<any>) => void;
  };

  export?: {
    execute: () => void;
  };

  destroy: () => void;
}

/** @deprecated */
export interface FilterTable<T = unknown> {
  data$: Observable<FilterTableData<T>>;
  refreshResults: (pageNumber?: number) => void;
}

export const defaultFilterTableData = <T>(): FilterTableData<T> => {
  const pagedResult: PagedResult<T> = { count: 0, error: null, results: [], skip: 0, take: 0 };

  return {
    showTable: false,
    pagedResult,
    filterHelper: {
      clearAllFilters: () => {},
      context: null,
      destroy: () => {},
      isLoading: of(true),
      openChange: () => {},
      openDialog: () => {},
      refreshResults: () => {},
      resultsChanged: of(pagedResult),
    },
  };
};

interface FilterTemporaryStorage {
  currentSegmentId: Id | null;
  isDirty: boolean;
  filters: SupportedFilters<any>;
  columns: string[];
}

type DynamicFilterArgs<TFilters extends readonly Type<FancyFilterImpl<unknown>>[]> = {
  filterTypes: TFilters;
};

type DynamicColumnArgs<TResult, TColumns extends readonly Type<TableColumnDefinition<TResult>>[]> = {
  columns: TColumns;
  hasDeletedData?: (results: TResult) => boolean;
};

const filterStorageKey = `FILTER_V2_$0`;

const getNonNullValues = (obj: SupportedFilters<any>): SupportedFilters<any> => {
  return obj.filter(m => m.value != null);
};

enum RestoreState {
  NotLoaded = 0,
  JustRestored = 1,
  Stable = 2,
}

@Injectable()
export class Filter2Service {
  private readonly injector = inject(Injector);
  private readonly cacheService = inject(CacheService);
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  private readonly windowRefService = inject(WindowRefService);
  private readonly http = inject(HttpClientService);
  private readonly featuresService = inject(FeaturesService);

  createTable = <
    TResult,
    TFilterTypes extends readonly Type<FancyFilterImpl<unknown>>[] = [],
    TColumnTypes extends readonly Type<TableColumnDefinition<unknown>>[] = [],
  >(
    args: {
      getRows$: FilterRegister<TResult, SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>;
      segments?: {
        get$: GetSegmentRegister<SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>;
        save$: SaveSegmentRegister<SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>;
        delete$: DeleteSegmentRegister;
        default$?: GetDefaultRegister<SupportedFilters<TFilterTypes>>;
      };
      exportToFile$?: ExportToFileRegister<SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>;
    },
    filterSettingKey: TechnicianSetting | undefined,
    dynamicColumnArgs?: DynamicColumnArgs<TResult, TColumnTypes>,
    dynamicFilterArgs?: DynamicFilterArgs<TFilterTypes>,
    injector?: Injector
  ): FilterTable2<TResult, TFilterTypes, TColumnTypes> => {
    const skip = signal(0);
    const take = signal(50);
    const flag = signal(false);
    const orders = signal<OrderBySettings[]>([]);
    const restoredFromCacheState = signal(RestoreState.NotLoaded);

    const userSegments = signal<TableSegment<SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>[] | undefined>(undefined);
    const systemSegments = signal<DefaultTableSegment<SupportedFilters<TFilterTypes>>[] | undefined>(undefined);
    const refreshUserSegments = () => {
      args.segments
        ?.get$()
        .pipe(first())
        .subscribe(sgs => {
          // The $types are not assigned at the top level and we need them.
          // This is an oversight after we did a lot of refactoring and now we would need more of a massive refactoring
          // on the FE if we wanted to support this properly.
          const final: TableSegment<SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>[] = sgs.map(segment => ({
            ...segment,
            system: false,
            query: {
              ...segment.query,
              filters: segment.query.filters.map(filter => ({
                ...filter,
                $type: (filter.value as ANY).$type,
                id: newGuid(),
              })),
            },
          }));
          userSegments.set(final);
        });
    };
    refreshUserSegments();

    const refreshDefaultSegments = () => {
      args.segments
        ?.default$?.()
        .pipe(first())
        .subscribe(sgs => {
          systemSegments.set(sgs);
        });
    };
    refreshDefaultSegments();

    const currentSegmentId = signal<Id | null>(null);

    const isLoading = signal<boolean>(false);
    const initialPagedResult: TableQueryResult<TResult> = {
      take: 0,
      skip: 0,
      count: 0,
      results: [],
    };

    const destroy$ = new Subject<void>();
    const dynamicFilterService = new DynamicFilterService2();

    const availableFilters$ = from(dynamicFilterArgs?.filterTypes.map(filterTypes => this.injector.get(filterTypes)) ?? []).pipe(
      mergeMap(x =>
        ((x.requiresFeatures?.length ?? 0) > 0 ? this.featuresService.hasFeature$(...(x.requiresFeatures ?? [])) : of(true)).pipe(
          filter(hasFeature => hasFeature),
          map(() => x)
        )
      ),
      toArray()
    );

    const availableFilters = toSignal(availableFilters$, { initialValue: [] });

    const filters = toSignal(dynamicFilterService.data.valueChanges, { injector });
    const requestedColumns = this.getRequestedColumns();

    isLoading.set(true);
    const pagedResult = toSignal(
      combineLatest([
        toObservable(skip, { injector }),
        toObservable(orders, { injector }),
        toObservable(requestedColumns, { injector }),
        toObservable(filters, { injector }).pipe(
          filter(notEmpty),
          map(m => m.filter(n => n.value !== null)),
          distinctUntilChangedByJson()
        ),
        toObservable(take, { injector }),
        toObservable(flag, { injector }), // Only to retrigger, like a refresh
      ]).pipe(
        // We need a mini debounce because multiple signal can change at once.
        debounceTime(10),
        switchMap(([skipValue, orders, columns, filters, takeValue]) => {
          this.saveCache(filterSettingKey, { columns, filters });
          isLoading.set(true);

          return args.getRows$({ skip: skipValue, orders, columns, filters, take: takeValue }).pipe(
            tap(pagedResult => {
              // in case we were not in sync with the server, we reset them here.
              // This is usually a no-op, but in case the server actually had a different number, we end up with the wrong
              // paging control.
              skip.set(pagedResult.skip);
              take.set(pagedResult.take);
            }),
            finalize(() => {
              isLoading.set(false);
            })
          );
        }),
        tap({
          error: () => {
            // In case we get an error we need to clear the cache so we don't end up in a stuck loop.
            if (filterSettingKey) {
              this.clearCache(filterSettingKey);
            }
          },
        }),
        catchError(() => of(initialPagedResult))
      ),
      { initialValue: initialPagedResult, injector }
    );

    const { columns, defaultColumns, hasChangedColumns } = this.getColumnObject(dynamicColumnArgs, requestedColumns, pagedResult, injector);
    const segments = toSignal<TableSegment<SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>[] | undefined>(
      combineLatest([
        toObservable(userSegments, { injector }),
        toObservable(systemSegments, { injector }),
        toObservable(columns.available, { injector }),
      ]).pipe(
        map(([userSegments, systemSegments, loadedAvailableColumns]) => {
          return [
            ...(userSegments ?? []),
            ...(systemSegments?.map(systemSegment => {
              return {
                id: -systemSegment.id,
                text: systemSegment.name,
                title: systemSegment.description,
                system: true,
                query: {
                  filters: systemSegment.filters.map(filter => ({
                    ...filter,
                    // The $types are not assigned at the top level and we need them.
                    // This is an oversight after we did a lot of refactoring and now we would need more of a massive refactoring
                    // on the FE if we wanted to support this properly.
                    $type: (filter.value as ANY).$type,
                    id: newGuid(),
                  })),
                  columns: loadedAvailableColumns.map(m => m.id),
                },
              } satisfies TableSegment<SupportedFilters<TFilterTypes>, SupportedColumns<TColumnTypes>>;
            }) ?? []),
          ];
        })
      ),
      { injector }
    );

    const requestedCodes = signal<SupportedFilters<any>>([]);

    const savedData = this.restoreCache(filterSettingKey, defaultColumns);
    effect(
      () => {
        const currentSegments = segments();

        hasChangedColumns.set(false);

        if (currentSegments) {
          const segmentId = currentSegmentId();
          const restoreState = untracked(() => restoredFromCacheState());
          if (restoreState === RestoreState.Stable) {
            if (segmentId) {
              const currentSegment = currentSegments.find(x => x.id === segmentId);
              if (currentSegment) {
                dynamicFilterService.init(currentSegment.query.filters, false);
                requestedColumns.set(currentSegment.query.columns);
                requestedCodes.set(getNonNullValues(currentSegment.query.filters));
              }
            } else {
              dynamicFilterService.init([], false);
              requestedColumns.set(defaultColumns);
              requestedCodes.set([]);
            }
          } else if (restoreState === RestoreState.JustRestored) {
            restoredFromCacheState.set(RestoreState.Stable);
          }

          if (restoreState === RestoreState.NotLoaded) {
            if (untracked(() => currentSegmentId()) === savedData.currentSegmentId) {
              restoredFromCacheState.set(RestoreState.Stable);
            } else {
              restoredFromCacheState.set(RestoreState.JustRestored);
              currentSegmentId.set(savedData.currentSegmentId);
            }

            dynamicFilterService.init(savedData.filters, savedData.isDirty);
            requestedColumns.set(savedData.columns);
            requestedCodes.set(getNonNullValues(savedData.filters));
          }
        }
      },
      { allowSignalWrites: true, injector }
    );

    effect(
      () => {
        const _ = filters();
        skip.set(0);
      },
      { allowSignalWrites: true, injector }
    );

    const exportToFile = () => {
      if (!args.exportToFile$) {
        throw new DeveloperError('exportToFile$ is not set');
      }

      const dialogRef = this.dialog2.confirm<void>({
        confirm: {
          disableExit: true,
          loading: true,
          title: 'Exporting',
          text: 'Your export is being generated, please wait...',
          type: DialogType.Information,
          buttons: buttons.confirmButtons({
            withCancel: true,
          }),
        },
      });

      const sub = args
        .exportToFile$({ filters: filters() as ANY, columns: requestedColumns(), orders: orders() })
        .pipe(
          concatMap(data => {
            if (!data.readUrl) {
              return EMPTY;
            }

            return this.http.get(data.readUrl, { responseType: 'blob' }).pipe(map(blob => ({ blob, data })));
          }),
          takeUntil(destroy$),
          finalize(() => {
            dialogRef.close();
          })
        )
        .subscribe(({ blob, data }) => {
          this.windowRefService.download(blob, data.fileName);
        });

      dialogRef.afterClosed().subscribe(() => {
        sub.unsubscribe();
      });
    };

    const isDirty = computed(() => {
      const _ = filters(); // If the filters change.
      return dynamicFilterService.data.dirty() || hasChangedColumns();
    });

    effect(
      () => {
        this.saveCache(filterSettingKey, { isDirty: isDirty() });
      },
      { injector }
    );

    effect(
      () => {
        this.saveCache(filterSettingKey, { currentSegmentId: currentSegmentId() });
      },
      { injector }
    );

    return {
      columns,
      segments: {
        available: segments,
        requested: currentSegmentId,
        isDirty,
        save: (segmentId, segmentName) => {
          return (
            args.segments
              ?.save$({
                id: segmentId ?? 0,
                text: segmentName,
                order: 0,
                query: {
                  columns: requestedColumns(),
                  filters: filters() as ANY,
                  orders: orders(),
                },
                system: false,
              })
              .pipe(
                tap(id => {
                  refreshUserSegments();
                  currentSegmentId.set(id);
                })
              ) ?? of(0)
          );
        },
        change: (segmentId: Id | null) => {
          currentSegmentId.set(segmentId);
        },
        remove: () => {
          const segmentToDeleteId = currentSegmentId();
          currentSegmentId.set(null); // Go back to default segmentToDeleteId.
          userSegments.set(userSegments()?.filter(x => x.id !== segmentToDeleteId));
          return (segmentToDeleteId ? args?.segments?.delete$(segmentToDeleteId) : of(undefined)) ?? of(undefined);
        },
      },
      results: {
        isLoading,
        pagedResult,
        refresh: (pageNumber?: number) => {
          pageNumber = pageNumber || 1;
          const newSkip = (pageNumber - 1) * take();
          const previousSkip = skip();

          // If we trigger both set, we will make 2 queries with RXJS
          if (previousSkip !== newSkip) {
            skip.set(newSkip);
          } else {
            flag.set(!flag());
          }
        },
      },
      filters: {
        available: availableFilters(),
        requestedCodes,
        service: dynamicFilterService,
        requested: filters as any,
        change: (codes: SupportedFilters<any>) => {
          requestedCodes.set(codes);
        },
      },
      export: args.exportToFile$
        ? {
            execute: exportToFile,
          }
        : undefined,
      destroy: () => {
        destroy$.next();
        destroy$.complete();
        dynamicFilterService.destroy();
      },
    };
  };

  createInMemoryTable = <TResult, TColumnTypes extends readonly Type<TableColumnDefinition<unknown>>[] = []>(
    rows: TResult[],
    take?: number,
    dynamicColumnArgs?: DynamicColumnArgs<TResult, TColumnTypes>,
    injector?: Injector
  ): FilterTable2<TResult, readonly any[], TColumnTypes> => {
    // readonly any[] should be never
    const requestedColumns = this.getRequestedColumns();
    const finalTake = take ?? rows.length;
    const pagedResult = signal<TableQueryResult<TResult>>({
      take: finalTake,
      skip: 0,
      count: rows.length,
      results: rows.slice(0, finalTake),
    });
    const { columns } = this.getColumnObject(dynamicColumnArgs, requestedColumns, pagedResult, injector);
    return {
      columns,
      results: {
        pagedResult,
        refresh: (pageNumber?: number) => {
          const p = pagedResult();
          const skip = ((pageNumber ?? 1) - 1) * finalTake;
          pagedResult.set({
            ...p,
            skip,
            results: rows.slice(skip, skip + finalTake),
          });
        },
        isLoading: signal(false),
      },
      destroy: () => {},
    };
  };

  private getRequestedColumns(): WritableSignal<string[]> {
    return signal<string[]>([], { equal: (a, b) => JSON.stringify(a) === JSON.stringify(b) });
  }

  private getColumnObject<TResult, TColumnTypes extends readonly Type<TableColumnDefinition<unknown>>[] = []>(
    dynamicFilterArgs: DynamicColumnArgs<TResult, TColumnTypes> | undefined,
    requestedColumns: WritableSignal<string[]>,
    pagedResult: Signal<TableQueryResult<TResult>>,
    injector?: Injector
  ): {
    columns: FilterTable2<TResult, any, TColumnTypes>['columns'];
    defaultColumns: string[];
    hasChangedColumns: WritableSignal<boolean>;
  } {
    const hasChangedColumns = signal(false);
    const availableColumns = dynamicFilterArgs?.columns.map(column => this.injector.get(column)) ?? [];
    const availableColumnIds = availableColumns.map(n => n.id);
    const defaultColumns = availableColumnIds.filter(m => m !== 'quickbooks');
    const columns = computed<TableColumnDefinition<unknown>[]>(() => {
      // Id & Deleted is always added no matter what.
      // We remove QuickBooks by default, but you may request it.
      const finalRequested = requestedColumns().length ? requestedColumns() : defaultColumns;
      const finalColumns = availableColumns
        .filter(availableColumn => availableColumn.required || finalRequested.includes(availableColumn.id))
        .map(availableColumn => availableColumn.id);

      const filter = () => {
        if (finalColumns) {
          return availableColumns.filter(availableColumn => finalColumns.includes(availableColumn.id));
        }

        return availableColumns;
      };

      const hasDeletedEntries = pagedResult().results.some(result => dynamicFilterArgs?.hasDeletedData?.(result) ?? false);
      return filter().filter(m => hasDeletedEntries || m.id !== 'deleted');
    });

    const loadedAvailableColumns = signal<TableColumnDefinition<unknown>[]>([]);
    effect(
      () => {
        const execute = async () => {
          for (const availableColumn of availableColumns) {
            await availableColumn.load?.();
          }

          loadedAvailableColumns.set(availableColumns);
        };

        void execute();
      },
      { injector }
    );

    return {
      columns: {
        available: loadedAvailableColumns,
        requested: columns,
        change: (columns: string[]) => {
          requestedColumns.set(columns);
          hasChangedColumns.set(true);
        },
      },
      defaultColumns,
      hasChangedColumns,
    };
  }

  private saveCache(key: TechnicianSetting | undefined, data: Partial<FilterTemporaryStorage>) {
    if (key) {
      const finalKey = this.getCacheKey(key);
      const cacheData = this.cacheService.getObject<Partial<FilterTemporaryStorage>>(finalKey);
      this.cacheService.setObject(finalKey, {
        ...cacheData,
        ...data,
      });
    }
  }

  private restoreCache(key: TechnicianSetting | undefined, defaultColumns: string[]): FilterTemporaryStorage {
    if (key) {
      const finalKey = this.getCacheKey(key);
      const cacheData = this.cacheService.getObject<Partial<FilterTemporaryStorage>>(finalKey);

      return {
        ...this.getDefaultStorage(defaultColumns),
        ...cacheData,
      };
    }

    return this.getDefaultStorage(defaultColumns);
  }

  private getCacheKey(key: TechnicianSetting): string {
    return filterStorageKey.replace('$0', key.toString());
  }

  private clearCache(key: TechnicianSetting): void {
    const finalKey = this.getCacheKey(key);
    this.cacheService.remove(finalKey);
  }

  private getDefaultStorage(defaultColumns: string[]): FilterTemporaryStorage {
    return {
      columns: defaultColumns,
      isDirty: false,
      currentSegmentId: null,
      filters: [],
    };
  }
}

@Injectable()
/** @deprecated */
export class FilterService {
  private readonly injector = inject(Injector);
  private readonly authService = inject(AuthService);
  private readonly technicianSettingsService = inject(TechnicianSettingsService);
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);

  createTable = <T, U extends Record<string, unknown> | undefined = undefined, TFilterData = unknown>(
    getRows$: FilterRegister0<T, U>,
    filterSettingKey: TechnicianSetting | undefined,
    take?: number,
    withLoading: boolean = true,
    extraFilterData?: TFilterData
  ): FilterTable<T> => {
    const filterHelper$ = this.register(filterSettingKey, getRows$, take, withLoading, extraFilterData).pipe(shareReplay(1));

    const pagedResult$ = filterHelper$.pipe(
      filter(notEmpty),
      switchMap(filterHelper => filterHelper.resultsChanged)
    );

    const showTable$ = pagedResult$.pipe(map(x => x.results.length !== 0));

    const refreshResults = (pageNumber?: number) => {
      filterHelper$.pipe(first()).subscribe(x => {
        x.refreshResults(pageNumber);
      });
    };

    return {
      data$: combineLatest([pagedResult$, filterHelper$, showTable$]).pipe(
        map(([pagedResult, filterHelper, showTable]) => ({
          pagedResult,
          filterHelper,
          showTable,
        })),
        startWith(defaultFilterTableData<T>())
      ),
      refreshResults,
    };
  };

  createInMemoryTable = <T>(rows$: Observable<T[]>, take?: number): FilterTable<T> => {
    const filterHelper$ = rows$.pipe(
      switchMap(rows => this.registerWithData(rows, take)),
      shareReplay(1)
    );

    const pagedResult$ = filterHelper$.pipe(
      filter(notEmpty),
      switchMap(filterHelper => filterHelper.resultsChanged)
    );

    const showTable$ = pagedResult$.pipe(map(x => x.results.length !== 0));

    const refreshResults = (pageNumber?: number) => {
      filterHelper$.pipe(first()).subscribe(x => {
        x.refreshResults(pageNumber);
      });
    };

    return {
      data$: combineLatest([pagedResult$, filterHelper$, showTable$]).pipe(
        map(([pagedResult, filterHelper, showTable]) => ({
          pagedResult,
          filterHelper,
          showTable,
        })),
        startWith(defaultFilterTableData<T>())
      ),
      refreshResults,
    };
  };

  private registerWithData<T>(data: T[], take?: number): Observable<FilterHelper<T>> {
    return this.register<T>(
      undefined,
      filter => {
        const skip = filter.skip ?? 0;

        // If we remove entries, make sure the skip value is always smaller than the total number of entries
        const localSkip = skip >= data.length ? Math.max(data.length - (take ?? 0), 0) : skip;
        const results = data.slice(localSkip, localSkip + (filter.take ?? 0));

        const pagedResult: PagedResult<T> = {
          take: filter.take ?? 0,
          skip: localSkip,
          count: data.length,
          results,
          error: null,
        };

        return of(pagedResult);
      },
      take,
      false
    );
  }

  register<T, U extends Record<string, unknown> | undefined = undefined, TFilterData = unknown>(
    filterSettingKey: TechnicianSetting | undefined,
    fnc: FilterRegister0<T, U>,
    take?: number,
    withLoading: boolean = true,
    extraFilterData?: TFilterData
  ): Observable<FilterHelper<T>> {
    return this.restoreFilterContext(filterSettingKey, take).pipe(
      map(filterContext => {
        const isLoading$ = new BehaviorSubject(false);
        const run$ = new Subject<void>();

        const makeRequest$ = (
          take: number | undefined,
          skip: number,
          filters: FilterInfo[],
          orders: OrderBySettings[]
        ): Observable<PagedResult<T>> => {
          if (withLoading) {
            isLoading$.next(true);
          }

          return fnc({ skip, orders, filters: this.serialize<U>(filters), take }).pipe(
            finalize(() => {
              isLoading$.next(false);
            })
          );
        };

        return {
          context: filterContext,
          resultsChanged: combineLatest([filterContext.skip, filterContext.filters, filterContext.orders]).pipe(
            takeUntil(run$),
            switchMap(([skip, filters, orders]) => makeRequest$(filterContext.take, skip, filters, orders)),
            defaultIfEmpty({
              count: 0,
              skip: 0,
              take: 0,
              results: [] as T[],
              error: null,
            }),
            shareReplay(1)
          ),
          isLoading: isLoading$.asObservable(),
          openDialog: () => {
            this.showFilterDialog(filterContext, extraFilterData);
          },
          clearAllFilters: () => {
            filterContext.filtersSubject.next([]);
            this.saveFilterContext(filterContext).subscribe();
          },
          openChange: this.createOpenChange(filterContext, extraFilterData, filterContext.openSubject),
          refreshResults: (pageNumber?: number) => {
            pageNumber = pageNumber || 1;
            const newSkip = (pageNumber - 1) * filterContext.take;
            filterContext.skipSubject.next(newSkip);
          },
          destroy: () => {
            run$.complete();
          },
        };
      })
    );
  }

  // 3CConnect wants to have different behavior on the filter.
  createSubFilter<T, TFilterData = any>(
    filterHelper: FilterHelper<T>,
    initialState: boolean,
    extraFilterData?: TFilterData
  ): FilterHelper<T> {
    const internalContext = filterHelper.context as InternalFilterContext;
    const openSubject = new BehaviorSubject<boolean>(initialState);
    const newFilterHelper = {
      clearAllFilters: filterHelper.clearAllFilters,
      context: {
        defaultFilters: internalContext.defaultFilters,
        defaultOrders: internalContext.defaultOrders,
        filterDialog: internalContext.filterDialog,
        filterOpenKey: internalContext.filterOpenKey,
        filters: internalContext.filters,
        filterSettingKey: internalContext.filterSettingKey,
        filtersSubject: internalContext.filtersSubject,
        open: openSubject.asObservable(),
        openSubject,
        openChange: null,
        orders: internalContext.orders,
        ordersSubject: internalContext.ordersSubject,
        skip: internalContext.skip,
        skipSubject: internalContext.skipSubject,
        take: internalContext.take,
      } as InternalFilterContext,
      destroy: filterHelper.destroy,
      openChange: filterHelper.openChange,
      refreshResults: filterHelper.refreshResults,
      resultsChanged: filterHelper.resultsChanged,
      isLoading: filterHelper.isLoading,
      openDialog: filterHelper.openDialog,
    } as FilterHelper<T>;

    const newInternalContext = newFilterHelper.context as InternalFilterContext;
    newFilterHelper.openChange = this.createOpenChange(newInternalContext, extraFilterData, openSubject, internalContext.openSubject);

    return newFilterHelper;
  }

  private createOpenChange<TFilterData>(
    filterContext: InternalFilterContext,
    filterData: TFilterData,
    openSubject: BehaviorSubject<boolean>,
    parentOpenSubject?: BehaviorSubject<boolean>
  ): (open: boolean) => void {
    return (open: boolean) => {
      openSubject.next(open);
      if (parentOpenSubject) {
        parentOpenSubject.next(open);
      }

      if (open && filterContext.filtersSubject.value.length === 0) {
        this.showFilterDialog(filterContext, filterData);
      }

      this.saveFilterContext(filterContext).subscribe();
    };
  }

  private restoreFilterContext(
    filterSettingKey: TechnicianSetting | undefined,
    take?: number
  ): Observable<InternalFilterContext & RequiredPick<InternalFilterContext, 'filters' | 'skip' | 'orders' | 'take'>> {
    const defaults: FilterContextDefaults = filterSettingKey
      ? getDefaults(filterSettingKey, this.authService.getCurrentToken())
      : {
          filters: [],
          orders: [],
        };

    return this.technicianSettingsService.getMultiple(...[filterSettingKey, defaults.openKey].filter(notEmpty)).pipe(
      map(m => {
        let filterValues = defaults.filters ?? [];
        if (filterSettingKey) {
          const v = m[filterSettingKey];
          filterValues = (v && JSON.parse(v)) ?? defaults.filters ?? [];
        }

        // We open the filter IF we didn't have a setting and we had some filters.
        // Or simply if it was opened before
        const openKey = defaults.openKey ?? '';
        const filterOpen = !!(openKey && (m[openKey] === '1' || (m[openKey] == null && (filterValues?.length ?? 0) > 0)));

        const ordersSubject = new BehaviorSubject(defaults.orders ?? []);
        const filtersSujbect = new BehaviorSubject(filterValues);
        const openSubject = new BehaviorSubject(filterOpen);
        const skipSubject = new BehaviorSubject(0);

        const result = {
          filters: filtersSujbect.asObservable(),
          orders: ordersSubject.asObservable(),
          open: openSubject.asObservable(),
          skip: skipSubject.asObservable(),
          filtersSubject: filtersSujbect,
          ordersSubject,
          openSubject,
          skipSubject,
          filterDialog: defaults.dialog,
          filterOpenKey: defaults.openKey,
          filterSettingKey,
          defaultFilters: defaults.filters,
          defaultOrders: defaults.orders,
          take: take || DEFAULT_TAKE,
        };

        return result;
      })
    );
  }

  private saveFilterContext(filter: InternalFilterContext): Observable<void> {
    const settings: Dictionary<TechnicianSetting, string> = {};
    if (filter.filterOpenKey) {
      settings[filter.filterOpenKey] = filter.openSubject.value ? '1' : '0';
    }

    if (filter.filterSettingKey) {
      settings[filter.filterSettingKey] = JSON.stringify(filter.filtersSubject.value);
    }

    return this.technicianSettingsService.save(settings);
  }

  private showFilterDialog<TFilterData>(filterContext: InternalFilterContext, extraFilterData: TFilterData): void {
    const filters = filterContext.filtersSubject.getValue().slice(0);
    this.copyEachObject(filters);
    const filterData: FilterData = {
      ...extraFilterData,
      filters,
    };
    const dialog = this.dialog2.filter(filterContext.filterDialog as ANY, {
      width: '600px',
      filter: {
        data: filterData,
      },
    });

    dialog.afterClosed().subscribe((filterResult: FilterResult<FilterData> | undefined) => {
      if (filterResult?.action === FilterAction.AddFilter) {
        filterContext.filtersSubject.next((filterResult.result as ANY).filters);
        this.saveFilterContext(filterContext).subscribe();
      }

      dialog.close();
    });
  }

  private copyEachObject<T>(objects: T[]): void {
    for (let i = 0; i < objects.length; i++) {
      objects[i] = Object.assign({}, objects[i]);
    }
  }

  private serialize<T extends Record<string, unknown> | undefined>(filters: FilterInfo[]): T {
    const results: Record<string, unknown> = {};
    filters.forEach(object => {
      const key: string = object.id;
      results[key] = object.value;
    });

    return results as T;
  }
}

