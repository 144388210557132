<div class="input-resource input-image" [class.show-text-in-item]="showTextInItem()">
  <div>
    <ng-select
      [(ngModel)]="value"
      [items]="visibleOptions()"
      [clearable]="clearable()"
      [appendTo]="dropdownParent() ?? ''"
      [searchFn]="searchFn"
      [bindValue]="bindValue()"
      [bindLabel]="bindLabel()"
      [selectOnTab]="true"
      [editableSearchTerm]="false"
      [trackByFn]="trackByFn"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="item-image" [class.image-center]="imageCenter()">
          @if (item.image) {
            <span class="image"><img src="{{ item.image }}" alt="" /></span>
          }
          <div class="text">
            <div class="primary" [ngOptionHighlight]="search">{{ item.text }}</div>
            @if (item.secondary) {
              <div class="secondary" [ngOptionHighlight]="search">{{ item.secondary }}</div>
            }
          </div>
        </div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item" let-search="searchTerm">
        <div class="item-image">
          @if (item.image) {
            <span class="image"><img src="{{ item.image }}" alt="" /></span>
          }
          <div class="text">
            <div class="primary" [ngOptionHighlight]="search">{{ item.text }}</div>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
