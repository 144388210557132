@for (selectedFilter of selectedFilters(); track selectedFilter.id) {
  <wm-filter-button
    #el="templateRef"
    templateRef
    [text]="selectedFilter.text"
    [matIcon]="selectedFilter.matIcon"
    [imageUrl]="selectedFilter.imageUrl"
    (textClick)="changeFilterClick(selectedFilter.id, el.elementRef)"
    (init)="initFilterClick(selectedFilter.id, el.elementRef)"
    (removeClick)="removeFilterClick(selectedFilter.id)"
  />
}
<div class="add-filter">
  <button type="button" class="as-link" (click)="addFilterClick()" [disabled]="addFilterDisabled()" #addFilterButton>
    <img src="/assets/images/add-assorted.svg" alt="" /> Add filter
  </button>
</div>
