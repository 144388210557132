import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, computed, inject, input, viewChild } from '@angular/core';
import { ButtonComponent } from '@controls/button/button.component';
import { BackdropType, DIALOG_SERVICE_IMPL, type PopoverButton } from '@models/dialog';
import { type FilterTable2 } from '@services/pager.service';
import { map } from 'rxjs';
import { SegmentDynamicSaveComponent } from './segment-dynamic-save.component';

@Component({
  selector: 'wm-segment-dynamic',
  templateUrl: 'segment-dynamic.component.html',
  styleUrl: 'segment-dynamic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ButtonComponent],
})
export class SegmentDynamicComponent<T> {
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);

  readonly segments = input.required<NonNullable<FilterTable2<T>['segments']>>();

  readonly currentSegmentName = computed(() => {
    const currentSegment = this.segments().requested();
    const segments = this.segments().available();

    return segments?.find(x => x.id === currentSegment)?.text ?? 'Default List';
  });

  readonly changeSegmentButton = viewChild<ElementRef<HTMLElement>, ElementRef<HTMLElement>>('changeSegmentButton', {
    read: ElementRef<HTMLElement>,
  });

  changeSegments(): void {
    const segmentObj = this.segments();
    const segments = segmentObj?.available();
    if (!segmentObj || !segments?.length) {
      return;
    }

    const button = this.changeSegmentButton();
    if (button) {
      const defaultSegment: PopoverButton = {
        id: 0,
        text: 'Default List',
        click: () => {
          segmentObj.change(null);
        },
        groupCode: 'default',
        selected: !segmentObj.requested(),
      };
      this.dialog2.menu(
        [defaultSegment].concat(
          segments.map(segment => {
            return {
              id: segment.id,
              text: segment.text,
              title: segment.title,
              click: () => {
                segmentObj.change(segment.id);
              },
              groupCode: segment.system ? 'system' : 'user',
              selected: segmentObj.requested() === segment.id,
            } satisfies PopoverButton;
          })
        ),
        button.nativeElement,
        'below',
        BackdropType.Transparent,
        [
          {
            code: 'user',
            name: 'User Defined',
            order: 1,
          },
          {
            code: 'system',
            name: 'System Defined',
            order: 2,
          },
        ]
      );
    }
  }

  saveSegment(): void {
    const segmentObj = this.segments();
    if (segmentObj) {
      SegmentDynamicSaveComponent.open(this.dialog2, {
        segmentId: segmentObj.requested(),
        segmentName: this.currentSegmentName(),
        save$: (segmentId: Id | null, segmentName: string) => {
          return segmentObj.save(segmentId, segmentName).pipe(map(() => undefined));
        },
      });
    }
  }
}
