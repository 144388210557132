import { type FilterData, type FilterInfo } from '@models/filter-models';

/** @deprecated */
export class FilterComponent {
  get filters(): FilterInfo[] | null {
    return this.filterData.filters;
  }

  set filters(value: FilterInfo[] | null) {
    this.filterData.filters = value as ANY;
    this.filtersChanged();
  }

  constructor(protected filterData: FilterData) {
    // We will use a copy of the filterData
    this.filterData.filters = this.filterData.filters.slice(0);
  }

  filtersChanged(): void {
    // abstract
  }

  getValue(filterName: string, predicate?: (filterInfo: FilterInfo) => any): any | undefined {
    if (this.filters) {
      const filter = this.filters.find(m => m.id === filterName);
      if (filter) {
        if (predicate) {
          return predicate(filter);
        }

        return filter.value;
      }
    }
  }

  setValue(filterName: string, display: string | undefined, value: any): void {
    if (!this.filters) {
      this.filters = [];
    }

    let filter = this.filters.find(m => m.id === filterName);
    if (!filter) {
      filter = {
        id: filterName,
        text: display,
        value,
      };
      this.filters.push(filter);
    }

    filter.text = display;
    filter.value = value;
  }

  removeValue(filterName: string): void {
    if (this.filters) {
      const filterIndex = this.filters.findIndex(m => m.id === filterName);

      if (filterIndex >= 0) {
        this.filters.splice(filterIndex, 1);
      }
    }
  }
}
