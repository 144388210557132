<div matDialogTitle>
  @if (data.loading) {
    <div class="loading">
      <span class="spinner"></span>
    </div>
  }

  @if (!data.loading && data.type) {
    <img class="type" src="/assets/images/dialog-{{ dialogImage }}.svg" alt="" />
  }

  <div class="text">
    <div class="title">{{ data.title }}</div>
    @if (data.text) {
      <div class="subtitle" [innerHTML]="data.text"></div>
    }
  </div>
  @if (!data.disableExit) {
    <button type="button" (click)="close()" class="as-link close">
      <img src="/assets/images/tag-remove.svg" title="Close" alt="Close" />
    </button>
  }
</div>
@if (data.buttons?.length) {
  <div matDialogActions>
    <div class="buttons">
      @for (button of data.buttons; track $index) {
        <button
          wm-button
          type="button"
          [ngClass]="button.secondary"
          (click)="internalButtonClick(button)"
          [attr.cdkFocusInitial]="button.focusInitial ? true : null"
        >
          {{ button.text }}
        </button>
      }
    </div>
  </div>
}
