import { Injectable, inject } from '@angular/core';
import { type TagEntityForEntityInformation, type TagEntityInformation, type TagEntityType } from '@models/tag-models';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { series } from '@utility/observable';
import { concatMap, map, share, tap, type MonoTypeOperatorFunction } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import { uniq } from 'lodash';

@Injectable()
export class TagEntitiesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  save(tagEntityInformation: TagEntityInformation) {
    return this.http.post<TagEntityInformation>(this.url.tagEntitySave, tagEntityInformation).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  listForEntity(entityType: TagEntityType, entityId: Id) {
    return this.http.get<TagEntityForEntityInformation[]>(
      this.url.tagEntityListForEntity.replace('$0', entityType.toString()).replace('$1', entityId.toString())
    );
  }

  delete(id: Id) {
    return this.http.delete<void>(this.url.tagEntityDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  private clearCache() {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.Tags);
    }
  }
}

export const saveTags = <T extends { id: Id; tagEntityInformations: TagEntityInformation[] | null }>(
  tagEntitiesService: TagEntitiesService,
  previous: TagEntityInformation[] | null,
  entityType: TagEntityType,
  tagsToDelete?: Id[]
): MonoTypeOperatorFunction<T> => {
  return source$ =>
    source$.pipe(
      concatMap(next => {
        const toDelete = (previous ?? [])
          .filter(p => !(next.tagEntityInformations ?? []).some(n => n.text === p.text))
          .filter(p => !!p.id)
          .map(x => x.id)
          .concat(tagsToDelete ?? []);

        const toDelete$ = uniq(toDelete).map(id => tagEntitiesService.delete(id));

        const toSave$ = (next.tagEntityInformations ?? [])
          .filter(n => !n.id)
          .map(n =>
            tagEntitiesService.save({
              id: 0,
              entityId: next.id,
              entityType,
              text: n.text,
            })
          );

        return series([...toDelete$, ...toSave$]).pipe(map(() => next));
      })
    );
};

