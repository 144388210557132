@if (betaFeatureOn()) {
  <wm-table-action
    [class.as-page-title]="asPageTitle()"
    [class.with-filter]="asPageTitle()"
    [printPage]="printPage()"
    [helpPage]="helpPage()"
    [helpSection]="helpSection()"
    [actions]="tableActions()"
  >
    <div class="title with-view">
      <div class="title-section">
        <ng-content select="[title-projection]" />
        <wm-segment-dynamic [segments]="table()!.segments!" />
      </div>
      <wm-column-dynamic [columns]="table()!.columns" />
    </div>
  </wm-table-action>

  <div class="dynamic">
    @if (table()!.filters; as filters) {
      <wm-filter-dynamic [filters]="filters" />
    }
  </div>

  <wm-table
    [rows]="table()!.results.pagedResult().results"
    [isLoading]="table()!.results.isLoading()"
    [withFooter]="withFooter()"
    [disablePadding]="disablePadding()"
    [includeColgroup]="includeColgroup()"
    [isFixedLayout]="isFixedLayout()"
    [rowSelection]="rowSelection()"
    [disableAllRowSelection]="disableAllRowSelection()"
    [rowDelete]="rowDelete()"
    [rowAdd]="rowAdd()"
    [errors]="errors()"
    [withHeader]="withHeader()"
    [withThead]="withThead()"
    [removeText]="removeText()"
    [rowSelectionRowSpan]="rowSelectionRowSpan()"
    [selectionModel]="selectionModel()"
    [rowAddText]="rowAddText()"
    [customRowClassFnc]="customRowClassFnc()"
    [draggable]="draggable()"
    [orderable]="orderable()"
    [shouldIncludeRowSelection]="shouldIncludeRowSelection()"
    [isRowSelectionDisabled]="isRowSelectionDisabled()"
    [shouldIncludeRowDelete]="shouldIncludeRowDelete()"
    [shouldShowCheckbox]="shouldShowCheckbox()"
    [isRowDeleteDisabled]="isRowDeleteDisabled()"
    [getRowTestId]="getRowTestId()"
    [idTracking]="idTracking()"
    (rowAddClick)="rowAddClick.emit($event)"
    (rowDeleteClick)="rowDeleteClick.emit($event)"
    (rowUpdated)="rowUpdated.emit($event)"
  >
    <ng-template #tableColgroupTemplate>
      @for (columnDefinition of displayColumns(); track columnDefinition.id) {
        @for (column of normalizeColumn(columnDefinition, table()!.results.pagedResult().results); track column.id) {
          <col [className]="['col', 'col-' + column.id].concat(column.classes ?? []).join(' ')" />
        }
      }
    </ng-template>
    <ng-template #tableHeaderTemplate>
      @for (columnDefinition of displayColumns(); track columnDefinition.id) {
        @for (column of normalizeColumn(columnDefinition, table()!.results.pagedResult().results); track column.id) {
          <th>{{ column.text }}</th>
        }
      }
    </ng-template>
    <ng-template #tableBodyTemplate let-line let-i="index">
      @for (columnDefinition of displayColumns(); track columnDefinition.id) {
        @for (column of normalizeColumn(columnDefinition, table()!.results.pagedResult().results); track column.id) {
          <td [attr.data-testid]="columnDefinition.dataTestId">
            @if (columnDefinition.renderComponent?.(line, column.id); as componentData) {
              <ng-container *ngComponentOutlet="componentData.component; inputs: componentData.inputs" />
            }
          </td>
        }
      }
    </ng-template>
    <ng-template #footerTemplate>
      @if (table()!.results.pagedResult().results.length !== 0) {
        <div wm-table-pager class="wm-pager">
          <wm-pager
            [showTotalCount]="showTotalCount()"
            [pagedResult]="table()!.results.pagedResult()"
            (currentPageChange)="table()!.results.refresh($event)"
          />
        </div>
      }
    </ng-template>
    <ng-template #noResults>
      <div class="no-entry">
        @if (noResult()?.url; as url) {
          <div class="robot">
            <img src="{{ url }}" alt="" />
          </div>
        }
        @if (noResult()?.text; as text) {
          <div class="text">{{ text }}</div>
        }
        @if (noResult()?.createText; as createText) {
          <div class="button">
            <button wm-button type="button" (click)="create.emit()">{{ createText }}</button>
          </div>
        }
      </div>
    </ng-template>
  </wm-table>
} @else {
  <wm-table
    [rows]="tableData().pagedResult.results"
    [isLoading]="isLoading()"
    [withFooter]="withFooter()"
    [disablePadding]="disablePadding()"
    [includeColgroup]="includeColgroup()"
    [isFixedLayout]="isFixedLayout()"
    [rowSelection]="rowSelection()"
    [disableAllRowSelection]="disableAllRowSelection()"
    [rowDelete]="rowDelete()"
    [rowAdd]="rowAdd()"
    [errors]="errors()"
    [withHeader]="withHeader()"
    [withThead]="withThead()"
    [removeText]="removeText()"
    [rowSelectionRowSpan]="rowSelectionRowSpan()"
    [selectionModel]="selectionModel()"
    [rowAddText]="rowAddText()"
    [customRowClassFnc]="customRowClassFnc()"
    [draggable]="draggable()"
    [orderable]="orderable()"
    [shouldIncludeRowSelection]="shouldIncludeRowSelection()"
    [isRowSelectionDisabled]="isRowSelectionDisabled()"
    [shouldIncludeRowDelete]="shouldIncludeRowDelete()"
    [shouldShowCheckbox]="shouldShowCheckbox()"
    [isRowDeleteDisabled]="isRowDeleteDisabled()"
    [getRowTestId]="getRowTestId()"
    [idTracking]="idTracking()"
    (rowAddClick)="rowAddClick.emit($event)"
    (rowDeleteClick)="rowDeleteClick.emit($event)"
    (rowUpdated)="rowUpdated.emit($event)"
  >
    <ng-template #tableColgroupTemplate>
      @for (column of columns(); track column.id) {
        <col [className]="['col', 'col-' + column.id].concat(column.classes ?? []).join(' ')" />
      }
    </ng-template>
    <ng-template #tableHeaderTemplate>
      @for (column of columns(); track column.id) {
        <th>{{ column.text }}</th>
      }
    </ng-template>
    <ng-template #tableBodyTemplate let-line let-i="index">
      @for (column of columns(); track column.id) {
        <td [attr.data-testid]="column.dataTestId">
          @if (column.renderComponent?.(line, column.id); as componentData) {
            <ng-container *ngComponentOutlet="componentData.component; inputs: componentData.inputs" />
          }
        </td>
      }
    </ng-template>
    <ng-template #footerTemplate>
      @if (tableData().showTable) {
        <div wm-table-pager class="wm-pager">
          <wm-pager
            [showTotalCount]="showTotalCount()"
            [pagedResult]="tableData().pagedResult"
            (currentPageChange)="tableDeprecated()!.refreshResults($event)"
          ></wm-pager>
        </div>
      }
    </ng-template>
    <ng-template #noResults>
      <div class="no-entry">
        @if (noResult()?.url; as url) {
          <div class="robot">
            <img src="{{ url }}" alt="" />
          </div>
        }
        @if (noResult()?.text; as text) {
          <div class="text">{{ text }}</div>
        }
        @if (noResult()?.createText; as createText) {
          <div class="button">
            <button wm-button type="button" (click)="create.emit()">{{ createText }}</button>
          </div>
        }
      </div>
    </ng-template>
  </wm-table>
}
