import { Injectable, inject } from '@angular/core';
import { type CallType } from '@models/call-type';
import { type CallInformation, type TakeChargeModel } from '@models/cards/call-information';
import { type Observable } from 'rxjs';
import { HttpClientService, type ExtraOptionsJson } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class CallsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getCall(id: Id): Observable<CallInformation> {
    return this.http.get<CallInformation>(this.url.callSingle.replace('$0', id.toString()));
  }

  getCallType(id: Id): Observable<CallType> {
    return this.http.get<CallType>(this.url.callType.replace('$0', id.toString()));
  }

  save(callInformation: CallInformation, extraOptions?: ExtraOptionsJson): Observable<{ id: Id; eventId: Id }> {
    let obj = this.http.removeProperties(callInformation, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id; eventId: Id }>(this.url.callSave, obj, extraOptions);
  }

  patch(id: Id, obj: Partial<CallInformation>, extraOptions?: ExtraOptionsJson): Observable<void> {
    obj = this.http.setEmptyStringToNull(obj);

    return this.http.patch<void>(this.url.callPatch.replace('$0', id.toString()), obj, extraOptions);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.callDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.callRestore.replace('$0', id.toString()), null);
  }

  takeCharge(id: Id): Observable<TakeChargeModel> {
    return this.http.post<TakeChargeModel>(this.url.callTakeCharge.replace('$0', id.toString()), {});
  }
}
