export interface Entity {
  company: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface DutyCustomerEntity extends Entity {
  email: string | null;
  phone: string | null;
}

export function createEmpty(): Entity {
  return {
    company: null,
    firstName: null,
    lastName: null,
  };
}
