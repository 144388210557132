import { I18nPluralPipe, NgLocalization } from '@angular/common';
import { Injectable, Pipe, inject, type PipeTransform } from '@angular/core';
import { type LoanRateInformation, type WorkOrderLoanApplicationInformation } from '@models/loans';
import { Decimal } from 'decimal.js';
import { MoneyPipe } from './money.pipe';

type LoanData = 'rate' | 'ratePenalty' | 'terms' | 'company';
type LoanDataWithMonthly = LoanData | 'monthly';
type SupportedLoan = LoanRateInformation | WorkOrderLoanApplicationInformation;

@Pipe({
  name: 'loan',
  standalone: true,
})
@Injectable()
export class LoanPipe implements PipeTransform {
  private readonly moneyPipe = inject(MoneyPipe);
  private readonly localization = inject(NgLocalization);

  private readonly termsPluralMapping = {
    '=0': '0 Months',
    '=1': '1 Month',
    other: '# Months',
  };

  private readonly i18nPlural: I18nPluralPipe;

  constructor() {
    this.i18nPlural = new I18nPluralPipe(this.localization);
  }

  // Need to keep string|boolean to satisfy the PercentPipe
  transform(loan: SupportedLoan, loanData: LoanData): string;
  transform(loan: SupportedLoan, loanData: LoanDataWithMonthly, estimatedCost: Money): string;
  transform(loan: SupportedLoan, loanData: LoanDataWithMonthly, other: any = null): string {
    switch (loanData) {
      case 'ratePenalty':
        return this.getRatePenalty(loan);
      case 'rate':
        let rate = this.getRate(loan);
        if (!rate) {
          rate = this.getRatePenalty(loan);
          if (rate) {
            return `${rate} after promotion`;
          }
        }

        return rate;
      case 'terms':
        return this.getTerms(loan);
      case 'monthly':
        return this.getMonthlyPayment(loan, other);
    }

    return '';
  }

  private getRate(loanApplication: SupportedLoan): string {
    if (loanApplication.interestRateMax) {
      return `${loanApplication.interestRateMin}% - ${loanApplication.interestRateMax}%`;
    } else if (loanApplication.interestRateMin) {
      return `${loanApplication.interestRateMin}%`;
    }

    return '';
  }

  private getTerms(loanApplication: SupportedLoan): string {
    return this.i18nPlural.transform(loanApplication.termsInMonths, this.termsPluralMapping);
  }

  private getRatePenalty(loanApplication: SupportedLoan): string {
    if (loanApplication.interestRatePenalty) {
      return `${loanApplication.interestRatePenalty}%`;
    }

    return '';
  }

  private getMonthlyPayment(loanApplication: SupportedLoan, estimatedCost: Money): string {
    let price = '';

    if (estimatedCost) {
      if (typeof loanApplication.interestRateMin === 'number' && loanApplication.termsInMonths) {
        const factor1 = this.getPaymentFactor(loanApplication.interestRateMin, loanApplication.termsInMonths);
        const price1 = factor1.times(estimatedCost);
        price += this.moneyPipe.transform(price1.toNumber());

        if (typeof loanApplication.interestRateMax === 'number') {
          const factor2 = this.getPaymentFactor(loanApplication.interestRateMax, loanApplication.termsInMonths);
          const price2 = factor2.times(estimatedCost);
          price += ' - ';
          price += this.moneyPipe.transform(price2.toNumber());
        }
      }
    }

    return price;
  }

  private getPaymentFactor(interest: Percentage, numberOfMonths: number): Decimal {
    const monthlyInterest = new Decimal(interest).dividedBy(100).dividedBy(12);
    const divisor = new Decimal(1).minus(new Decimal(1).plus(monthlyInterest).toPower(new Decimal(numberOfMonths).times(-1)));
    const paymentFactor = monthlyInterest.dividedBy(divisor);

    return paymentFactor;
  }
}
