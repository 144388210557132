import { HttpContext, type HttpHeaders, type HttpParams } from '@angular/common/http';
import { type FormGroup, type AbstractControl, type ValidationErrors } from '@angular/forms';
import { SKIP_ERROR_HANDLING } from '@services/http/error.interceptor';
import { ALLOW_RETRIES, DEFAULT_RETRY_COUNT } from '@services/http/retry.interceptor';
import { getObjectKeys } from './object';

export interface HttpOptions<OBSERVE extends string, RESPONSETYPE extends string> {
  headers?: HttpHeaders | Record<string, string | string[]>;
  observe?: OBSERVE;
  context?: HttpContext;
  params?: HttpParams | Record<string, string | string[]>;
  reportProgress?: boolean;
  responseType?: RESPONSETYPE;
  withCredentials?: boolean;
}

export interface HttpOptionsJson extends HttpOptions<'body', 'json'> {}

export function trackingById(_index: number, obj: { id: Id } | undefined | null): Id {
  return obj?.id ?? 0;
}

export function notFalsy(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return { required: true };
  }

  return null;
}

export function withAllowRetries(context: HttpContext, retryCount: number = DEFAULT_RETRY_COUNT) {
  return context.set(ALLOW_RETRIES, retryCount);
}

export function allowRetries(retryCount: number = DEFAULT_RETRY_COUNT) {
  return { context: withAllowRetries(new HttpContext(), retryCount) };
}

export function withSkipBubbleError(context: HttpContext) {
  return context.set(SKIP_ERROR_HANDLING, true);
}

export function skipBubbleError() {
  return { context: withSkipBubbleError(new HttpContext()) };
}

export const removeAllControls = (formGroup: FormGroup) => {
  getObjectKeys(formGroup.controls).forEach(controlName => {
    formGroup.removeControl(controlName as string);
  });
};
