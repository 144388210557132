import { Injectable, inject } from '@angular/core';
import { WindowRefService } from './window-ref.service';

const easings = {
  linear(t: number) {
    return t;
  },
  easeInQuad(t: number) {
    return t * t;
  },
  easeOutQuad(t: number) {
    return t * (2 - t);
  },
  easeInOutQuad(t: number) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },
  easeInCubic(t: number) {
    return t * t * t;
  },
  easeOutCubic(t: number) {
    return --t * t * t + 1;
  },
  easeInOutCubic(t: number) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  easeInQuart(t: number) {
    return t * t * t * t;
  },
  easeOutQuart(t: number) {
    return 1 - --t * t * t * t;
  },
  easeInOutQuart(t: number) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },
  easeInQuint(t: number) {
    return t * t * t * t * t;
  },
  easeOutQuint(t: number) {
    return 1 + --t * t * t * t * t;
  },
  easeInOutQuint(t: number) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  },
};

const listeningEvents = ['mousedown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'keydown'];

@Injectable()
export class ScrollService {
  private readonly windowRefService = inject(WindowRefService);

  // eslint-disable-next-line @typescript-eslint/ban-types
  centerInViewPort(object: HTMLElement, duration: number, easing: keyof typeof easings = 'linear', callback?: Function): void {
    const scrollableParent = this.findScrollableParent(object);
    const scrollableParentHeight = scrollableParent.offsetHeight;
    const objectHeight = object.offsetHeight;
    const offsetTop = object.offsetTop;
    const scrollTo = Math.min(offsetTop, offsetTop - (scrollableParentHeight / 2 - objectHeight / 2));
    this.animateScrolling(object, scrollTo, duration, easing, callback);
  }

  animateScrolling(
    object: HTMLElement,
    scrollTo: number,
    duration: number,
    easing: keyof typeof easings = 'linear',
    // eslint-disable-next-line @typescript-eslint/ban-types
    _callback?: Function
  ): void {
    let hasReachedDestination = false;
    let hasRequestedToStop = false;
    const stopScrollingHandler = function () {
      hasRequestedToStop = true;
    };

    const scrollableParent = this.findScrollableParent(object);

    const startOffset = scrollableParent.scrollTop;
    this.run(startTime => {
      const scroll = (tick: number) => {
        const time = Math.min(1, (tick - startTime) / duration);
        const timeFunction = easings[easing](time);
        scrollableParent.scrollTop = Math.ceil(timeFunction * (scrollTo - startOffset) + startOffset);

        hasReachedDestination = Math.ceil(scrollableParent.scrollTop) === scrollTo;
        if (hasReachedDestination || hasRequestedToStop) {
          this.removeEventListener(scrollableParent, stopScrollingHandler);
          return;
        }

        this.run(scroll);
      };

      this.run(scroll);
    });

    this.addEventListener(scrollableParent, stopScrollingHandler);
  }

  private addEventListener(element: HTMLElement, callback: () => void) {
    listeningEvents.forEach(eventName => {
      element.addEventListener(eventName, callback);
    });
  }

  private removeEventListener(element: HTMLElement, callback: () => void) {
    listeningEvents.forEach(eventName => {
      element.removeEventListener(eventName, callback);
    });
  }

  private run(callback: (tick: number) => void) {
    this.windowRefService.nativeWindow.requestAnimationFrame(a => {
      callback(a);
    });
  }

  private findScrollableParent(element: HTMLElement, includeHidden: boolean = false): HTMLElement {
    let style = getComputedStyle(element);
    const excludeStaticParent = style.position === 'absolute';
    const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === 'fixed') {
      return document.body;
    }
    for (let parent: HTMLElement | null = element; (parent = parent.parentElement); ) {
      style = getComputedStyle(parent);
      if (excludeStaticParent && style.position === 'static') {
        continue;
      }
      if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
        return parent;
      }
    }

    return document.body;
  }
}
