import { type Creatable, type Mutable, type Updatable } from '@models/mutable';
import { type PartItemInformation, type ServiceRepairInformation } from '@models/price-book-models';
import { createReducer, on, type Action } from '@ngrx/store';
import { type SaveState } from '@services/save-store/save.actions';
import { indexBy } from '@utility/array';
import { clone } from '@utility/object';
import * as ServiceRepairDetailActions from './service-repair-detail.actions';

export type ServiceRepairInformationState = ServiceRepairInformation & Creatable & Updatable;
export type PartItemState = { ids: { id: Id; isNew: boolean }[] } & Updatable;
export type PartItemInformationState = Record<string, PartItemInformation & Creatable & Updatable>;
export type ServiceRepairCategoryIdState = { ids: [] } & Updatable;

const counters = {
  partItems: 0,
};

export interface EntitiesState {
  serviceRepair: ServiceRepairInformationState;
  categoryIds: ServiceRepairCategoryIdState;
  partItemIds: PartItemState;
  partItems: PartItemInformationState;
}

export interface ServiceRepairDetailState extends SaveState {
  edit: boolean;
  entities: EntitiesState;
  entitiesSnapshot: EntitiesState | null;
}

const initialState: ServiceRepairDetailState = {
  edit: false,
  entities: {
    serviceRepair: null as ANY,
    categoryIds: { ids: [] },
    partItemIds: null as ANY,
    partItems: null as ANY,
  },
  entitiesSnapshot: null,
  _submitCounter: 0,
  _error: null,
  _cacheCorrelationId: null as ANY,
  _correlationId: null as ANY,
};

const loadReducer = function <T extends { id: Id }>(arg: { data: T[] }) {
  return indexBy(arg.data, 'id');
};

const updateReducer = function (stateResource: Record<number, { id: Id }>, arg: { id: Id; patch: Partial<{ id: Id } & Mutable> }) {
  return {
    [arg.id]: { ...stateResource[arg.id], ...arg.patch, updated: true },
  };
};

const deleteReducer = function (stateResource: Record<number, { id: Id }>, arg: { id: Id }) {
  return {
    [arg.id]: { ...stateResource[arg.id], deleted: true },
  };
};

const entityReducer = function (state: ServiceRepairDetailState, key: keyof ServiceRepairDetailState['entities'], value: any) {
  const obj: Record<string, unknown> = {};
  obj[key] = value;
  return { ...state, entities: { ...state.entities, ...obj } };
};

// Start: serviceRepairDetailReducer
const serviceRepairDetailReducer = createReducer(
  initialState,
  on(ServiceRepairDetailActions.clear, (_, __) => {
    return clone(initialState);
  }),
  on(ServiceRepairDetailActions.loadServiceRepair, (state, action) => entityReducer(state, 'serviceRepair', action.data)),
  on(ServiceRepairDetailActions.updateServiceRepair, (state, action) => {
    const x = entityReducer(state, 'serviceRepair', { ...state.entities.serviceRepair, ...action.patch, updated: true });
    return x;
  }),
  on(ServiceRepairDetailActions.loadCategoryIds, (state, action) =>
    entityReducer(state, 'categoryIds', {
      updated: false,
      ids: action.data,
    })
  ),
  on(ServiceRepairDetailActions.setCategoryIds, (state, action) =>
    entityReducer(state, 'categoryIds', {
      updated: true,
      ids: action.data.slice(0),
    })
  ),
  on(ServiceRepairDetailActions.loadPartItems, (state, action) =>
    entityReducer(
      {
        ...state,
        entities: {
          ...state.entities,
          partItemIds: {
            updated: false,
            ids: action.data.map(n => ({ id: n.id, isNew: false })),
          },
        },
      },
      'partItems',
      loadReducer({ data: action.data })
    )
  ),
  on(ServiceRepairDetailActions.addPartItem, (state, action) => {
    let partItemId = action.data.id;
    if (partItemId <= 0) {
      partItemId = --counters.partItems;
    }

    const addObj = {
      [partItemId]: { ...action.data, id: partItemId, created: partItemId < 0 }, // TODO order
    };

    let ids = state.entities.partItemIds.ids;

    if (!ids.some(n => n.id === partItemId)) {
      ids = ids.concat([{ id: partItemId, isNew: true }]);
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        partItemIds: {
          updated: true,
          ids,
        },
        partItems: {
          ...state.entities.partItems,
          ...addObj,
        },
      },
    };
  }),
  on(ServiceRepairDetailActions.updatePartItem, (state, action) =>
    entityReducer(state, 'partItems', { ...state.entities.partItems, ...updateReducer(state.entities.partItems, action) })
  ),
  on(ServiceRepairDetailActions.deletePartItem, (state, action) =>
    entityReducer(
      {
        ...state,
        entities: {
          ...state.entities,
          partItemIds: {
            updated: true,
            ids: state.entities.partItemIds.ids.filter(m => m.id !== action.id),
          },
        },
      },
      'partItems',
      { ...state.entities.partItems, ...deleteReducer(state.entities.partItems, action) }
    )
  ),
  on(ServiceRepairDetailActions.saveServiceRepairSuccess, (state, action) =>
    entityReducer(state, 'serviceRepair', { ...action.serviceRepair, created: false, updated: false })
  ),
  on(ServiceRepairDetailActions.saveCategoryIdsSuccess, (state, _) =>
    entityReducer(state, 'categoryIds', { updated: false, ids: state.entities.categoryIds?.ids?.slice(0) || [] })
  ),
  on(ServiceRepairDetailActions.savePartItemSaveSuccess, (state, action) => {
    const newPartItems = { ...state.entities.partItems };
    newPartItems[action.newId] = {
      ...newPartItems[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newPartItems[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        partItemIds: {
          ...state.entities.partItemIds,
          ids: state.entities.partItemIds.ids.filter(m => m.id !== action.oldId).concat({ id: action.newId, isNew: false }),
        },
        partItems: newPartItems,
      },
    };
  }),
  on(ServiceRepairDetailActions.savePartItemServiceServiceRepairsSaveSuccess, (state, _) =>
    entityReducer(state, 'partItemIds', { updated: false, ids: state.entities.partItemIds.ids.map(n => ({ id: n.id, isNew: false })) })
  ),
  on(ServiceRepairDetailActions.editMode, state => ({ ...state, entitiesSnapshot: clone(state.entities), edit: true })),
  on(ServiceRepairDetailActions.cancel, state => ({
    ...state,
    entities: state.entitiesSnapshot as ANY,
    entitiesSnapshot: null,
    edit: false,
  }))
);

export function reducer(state: ServiceRepairDetailState | undefined, action: Action) {
  return serviceRepairDetailReducer(state, action);
}
