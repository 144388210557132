import { DatePipe } from '@angular/common';
import { inject, type Type } from '@angular/core';
import { FilterDynamicCheckboxComponent } from '@controls/filter-dynamic/filter-dynamic-checkbox.component';
import { FilterCustomerSiteSystemYearManufacturedComponent } from '@controls/filter-dynamic/filter-dynamic-custom-year-manufactured.component';
import { FilterDynamicDropdownComponent } from '@controls/filter-dynamic/filter-dynamic-dropdown.component';
import { FilterDynamicMagicDateComponent } from '@controls/filter-dynamic/filter-dynamic-magicdate.component';
import { FilterDynamicTextComponent } from '@controls/filter-dynamic/filter-dynamic-text.component';
import { TableDynamicNodeDateComponent } from '@controls/table-dynamic/table-dynamic-node-date.component';
import { TableDynamicNodeDeletedComponent } from '@controls/table-dynamic/table-dynamic-node-deleted.component';
import { TableDynamicNodeLinkComponent } from '@controls/table-dynamic/table-dynamic-node-link.component';
import { TableDynamicNodePhoneComponent } from '@controls/table-dynamic/table-dynamic-node-phone.component';
import { TableDynamicNodeQuickBooksComponent } from '@controls/table-dynamic/table-dynamic-node-quickbooks.component';
import { TableDynamicNodeTextComponent } from '@controls/table-dynamic/table-dynamic-node-text.component';
import { AddressPipe } from '@pipes/address.pipe';
import { EntityPipe } from '@pipes/entity.pipe';
import { CallReasonsService } from '@services/live/call-reasons.service';
import { CallWorkOrderTypesService } from '@services/live/call-work-order-types.service';
import { DutyCategoriesService } from '@services/live/duty-categories.service';
import { SystemTypesService } from '@services/live/system-types.service';
import { ZonesService } from '@services/live/zones.service';
import { notEmpty, unique } from '@utility/array';
import { firstValueFrom } from 'rxjs';
import { type CallReasonInformation } from './call-reason-models';
import { type CallWorkOrderTypeInformation } from './call-work-order-type-models';
import { type CustomerInformationForList } from './cards/customer-information';
import { type DutyCategoryInformation } from './duty-models';
import {
  combineData,
  getReversedFilterConfig,
  LogicalOperator,
  magicDateTransform,
  type FancyFilterConfig,
  type FancyFilterImpl,
  type FancyFilterMagicDateRange,
  type LogicalOperatorData,
  type TableColumnDefinition,
} from './filter-models';
import { type RequiredTextExtraResource1, type RequiredTextResource1 } from './resource';
import { SalesProposalStatus } from './sales-proposal-models';
import { type SystemTypeInformation } from './system-type-models';
import { TagEntityType } from './tag-models';
import { FilterTag } from './filters/filterTag';

export enum FilterCustomerDeletedData {
  NotSet = 0,
  DeletedOnly = 1,
  IncludesDeleted = 2,
}

interface FilterCustomerCustomerData {
  date?: FancyFilterMagicDateRange;
  name?: { text: string };
  entityStatus?: FilterCustomerDeletedData | null;
}

export class FilterCustomer implements FancyFilterImpl<FilterCustomerCustomerData, FilterCustomerDeletedData> {
  private readonly datePipe = inject(DatePipe);

  readonly code = 'Customer:Customer';

  getUIConfig(): FancyFilterConfig<FilterCustomerCustomerData, any>[] {
    const config1: FancyFilterConfig<FilterCustomerCustomerData, FilterCustomerDeletedData> = {
      get: controlValue => {
        return controlValue?.entityStatus ?? null;
      },
      set: (value, context) => {
        return combineData('entityStatus', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Status',
          code: this.code,
          name: `${this.code}-status`,
          options: [
            { id: 0, text: 'Active Customers Only' },
            { id: 1, text: 'Deleted Customers Only' },
            { id: 2, text: 'Includes Deleted Customers' },
          ],
        },
      },
    };

    const config2: FancyFilterConfig<FilterCustomerCustomerData, string> = {
      get: controlValue => {
        return controlValue?.name?.text ?? null;
      },
      set: (value, context) => {
        return combineData('name', value ? { text: value } : null, context);
      },
      ui: {
        type: FilterDynamicTextComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Name',
          code: this.code,
          name: `${this.code}-name`,
        },
      },
    };
    const config3: FancyFilterConfig<FilterCustomerCustomerData, FancyFilterMagicDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value?.from == null && value?.to == null ? null : value, context);
      },
      ui: {
        type: FilterDynamicMagicDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Created Date',
          code: this.code,
          name: `${this.code}-created`,
          fromLabel: 'After',
          toLabel: 'Before',
        },
      },
    };
    return [config1, config2, config3];
  }

  getUISummary(value: FilterCustomerCustomerData | null): string {
    let createdMoment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        createdMoment = `created between ${magicDateTransform(value.date, 'from', false, this.datePipe)} and ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      } else if (value?.date.from) {
        createdMoment = `created after ${magicDateTransform(value.date, 'from', false, this.datePipe)}`;
      } else if (value?.date.to) {
        createdMoment = `created before ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      }
    }

    let name: string | null = null;
    if (value?.name) {
      name = `name containing "${value.name.text}"`;
    }

    let entityStatus = '';
    switch (value?.entityStatus) {
      case FilterCustomerDeletedData.DeletedOnly:
        entityStatus = 'Customers are deleted';
        break;
      case FilterCustomerDeletedData.IncludesDeleted:
        entityStatus = 'Customers are active or deleted';
        break;
    }

    if (!entityStatus) {
      entityStatus = 'Customers are active';
    }

    return [entityStatus, name, createdMoment].filter(notEmpty).join(', ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Active/Deleted Customers',
    };
  }

  getIcon() {
    return { matIcon: 'person' };
  }
}

enum FilterAgreementStatusType {
  Agreement = 1,
  NonAgreement = 2,
  Expired = 3,
}

type FilterCustomerAgreementData = {
  value: FilterAgreementStatusType;
};

export class FilterCustomerAgreement implements FancyFilterImpl<FilterCustomerAgreementData, FilterAgreementStatusType> {
  readonly code = 'Customer:Agreement';

  getUIConfig(): FancyFilterConfig<FilterCustomerAgreementData, FilterAgreementStatusType>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.value ?? null;
        },
        set: (value, context) => {
          return combineData('value', value, context);
        },
        ui: {
          type: FilterDynamicDropdownComponent,
          closeAfterSelection: true,
          inputs: {
            code: this.code,
            name: this.code,
            clearable: true,
            options: [
              { id: FilterAgreementStatusType.Agreement, text: 'Active Agreement Customers' },
              { id: FilterAgreementStatusType.NonAgreement, text: 'Non-Agreement Customers' },
              { id: FilterAgreementStatusType.Expired, text: 'Expired Agreement Customers' },
            ],
          },
        },
      },
    ];
  }

  getUISummary(value: FilterCustomerAgreementData | null): string {
    if (value?.value != null) {
      switch (value.value) {
        case FilterAgreementStatusType.Agreement:
          return 'Customers with active agreements';
        case FilterAgreementStatusType.NonAgreement:
          return 'Customers without active agreements';
        case FilterAgreementStatusType.Expired:
          return 'Customers with expired agreements';
      }
    }

    return 'Customers with or without agreements';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Agreements',
    };
  }

  getIcon() {
    return { matIcon: 'local_police' };
  }
}

interface FilterCustomerResidentialData {
  items: Id[];
}

export class FilterCustomerResidential implements FancyFilterImpl<FilterCustomerResidentialData, Id> {
  readonly code = 'Customer:PropertyType';

  getUIConfig(): FancyFilterConfig<FilterCustomerResidentialData, Id>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.items[0] ?? null;
        },
        set: (value, context) => {
          return combineData('items', value === null ? null : [value], context);
        },
        ui: {
          type: FilterDynamicDropdownComponent,
          closeAfterSelection: true,
          inputs: {
            /// /label: 'Property Type',
            code: this.code,
            name: this.code,
            clearable: true,
            options: [
              { id: 1, text: 'Residential' },
              { id: 2, text: 'Commercial' },
            ],
          },
        },
      },
    ];
  }

  getUISummary(value: FilterCustomerResidentialData | null): string {
    if (value) {
      switch (value.items[0]) {
        case 1:
          return 'Property type is Residential';
        case 2:
          return 'Property type is Commercial';
      }
    }

    return 'Property type is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Property Type',
    };
  }

  getIcon() {
    return { matIcon: 'domain' };
  }
}

export class FilterCustomerTag extends FilterTag {
  constructor() {
    super('Customer:Tag', [
      TagEntityType.Customer,
      TagEntityType.Site,
      TagEntityType.Call,
      TagEntityType.WorkOrder,
      TagEntityType.SiteSystem,
    ]);
  }
}

interface FilterCustomerZoneData extends LogicalOperatorData {
  items?: (Id | null)[];
}

export class FilterCustomerZone implements FancyFilterImpl<FilterCustomerZoneData, Id[]> {
  private readonly zonesService = inject(ZonesService);

  readonly code = 'Customer:Zone';

  private options: RequiredTextResource1<Id | null>[] = [];

  async load(): Promise<void> {
    const zones: RequiredTextResource1<Id | null>[] = await firstValueFrom(this.zonesService.list());
    this.options = zones.concat([{ id: null, text: 'Without a zone' }]);
  }

  getUIConfig(): FancyFilterConfig<FilterCustomerZoneData, any>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.items ?? [];
        },
        set: (value: (number | null)[], context) => {
          return combineData('items', value?.length ? value : null, context);
        },
        ui: {
          type: FilterDynamicDropdownComponent,
          closeAfterSelection: false,
          inputs: {
            /// /label: 'Zone',
            code: this.code,
            name: this.code,
            clearable: true,
            multiselect: true,
            options: this.options,
          },
        },
      },
      getReversedFilterConfig(this.code),
    ];
  }

  getUISummary(value: FilterCustomerZoneData | null): string {
    const results: string[] = [];

    const getCustomerZone = (zoneId: Id | null, zoneName: string) => {
      return zoneId ? zoneName : 'unassigned';
    };

    if (value?.items) {
      for (const zoneId of value.items) {
        const zone = this.options.find(option => option.id === zoneId);
        if (zone) {
          results.push(getCustomerZone(zone.id, zone.text as ANY));
        }
      }
    }

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    if (results.length === 1) {
      return `Zone is ${notWord}${results[0]}`;
    } else if (results.length) {
      return `Zone is ${notWord}one of ${results.join(', ')}`;
    }

    return 'Zone is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Zone',
    };
  }

  getIcon() {
    return { matIcon: 'activity_zone' };
  }
}

interface FilterCustomerLastAppointmentTypeData extends LogicalOperatorData {
  date?: FancyFilterMagicDateRange;
  callTypeIds?: {
    items: (Id | null)[];
  };
}

export class FilterCustomerLastAppointmentType implements FancyFilterImpl<FilterCustomerLastAppointmentTypeData, any> {
  private readonly datePipe = inject(DatePipe);
  private readonly callWorkOrderTypesService = inject(CallWorkOrderTypesService);

  readonly code = 'Customer:Call';

  readonly multiple = true;

  private options: CallWorkOrderTypeInformation[] = [];

  async load(): Promise<void> {
    const callWorkOrderTypes = await firstValueFrom(this.callWorkOrderTypesService.list());
    this.options = callWorkOrderTypes;
  }

  getUIConfig(): FancyFilterConfig<FilterCustomerLastAppointmentTypeData, any>[] {
    const config1: FancyFilterConfig<FilterCustomerLastAppointmentTypeData, any> = {
      get: controlValue => {
        return controlValue?.callTypeIds?.items ?? [];
      },
      set: (value, context) => {
        return combineData('callTypeIds', value?.length ? { items: value } : null, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          /// /label: 'Type of Appointment',
          code: this.code,
          name: `${this.code}-type`,
          clearable: true,
          multiselect: true,
          options: this.options
            .map(option => {
              return {
                id: option.id,
                text: option.text,
              };
            })
            .concat([{ id: null as ANY, text: 'Without a type' }]),
        },
      },
    };
    const config2: FancyFilterConfig<FilterCustomerLastAppointmentTypeData, FancyFilterMagicDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value?.from == null && value?.to == null ? null : value, context);
      },
      ui: {
        type: FilterDynamicMagicDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Appointment Date',
          code: this.code,
          name: `${this.code}-date`,
          fromLabel: 'After',
          toLabel: 'Before',
        },
      },
    };
    return [config1, config2, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterCustomerLastAppointmentTypeData | null): string {
    let moment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        moment = `from ${magicDateTransform(value.date, 'from', false, this.datePipe)} to ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      } else if (value?.date.from) {
        moment = `from ${magicDateTransform(value.date, 'from', false, this.datePipe)}`;
      } else if (value?.date.to) {
        moment = `until ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      }
    }

    const results: string[] = [];

    if (value?.callTypeIds?.items) {
      for (const callWorkOrderTypeId of value.callTypeIds.items) {
        const callWorkOrderType = this.options.find(option => option.id === callWorkOrderTypeId);
        if (callWorkOrderType) {
          results.push(callWorkOrderType.text);
        }
      }
    }

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    let lastAppointment = '';
    if (results.length === 1) {
      lastAppointment = `Appointment type is ${notWord}${results[0]}`;
    } else if (results.length) {
      lastAppointment = `Appointment type is ${notWord}one of ${results.join(', ')}`;
    } else {
      lastAppointment = 'Appointment type is any';
    }

    return [lastAppointment, moment].filter(notEmpty).join(', ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Appointment Type',
    };
  }

  getIcon() {
    return { matIcon: 'edit_document' };
  }
}

export enum FilterDutyStatusDataType {
  All = 0,
  Open = 1,
  InProgress = 2,
  Completed = 3,
}

interface FilterCustomerDutyCategoryData extends LogicalOperatorData {
  date?: FancyFilterMagicDateRange;
  unspecifiedDateOnly?: boolean;
  dutyStatus?: { value: FilterDutyStatusDataType[] };
  dutyCategoryIds?: {
    items: Id[];
  };
}

export class FilterCustomerDutyCategory implements FancyFilterImpl<FilterCustomerDutyCategoryData, any> {
  private readonly datePipe = inject(DatePipe);
  private readonly dutyCategoriesService = inject(DutyCategoriesService);

  readonly code = 'Customer:Duty';

  readonly multiple = true;

  private options: DutyCategoryInformation[] = [];

  async load(): Promise<void> {
    const dutyCategories = await firstValueFrom(this.dutyCategoriesService.list());
    this.options = dutyCategories;
  }

  getUIConfig(): FancyFilterConfig<FilterCustomerDutyCategoryData, any>[] {
    const config1: FancyFilterConfig<FilterCustomerDutyCategoryData, Id[]> = {
      get: controlValue => {
        return controlValue?.dutyCategoryIds?.items ?? [];
      },
      set: (value, context) => {
        return combineData('dutyCategoryIds', value?.length ? { items: value } : null, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          /// /label: 'Task Type',
          code: this.code,
          name: `${this.code}-type`,
          clearable: true,
          multiselect: true,
          options: this.options.map(option => {
            return {
              id: option.id,
              text: option.text,
            };
          }),
        },
      },
    };
    const config2: FancyFilterConfig<FilterCustomerDutyCategoryData, [boolean?]> = {
      get: controlValue => {
        return controlValue?.unspecifiedDateOnly ? [true] : [];
      },
      set: (value, context) => {
        return combineData('unspecifiedDateOnly', value?.[0] || null, context);
      },
      ui: {
        type: FilterDynamicCheckboxComponent,
        closeAfterSelection: false,
        inputs: {
          /// /label: 'Task Type Options',
          code: this.code,
          name: `${this.code}-options`,
          options: [
            {
              id: true,
              text: 'With Unspecified Due Date Only',
            },
          ],
        },
      },
    };
    const config3: FancyFilterConfig<FilterCustomerDutyCategoryData, FilterDutyStatusDataType[]> = {
      get: controlValue => {
        return controlValue?.dutyStatus?.value ?? [];
      },
      set: (value, context) => {
        return combineData('dutyStatus', value?.length ? { value } : null, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Status',
          code: this.code,
          name: `${this.code}-status`,
          multiselect: true,
          options: [
            { id: FilterDutyStatusDataType.Open, text: 'Open' },
            { id: FilterDutyStatusDataType.InProgress, text: 'In Progress' },
            { id: FilterDutyStatusDataType.Completed, text: 'Completed' },
          ],
        },
      },
    };
    const config4: FancyFilterConfig<FilterCustomerDutyCategoryData, FancyFilterMagicDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value?.from == null && value?.to == null ? null : value, context);
      },
      ui: {
        type: FilterDynamicMagicDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Due Date',
          code: this.code,
          name: `${this.code}-due`,
        },
      },
    };

    return [config1, config2, config3, config4, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterCustomerDutyCategoryData | null): string {
    const results: string[] = [];

    if (value?.dutyCategoryIds?.items) {
      for (const dutyCategoryId of value.dutyCategoryIds.items) {
        const callWorkOrderType = this.options.find(option => option.id === dutyCategoryId);
        if (callWorkOrderType?.text) {
          results.push(callWorkOrderType.text);
        }
      }
    }

    let moment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        moment = `from ${magicDateTransform(value.date, 'from', false, this.datePipe)} to ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      } else if (value?.date.from) {
        moment = `from ${magicDateTransform(value.date, 'from', false, this.datePipe)}`;
      } else if (value?.date.to) {
        moment = `until ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      }
    }

    const dutyStatuses: string[] = [];
    if (value?.dutyStatus?.value) {
      for (const v of value.dutyStatus.value) {
        switch (v) {
          case FilterDutyStatusDataType.Open:
            dutyStatuses.push(`open`);
            break;
          case FilterDutyStatusDataType.InProgress:
            dutyStatuses.push(`in progress`);
            break;
          case FilterDutyStatusDataType.Completed:
            dutyStatuses.push(`completed`);
            break;
        }
      }
    }

    let dutyStatus = '';
    if (dutyStatuses.length) {
      dutyStatus = ` and ${dutyStatuses.join(' or ')}`;
    }

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';
    let taskType = '';
    const withUnspecifiedDate = value?.unspecifiedDateOnly ? ' with unspecified due date' : '';
    if (results.length === 1) {
      taskType = `Task type${withUnspecifiedDate} is ${notWord}${results[0]}${dutyStatus}`;
    } else if (results.length) {
      taskType = `Task type${withUnspecifiedDate} is ${notWord}one of ${results.join(', ')}${dutyStatus}`;
    } else {
      taskType = `Task type${withUnspecifiedDate} is any${dutyStatus}`;
    }

    return [taskType, moment].filter(notEmpty).join(', ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Task',
    };
  }

  getIcon() {
    return { matIcon: 'task' };
  }
}

interface FilterCustomerBillableData extends LogicalOperatorData {
  date?: FancyFilterMagicDateRange;
  isBillable?: boolean;
  includeRecurring?: boolean;
}

export class FilterCustomerBillable implements FancyFilterImpl<FilterCustomerBillableData, any> {
  private readonly datePipe = inject(DatePipe);

  readonly code = 'Customer:Billable';

  getUIConfig(): FancyFilterConfig<FilterCustomerBillableData, any>[] {
    const config1: FancyFilterConfig<FilterCustomerBillableData, boolean> = {
      get: controlValue => {
        return controlValue?.isBillable ?? null;
      },
      set: (value, context) => {
        return combineData('isBillable', value ?? null, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          /// /label: 'Billable Status',
          code: this.code,
          name: `${this.code}-billable`,
          clearable: true,
          options: [
            { id: false, text: 'Non-Billable' },
            { id: true, text: 'Billable' },
          ],
        },
      },
    };
    const config2: FancyFilterConfig<FilterCustomerBillableData, [boolean?]> = {
      get: controlValue => {
        return controlValue?.includeRecurring ? [true] : [];
      },
      set: (value, context) => {
        return combineData('includeRecurring', value?.[0] || null, context);
      },
      ui: {
        type: FilterDynamicCheckboxComponent,
        closeAfterSelection: false,
        inputs: {
          /// /label: 'Task Type Options',
          code: this.code,
          name: `${this.code}-recurring`,
          options: [
            {
              id: true,
              text: 'Show Recurring Invoices',
            },
          ],
        },
      },
    };
    const config3: FancyFilterConfig<FilterCustomerBillableData, FancyFilterMagicDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value?.from == null && value?.to == null ? null : value, context);
      },
      ui: {
        type: FilterDynamicMagicDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Completed Date',
          code: this.code,
          name: `${this.code}-completed`,
          fromLabel: 'After',
          toLabel: 'Before',
        },
      },
    };
    return [config1, config2, config3, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterCustomerBillableData | null): string {
    let moment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        moment = `between ${magicDateTransform(value.date, 'from', false, this.datePipe)} and ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      } else if (value?.date.from) {
        moment = `after ${magicDateTransform(value.date, 'from', false, this.datePipe)}`;
      } else if (value?.date.to) {
        moment = `before ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      }
    }

    let recurring: string | null = null;
    if (value?.includeRecurring) {
      recurring = 'show recurring invoices';
    }

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    let billable = '';
    if (value) {
      switch (value.isBillable) {
        case true:
          billable = `${notWord}Billable customers`;
          break;
        case false:
          billable = `${notWord}Non-billable customers`;
          break;
      }
    }

    if (!billable) {
      billable = 'Billable is any';
    }

    return [billable, recurring, moment].filter(notEmpty).join(', ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Billable',
    };
  }

  getIcon() {
    return { matIcon: 'attach_money' };
  }
}

type FilterCustomerAlertData = {
  value: boolean;
};

export class FilterCustomerAlert implements FancyFilterImpl<FilterCustomerAlertData, boolean> {
  readonly code = 'Customer:Alert';

  getUIConfig(): FancyFilterConfig<FilterCustomerAlertData, boolean>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.value ?? null;
        },
        set: (value, context) => {
          return combineData('value', value, context);
        },
        ui: {
          type: FilterDynamicDropdownComponent,
          closeAfterSelection: true,
          inputs: {
            /// /label: 'Customer Alert',
            code: this.code,
            name: this.code,
            clearable: true,
            options: [
              { id: false, text: 'Has No Alerts' },
              { id: true, text: 'Has Alert' },
            ],
          },
        },
      },
    ];
  }

  getUISummary(value: FilterCustomerAlertData | null): string {
    if (value?.value != null) {
      switch (value.value) {
        case true:
          return 'Customers have alert';
        case false:
          return 'Customers have no alerts';
      }
    }

    return 'Customer alert is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Alerts',
    };
  }

  getIcon() {
    return {
      matIcon: 'notification_important',
    };
  }
}

interface FilterCustomerSystemTypeData extends LogicalOperatorData {
  items: Id[];
}

export class FilterCustomerSystemType implements FancyFilterImpl<FilterCustomerSystemTypeData, Id[]> {
  private readonly systemTypesService = inject(SystemTypesService);

  readonly code = 'Customer:SystemType';

  readonly multiple = true;

  private options: SystemTypeInformation[] = [];

  async load(): Promise<void> {
    const systemTypes = await firstValueFrom(this.systemTypesService.list());
    this.options = systemTypes;
  }

  getUIConfig(): FancyFilterConfig<FilterCustomerSystemTypeData, any>[] {
    const options: RequiredTextResource1<Id | null>[] = this.options.map(option => {
      return {
        id: option.id,
        text: option.text,
      };
    });
    const finalOptions = options.concat({
      id: null,
      text: 'Other',
    });
    return [
      {
        get: controlValue => {
          return controlValue?.items ?? [];
        },
        set: (value: (number | null)[], context) => {
          return combineData('items', value?.length ? value : null, context);
        },
        ui: {
          type: FilterDynamicDropdownComponent,
          closeAfterSelection: false,
          inputs: {
            /// /label: 'Equipment Type',
            code: this.code,
            name: this.code,
            clearable: true,
            multiselect: true,
            options: finalOptions,
          },
        },
      },
      getReversedFilterConfig(this.code),
    ];
  }

  getUISummary(value: FilterCustomerSystemTypeData | null): string {
    const results: string[] = [];

    if (value) {
      for (const systemTypeId of value.items) {
        const systemType = this.options.find(option => option.id === systemTypeId);
        if (systemType) {
          results.push(systemType.text);
        }
      }
    }

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    if (results.length === 1) {
      return `Equipment type is ${notWord}${results[0]}`;
    } else if (results.length) {
      return `Equipment type is ${notWord}one of ${results.join(', ')}`;
    }

    return `Equipment type is any`;
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Equipment Type',
    };
  }

  getIcon() {
    return { matIcon: 'construction' };
  }
}

type FilterCustomerSiteSystemYearManufacturedData = {
  date: FancyFilterMagicDateRange;
};

export class FilterCustomerSiteSystemYearManufactured
  implements FancyFilterImpl<FilterCustomerSiteSystemYearManufacturedData, FancyFilterMagicDateRange>
{
  private readonly datePipe = inject(DatePipe);

  readonly code = 'Customer:SiteSystemYear';

  getEndOfYearUtc(year: number): Date {
    return new Date(Date.UTC(year, 11, 31, 23, 59, 59));
  }

  getUIConfig(): FancyFilterConfig<FilterCustomerSiteSystemYearManufacturedData, FancyFilterMagicDateRange>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.date ?? null;
        },
        set: (value, context) => {
          return combineData('date', value?.from == null && value?.to == null ? null : value, context);
        },
        ui: {
          type: FilterCustomerSiteSystemYearManufacturedComponent,
          closeAfterSelection: false,
          inputs: {
            /// /label: 'Equipment Year Manufactured',
            code: this.code,
            name: this.code,
          },
        },
      },
    ];
  }

  getUISummary(value: FilterCustomerSiteSystemYearManufacturedData | null): string {
    if (value?.date) {
      if (value.date.from != null && value.date.to != null) {
        return `Equipment older than ${magicDateTransform(value.date, 'to', true, this.datePipe)} but not older than ${magicDateTransform(value.date, 'from', true, this.datePipe)}`;
      } else if (value.date.to != null) {
        return `Equipment older than ${magicDateTransform(value.date, 'to', true, this.datePipe)}`;
      } else if (value.date.from != null) {
        return `Equipment not older than ${magicDateTransform(value.date, 'from', true, this.datePipe)}`;
      }
    }

    return 'Year manufactured is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Equipment Year Manufactured',
    };
  }

  getIcon() {
    return { matIcon: 'today' };
  }
}

interface FilterCustomerSiteSystemBrandData {
  text: string;
}

export class FilterCustomerSiteSystemBrand implements FancyFilterImpl<FilterCustomerSiteSystemBrandData, string> {
  readonly code = 'Customer:SiteSystemBrand';

  getUIConfig(): FancyFilterConfig<FilterCustomerSiteSystemBrandData, string>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.text ?? null;
        },
        set: (value, context) => {
          return combineData('text', value == null || value === '' ? null : value, context);
        },
        ui: {
          type: FilterDynamicTextComponent,
          closeAfterSelection: false,
          inputs: {
            /// /label: 'Brand',
            code: this.code,
            name: this.code,
          },
        },
      },
    ];
  }

  getUISummary(value: FilterCustomerSiteSystemBrandData | null): string {
    if (value?.text) {
      return `Brand is ${value.text}`;
    }

    return 'Brand is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Equipment Brand',
    };
  }

  getIcon() {
    return { matIcon: 'branding_watermark' };
  }
}

interface FilterCustomerSiteSystemModelData {
  text: string;
}

export class FilterCustomerSiteSystemModel implements FancyFilterImpl<FilterCustomerSiteSystemModelData, string> {
  readonly code = 'Customer:SiteSystemModel';

  getUIConfig(): FancyFilterConfig<FilterCustomerSiteSystemModelData, string>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.text ?? null;
        },
        set: (value, context) => {
          return combineData('text', value == null || value === '' ? null : value, context);
        },
        ui: {
          type: FilterDynamicTextComponent,
          closeAfterSelection: false,
          inputs: {
            /// /label: 'Model',
            code: this.code,
            name: this.code,
          },
        },
      },
    ];
  }

  getUISummary(value: FilterCustomerSiteSystemModelData | null): string {
    if (value?.text) {
      return `Model is ${value.text}`;
    }

    return 'Model is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Equipment Model',
    };
  }

  getIcon() {
    return { matIcon: 'font_download' };
  }
}

interface FilterCustomerSalesProposalData extends LogicalOperatorData {
  date?: FancyFilterMagicDateRange;
  name?: { text: string };
  salesProposalStatus?: { value: SalesProposalStatus };
}

export class FilterCustomerSalesProposal implements FancyFilterImpl<FilterCustomerSalesProposalData, FilterCustomerDeletedData> {
  private readonly datePipe = inject(DatePipe);

  readonly code = 'Customer:SalesProposal';

  getUIConfig(): FancyFilterConfig<FilterCustomerSalesProposalData, any>[] {
    const config1: FancyFilterConfig<FilterCustomerSalesProposalData, SalesProposalStatus> = {
      get: controlValue => {
        return controlValue?.salesProposalStatus?.value ?? null;
      },
      set: (value, context) => {
        return combineData('salesProposalStatus', value ? { value } : null, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Status',
          code: this.code,
          name: `${this.code}-status`,
          clearable: true,
          options: [
            { id: SalesProposalStatus.Created, text: 'Created' },
            { id: SalesProposalStatus.Ready, text: 'Ready' },
            { id: SalesProposalStatus.Finalized, text: 'Finalized' },
          ],
        },
      },
    };

    const config2: FancyFilterConfig<FilterCustomerSalesProposalData, string> = {
      get: controlValue => {
        return controlValue?.name?.text ?? null;
      },
      set: (value, context) => {
        return combineData('name', value ? { text: value } : null, context);
      },
      ui: {
        type: FilterDynamicTextComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Name',
          code: this.code,
          name: `${this.code}-name`,
        },
      },
    };
    const config3: FancyFilterConfig<FilterCustomerSalesProposalData, FancyFilterMagicDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value?.from == null && value?.to == null ? null : value, context);
      },
      ui: {
        type: FilterDynamicMagicDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Created Date',
          code: this.code,
          name: `${this.code}-created`,
          fromLabel: 'After',
          toLabel: 'Before',
        },
      },
    };
    return [config1, config2, config3, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterCustomerSalesProposalData | null): string {
    let createdMoment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        createdMoment = `created between ${magicDateTransform(value.date, 'from', false, this.datePipe)} and ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      } else if (value?.date.from) {
        createdMoment = `created after ${magicDateTransform(value.date, 'from', false, this.datePipe)}`;
      } else if (value?.date.to) {
        createdMoment = `created before ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      }
    }

    let name: string | null = null;
    if (value?.name) {
      name = `name containing "${value.name.text}"`;
    }

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    let salesProposalStatus = '';
    switch (value?.salesProposalStatus?.value) {
      case SalesProposalStatus.Created:
        salesProposalStatus = `${notWord}Created sales proposals`;
        break;
      case SalesProposalStatus.Finalized:
        salesProposalStatus = `${notWord}Finalized sales proposals`;
        break;
      case SalesProposalStatus.Ready:
        salesProposalStatus = `${notWord}Ready sales proposals`;
        break;
    }

    if (!salesProposalStatus) {
      salesProposalStatus = 'Any sales proposals';
    }

    return [salesProposalStatus, name, createdMoment].filter(notEmpty).join(', ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Sales Proposals',
    };
  }

  getIcon() {
    return { matIcon: 'attach_money' };
  }
}

interface FilterCustomerAppointmentReasonData extends LogicalOperatorData {
  date?: FancyFilterMagicDateRange;
  callReasonIds?: {
    items: (Id | null)[];
  };
}

export class FilterCustomerAppointmentReason implements FancyFilterImpl<FilterCustomerAppointmentReasonData, any> {
  private readonly datePipe = inject(DatePipe);
  private readonly callReasonsService = inject(CallReasonsService);

  readonly code = 'Customer:CallReason';

  readonly multiple = true;

  private options: CallReasonInformation[] = [];

  async load(): Promise<void> {
    const callReasons = await firstValueFrom(this.callReasonsService.list());
    this.options = callReasons;
  }

  getUIConfig(): FancyFilterConfig<FilterCustomerAppointmentReasonData, any>[] {
    const config1: FancyFilterConfig<FilterCustomerAppointmentReasonData, any> = {
      get: controlValue => {
        return controlValue?.callReasonIds?.items ?? [];
      },
      set: (value, context) => {
        return combineData('callReasonIds', value?.length ? { items: value } : null, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          code: this.code,
          name: `${this.code}-reasons`,
          clearable: true,
          multiselect: true,
          options: this.options
            .map(option => {
              return {
                id: option.id,
                text: option.text,
              };
            })
            .concat([{ id: null as ANY, text: 'Without a reason' }]),
        },
      },
    };
    const config2: FancyFilterConfig<FilterCustomerAppointmentReasonData, FancyFilterMagicDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value?.from == null && value?.to == null ? null : value, context);
      },
      ui: {
        type: FilterDynamicMagicDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Appointment Date',
          code: this.code,
          name: `${this.code}-date`,
          fromLabel: 'After',
          toLabel: 'Before',
        },
      },
    };
    return [config1, config2, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterCustomerAppointmentReasonData | null): string {
    let moment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        moment = `from ${magicDateTransform(value.date, 'from', false, this.datePipe)} to ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      } else if (value?.date.from) {
        moment = `from ${magicDateTransform(value.date, 'from', false, this.datePipe)}`;
      } else if (value?.date.to) {
        moment = `until ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      }
    }

    const results: string[] = [];

    if (value?.callReasonIds?.items) {
      for (const callWorkOrderTypeId of value.callReasonIds.items) {
        const callWorkOrderType = this.options.find(option => option.id === callWorkOrderTypeId);
        if (callWorkOrderType) {
          results.push(callWorkOrderType.text);
        }
      }
    }

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    let appointmentReason = '';
    if (results.length === 1) {
      appointmentReason = `Appointment reason is ${notWord}${results[0]}`;
    } else if (results.length) {
      appointmentReason = `Appointment reason is ${notWord}one of ${results.join(', ')}`;
    } else {
      appointmentReason = 'Appointment reason is any';
    }

    return [appointmentReason, moment].filter(notEmpty).join(', ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Appointment Reason',
    };
  }

  getIcon() {
    return { matIcon: 'call' };
  }
}

interface FilterCustomerLocationData {
  city?: { text: string };
  zipCode?: { text: string };
}

export class FilterCustomerLocation implements FancyFilterImpl<FilterCustomerLocationData, string> {
  readonly code = 'Customer:Location';

  getUIConfig(): FancyFilterConfig<FilterCustomerLocationData, string>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.city?.text ?? null;
        },
        set: (value, context) => {
          return combineData('city', value ? { text: value } : null, context);
        },
        ui: {
          type: FilterDynamicTextComponent,
          closeAfterSelection: false,
          inputs: {
            label: 'City',
            code: this.code,
            name: `${this.code}-city`,
          },
        },
      },
      {
        get: controlValue => {
          return controlValue?.zipCode?.text ?? null;
        },
        set: (value, context) => {
          return combineData('zipCode', value ? { text: value } : null, context);
        },
        ui: {
          type: FilterDynamicTextComponent,
          closeAfterSelection: false,
          inputs: {
            label: 'Postal Code',
            code: this.code,
            name: `${this.code}-zipCode`,
          },
        },
      },
    ];
  }

  getUISummary(value: FilterCustomerLocationData | null): string {
    const results: string[] = [];
    if (value?.city?.text) {
      results.push(`City is ${value.city?.text}`);
    }

    if (value?.zipCode?.text) {
      results.push(`Postal Code is ${value.zipCode?.text}`);
    }

    if (!results.length) {
      return 'Location is any';
    }

    return results.join('; ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Location',
    };
  }

  getIcon() {
    return { matIcon: 'location_on' };
  }
}

export class ColumnCustomerName implements TableColumnDefinition<CustomerInformationForList> {
  private readonly entity = inject(EntityPipe);

  readonly id = 'name';

  readonly text = 'Name';

  readonly dataTestId = 'customer-name';

  readonly required = true;

  readonly order = Number.NEGATIVE_INFINITY;

  renderComponent(item: CustomerInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        routerLink: ['/customers', item.data.id],
        text: this.entity.transform(item.data.entity),
      },
    };
  }
}

export class ColumnCustomerAddress implements TableColumnDefinition<CustomerInformationForList> {
  private readonly address = inject(AddressPipe);

  readonly id = 'address';

  readonly text = 'Address';

  readonly classes = ['col-address'];

  renderComponent(item: CustomerInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: this.address.transform(item.data.address),
      },
    };
  }
}

export class ColumnCustomerHomePhone implements TableColumnDefinition<CustomerInformationForList> {
  readonly id = 'phone-main';

  readonly text = 'Main Phone';

  readonly classes = ['col-phone'];

  renderComponent(item: CustomerInformationForList) {
    return {
      component: TableDynamicNodePhoneComponent,
      inputs: {
        phone: item.data.mainPhone,
        label: item.data.mainPhoneLabel,
      },
    };
  }
}

export class ColumnCustomerMobilePhone implements TableColumnDefinition<CustomerInformationForList> {
  readonly id = 'phone-mobile';

  readonly text = 'Mobile Phone';

  readonly classes = ['col-phone'];

  renderComponent(item: CustomerInformationForList) {
    return {
      component: TableDynamicNodePhoneComponent,
      inputs: {
        phone: item.data.mobilePhone,
        label: item.data.mobilePhoneLabel,
      },
    };
  }
}

export class ColumnCustomerWorkPhone implements TableColumnDefinition<CustomerInformationForList> {
  readonly id = 'phone-work';

  readonly text = 'Work Phone';

  readonly classes = ['col-phone'];

  renderComponent(item: CustomerInformationForList) {
    return {
      component: TableDynamicNodePhoneComponent,
      inputs: {
        phone: item.data.workPhone,
        label: item.data.workPhoneLabel,
      },
    };
  }
}

export class ColumnCustomerEmail implements TableColumnDefinition<CustomerInformationForList> {
  readonly id = 'email';

  readonly text = 'Email';

  readonly classes = ['col-email'];

  renderComponent(item: CustomerInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: item.data.email,
      },
    };
  }
}

const idSeparator = '/';
export class ColumnCustomerLastVisit implements TableColumnDefinition<CustomerInformationForList> {
  readonly callWorkOrderTypesService = inject(CallWorkOrderTypesService);

  readonly id = 'last-visit';

  readonly multiple = true;

  readonly text = 'Last Visit';

  readonly classes = ['col-short-date'];

  private callWorkOrderTypes: CallWorkOrderTypeInformation[] = [];

  async load(): Promise<void> {
    this.callWorkOrderTypes = await firstValueFrom(this.callWorkOrderTypesService.list());
  }

  getColumns(items: CustomerInformationForList[]) {
    const callWorkOrderTypeIds = unique(items.flatMap(item => item.lastVisits?.map(lastVisit => lastVisit.callTypeId) ?? []));

    return [{ id: `${this.id}${idSeparator}0`, text: 'Last Visit' }].concat(
      callWorkOrderTypeIds.map(callWorkOrderTypeId => ({
        id: `${this.id}${idSeparator}${callWorkOrderTypeId}`,
        text: `${this.callWorkOrderTypes.find(callWorkOrderType => callWorkOrderType.id === callWorkOrderTypeId)?.text ?? ''} Last Visit`,
      }))
    );
  }

  renderComponent(item: CustomerInformationForList, id: string) {
    const [_, callWorkOrderTypeIdStr] = id.split(idSeparator);
    const callWorkOrderTypeId = parseInt(callWorkOrderTypeIdStr, 10);

    return {
      component: TableDynamicNodeDateComponent,
      inputs: {
        date: callWorkOrderTypeId
          ? item.lastVisits?.find(lastVisit => lastVisit.callTypeId === callWorkOrderTypeId)?.lastVisit
          : item.lastVisit,
        format: 'shortDate',
      },
    };
  }
}

export class ColumnCustomerQuickBooks implements TableColumnDefinition<CustomerInformationForList> {
  readonly id = 'quickbooks';

  readonly text = 'QuickBooks';

  readonly classes = ['col-status'];

  renderComponent(item: CustomerInformationForList) {
    return {
      component: TableDynamicNodeQuickBooksComponent,
      inputs: {
        quickBooks: item.data.quickBooksStatus,
      },
    };
  }
}

export class ColumnCustomerDeleted implements TableColumnDefinition<CustomerInformationForList> {
  readonly id = 'deleted';

  readonly text = 'Status';

  readonly classes = ['col-status'];

  readonly required = true;

  readonly order = Number.POSITIVE_INFINITY;

  renderComponent(item: CustomerInformationForList) {
    return {
      component: TableDynamicNodeDeletedComponent,
      inputs: {
        show: item.data.hidden,
      },
    };
  }
}

export const supportedCustomerFilterTypes: readonly Type<FancyFilterImpl<any>>[] = [
  FilterCustomer,
  FilterCustomerAgreement,
  FilterCustomerResidential,
  FilterCustomerZone,
  FilterCustomerTag,
  FilterCustomerLastAppointmentType,
  FilterCustomerDutyCategory,
  FilterCustomerBillable,
  FilterCustomerAlert,
  FilterCustomerSystemType,
  FilterCustomerSiteSystemYearManufactured,
  FilterCustomerSiteSystemBrand,
  FilterCustomerSiteSystemModel,
  FilterCustomerSalesProposal,
  FilterCustomerAppointmentReason,
  FilterCustomerLocation,
] as const;

export const supportedCustomerColumns: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnCustomerName,
  ColumnCustomerAddress,
  ColumnCustomerHomePhone,
  ColumnCustomerMobilePhone,
  ColumnCustomerWorkPhone,
  ColumnCustomerEmail,
  ColumnCustomerLastVisit,
  ColumnCustomerQuickBooks,
  ColumnCustomerDeleted,
] as const;

export const providers = [...supportedCustomerFilterTypes, ...supportedCustomerColumns];

