import { type TagEntityInformation } from '@models/tag-models';
import { createEmpty as createEmptyAddress, type Address } from '../address';
import { createEmpty as createEmptyEntity, type Entity } from '../entity';
import { QuickBooksSynchronizationStatus } from '../quick-books';
import { type OptionalTextStateResource } from '../resource';

export interface CustomerInformation extends OptionalTextStateResource {
  localId: Id;
  entity: Entity;
  address: Address;
  mainPhone: Phone | null;
  workPhone: Phone | null;
  mobilePhone: Phone | null;
  email: string | null;
  /// / goodStanding: boolean;
  quickBooksStatus: QuickBooksSynchronizationStatus;
  notes: string | null;
  phoneMobileAllowTexting: boolean;
  alert: string | null;
  saveCreditCards: boolean | null;
  mainPhoneLabel: string | null;
  workPhoneLabel: string | null;
  mobilePhoneLabel: string | null;
  tagEntityInformations: TagEntityInformation[] | null;
}

export interface CustomerInformationLastVisit {
  callTypeId: Id;
  lastVisit: Date;
}

export interface CustomerInformationForList {
  data: CustomerInformation;
  lastVisit: Date | null;
  lastVisits: CustomerInformationLastVisit[] | null;
}

export function createEmpty(): CustomerInformation {
  return {
    id: 0,
    text: '',
    localId: 0,
    entity: createEmptyEntity(),
    address: createEmptyAddress(),
    email: null,
    mainPhone: null,
    mobilePhone: null,
    workPhone: null,
    notes: null,
    quickBooksStatus: QuickBooksSynchronizationStatus.None,
    hidden: false,
    phoneMobileAllowTexting: true,
    alert: null,
    saveCreditCards: null,
    mainPhoneLabel: null,
    workPhoneLabel: null,
    mobilePhoneLabel: null,
    tagEntityInformations: [],
  };
}

