<wm-rw2 [name]="name()" [formControl]="control" [mode]="mode()">
  @if (helperTemplate2(); as helperTemplate2) {
    <ng-template #helperTemplate let-control>
      <ng-container [ngTemplateOutlet]="helperTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    </ng-template>
  }
  <ng-template #writeTemplate let-control>
    <wm-input-image
      [formControl]="control"
      [showTextInItem]="true"
      [options]="options()"
      [required]="required()"
      [clearable]="clearable()"
    />
  </ng-template>
  <ng-template #readTemplate let-control>
    <span class="template" *ngIf="selectedOptions() as option">
      <img *ngIf="option.image" src="{{ option.image }}" alt="" />
      <span>{{ option.text }}</span>
    </span>
  </ng-template>
</wm-rw2>
