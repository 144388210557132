<div class="notes wm-description">
  <div class="note-title">
    <label>Notes</label>
    <ng-template [ngIf]="allowNoteEditing">
      <div *ngIf="noteMetadata.saved">
        <img src="/assets/images/dashboard-popover-checkmark-saved.svg" alt="" />
        <span>Saved</span>
      </div>
      <a *ngIf="!noteMetadata.saved && !noteMetadata.editing" href="javascript:;" (click)="editNotes(noteMetadata)">
        <img src="/assets/images/dashboard-popover-pencil-edit-notes.svg" alt="" />
        <span>Edit</span>
      </a>
    </ng-template>
  </div>
  <div class="read" *ngIf="!noteMetadata.editing" (click)="editNotes(noteMetadata)">{{ noteMetadata.notes }}</div>
  <div class="write" *ngIf="noteMetadata.editing">
    <textarea
      #textarea
      (blur)="onBlur(noteMetadata, textarea.value)"
      (keyup)="noteMetadata.notesStream.next(textarea.value)"
      (init)="goFocus($event)"
      >{{ noteMetadata.notes }}</textarea
    >
  </div>
</div>
