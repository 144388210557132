import { type Creatable } from '@models/mutable';
import { type TaxItemEntry } from '@models/pricing-models';
import {
  type PackageSelectionOption,
  type SalesProposalInformation,
  type SalesProposalLoanApplicationInformation,
  type SalesProposalPackageDetailInformation,
  type SalesProposalPackageDiscountInformation,
  type SalesProposalPackageInformation,
  type SalesProposalPackageRebateInformation,
  type SalesProposalPaymentInformation,
} from '@models/sales-proposal-models';
import { type TaxItemSection } from '@models/tax-item-section';
import { type FileInformation } from '@models/upload';
import { createAction, props } from '@ngrx/store';
import {
  createSubmitAction,
  createSubmitFailAction,
  createSubmitInitialAction,
  createSubmitSuccessAction,
} from '../save-store/save.actions';
import { SALES_PROPOSAL_STORE_KEY } from './sales-proposal.selectors';

export const loadSalesProposal = createAction('[SalesProposal] load', props<{ data: SalesProposalInformation }>());
export const updateSalesProposal = createAction('[SalesProposal] update', props<{ patch: Partial<SalesProposalInformation> }>());
export const loadPackages = createAction('[SalesProposal] load packages', props<{ data: SalesProposalPackageInformation[] }>());
export const addPackage = createAction('[SalesProposal] add package', props<{ data: Partial<SalesProposalPackageInformation> }>());
export const duplicatePackage = createAction('[SalesProposal] duplicate package', props<{ id: Id }>());
export const updatePackage = createAction(
  '[SalesProposal] update package',
  props<{ id: Id; patch: Partial<SalesProposalPackageInformation> }>()
);
export const deletePackage = createAction('[SalesProposal] delete package', props<{ id: Id }>());
export const loadDetails = createAction('[SalesProposal] load details', props<{ data: SalesProposalPackageDetailInformation[] }>());
export const addDetail = createAction(
  '[SalesProposal] add detail',
  props<{ data: Partial<SalesProposalPackageDetailInformation>; conserveOrder: boolean }>()
);
export const updateDetail = createAction(
  '[SalesProposal] update detail',
  props<{ id: Id; patch: Partial<SalesProposalPackageDetailInformation> }>()
);
export const deleteDetail = createAction('[SalesProposal] delete detail', props<{ id: Id }>());
export const loadDiscounts = createAction('[SalesProposal] load discounts', props<{ data: SalesProposalPackageDiscountInformation[] }>());
export const addDiscount = createAction(
  '[SalesProposal] add discount',
  props<{ data: Partial<SalesProposalPackageDiscountInformation> }>()
);
export const updateDiscount = createAction(
  '[SalesProposal] update discount',
  props<{ id: Id; patch: Partial<SalesProposalPackageDiscountInformation> }>()
);
export const deleteDiscount = createAction('[SalesProposal] delete discount', props<{ id: Id }>());
export const loadRebates = createAction('[SalesProposal] load rebates', props<{ data: SalesProposalPackageRebateInformation[] }>());
export const addRebate = createAction('[SalesProposal] add rebate', props<{ data: Partial<SalesProposalPackageRebateInformation> }>());
export const updateRebate = createAction(
  '[SalesProposal] update rebate',
  props<{ id: Id; patch: Partial<SalesProposalPackageRebateInformation> }>()
);
export const deleteRebate = createAction('[SalesProposal] delete rebate', props<{ id: Id }>());
export const loadLoans = createAction('[SalesProposal] load loans', props<{ data: SalesProposalLoanApplicationInformation[] }>());
export const setLoanRates = createAction('[SalesProposal] set loan rates', props<{ data: Id[] }>());
export const loadFileInformations = createAction('[SalesProposal] load file informations', props<{ data: FileInformation[] }>());
export const addFileInformation = createAction('[SalesProposal] addFileInformation', props<{ data: Partial<FileInformation> }>());
export const deleteFileInformation = createAction('[SalesProposal] deleteFileInformation', props<{ id: Id }>());
export const loadTaxItemSections = createAction('[SalesProposal] loadTaxItemSections', props<{ taxItemSections: TaxItemSection[] }>());
export const updateTax = createAction('[SalesProposal] update tax', props<{ taxItemEntry: TaxItemEntry }>());
export const loadPayments = createAction('[SalesProposal] loadPayments', props<{ data: SalesProposalPaymentInformation[] }>());
export const addPayment = createAction(
  '[SalesProposal] addPayment',
  props<{ data: Partial<SalesProposalPaymentInformation>; isAlreadySavedOnServer: boolean }>()
);
export const updatePayment = createAction(
  '[SalesProposal] updatePayment',
  props<{ id: Id; patch: Partial<SalesProposalPaymentInformation> }>()
);
export const deletePayment = createAction('[SalesProposal] deletePayment', props<{ id: Id }>());
export const markSalesProposalAsNew = createAction('[SalesProposal] mark sales proposal as new');
export const markSalesProposalChildrenAsNew = createAction(
  '[SalesProposal] mark sales proposal children as new',
  props<{ salesProposalId: Id }>()
);
export const markSalesProposalPackageChildrenAsNew = createAction(
  '[SalesProposal] mark sales proposal package children as new',
  props<{ salesProposalPackage: SalesProposalPackageInformation & Creatable }>()
);
export const copyToSnapshot = createAction('[SalesProposal] copy to snapshot');
export const restoreFromSnapshot = createAction('[SalesProposal] restore from snapshot');
export const makeTemplate = createAction('[SalesProposal] make template', props<{ text: string }>());
export const makeReal = createAction('[SalesProposal] make real');

export const loadWorkOrderId = createAction('[SalesProposal] load work order id', props<{ data: Id }>());

export const selectPackage = createSubmitInitialAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save] select package',
  props<{ packageId: Id; packageSelectionOptions: PackageSelectionOption }>()
);
export const selectPackageSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] select package',
  props<{ packageId: Id; workOrderId: Id }>()
);
export const selectPackageFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] select package',
  props<{ error: string }>()
);

export const editMode = createAction('[SalesProposal] edit mode');
export const cancel = createAction('[SalesProposal] cancel');
export const save = createSubmitInitialAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal] save');

export const saveSalesProposal = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Save] Sales Proposal');
export const saveSalesProposalSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] Sales Proposal',
  props<{ resource: { id: Id } }>()
);
export const saveSalesProposalFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] Sales Proposal',
  props<{ error: string }>()
);

export const saveLoanSave = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Save] Loan', props<{ id: Id }>());
export const saveLoanDelete = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Delete] Loan', props<{ id: Id }>());
export const saveLoanSaveSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] Loan',
  props<{ oldId: Id; newId: Id }>()
);
export const saveLoanDeleteSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Delete][Success] Loan',
  props<{ resource: { id: Id } }>()
);
export const saveLoanFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] Loan',
  props<{ error: string }>()
);

export const savePackageSave = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Save] Package', props<{ id: Id }>());
export const savePackageDelete = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Delete] Package', props<{ id: Id }>());
export const savePackageSaveSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] Package',
  props<{ oldId: Id; newId: Id }>()
);
export const savePackageDeleteSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Delete][Success] Package',
  props<{ resource: { id: Id } }>()
);
export const savePackageFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] Package',
  props<{ error: string }>()
);

export const saveDiscountSave = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Save] Discount', props<{ id: Id }>());
export const saveDiscountDelete = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Delete] Discount', props<{ id: Id }>());
export const saveDiscountSaveSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] Discount',
  props<{ oldId: Id; newId: Id }>()
);
export const saveDiscountDeleteSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Delete][Success] Discount',
  props<{ resource: { id: Id } }>()
);
export const saveDiscountFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] Discount',
  props<{ error: string }>()
);

export const saveRebateSave = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Save] Rebate', props<{ id: Id }>());
export const saveRebateDelete = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Delete] Rebate', props<{ id: Id }>());
export const saveRebateSaveSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] Rebate',
  props<{ oldId: Id; newId: Id }>()
);
export const saveRebateDeleteSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Delete][Success] Rebate',
  props<{ resource: { id: Id } }>()
);
export const saveRebateFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] Rebate',
  props<{ error: string }>()
);

export const savePaymentSave = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Save] Payment', props<{ id: Id }>());
export const savePaymentDelete = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Delete] Payment', props<{ id: Id }>());
export const savePaymentSaveSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] Payment',
  props<{ oldId: Id; newId: Id }>()
);
export const savePaymentDeleteSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Delete][Success] Payment',
  props<{ resource: { id: Id } }>()
);
export const savePaymentFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] Payment',
  props<{ error: string }>()
);

export const validateFileInformation = createSubmitAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Validate] FileInformation',
  props<{ id: Id }>()
);
export const validateFileInformationSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Validate][Success] FileInformation',
  props<{ salesProposalId: Id; oldId: Id; newResource: FileInformation }>()
);
export const validateFileInformationFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Validate][Faile] FileInformation',
  props<{ error: string }>()
);

export const saveFileInformationSave = createSubmitAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save] FileInformation',
  props<{ salesProposalId: Id; oldId: Id; newResource: FileInformation }>()
);
export const saveFileInformationsDelete = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Delete] FileInformations');
export const saveFileInformationSaveSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] FileInformation',
  props<{ oldId: Id; newResource: FileInformation }>()
);
export const saveFileInformationsDeleteSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Delete][Success] FileInformation',
  props<{ resources: { id: Id }[] }>()
);
export const saveFileInformationFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] FileInformation',
  props<{ error: string }>()
);

export const saveDetailSave = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Save] Detail', props<{ id: Id }>());
export const saveDetailDelete = createSubmitAction(SALES_PROPOSAL_STORE_KEY, '[SalesProposal][Delete] Detail', props<{ id: Id }>());
export const saveDetailSaveSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Success] Detail',
  props<{ oldId: Id; newId: Id }>()
);
export const saveDetailDeleteSuccess = createSubmitSuccessAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Delete][Success] Detail',
  props<{ resource: { id: Id } }>()
);
export const saveDetailFail = createSubmitFailAction(
  SALES_PROPOSAL_STORE_KEY,
  '[SalesProposal][Save][Fail] Detail',
  props<{ error: string }>()
);

export type Actions = ReturnType<
  | typeof loadSalesProposal
  | typeof updateSalesProposal
  | typeof loadPackages
  | typeof addPackage
  | typeof duplicatePackage
  | typeof updatePackage
  | typeof deletePackage
  | typeof loadDetails
  | typeof addDetail
  | typeof updateDetail
  | typeof deleteDetail
  | typeof loadDiscounts
  | typeof addDiscount
  | typeof updateDiscount
  | typeof deleteDiscount
  | typeof loadRebates
  | typeof addRebate
  | typeof updateRebate
  | typeof deleteRebate
  | typeof loadLoans
  | typeof setLoanRates
  | typeof loadFileInformations
  | typeof addFileInformation
  | typeof deleteFileInformation
  | typeof updateTax
  | typeof markSalesProposalAsNew
  | typeof markSalesProposalChildrenAsNew
  | typeof markSalesProposalPackageChildrenAsNew
  | typeof copyToSnapshot
  | typeof restoreFromSnapshot
  | typeof makeTemplate
  | typeof makeReal
  | typeof editMode
  | typeof cancel
  | typeof save
  | typeof saveSalesProposal
  | typeof saveSalesProposalSuccess
  | typeof saveSalesProposalFail
  | typeof saveLoanSave
  | typeof saveLoanDelete
  | typeof saveLoanSaveSuccess
  | typeof saveLoanDeleteSuccess
  | typeof saveLoanFail
  | typeof savePackageSave
  | typeof savePackageDelete
  | typeof savePackageSaveSuccess
  | typeof savePackageDeleteSuccess
  | typeof savePackageFail
  | typeof saveDiscountSave
  | typeof saveDiscountDelete
  | typeof saveDiscountSaveSuccess
  | typeof saveDiscountDeleteSuccess
  | typeof saveDiscountFail
  | typeof saveRebateSave
  | typeof saveRebateDelete
  | typeof saveRebateSaveSuccess
  | typeof saveRebateDeleteSuccess
  | typeof saveRebateFail
  | typeof savePaymentSave
  | typeof savePaymentDelete
  | typeof savePaymentSaveSuccess
  | typeof savePaymentDeleteSuccess
  | typeof savePaymentFail
  | typeof validateFileInformation
  | typeof validateFileInformationSuccess
  | typeof validateFileInformationFail
  | typeof saveFileInformationSave
  | typeof saveFileInformationsDelete
  | typeof saveFileInformationSaveSuccess
  | typeof saveFileInformationsDeleteSuccess
  | typeof saveFileInformationFail
  | typeof saveDetailSave
  | typeof saveDetailDelete
  | typeof saveDetailSaveSuccess
  | typeof saveDetailDeleteSuccess
  | typeof saveDetailFail
>;
