import { Injectable, SecurityContext, inject } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';
import { PaymentGateway } from '@models/payments';
import { type SubscriptionDetail } from '@models/subscriptions';
import { type PaymentGatewayHandler } from './payment-gateway-handler';

@Injectable()
export class NonePaymentGatewayHandlerService implements PaymentGatewayHandler {
  readonly paymentGateway = PaymentGateway.None;

  private readonly sanitizer = inject(DomSanitizer);

  getErrorMessage(confirmation: string | null): string | null {
    return null;
  }

  hasCustomConfirmationMessage(): boolean {
    return false;
  }

  getConfirmationMessage(confirmation: string): SafeHtml {
    const result = this.sanitizer.sanitize(SecurityContext.HTML, confirmation);
    return result ?? '';
  }

  getSubscriptionInformation(_subscriptionExtra: string | null): SubscriptionDetail | null {
    return null;
  }
}
