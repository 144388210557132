import { DatePipe } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { type DialogIntakeComponent } from '@dialogs/dialog-intake.component';
import { type AgreementSiteSystemInformationForList, type AgreementVisit } from '@models/cards/agreement-site-system-information';
import { DIALOG_DATA_TYPED, DIALOG_SERVICE_IMPL, buttons, type Dialog2ServiceImpl, type DialogIntakeImpl } from '@models/dialog';
import { getStatusText } from '@models/duty-models';
import { type Entity } from '@models/entity';

interface AgreementVisitContentComponentData {
  localId: Int;
  customerEntity: Entity;
  agreementVisits: AgreementVisit[];
}

const injectionToken = DIALOG_DATA_TYPED<AgreementVisitContentComponentData>();

@Component({
  template: `<ul>
    @for (agreementVisit of data!.agreementVisits; track $index) {
      <li class="visit">
        <span class="status">{{ getStatusText(agreementVisit.status) }}</span>
        <a [routerLink]="['/tasks', agreementVisit.id]" class="date">
          @if (agreementVisit.dueDate) {
            <span>{{ agreementVisit.dueDate | date: 'shortDate' }}</span>
          } @else {
            <span>No Due Date</span>
          }
        </a>
      </li>
    }
  </ul>`,
  styles: [
    `
      @import 'variables';
      ul {
        list-style-type: disc;
        margin-left: 20px;
        padding: 2px 0;
      }

      .visit {
        @include font-semibold();
        border-radius: $border-radius;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        .status {
          display: inline-block;
          margin-right: 4px;
        }
      }
    `,
  ],
  standalone: true,
  imports: [RouterModule, DatePipe],
})
export class AgreementVisitContentComponent implements DialogIntakeImpl {
  readonly getStatusText = getStatusText;

  readonly data = inject(injectionToken, { optional: true });
  private readonly dialogRef = inject(MatDialogRef<DialogIntakeComponent<AgreementVisitContentComponent>>);

  save() {
    this.dialogRef.close();
  }

  static open(dialog: Dialog2ServiceImpl, data: AgreementVisitContentComponentData) {
    return dialog.intake(AgreementVisitContentComponent, {
      width: '510px',
      disableClose: false,
      intake: {
        title: 'Agreement Visits',
        text: `Agreement #${data.localId}`,
        intakeData: {
          injectionToken,
          data,
        },
        buttons: buttons.confirmButtons({
          text: 'Close',
        }),
      },
    });
  }
}

@Component({
  template: `@if (line(); as line) {
    @if (!(line.agreementVisits && line.agreementVisits.length)) {
      <span>None</span>
    } @else {
      <button type="button" class="as-link" (click)="showDuties()">Show</button>
    }
  }`,
  imports: [DatePipe],
  standalone: true,
})
export class TableDynamicNodeAgreementVisitsComponent {
  readonly dialog2 = inject(DIALOG_SERVICE_IMPL);

  readonly line = input<AgreementSiteSystemInformationForList>();

  showDuties(): void {
    const line = this.line();
    AgreementVisitContentComponent.open(this.dialog2, {
      localId: (line as ANY).data.localId,
      customerEntity: (line as ANY).customerEntity,
      agreementVisits: (line as ANY).agreementVisits,
    });
  }
}

