<div class="content" [class.with-transparent-background]="data.backdrop === BackdropType.Transparent">
  <ul>
    @for (group of groups(); track group.id) {
      <li class="section">
        @if (group.name; as name) {
          <div class="title">{{ name }}</div>
        }
        <ul>
          @for (button of group.buttons; track button.id) {
            <li>
              <button
                type="button"
                class="as-link"
                (click)="internalClick(button)"
                [class.selected]="button.selected"
                [attr.title]="button.title"
              >
                <span class="text">{{ button.text }}</span>
                <span class="selected-icon">
                  <img src="/assets/images/popover-button-selected.svg" alt="Selected" />
                </span>
              </button>
            </li>
          }
        </ul>
      </li>
    }
  </ul>
</div>
