import { Injectable, inject } from '@angular/core';
import { type AgreementConfiguration, type AgreementInformation } from '@models/cards/agreement-information';
import { skipBubbleError } from '@utility/angular';
import { of, type Observable } from 'rxjs';
import { concatMap, share, tap } from 'rxjs/operators';
import { ApplicationCacheService, UpdateCategory } from '../application-cache.service';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class AgreementsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(id?: Id): Observable<AgreementInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.Agreements, this.url.agreementList, id);
  }

  save(agreementInformation: AgreementInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.agreementsCreate, agreementInformation, skipBubbleError()).pipe(
      tap(_ => {
        this.clearCache(UpdateCategory.Agreements);
      }),
      share()
    );
  }

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.agreementsDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache(UpdateCategory.Agreements);
      }),
      share()
    );
  }

  setSettings(configuration: AgreementConfiguration): Observable<void> {
    return this.http.post<void>(this.url.setAgreementSettings, configuration).pipe(
      tap(_ => {
        this.clearCache(UpdateCategory.AgreementConfigurations);
      }),
      share()
    );
  }

  buildAgreementList$(ids: number[]): Observable<AgreementInformation[]> {
    return this.list().pipe(
      concatMap(agreementInformations => {
        return this.buildAgreementListInternal$(agreementInformations, ids);
      })
    );
  }

  private buildAgreementListInternal$(agreementInformations: AgreementInformation[], ids: number[]): Observable<AgreementInformation[]> {
    const result = agreementInformations.slice(0);
    for (let i = 0; i < ids.length; i++) {
      if (!result.some(n => n.id === ids[i])) {
        return this.list(ids[i]).pipe(
          concatMap(agreementServerInformations => {
            const requestedAgreementServerInformations = agreementServerInformations.find(n => n.id === ids[i]);
            if (requestedAgreementServerInformations) {
              result.push(requestedAgreementServerInformations);
            }

            return this.buildAgreementListInternal$(result, ids.slice(i + 1));
          })
        );
      }
    }

    return of(result);
  }

  private clearCache(updateCategory: UpdateCategory) {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(updateCategory);
    }
  }
}
