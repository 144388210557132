<div class="input text" [class.invalid]="inputModel.invalid && (!inputModel.pristine || inputModel.touched)">
  <div class="content">
    <input
      #input
      #inputModel="ngModel"
      [attr.autocomplete]="autoComplete"
      [attr.autocorrect]="autoCorrect"
      [attr.autocapitalize]="autoCapitalize"
      [autofocus]="autoFocus"
      [disabled]="disabled"
      [attr.list]="list"
      [attr.max]="max"
      [attr.maxlength]="maxLength"
      [attr.min]="min"
      [attr.minlength]="minLength"
      [readonly]="readOnly"
      [required]="required"
      [spellcheck]="spellCheck"
      [attr.step]="step"
      [attr.tabindex]="tabIndex"
      [placeholder]="placeholder"
      [type]="type"
      [attr.name]="name"
      (focus)="_handleFocus($event)"
      (blur)="_handleBlur($event)"
      [(ngModel)]="value"
      (change)="_handleChange($event)"
    />
    <div class="extra">
      <div class="mark" *ngIf="inputModel.invalid && (!inputModel.pristine || inputModel.touched)">!</div>
    </div>
  </div>
  @if (helperUI()) {
    <div class="input-helper">{{ helperUI() }}</div>
  }
</div>
