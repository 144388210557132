import { CommonModule } from '@angular/common';
import { Component, TemplateRef, computed, contentChild, forwardRef, input, type Provider } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { InputImageComponent } from '@controls/input-image/input-image.component';
import { RwComponent } from '@controls/rw/rw.component';
import { Mode } from '@models/form';
import { type OptionalTextResource1, type RequiredTextExtraResource1, type RequiredTextResource1 } from '@models/resource';

const RW_DROPDOWN_COMPONENT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RwDropdown2Component),
  multi: true,
};

type PossibleOptions<T> = RequiredTextResource1<T> | RequiredTextExtraResource1<T> | OptionalTextResource1<T>;

@Component({
  selector: 'wm-rw-dropdown2',
  templateUrl: 'rw-dropdown2.component.html',
  styleUrls: ['rw-dropdown.component.scss'],
  providers: [RW_DROPDOWN_COMPONENT_VALUE_ACCESSOR],
  exportAs: 'rwDropdown',
  host: {
    '[class.rw]': 'true',
    '[class.with-image]': 'hasImage()',
  },
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RwComponent, InputDropdownComponent, InputImageComponent],
})
export class RwDropdown2Component<T> extends ElementBaseWithFormControl<T> {
  readonly Mode = Mode;

  readonly name = input<string>();
  readonly imageCenter = input(false);
  readonly changeEmptyTo = input<T | null>(null);
  readonly options = input.required({ transform: (v: PossibleOptions<T>[] | null | undefined) => v ?? [] });
  readonly mode = input(Mode.Write);
  readonly required = input(false);
  readonly clearable = input(false);
  readonly dropdownParent = input<'body'>();
  readonly bindValue = input('id');
  readonly bindLabel = input('text');
  readonly searchFn = input<(item: string, term: PossibleOptions<T>) => boolean>();
  readonly placeholder = input<string>();
  readonly optgroupField = input<string | undefined>(undefined);
  readonly optgroups = input<{ value: string; label: string }[] | undefined>(undefined);

  readonly hasImage = computed(() => this.options().some(m => (m as { image?: string }).image != null) ?? false);

  readonly defaultOption = computed(() => this.options().find(m => m.id === -1) ?? null);
  private readonly signalValue = toSignal(this.innerValue);
  readonly selectedOption = computed(() => {
    const signalValue = this.signalValue();
    return this.options().find(m => m.id === signalValue) ?? this.defaultOption();
  });

  readonly writeTemplate2 = contentChild('writeTemplate', { read: TemplateRef });
  readonly readTemplate2 = contentChild('readTemplate', { read: TemplateRef });
  readonly helperTemplate2 = contentChild('helperTemplate', { read: TemplateRef });

  getImage(option: PossibleOptions<T>): string | undefined {
    return (option as { image?: string }).image;
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}

