import { EventEmitter, Injectable } from '@angular/core';
import { type Control } from '@models/actions';
import { type CreatableOption, type CreateViewType } from '@models/cards/create-view-data';
import { Mode } from '@models/form';
import { uniqueInPlace } from '@utility/array';
import { of, type Observable } from 'rxjs';

@Injectable()
export class ActionService {
  private readonly actionControls: Control[] = [];
  public mode: Observable<Mode> = of(Mode.Read);
  private creatableOptions: CreatableOption[] = [];

  public actionControlChange = new EventEmitter<Control[]>();
  public creatableOptionsChange = new EventEmitter<CreatableOption[]>();

  setMode(mode: Observable<Mode>): void {
    this.mode = mode;
  }

  unsetMode(): void {
    this.mode = of(Mode.Read);
  }

  registerActionControl(actionControls: Control[]): void {
    this.actionControls.push(...actionControls);
    uniqueInPlace(this.actionControls);

    if (actionControls.length > 0) {
      this.actionControlChange.emit(this.actionControls);
    }
  }

  unregisterActionControl(actionControls: Control[]): void {
    let hasChanged = false;
    for (const actionControl of actionControls) {
      const pos = this.actionControls.indexOf(actionControl);
      if (pos >= 0) {
        hasChanged = true;
        this.actionControls.splice(pos, 1);
      }
    }

    if (hasChanged) {
      this.actionControlChange.emit(this.actionControls);
    }
  }

  registerCreatableOption(...creatableOptions: CreatableOption[]): void {
    for (const creatableOption of creatableOptions.slice()) {
      // If we pushed the same thing, let's replace it.
      const index = this.creatableOptions.findIndex(m => m.type === creatableOption.type);
      if (index >= 0) {
        this.creatableOptions.splice(index, 1);
      }

      this.creatableOptions.push(creatableOption);
    }

    this.creatableOptionsChange.emit(this.creatableOptions);
  }

  unregisterCreatableOption(...createViewTypes: CreateViewType[]): void {
    for (const createViewType of createViewTypes.slice()) {
      const found = this.creatableOptions.findIndex(m => m.type === createViewType);
      if (found >= 0) {
        this.creatableOptions.splice(found, 1);
      }
    }

    this.creatableOptionsChange.emit(this.creatableOptions);
  }

  unregisterCreatableOptions(): void {
    this.creatableOptions = [];
    this.creatableOptionsChange.emit(this.creatableOptions);
  }
}
