import { type HttpErrorResponse } from '@angular/common/http';
import { type ElementRef } from '@angular/core';
import { fromEvent, type OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const mapHttpError = (err: HttpErrorResponse | null | undefined, supportedTypes: string[] | null = null): string => {
  const problemDetails = err?.error as ProblemDetails;

  if (!problemDetails) {
    if ((err as ANY).status === 403) {
      return 'You do not have the permissions to do this action.';
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw err;
  }

  return problemDetailsHandler(problemDetails, supportedTypes);
};

export const ERROR_URI = 'https://api.3cconnect.com/errors/';

export interface ProblemDetails {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  extensions?: Record<string, any>;
}

export function problemDetailsHandler(problemDetails: ProblemDetails, supportedTypes: string[] | null = null): string {
  if (supportedTypes && problemDetails.type) {
    const trimmedStr = problemDetails.type.startsWith(ERROR_URI) ? problemDetails.type.substring(ERROR_URI.length) : problemDetails.type;
    if (!supportedTypes.includes(trimmedStr)) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw problemDetails;
    }
  }

  if (problemDetails.detail) {
    return problemDetails.detail;
  } else if (problemDetails.title) {
    return problemDetails.title;
  }

  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw problemDetails;
}

export function httpErrorMultipleHandlers<T>(
  errorHandlers: { handler: (error: string, err: HttpErrorResponse) => T; supportedTypes?: string[] | null }[]
): (err: HttpErrorResponse) => T {
  return (err: HttpErrorResponse) => {
    for (const errorHandler of errorHandlers) {
      try {
        return errorHandler.handler(mapHttpError(err, errorHandler.supportedTypes), err);
      } catch {}
    }

    // If we were not able to handle it, we give up.
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw err;
  };
}

export function httpErrorHandler<T>(
  handler: (error: string, err: HttpErrorResponse) => T,
  supportedTypes: string[] | null = null
): (err: HttpErrorResponse) => T {
  return (err: HttpErrorResponse) => handler(mapHttpError(err, supportedTypes), err);
}

export function httpErrorHandlers<T>(
  handler: (error: string[], err: HttpErrorResponse[]) => T,
  supportedTypes: string[] | null = null
): (err: HttpErrorResponse[]) => T {
  return (errors: HttpErrorResponse[]) =>
    handler(
      errors.map(x => mapHttpError(x, supportedTypes)),
      errors
    );
}

export function attachBlobErrorDetection<T>(): OperatorFunction<T, T> {
  return catchError(err => {
    if (err.error instanceof Blob && err.error.type === 'application/json') {
      const reader = new FileReader();
      const evt = fromEvent(reader, 'loadend').pipe(
        map(_ => {
          const error = new Error('Blob error');
          (error as any).error = JSON.parse(reader.result as string);
          throw error;
        })
      );

      // Start reading the blob as text.
      reader.readAsText(err.error);

      return evt;
    } else {
      throw err;
    }
  });
}

export enum ErrorCode {
  Unknown = 0,
  Simple = 1,
  HttpError = 2,
}

export type HttpErrorModelState = Record<string, string[]>;

export interface HttpError {
  message: string;
  modelState?: HttpErrorModelState;
}

export interface AppErrorHandler {
  /**
   * Triggered when an error happens.
   * Return true if the error has been handled.
   * Return falsy, then the error will bubble to the next error handler.
   */
  onAppError: <T>(errorMessage: ErrorMessage<T>) => boolean;
}

export class DeveloperError extends Error {}

export class ErrorMessage<T> {
  code: ErrorCode = ErrorCode.Unknown;
  payload: T | undefined;

  toString(): string {
    switch (this.code) {
      case ErrorCode.Simple:
        return this.payload as any as string;
      case ErrorCode.HttpError: {
        let message = '';
        const payload: HttpError = this.payload as any;
        if (payload.modelState) {
          for (const property in payload.modelState) {
            // eslint-disable-next-line no-prototype-builtins
            if (payload.modelState.hasOwnProperty(property)) {
              for (const error of payload.modelState[property]) {
                message += `${error}\n`;
              }
            }
          }
        } else {
          message += payload.message;
        }

        return message.trim();
      }
    }

    return 'Unknown error';
  }
}

export class SimpleErrorMessage extends ErrorMessage<string> {
  constructor(message: string) {
    super();
    this.code = ErrorCode.Simple;
    this.payload = message;
  }
}

export interface AppResponseErrorOptions {
  noLogOut?: boolean;
}

export class AppResponseError<T> extends ErrorMessage<T> {
  constructor(public options: AppResponseErrorOptions = {}) {
    super();
  }

  response: HttpErrorResponse | undefined;
  url: string | undefined;

  toString(): string {
    let err = super.toString();

    // In case we receive a 405 or bad request, this is for debugging.
    if (err === 'Unknown error') {
      if (this.response) {
        if (this.response.message) {
          err = this.response.message;
        }
      }
    }

    return err;
  }
}

export interface ComponentError {
  elementRef: ElementRef;
  error: ErrorMessage<string>;
}
