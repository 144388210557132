<div class="top-content" [class.filter-open]="(filterHelper?.context?.open | async) || (dynamicFilterHelper?.opened$ | async)">
  <div class="title-content">
    <ng-content select=".title"></ng-content>
  </div>
  <div class="filter-content" *ngIf="filterHelper?.context" [hidden]="!(filterHelper?.context?.open | asyncSafe: false)">
    <wm-filter
      [activeFilters]="activeFilters"
      [clearActive]="true"
      [pointerAt]="pointerAt"
      (editClick)="internalEditClick()"
      (clearAllClick)="internalClearAllClick()"
    />
  </div>
  <div class="filter-content" *ngIf="dynamicFilterHelper" [hidden]="!(dynamicFilterHelper.opened$ | asyncSafe: false)">
    <wm-filter
      [activeFilters]="activeFilters$ | asyncSafe: []"
      [clearActive]="true"
      [pointerAt]="pointerAt"
      (editClick)="internalEditClick()"
      (clearAllClick)="internalClearAllClick()"
    />
  </div>
  <div class="actions">
    <ul>
      <li *ngIf="shouldDisplayLocation()">
        {{ helpId() }}
      </li>
      @if (shouldDisplayCog()) {
        <li>
          <button
            wm-button
            type="button"
            class="settings secondary"
            [imageUrl]="'/assets/images/topbar-icon-help.svg'"
            [round]="true"
            title="Help"
            (click)="helpClick()"
          ></button>
        </li>
      }
      <li *ngIf="filterHelper?.context?.filterSettingKey">
        <wm-filter-button-deprecated
          (init)="positionFilter($event)"
          [amount]="activeFilters.length"
          [open]="filterHelper?.context?.open | asyncSafe: false"
          (openChange)="internalOpenChange($event)"
        />
      </li>
      <li *ngIf="dynamicFilterHelper">
        <wm-filter-button-deprecated
          (init)="positionFilter($event)"
          [amount]="dynamicFilterHelper.filterAmount$ | asyncSafe: 0"
          [open]="dynamicFilterHelper.opened$ | asyncSafe: false"
          (openChange)="internalOpenChange($event)"
        />
      </li>
      <li *ngIf="showSettingsCog()">
        <button
          #settingCog="templateRef"
          templateRef
          (init)="initSettingCog(settingCog.elementRef)"
          wm-button
          type="button"
          class="settings more-settings secondary"
          [imageUrl]="'/assets/images/more-settings.svg'"
          [round]="true"
          title="Settings"
          (click)="settingClick()"
          [disabled]="!settingsCogEnabled"
        ></button>
      </li>
    </ul>
  </div>
</div>
