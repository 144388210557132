import { animate, style, transition, trigger } from '@angular/animations';
import { CdkPortalOutlet, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Injector,
  ViewChild,
  type ComponentRef,
  type OnInit,
  type Provider,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ANIMATION_TIMINGS } from '@models/animations';
import { DialogIntakeDataInternal, dialogTypeToImage, type DialogIntakeImpl, type DialogType, type IntakeButtons } from '@models/dialog';
import { type OptionalTextResource1, type RequiredTextResource1 } from '@models/resource';
import { SharedModule } from '@modules/shared.module';
import { SubscriptionManagerService } from '@services/ephemerals/subscription-manager.service';
import { BehaviorSubject, of, type Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: 'dialog-intake.component.html',
  styleUrls: ['dialog-intake.component.scss'],
  providers: [SubscriptionManagerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, PortalModule, MatDialogModule],
  animations: [
    trigger('backButton', [
      transition(':enter', [style({ opacity: 0, width: 0 }), animate(ANIMATION_TIMINGS, style({ opacity: 100, width: 56 }))]),
      transition(':leave', [animate(ANIMATION_TIMINGS, style({ opacity: 0, width: 0 }))]),
    ]),
  ],
})
export class DialogIntakeComponent<T extends DialogIntakeImpl> implements OnInit {
  buttons: IntakeButtons[];
  disableExit = false;
  disableEnter = false;
  title: string | null = null;
  text: string | null = null;
  type: DialogType | null = null;
  intakePortal: ComponentPortal<T>;
  dataTestId: string | null = null;

  get dialogImage(): string | null {
    if (this.type) {
      return dialogTypeToImage[this.type] ?? null;
    }

    return null;
  }

  @ViewChild('portal', { static: true })
  portal!: CdkPortalOutlet;

  @HostBinding('class.is-multi-step')
  get isMultiStep(): boolean {
    return this.dialogIntakeImpl?.getStepTitles && (!(this.dialogIntakeImpl.getStepTitles().length === 0) as ANY);
  }

  get showBackButton(): boolean {
    return this.stepIndex > 0;
  }

  get dialogIntakeImpl(): DialogIntakeImpl | null {
    const componentRef = this.portal.attachedRef as ComponentRef<T>;
    return componentRef?.instance || null;
  }

  stepIndex$ = new BehaviorSubject<number>(0);
  public get stepIndex(): number {
    return this.stepIndex$.getValue();
  }

  public set stepIndex(value: number) {
    this.stepIndex$.next(value);
  }

  constructor(
    private readonly dialogRef: MatDialogRef<DialogIntakeComponent<T>>,
    @Inject(MAT_DIALOG_DATA)
    dialogData: DialogIntakeDataInternal<T>,
    injector: Injector,
    private readonly cd: ChangeDetectorRef
  ) {
    this.title = dialogData.title;
    this.text = dialogData.text as ANY;
    this.type = dialogData.type as ANY;
    this.buttons = dialogData.buttons;
    this.disableExit = !!dialogData.disableExit;
    this.disableEnter = !!dialogData.disableEnter;
    this.dataTestId = dialogData.dataTestId ?? null;

    const injectionTokens: Provider[] = [];

    // Set custom injection tokens
    if (dialogData.intakeData) {
      injectionTokens.push({ provide: dialogData.intakeData.injectionToken, useValue: dialogData.intakeData.data });
    }

    const portalInjector = Injector.create({
      providers: Array.from(injectionTokens),
      parent: injector,
    });

    this.intakePortal = new ComponentPortal(dialogData.component, null, portalInjector);
  }

  ngOnInit(): void {
    // Because we fetch the intakePortal later, we need to call this.
    this.cd.detectChanges();
  }

  close(): void {
    if (this.dialogIntakeImpl?.close) {
      this.dialogIntakeImpl.close();
    } else {
      this.dialogRef.close();
    }
  }

  back(): void {
    if (this.dialogIntakeImpl?.back) {
      this.dialogIntakeImpl.back();
    }
  }

  internalButtonClick(button: RequiredTextResource1<string>): void {
    switch (button.id) {
      case 'cancel':
        this.close();
        break;
      case 'save':
        this.save();
        break;
      default:
        if (this.dialogIntakeImpl?.buttonClicked) {
          this.dialogIntakeImpl.buttonClicked(button.id);
        }

        break;
    }
  }

  enterPressed(): void {
    if (!this.disableEnter) {
      this.save();
    }
  }

  save(): void {
    this.getButtonDisabled$({ id: 'save' })
      .pipe(first())
      .subscribe(isDisabled => {
        if (!isDisabled) {
          this.dialogIntakeImpl?.save();
        }
      });
  }

  getButtonDisabled$(button: OptionalTextResource1<string>): Observable<boolean> {
    if (this.dialogIntakeImpl?.getButtonDisabled$) {
      return this.dialogIntakeImpl.getButtonDisabled$(button.id);
    }

    return of(false);
  }

  getButtonText$(button: RequiredTextResource1<string>): Observable<string> {
    if (this.dialogIntakeImpl?.getButtonText$) {
      return this.dialogIntakeImpl.getButtonText$(button.id);
    }

    return of(button.text) as ANY;
  }
}
