import { Injectable, inject, type OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { PubSubService } from '@services/pub-sub.service';
import { EMPTY, type Observable, of, Subject } from 'rxjs';
import { buffer, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { swap } from '../../utility/array';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import { allowRetries } from '@utility/angular';
import { reduce } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export enum TechnicianSetting {
  IPAD_SERVICE_RATING_STARS,
  WEB_DASHBOARD_ZOOM_LEVEL,
  WEB_DASHBOARD_BOARD,
  WEB_DASHBOARD_VERTICAL,
  WEB_NAVBAR_COLLAPSED,
  WEB_DASHBOARD_CURRENT_DATE,
  WEB_PREFERRED_SUBDOMAIN,
  WEB_DASHBOARD_LIVE_UPDATE,
  WEB_SIGNALR_DISABLED,

  WEB_FILTER_OPEN_AGREEMENT_SITE_SYSTEM,
  WEB_FILTER_OPEN_CUSTOMER,
  WEB_FILTER_OPEN_DUTY,
  WEB_FILTER_OPEN_REPLENISHMENT,
  WEB_FILTER_OPEN_REPORT_DETAIL,
  WEB_FILTER_OPEN_REPORT_INCENTIVE,
  WEB_FILTER_OPEN_REPORT_LEADERBOARD,
  WEB_FILTER_OPEN_REPORT_PERFORMANCE,
  WEB_FILTER_OPEN_REPORT_OVERVIEW,
  WEB_FILTER_OPEN_WORK_ORDER,
  WEB_FILTER_OPEN_SALES_PROPOSAL,
  WEB_FILTER_OPEN_UNSUBSCRIBES,
  WEB_FILTER_OPEN_EXPORT,

  WEB_FILTER_AGREEMENT_SITE_SYSTEM,
  WEB_FILTER_CUSTOMER,
  WEB_FILTER_DUTY,
  WEB_FILTER_REPLENISHMENT,
  WEB_FILTER_REPORT_DETAIL,
  WEB_FILTER_REPORT_INCENTIVE,
  WEB_FILTER_REPORT_PERFORMANCE,
  WEB_FILTER_REPORT_OVERVIEW,
  WEB_FILTER_WORK_ORDER,
  WEB_FILTER_SALES_PROPOSAL,
  WEB_FILTER_UNSUBSCRIBES,
  WEB_FILTER_REPORT_LEADERBOARD,
  WEB_FILTER_EXPORT,

  WEB_HELP_HIDE_CREATE_APPOINTMENT,
}

const technicianSettingKey: Record<number, string> = {};
technicianSettingKey[TechnicianSetting.IPAD_SERVICE_RATING_STARS] = 'IPad.ServiceRating.Stars';
technicianSettingKey[TechnicianSetting.WEB_DASHBOARD_ZOOM_LEVEL] = 'Web.Dashboard.ZoomLevel';
technicianSettingKey[TechnicianSetting.WEB_DASHBOARD_BOARD] = 'Web.Dashboard.Board';
technicianSettingKey[TechnicianSetting.WEB_DASHBOARD_VERTICAL] = 'Web.Dashboard.Vertical';
technicianSettingKey[TechnicianSetting.WEB_NAVBAR_COLLAPSED] = 'Web.Navbar.Collapsed';
technicianSettingKey[TechnicianSetting.WEB_DASHBOARD_CURRENT_DATE] = 'Web.Dashboard.CurrentDate';
technicianSettingKey[TechnicianSetting.WEB_PREFERRED_SUBDOMAIN] = 'Web.Preferred.Subdomain';
technicianSettingKey[TechnicianSetting.WEB_DASHBOARD_LIVE_UPDATE] = 'Web.Dashboard.LiveUpdate';
technicianSettingKey[TechnicianSetting.WEB_SIGNALR_DISABLED] = 'Web.SignalR.Disabled';

technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_AGREEMENT_SITE_SYSTEM] = 'Web.Filter.Open.AgreementSiteSystem';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_CUSTOMER] = 'Web.Filter.Open.Customer';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_DUTY] = 'Web.Filter.Open.Duty';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_REPLENISHMENT] = 'Web.Filter.Open.Replenishment';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_REPORT_DETAIL] = 'Web.Filter.Open.ReportDetail';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_REPORT_INCENTIVE] = 'Web.Filter.Open.ReportIncentive';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_REPORT_LEADERBOARD] = 'Web.Filter.Open.ReportLeaderboard';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_REPORT_PERFORMANCE] = 'Web.Filter.Open.ReportPerformance';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_REPORT_OVERVIEW] = 'Web.Filter.Open.ReportOverview';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_WORK_ORDER] = 'Web.Filter.Open.WorkOrder';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_SALES_PROPOSAL] = 'Web.Filter.Open.SalesProposal';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_UNSUBSCRIBES] = 'Web.Filter.Open.Unsubscribes';
technicianSettingKey[TechnicianSetting.WEB_FILTER_OPEN_EXPORT] = 'Web.Filter.Open.Export';

technicianSettingKey[TechnicianSetting.WEB_FILTER_AGREEMENT_SITE_SYSTEM] = 'Web.Filter.AgreementSiteSystem';
technicianSettingKey[TechnicianSetting.WEB_FILTER_CUSTOMER] = 'Web.Filter.Customer';
technicianSettingKey[TechnicianSetting.WEB_FILTER_DUTY] = 'Web.Filter.Duty';
technicianSettingKey[TechnicianSetting.WEB_FILTER_REPLENISHMENT] = 'Web.Filter.Replenishment';
technicianSettingKey[TechnicianSetting.WEB_FILTER_REPORT_DETAIL] = 'Web.Filter.ReportDetail';
technicianSettingKey[TechnicianSetting.WEB_FILTER_REPORT_INCENTIVE] = 'Web.Filter.ReportIncentive';
technicianSettingKey[TechnicianSetting.WEB_FILTER_REPORT_LEADERBOARD] = 'Web.Filter.ReportLeaderboard';
technicianSettingKey[TechnicianSetting.WEB_FILTER_REPORT_PERFORMANCE] = 'Web.Filter.ReportPerformance';
technicianSettingKey[TechnicianSetting.WEB_FILTER_REPORT_OVERVIEW] = 'Web.Filter.ReportOverview';
technicianSettingKey[TechnicianSetting.WEB_FILTER_WORK_ORDER] = 'Web.Filter.WorkOrder';
technicianSettingKey[TechnicianSetting.WEB_FILTER_SALES_PROPOSAL] = 'Web.Filter.SalesProposal';
technicianSettingKey[TechnicianSetting.WEB_FILTER_UNSUBSCRIBES] = 'Web.Filter.Unsubscribes';
technicianSettingKey[TechnicianSetting.WEB_FILTER_EXPORT] = 'Web.Filter.Export';

technicianSettingKey[TechnicianSetting.WEB_HELP_HIDE_CREATE_APPOINTMENT] = 'Web.Help.HideCreateAppointment';

@Injectable()
export class TechnicianSettingsService implements OnDestroy {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly authService = inject(AuthService);
  private readonly pubSubService = inject(PubSubService);

  private readonly destroy$ = new Subject<void>();
  _cachedDictionary: Dictionary<number, string> = {};

  saveSubject = new Subject<Dictionary<string, string>>();
  saveSubjectDebounce$ = this.saveSubject.pipe(debounceTime(2000));

  _ = this.saveSubject
    .pipe(
      buffer(this.saveSubjectDebounce$),
      takeUntilDestroyed(),
      switchMap(allSettings => {
        const settings = reduce(
          allSettings,
          (result, x) => {
            result = { ...result, ...x };
            return result;
          },
          {}
        );

        return this.http.post<void>(this.url.technicianSettingSave, settings, allowRetries()).pipe(
          tap(_ => {
            const swappedSettings = swap(technicianSettingKey);
            for (const key in settings) {
              if (swappedSettings[key]) {
                this._cachedDictionary[swappedSettings[key]] = (settings as ANY)[key];
              }
            }
          })
        );
      })
    )
    .subscribe();

  __ = this.pubSubService
    .getEventEmitter('LOGIN')
    .pipe(
      takeUntilDestroyed(),
      tap(() => {
        console.log('Clearing TechnicianSettingsService.');
      })
    )
    .subscribe(() => {
      this.clearCache();
    });

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getMultiple(...settings: TechnicianSetting[]): Observable<Dictionary<number, string | null>> {
    const finalArrayRequest = settings
      .filter(m => !!technicianSettingKey[m])
      .filter(m => !this._cachedDictionary[m])
      .map(m => technicianSettingKey[m]);

    let observableRequest: Observable<Dictionary<string, string>> = of(this._cachedDictionary);
    if (finalArrayRequest.length > 0) {
      observableRequest = this.http
        .post<Dictionary<string, string>>(this.url.technicianSettingGetMultiple, finalArrayRequest, allowRetries())
        .pipe(
          map(m => {
            const swappedSettings = swap(technicianSettingKey);
            for (const key in m) {
              if (swappedSettings[key]) {
                this._cachedDictionary[swappedSettings[key]] = m[key];
              }
            }

            return this._cachedDictionary;
          })
        );
    }

    return observableRequest.pipe(
      map(m => {
        const finalDictionary: Dictionary<number, string> = {};

        for (const setting of settings) {
          finalDictionary[setting] = m[setting];
        }

        return finalDictionary;
      })
    );
  }

  // TODO Cache this.
  list(): Observable<Dictionary<TechnicianSetting, string | null>> {
    return this.http.get(this.url.technicianSettingList).pipe(
      map((m: Dictionary<string, string | null>) => {
        const finalDict: Dictionary<number, string | null> = {};

        const swappedSettings = swap(technicianSettingKey);
        for (const key in m) {
          if (swappedSettings[key]) {
            finalDict[swappedSettings[key]] = m[key];
          }
        }

        return finalDict;
      }) as ANY
    );
  }

  save(setting: TechnicianSetting, value: string): Observable<void>;
  save(settings: Dictionary<TechnicianSetting, string>): Observable<void>;
  save(value1: any, value2?: string): Observable<void> {
    // When we are impersonating, we actually don't make requests to the server.
    if (this.authService && this.authService.isImpersonating()) {
      return EMPTY;
    }

    let settings: Dictionary<TechnicianSetting, string> = {};
    if (typeof value2 !== 'undefined') {
      settings[value1] = value2;
    } else {
      settings = value1;
    }

    const finalDict: Dictionary<string, string> = {};

    for (const setting in settings) {
      finalDict[technicianSettingKey[setting as ANY]] = settings[setting];
    }

    this.saveSubject.next(finalDict);

    return of(undefined);
  }

  clearCache(): void {
    this._cachedDictionary = {};
  }
}

