import { type Observable } from 'rxjs';
import { type OptionalTextExtraResource, type OptionalTextResource, type OptionalTextStateResource } from './resource';

export enum BlobContainer {
  Pending = 1,
  Validated,
  WorkOrder,
  WorkOrderPhoto,
  SiteSystemSignature,
  EmailAttachment,
  DistributorProduct,
  ContractorLogo,
  Export,
  Video,
  WorkOrderSignature,
  FindingRecommend,
  ProfilePhoto,
  ContractorFiles,
}

export enum FileType {
  ProfilePhoto = 10,
  ServiceAgreementFile = 12,
}

export interface FileInformation extends OptionalTextStateResource {
  isCommon?: boolean;
  isDefault?: boolean;

  createdDate?: Date;
  blobContainer?: BlobContainer | null;
  fileType?: FileType | null;
  mimeType?: string;
  sizeInBytes?: number;
  url?: string;
}

export interface PreparedFile {
  url: string;
  writeUrl: string;
  readUrl: string;
}

export interface UploadingFile extends OptionalTextResource {
  valid: boolean;
  percentage: Percentage | null;
  sizeInBytes: number;
  readUrl: string | null;
  error?: string;
  isDefault?: boolean;
  abort?: () => void;
  createdDate?: Date;
}

export type Upload = FileInformation | UploadingFile;

export interface UploadDocument extends OptionalTextExtraResource {
  uploadedFile: FileInformation | null;
}

export interface FileUploader {
  uploadFile: (file: File) => UploadHelper;
  getUploadingFile$: (guid: string) => Observable<UploadingFile> | undefined;
  // updateFile(data: FileInformation): Observable<void>;
}

export interface FileDownloader {
  downloadFile: (data: Upload) => void;
}

export enum UploadedStatus {
  Created = 0,
  Validated = 1,
}

export interface UploadStatus {
  progress: Percentage;
  loaded: number;
  total: number;
}

export interface UploadHelper {
  guid: string;
  uploadingFile$: Observable<UploadingFile>;
  onStatus: (fnc: (uploadStatus: UploadStatus) => void) => UploadHelper;
  abort: () => void;
  prepare: (prepared?: PreparedFile) => Observable<string>;
  validate: (addToFileInformation?: Partial<FileInformation>) => Observable<FileInformation>;
}
