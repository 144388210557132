<ng-select
  [disabled]="disabled()"
  [items]="unfrozenOptions()"
  [(ngModel)]="value"
  [multiple]="multiselect()"
  [groupBy]="optgroupField() ?? ''"
  [bindValue]="bindValue()"
  [bindLabel]="bindLabel()"
  [addTag]="allowCreate()"
  [clearable]="clearable()"
  [appendTo]="dropdownParent() ?? ''"
  [selectOnTab]="true"
  [editableSearchTerm]="!!allowCreate()"
  [searchFn]="searchFn()"
  [closeOnSelect]="!multiselect() || closeOnSelection()"
  [placeholder]="placeholder() ?? ''"
>
  <ng-template ng-optgroup-tmp let-item="item">
    {{ getOptGroup(item)?.label || 'Unnamed group' }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="option" [attr.data-testid]="'select-item-' + item.id">
      <span [ngOptionHighlight]="search">{{ item.text }}</span>
    </div>
  </ng-template>
  <ng-template ng-tag-tmp let-search="searchTerm">
    Add <strong>{{ search }}</strong>
  </ng-template>
  <ng-template ng-label-tmp let-item="item" let-clear="clear" let-label="label">
    @if (multiselect()) {
      <wm-tag [editable]="true" (deleteClick)="clear(item)">{{ label }}</wm-tag>
    } @else {
      <span class="ng-value-icon left" (click)="clear()" aria-hidden="true">×</span>
      <span class="ng-value-label">{{ label }}</span>
    }
  </ng-template>
</ng-select>

