import { Injectable, inject } from '@angular/core';
import { type WorkOrderAgreementInformation } from '@models/cards/work-order-agreement-information';
import { type WorkOrderDetailInformation } from '@models/cards/work-order-detail-information';
import {
  type WorkOrderDiscountInformation,
  type WorkOrderForList,
  type WorkOrderInformation,
  type WorkOrderInformationForList,
  type WorkOrderRebateInformation,
} from '@models/cards/work-order-information';
import { type WorkOrderPaymentInformation } from '@models/cards/work-order-payment-information';
import { type WorkOrderRepairPartInformation } from '@models/cards/work-order-repair-part-information';
import { type InvoiceResult, type WorkOrderBaseViewData, type WorkOrderViewData } from '@models/cards/work-order-view-data';
import { type EmailMessageState, type WorkOrderEmailMessageInformation } from '@models/email-models';
import { attachBlobErrorDetection } from '@models/error-models';
import { type SearchResult, type SortModel } from '@models/result';
import { type FileInformation } from '@models/upload';
import { PubSubService } from '@services/pub-sub.service';
import { allowRetries, skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import { type AgreementSiteSystemInformation } from '@models/cards/agreement-site-system-information';
import { type NonStockItemInformation } from '@models/cards/non-stock-item-information';
import { type Filter, type PagedResult } from '@models/filter-models';

export type PossibleFilter = {
  status?: number;
  dateFrom?: Date;
  dateEnd?: Date;
  technicianId?: number;
  excludeRecurringInvoice?: boolean;
  callDepartmentTypeId?: Id;
  callWorkOrderTypeId?: Id;
  invoice?: number;
  debrief?: number;
  happyCall?: number;
  completion?: number;
  agreementSold?: number;
  balanceType?: number;
  deleted?: boolean;
};

@Injectable()
export class WorkOrdersService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly pubSubService = inject(PubSubService);

  list(filter: Filter<PossibleFilter>): Observable<PagedResult<WorkOrderInformationForList>> {
    return this.http.post<PagedResult<WorkOrderInformationForList>>(this.url.workOrderList, filter, allowRetries());
  }

  get(id: Id): Observable<WorkOrderInformation> {
    return this.http.get<WorkOrderInformation>(this.url.workOrderSingle.replace('$0', id.toString()));
  }

  create(workOrderInformation: WorkOrderInformation): Observable<{ id: Id }> {
    return this._saveWorkOrder(workOrderInformation, this.url.workOrderCreate);
  }

  save(workOrderInformation: WorkOrderInformation): Observable<{ id: Id }> {
    return this._saveWorkOrder(workOrderInformation, this.url.workOrderSave);
  }

  finishUpdate(id: Id): Observable<void> {
    return this.http.post<void>(this.url.workOrderFinishUpdate.replace('$0', id.toString()), null);
  }

  private _saveWorkOrder(workOrderInformation: WorkOrderInformation, url: string): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(workOrderInformation, 'quickBooksStatus', 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(url, obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.workOrderDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.workOrderRestore.replace('$0', id.toString()), null);
  }

  saveWorkOrderAgreements(workOrderId: Id, workOrderAgreementInformations: WorkOrderAgreementInformation[]): Observable<{ id: Id }[]> {
    return this.http.post<{ id: Id }[]>(
      this.url.workOrderAgreementSave.replace('$0', workOrderId.toString()),
      workOrderAgreementInformations
    );
  }

  saveWorkOrderDetails(workOrderId: Id, workOrderDetailInformations: WorkOrderDetailInformation[]): Observable<{ id: Id }[]> {
    return this.http.post<{ id: Id }[]>(this.url.workOrderDetailSave.replace('$0', workOrderId.toString()), workOrderDetailInformations);
  }

  saveWorkOrderRepairParts(
    workOrderId: Id,
    workOrderDetailId: Id,
    workOrderRepairPartInformations: WorkOrderRepairPartInformation[]
  ): Observable<{ id: Id }[]> {
    return this.http
      .post<
        { id: Id }[]
      >(this.url.workOrderRepairPartSave.replace('$0', workOrderId.toString()).replace('$1', workOrderDetailId.toString()), workOrderRepairPartInformations)
      .pipe(
        tap(() => {
          if (this.pubSubService) {
            this.pubSubService.publish('workOrderRepairPartsSaved');
          }
        })
      );
  }

  getWorkOrderDetailsForWorkOrder(id: Id): Observable<WorkOrderDetailInformation[]> {
    return this.http.get<WorkOrderDetailInformation[]>(this.url.workOrderDetails.replace('$0', id.toString()));
  }

  getWorkOrderAgreementsForWorkOrder(id: Id): Observable<WorkOrderAgreementInformation[]> {
    return this.http.get<WorkOrderAgreementInformation[]>(this.url.workOrderAgreements.replace('$0', id.toString()));
  }

  getViewData(id: Id): Observable<WorkOrderViewData> {
    return this.http.get<WorkOrderViewData>(this.url.workOrderViewData.replace('$0', id.toString()));
  }

  getBaseViewData(): Observable<WorkOrderBaseViewData> {
    return this.http.get<WorkOrderBaseViewData>(this.url.workOrderCreateViewData);
  }

  toggleProcess(id: Id, processed: boolean): Observable<boolean> {
    return this.http.post<boolean>(this.url.workOrderToggleProcess.replace('$0', id.toString()), { input: processed });
  }

  unlinkQuickBooks(id: Id): Observable<InvoiceResult> {
    return this.http.post<InvoiceResult>(this.url.workOrderQuickBooksUnlink.replace('$0', id.toString()), null);
  }

  toggleSynchronizeWithQuickBooks(id: Id): Observable<InvoiceResult> {
    return this.http.post<InvoiceResult>(this.url.workOrderToggleQuickBooksStatus.replace('$0', id.toString()), null);
  }

  search(
    search: string,
    startDate: Date | null = null,
    endDate: Date | null = null,
    technicianId: Id | null = null,
    sort: SortModel | null = null,
    start: number | null = null,
    withHighlight: boolean = false
  ): Observable<SearchResult<WorkOrderForList>[]> {
    return this.http.post<SearchResult<WorkOrderForList>[]>(
      this.url.workOrderSearch,
      {
        search,
        startDate,
        endDate,
        technicianId,
        sort,
        start,
        withHighlight,
      },
      allowRetries()
    );
  }

  getEmailMessages(id: Id): Observable<WorkOrderEmailMessageInformation[]> {
    return this.http.get<WorkOrderEmailMessageInformation[]>(this.url.workOrderGetEmailMessages.replace('$0', id.toString()));
  }

  getEmailStates(id: Id, emailMessageId: Id): Observable<EmailMessageState[]> {
    return this.http.get<EmailMessageState[]>(
      this.url.workOrderGetEmailStates.replace('$0', id.toString()).replace('$1', emailMessageId.toString())
    );
  }

  getAttachment(id: Id, attachmentId: Id): Observable<Blob> {
    return this.http
      .get(this.url.workOrderGetAttachment.replace('$0', id.toString()).replace('$1', attachmentId.toString()), {
        responseType: 'blob',
      })
      .pipe(attachBlobErrorDetection());
  }

  getFiles(id: Id): Observable<FileInformation[]> {
    return this.http.get<FileInformation[]>(this.url.workOrderListFiles.replace('$0', id.toString()));
  }

  addFile(id: Id, fileId: Id): Observable<void> {
    return this.http.post<void>(this.url.workOrderAddFile.replace('$0', id.toString()).replace('$1', fileId.toString()), null);
  }

  deleteFile(id: Id, fileId: Id): Observable<void> {
    return this.http.delete<void>(this.url.workOrderDeleteFile.replace('$0', id.toString()).replace('$1', fileId.toString()));
  }

  getDiscounts(workOrderId: Id): Observable<WorkOrderDiscountInformation[]> {
    return this.http.get<WorkOrderDiscountInformation[]>(this.url.workOrderGetDiscounts.replace('$0', workOrderId.toString()));
  }

  saveDiscount(workOrderId: Id, workOrderDiscountInformation: WorkOrderDiscountInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.workOrderSaveDiscounts.replace('$0', workOrderId.toString()), workOrderDiscountInformation);
  }

  hideDiscount(workOrderId: Id, discountId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.workOrderDeleteDiscounts.replace('$0', workOrderId.toString()).replace('$1', discountId.toString())
    );
  }

  getRebates(workOrderId: Id): Observable<WorkOrderRebateInformation[]> {
    return this.http.get<WorkOrderRebateInformation[]>(this.url.workOrderGetRebates.replace('$0', workOrderId.toString()));
  }

  saveRebate(workOrderId: Id, workOrderRebateInformation: WorkOrderRebateInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.workOrderSaveRebates.replace('$0', workOrderId.toString()), workOrderRebateInformation);
  }

  hideRebate(workOrderId: Id, rebateId: Id): Observable<void> {
    return this.http.delete<void>(this.url.workOrderDeleteRebates.replace('$0', workOrderId.toString()).replace('$1', rebateId.toString()));
  }

  getPayments(workOrderId: Id): Observable<WorkOrderPaymentInformation[]> {
    return this.http.get<WorkOrderPaymentInformation[]>(this.url.workOrderGetPayments.replace('$0', workOrderId.toString()));
  }

  savePayment(workOrderId: Id, workOrderPayment: WorkOrderPaymentInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.workOrderSavePayments.replace('$0', workOrderId.toString()), workOrderPayment);
  }

  hidePayment(workOrderId: Id, paymentId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.workOrderDeletePayments.replace('$0', workOrderId.toString()).replace('$1', paymentId.toString())
    );
  }

  saveDetail(workOrderId: Id, workOrderDetail: WorkOrderDetailInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.workOrderSaveDetails.replace('$0', workOrderId.toString()), workOrderDetail);
  }

  hideDetail(workOrderId: Id, detailId: Id): Observable<void> {
    return this.http.delete<void>(this.url.workOrderDeleteDetails.replace('$0', workOrderId.toString()).replace('$1', detailId.toString()));
  }

  saveNonStockItem(workOrderId: Id, nonStockItem: NonStockItemInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.workOrderSaveNonStockItem.replace('$0', workOrderId.toString()), nonStockItem);
  }

  saveRepairPart(workOrderId: Id, detailId: Id, repairPart: WorkOrderRepairPartInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(
      this.url.workOrderSaveRepairParts.replace('$0', workOrderId.toString()).replace('$1', detailId.toString()),
      repairPart
    );
  }

  hideRepairPart(workOrderId: Id, detailId: Id, repairPart: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.workOrderDeleteRepairParts
        .replace('$0', workOrderId.toString())
        .replace('$1', detailId.toString())
        .replace('$2', repairPart.toString())
    );
  }

  saveAgreement(
    workOrderId: Id,
    workOrderAgreement: WorkOrderAgreementInformation
  ): Observable<{ id: Id; agreementSiteSystemId?: Id; agreementSiteSystem?: AgreementSiteSystemInformation }> {
    return this.http.post<{ id: Id }>(this.url.workOrderSaveAgreements.replace('$0', workOrderId.toString()), workOrderAgreement);
  }

  deleteAgreement(workOrderId: Id, workOrderAgreementId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.workOrderDeleteAgreements.replace('$0', workOrderId.toString()).replace('$1', workOrderAgreementId.toString())
    );
  }

  getOrCreatePaymentToken(workOrderId: Id): Observable<string> {
    return this.http.post<string>(this.url.workOrdersGetOrCreatePaymentToken.replace('$0', workOrderId.toString()), null);
  }

  forceComplete(workOrderId: Id): Observable<void> {
    return this.http.post<void>(this.url.workOrdersForceComplete.replace('$0', workOrderId.toString()), null, skipBubbleError());
  }
}
