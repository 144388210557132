import { type RequiredTextOrderedResource } from '../resource';

export enum SubscriptionInterval {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export interface AgreementInformation extends RequiredTextOrderedResource {
  description: string;
  monthsValidFor: number;
  price: Money;
  discountPercentage: Percentage;
  visitsOwed: number;
  interval: SubscriptionInterval | null;
  intervalCount: number | null;
}

export interface AgreementInformationForList {
  data: AgreementInformation;
}

export interface AgreementConfiguration {
  autoEndingBenefits: boolean;
  zoneRoundUp: boolean;
}

export function getText(interval: SubscriptionInterval): {
  text: string;
  singular: string;
  period: string;
} {
  switch (interval) {
    case SubscriptionInterval.Day:
      return { text: 'days', singular: 'day', period: 'daily' };
    case SubscriptionInterval.Week:
      return { text: 'weeks', singular: 'week', period: 'weekly' };
    case SubscriptionInterval.Month:
      return { text: 'months', singular: 'month', period: 'monthly' };
    case SubscriptionInterval.Year:
      return { text: 'years', singular: 'year', period: 'yearly' };
  }

  return { text: '', singular: '', period: '' };
}

export function createEmpty(): AgreementInformation {
  return {
    id: 0,
    text: '',
    description: '',
    discountPercentage: 0,
    price: 0,
    monthsValidFor: 0,
    visitsOwed: 0,
    interval: null,
    intervalCount: null,
  };
}
