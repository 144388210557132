<div matDialogTitle>
  <div class="text">
    <div class="title">Filters</div>
  </div>
</div>
<div matDialogContent>
  <ng-template #portal="cdkPortalOutlet" [cdkPortalOutlet]="filterPortal"></ng-template>
</div>
<div matDialogActions>
  <div class="buttons">
    <button wm-button type="button" class="secondary" (click)="cancel()">Cancel</button>
    <button wm-button type="button" class="primary" (click)="addFilters()" cdkFocusInitial="true">Add Filters</button>
  </div>
</div>
