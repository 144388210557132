<div class="tag" [class.editable]="editable()" [ngClass]="backgroundColor()">
  @if (editable()) {
    <button type="button" (click)="deleteClicked()" title="Delete">
      <img src="/assets/images/tag-delete.svg" alt="" />
    </button>
  }
  <span>
    <ng-content></ng-content>
  </span>
</div>
