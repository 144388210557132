<wm-rw2 [name]="name()" [formControl]="control" [mode]="mode()">
  @if (helperTemplate2(); as helperTemplate2) {
    <ng-template #helperTemplate let-control>
      <ng-container [ngTemplateOutlet]="helperTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    </ng-template>
  }
  <ng-template #writeTemplate let-control>
    <wm-input-money [(ngModel)]="value" [required]="required()" [min]="min()" [max]="max()" />
  </ng-template>
  <ng-template #readTemplate let-control>
    {{ value | money }}
  </ng-template>
</wm-rw2>
