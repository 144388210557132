<label [attr.for]="inputId" class="radio-label" #label>
  <!-- The actual 'radio' part of the control. -->
  <div class="wm-radio-container">
    <div class="wm-radio-outer-circle"></div>
    <div class="wm-radio-inner-circle"></div>
  </div>

  <input
    #input
    class="wm-radio-input wm-visually-hidden"
    type="radio"
    [id]="inputId"
    [checked]="checked"
    [disabled]="disabled"
    [name]="name"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    (change)="_onInputChange($event)"
    (focus)="_onInputFocus()"
    (blur)="_onInputBlur()"
    (click)="_onInputClick($event)"
  />

  <!-- The label content for radio control. -->
  <div class="wm-radio-label-content" [class.radio-label-before]="labelPosition === 'before'">
    <ng-content></ng-content>
  </div>
</label>
