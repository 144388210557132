<div class="input-resource">
  <div>
    <wm-input-dropdown
      [options]="options()"
      [(ngModel)]="value"
      [multiselect]="true"
      [ngModelOptions]="{ standalone: true }"
      [clearable]="true"
      [closeOnSelection]="closeOnSelection()"
      [allowCreate]="allowCreate()"
      [bindValue]="bindValue()"
      [optgroups]="optgroups()"
      [optgroupField]="optgroupField()"
    />
  </div>
</div>

