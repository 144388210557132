import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, computed, effect, inject, input, signal, viewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@controls/button/button.component';
import { FilterButtonComponent } from '@controls/filter-button/filter-button.component';
import { FilterDynamicModule } from '@controls/filter-dynamic/filter-dynamic.module';
import { TagComponent } from '@controls/tag/tag.component';
import { PopoverFilterDynamicComponent } from '@dialogviews/popover-filter-dynamic.component';
import { InitDirective } from '@directives/init.directive';
import { TemplateRefDirective } from '@directives/template-ref.directive';
import { BackdropType, DIALOG_SERVICE_IMPL, type PopoverButton } from '@models/dialog';
import { getNormalizedUIConfigs, type FancyFilterConfig, type SupportedFilters } from '@models/filter-models';
import { type FilterTable2 } from '@services/pager.service';
import { sortFnc } from '@utility/sort-fnc';
import { newGuid } from '@utility/string';
import { combineLatest, concatMap, map } from 'rxjs';

@Component({
  selector: 'wm-filter-dynamic',
  templateUrl: 'filter-dynamic.component.html',
  styleUrl: 'filter-dynamic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FilterDynamicModule,
    TagComponent,
    InitDirective,
    FilterButtonComponent,
    TemplateRefDirective,
    ButtonComponent,
  ],
})
export class FilterDynamicComponent<T> {
  readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  readonly filters = input.required<NonNullable<FilterTable2<T>['filters']>>();

  private readonly loaded = signal(false);

  private readonly shouldOpenOnInit = signal(false);

  readonly addFilterButton = viewChild<ElementRef<HTMLButtonElement>, ElementRef<HTMLButtonElement>>('addFilterButton', {
    read: ElementRef<HTMLButtonElement>,
  });

  private readonly reactiveFormGroup = toSignal(toObservable(this.filters).pipe(concatMap(filters => filters.service.data.valueChanges)), {
    initialValue: [],
  });

  readonly isDirty = toSignal(
    combineLatest([toObservable(this.filters), toObservable(this.reactiveFormGroup)]).pipe(
      map(([filters, _]) => {
        // Dirty in Angular is not reactive, so we look at the data change.
        return filters.service.data.dirty();
      })
    )
  );

  _ = effect(() => {
    const exec = async () => {
      const filters = this.filters();
      for (const filter of filters.available) {
        await filters.service.register(filter);
      }

      this.loaded.set(true);
      filters.service.fullyLoaded();
    };

    void exec();
  });

  readonly normalizedFilters = computed(() => {
    if (!this.loaded()) {
      return [];
    }

    return this.filters().available.map(filter => {
      return {
        filter,
        uiConfigs: getNormalizedUIConfigs(filter),
      };
    });
  });

  readonly selectedFilters = computed(() => {
    const value = this.reactiveFormGroup();

    const normalizedFilters = this.normalizedFilters();
    return this.filters()
      .requestedCodes()
      .map(selectedFilterCode => {
        const filter = normalizedFilters.find(m => m.filter.code === selectedFilterCode.$type)?.filter;
        return {
          id: selectedFilterCode,
          text: filter?.getUISummary(value.find(m => m.id === selectedFilterCode.id)?.value ?? null) ?? '',
          matIcon: filter?.getIcon?.().matIcon,
          imageUrl: filter?.getIcon?.().url,
        };
      });
  });

  readonly selectableNewFilters = computed(() => {
    const allFilters = this.normalizedFilters().map(normalizedFilter => ({
      multiple: normalizedFilter.filter.multiple ?? false,
      name: normalizedFilter.filter.getSimpleName(),
    }));
    const selectedFilters = this.filters()
      .requestedCodes()
      .map(m => m.$type);
    const availableFilters = allFilters
      .filter(allFilter => allFilter.multiple || !selectedFilters.includes(allFilter.name.id))
      .map(m => m.name);

    return availableFilters.sort(sortFnc(false, 'text'));
  });

  readonly addFilterDisabled = computed(() => {
    return this.selectableNewFilters().length === 0;
  });

  getInputs(config: FancyFilterConfig): Record<string, unknown> {
    return {
      ...((config.ui as { inputs?: Record<string, unknown> }).inputs ?? {}),
    };
  }

  addFilterClick(): void {
    const filterButton = this.addFilterButton();
    if (filterButton) {
      this.dialog2.menu(
        this.selectableNewFilters().map(selectableNewFilter => {
          return {
            ...selectableNewFilter,
            click: () => {
              this.shouldOpenOnInit.set(true);
              const requestedCodes = this.filters().requestedCodes();
              const newEntry = {
                id: newGuid(),
                $type: selectableNewFilter.id,
                value: null,
              } satisfies SupportedFilters<any>[0];
              this.filters().change(requestedCodes.concat(newEntry));
            },
          } satisfies PopoverButton;
        }),
        filterButton.nativeElement,
        'below',
        BackdropType.Transparent
      );
    }
  }

  changeFilterClick(filterCode: SupportedFilters<any>[0], elementRef: ElementRef<HTMLElement>): void {
    const filter = this.normalizedFilters().find(m => m.filter.code === filterCode.$type);
    if (filter) {
      PopoverFilterDynamicComponent.open(this.dialog2, elementRef.nativeElement, {
        uiConfigs: filter.uiConfigs,
        service: this.filters().service,
        filterId: filterCode.id,
      });
    }
  }

  removeFilterClick(filterCode: SupportedFilters<any>[0]): void {
    this.filters().service.reset(filterCode);
    this.filters().change(
      this.filters()
        .requestedCodes()
        .filter(selectedFilterCode => selectedFilterCode.id !== filterCode.id)
    );
  }

  initFilterClick(filterCode: SupportedFilters<any>[0], elementRef: ElementRef<HTMLElement>): void {
    if (this.shouldOpenOnInit()) {
      this.changeFilterClick(filterCode, elementRef);
      this.shouldOpenOnInit.set(false);
    }
  }

  saveViewClick(): void {}
}
