export enum Feature {
  RoleAssignments = 1, // ALL
  CostRecoveryManager = 2, // ALL
  BoardManagement = 3, // ALL
  GpsLocationTracking = 4,
  EmailWhitelisting = 5,
  HelpCenter = 6, // ALL
  PhoneSupport = 7,
  HVACFieldDiagnosticVideos = 8, // ALL
  KPIDashboardReporting = 9,
  IncentiveTracking = 10,
  Leaderboards = 11,
  Debrief = 12,
  CustomerSiteManagement = 13, // ALL
  EquipmentManagement = 14, // ALL
  SchedulingDashboard = 15, // ALL
  TaskManagement = 16, // ALL
  AgreementManagement = 17,
  ZoneManagement = 18,
  RecurringBilling = 19,
  WorkOrders = 20, // ALL
  Discounts = 21,
  Rebates = 22,
  PrintingEmailingInvoices = 23, // ALL
  TaxManagement = 24, // ALL
  AcceptCreditCardPayments = 25,
  FieldPricingCalculator = 26, // ALL
  ServicePriceBook = 27,
  SalesPriceBook = 28,
  PartItems = 29,
  ReplenishmentDistributors = 31,
  Podium = 34,
  SmsTextMagic = 35,
  QuickBooks = 36,
  PictureRecommendations = 37, // ALL
  CustomWorkOrders = 38, // ALL
  CustomerHistory = 39, // ALL
  FieldInvoicing = 40, // ALL
  InspectionSheets = 41,
  BeltsAndFilters = 42,
  Tag = 46,
  Marketing = 47,
  Zapier = 48,
  Loan = 49,
}

export enum ConciseFeature {
  FieldMobileApp = 1,
  /** @deprecated */
  CRM = 2,
  /** @deprecated */
  Account = 3,
  FlatRateServicePricing = 4,
  /** @deprecated */
  AdvancedReporting = 5,
  /** @deprecated */
  BaseReporting = 6,
  Agreements = 7,
  /** @deprecated */
  Payment = 8,
  /** @deprecated */
  Accounting = 9,
  RecurringMemberships = 10,
  BestBetterGoodSalesSystem = 11,
  Marketing = 12,
  /** @deprecated */
  Texting = 13,
  /** @deprecated */
  WhiteGlove = 14,
  ProductSupport = 15,
  Onboarding = 16,
  EliteMembership = 17,
  ReplenishmentDistributors = 18,
  DispatchScheduling = 19,
  CustomerSiteManagement = 20,
  DigitalInvoices = 21,
  DragNDropScheduling = 22,
  EquipmentHistory = 23,
  TaskManager = 24,
  TextNotifications = 25,
  RoleAssignments = 26,
  DetailedReportsManager = 27,
  QuickBooks = 28,
  PictureHistory = 29,
  AcceptCreditCardPayments = 30,
  PricingCalculator = 31,
  FieldOfficeQuote = 32,
  GeographicZonePricing = 33,
  Walkthrough = 34,
  DiagnosticVideos = 35,
  SalesFinancing = 36,
  EmailWhitelisting = 37,
  DiscountsAndRebates = 38,
  CustomizableFlatRate = 39,
  InventoryReplenishment = 40,
  InspectionSheets = 41,
  TechnicianIncentive = 42,
  Leaderboards = 43,
  GpsLocationTracking = 44,
  Tag = 45,
  QuaterlyAccountReview = 46,
  ProposalTemplates = 47,
  ProposalManager = 48,
  MarketingReview = 49,
  Zapier = 50,
  PhoneSupport = 51,
  StarterResource = 52,
  ProResource = 53,
  EnterpriseResource = 54,
  UserAddition = 55,
}

export const FeatureLabels: Record<number, { text: string; helper?: string }> = {
  [Feature.RoleAssignments]: {
    text: 'Role Assignments',
    helper: 'Assign specific roles to each of your user giving them access to only what they need',
  },
  [Feature.CostRecoveryManager]: {
    text: 'Cost Recovery Manager',
    helper:
      'By adding a small amount to each service line item per day, 3C Connect effectively pays for itself, which means you get all of the benefits and none of the overhead',
  },
  [Feature.BoardManagement]: {
    text: 'Scheduling & Board Management',
    helper: 'Create custom schedule boards for individual users and teams',
  },
  [Feature.GpsLocationTracking]: {
    text: 'GPS Location Tracking',
    helper: 'Monitor field users location as well as geographic scheduling efficiency',
  },
  [Feature.EmailWhitelisting]: {
    text: 'Email Whitelisting',
    helper: 'Email whitelisting ensures a "safe" and approved list of email addresses that are always allowed through spam filters',
  },
  [Feature.HelpCenter]: {
    text: 'Help Center, Walkthroughs & Virtual Support',
    helper:
      'Interactive guides provide a significantly better user experience, helping customer walk through the solution they need one step at a time without waiting for a live business support agent to assist',
  },
  [Feature.PhoneSupport]: {
    text: 'Phone Support',
    helper: "Includes Phone Support from your direct account manager. Starter accounts can access support by email and 3C's help center",
  },
  [Feature.HVACFieldDiagnosticVideos]: {
    text: '(HVAC) Field Diagnostic Video Support',
    helper: 'HVAC service technician training and diagnostic video support',
  },
  [Feature.KPIDashboardReporting]: {
    text: 'KPI Dashboard Reporting',
    helper: 'Fully customizable, easy to use reporting so you can track what’s important to you and your business',
  },
  [Feature.IncentiveTracking]: {
    text: 'Incentive Tracking',
    helper: 'Generate technician incentive reports to reward and stimulate additional field sales',
  },
  [Feature.Leaderboards]: {
    text: 'Leaderboards',
    helper:
      'Create fully customizable leaderboards for data driven management and productive performance reviews and inner-department meetings',
  },
  [Feature.Debrief]: {
    text: 'Work Order Debrief',
    helper:
      'Save hours in administration with our work order debrief system to close appointments accurately and generate real time reports',
  },
  [Feature.CustomerSiteManagement]: {
    text: 'Customer & Site Management',
    helper: "Keep a comprehensive roster of sites and systems under a customer's profile with complete history in one location",
  },
  [Feature.EquipmentManagement]: {
    text: 'Equipment & Work Area Management',
    helper: 'Better manage your individual equipment and work area information',
  },
  [Feature.SchedulingDashboard]: {
    text: 'Scheduling Dashboard',
    helper:
      'The scheduling dashboard saves you time by allowing you to easily locate technicians out in the field, track their progress and adjust their workload in real time',
  },
  [Feature.TaskManagement]: {
    text: 'Task Management',
    helper:
      'Increase customer retention and revenue with detailed customer tracking and task management, preventing frustrated customers and sales opportunities from falling through the cracks',
  },
  [Feature.Tag]: {
    text: 'Opportunity Tagging',
    helper: 'Tag call opportunities and grow your marketing funnel',
  },
  [Feature.Marketing]: {
    text: 'List Management',
    helper: 'Sort and filter your marketing lists to enhances your email & SMS efforts',
  },
  [Feature.AgreementManagement]: {
    text: 'Agreement Management',
    helper: 'Automate Annual and Monthly memberships, billing and receiving, allowing the support team to focus on other tasks',
  },
  [Feature.ZoneManagement]: {
    text: 'Geographic Zone Management',
    helper: 'Assign customers to geographic zones and automate pricing based on travel time/distance',
  },
  [Feature.RecurringBilling]: {
    text: 'Recurring Payment Manager',
    helper:
      '3C Connect automatically generates monthly invoices, processes recurring payments and seamlessly synchronizes them to QuickBooks. Set it and forget it!',
  },
  [Feature.WorkOrders]: {
    text: 'Work Orders',
    helper: 'Create individual work orders within one appointment. Great for multiple work areas, rooms, equipment etc.',
  },
  [Feature.Discounts]: {
    text: 'Discounts',
    helper: 'Assign company approved discounts for field users to apply when invoicing customer or allow for manual discount entries',
  },
  [Feature.Rebates]: {
    text: 'Rebates',
    helper: 'Add or update company approved rebates for your sales and field users to select when quoting or invoicing customers.',
  },
  [Feature.PrintingEmailingInvoices]: {
    text: 'Printing & Emailing',
    helper: 'Easily email completed invoices or print them out directly from the customers work order',
  },
  [Feature.TaxManagement]: { text: 'Tax Management', helper: 'Add or update your companies tax data for individual regions' },
  [Feature.AcceptCreditCardPayments]: {
    text: 'Accept Credit Card Payments',
    helper: 'Process online or send payment request links right from 3C Connects completed work orders',
  },
  [Feature.FieldPricingCalculator]: {
    text: 'Field Pricing Calculator',
    helper:
      'Allows field users to manually add service description and pricing by entering the necessary information into the pricing calculator',
  },
  [Feature.ServicePriceBook]: {
    text: 'Flat Rate Service Pricing',
    helper: 'Select pricing from your companies fully customizable approved service price book',
  },
  [Feature.SalesPriceBook]: {
    text: 'Sales Price Book',
    helper: "Customize your own sales book and Best, Better, Good pricing templates through 3C's sales builder",
  },
  [Feature.PartItems]: {
    text: 'Part Items',
    helper:
      'Offer different brands of parts from multiple distributors under the same services or repairs, and use part markups to maintain consistent Gross Margin',
  },
  [Feature.ReplenishmentDistributors]: {
    text: 'Inventory Replenishment',
    helper:
      'Automated parts and materials replenishment sent directly to your own warehouse inventory or parts distributor for immediate replenishment',
  },
  [Feature.Podium]: {
    text: 'Review Accelerator',
    helper:
      '3C initiates a proactive review solicitation through our invoicing as well as an automated text review link sent directly from the technicians field device upon the call completion<br />*The Automated text invites require using one of our integrations',
  },
  [Feature.SmsTextMagic]: {
    text: 'SMS Text Magic',
    helper: 'Automatically group and send all next day appointment confirmations with the push of a button',
  },
  [Feature.QuickBooks]: {
    text: 'QuickBooks Desktop / Online',
    helper:
      "Automatically create new QB's customers or update existing customers, sync all invoice line items to appropriate class, auto sync payments with invoices and, if needed, collect payments via your customers' invoice",
  },
  [Feature.PictureRecommendations]: {
    text: 'Capture and Pictures',
    helper: 'Attach pictures, findings and recommendations to work orders and easily access for future review',
  },
  [Feature.CustomWorkOrders]: {
    text: 'Custom Work Orders',
    helper:
      'Create individual work orders within one appointment.  (Great for multiple work areas, rooms, equipment, etc.) Add service line items with and without agreements and capture customers approval',
  },
  [Feature.CustomerHistory]: { text: 'Customer History', helper: 'Easily review, add or edit your customers information from the field' },
  [Feature.FieldInvoicing]: {
    text: 'Technician Sales Flow',
    helper: 'The 3C Field application has a built-in steps technician sales presentation process that when used will skyrocket services',
  },
  [Feature.InspectionSheets]: {
    text: 'Work Performed & Inspection Forms',
    helper: 'Fully customizable red, yellow, green inspection and work performed sheets',
  },
  [Feature.Zapier]: {
    text: 'Zapier',
    helper: 'Creates Zap with our partner to automate other parts of your business with other software',
  },
};

export const ConciseFeatureLabels: Record<ConciseFeature, { text: string; helper?: string; emphasize?: boolean; support?: boolean }> = {
  [ConciseFeature.FieldMobileApp]: {
    text: 'Field Mobile App',
    helper:
      "The 3C Field application includes a built-in call sequence to help technicians review findings and recommendations with customers. When used effectively, 3C's call sequence can significantly boost technician revenue, sales leads, and agreement sales",
  },
  [ConciseFeature.ReplenishmentDistributors]: {
    text: 'Distributors Parts Manager',
    helper: 'Add multiple distributors and part inventories, and link them to your price books for auto replenishment',
  },
  [ConciseFeature.CRM]: { text: 'CRM', helper: "Manage your company's relationship and interactions with your customers" },
  [ConciseFeature.Account]: { text: '3C Account Management', helper: 'Manage your 3C account by tracking taxes and your technicians' },
  [ConciseFeature.FlatRateServicePricing]: {
    text: 'Flat Rate Service Book',
    helper: "Select pricing from your company's fully customizable approved service price book",
  },
  [ConciseFeature.AdvancedReporting]: {
    text: 'Advanced Reporting',
    helper: 'Create fully customizable reports tracking KPI and user leaderboards by following important metrics',
  },
  [ConciseFeature.BaseReporting]: {
    text: 'Base Reporting',
    helper: 'Create fully customizable reports tracking KPI by following important metrics',
  },
  [ConciseFeature.Agreements]: {
    text: 'Service Agreement Manager',
    helper: 'Automate service agreements to unburden your support team and allow them to focus on more important tasks',
  },
  [ConciseFeature.Payment]: {
    text: 'Payment Processing',
    helper: 'Process online or send payment request links right from 3C Connects completed work orders',
  },
  [ConciseFeature.Accounting]: {
    text: 'Accounting Integration',
    helper: 'Integrate seemlesly with QuickBooks Desktop or Online for your accounting needs',
  },
  [ConciseFeature.RecurringMemberships]: {
    text: 'Automated Recurring Memberships',
    helper:
      '3C Connect automatically generates future tune-up calls, monthly invoices, and recurring payments, seamlessly synchronizing them with QuickBooks. Set it and forget it!',
  },
  [ConciseFeature.BestBetterGoodSalesSystem]: {
    text: 'Best, Better, Good Sales Builder',
    helper: 'Fully customizable sales book access',
  },
  [ConciseFeature.Marketing]: {
    text: 'Marketing List Builder',
    helper: 'Easily sort and filter customers based on your marketing criteria',
  },
  [ConciseFeature.Texting]: {
    text: 'Texting (In-route and review automation)',
    helper:
      '3C initiates a proactive review solicitation through our invoicing as well as an automated text review link sent directly from the technicians field device upon the call completion<br />*The Automated text invites require using one of our integrations',
  },
  [ConciseFeature.WhiteGlove]: {
    text: 'White Glove Support',
    helper:
      'Priority Service Level product support<br /><br />Priority Support includes access through Chat, email, or 1:1 support with a 3C product specialist',
  },
  [ConciseFeature.ProductSupport]: { text: 'Email/Web Chat Support', helper: '24/7 email and web chat support', support: true },
  [ConciseFeature.EliteMembership]: {
    text: '3C Elite Resource Center Access',
    helper:
      "3C Elite Resource center access<ul><li>Access to 3C Academy</li><li>Monthly coaching session</li><li>Unlocked Service & Sales Pricing Programs</li><li>Unlocked Job Cost Calculators</li><li>Unlocked Annual budget tools</li><li>Unlocked Departmental forecasting tools</li><li>Unlocked Breakeven Tracker</li><li>Incoming & outgoing call scripts</li><li>Sales & Service Training playbooks</li><li>Sales & installation Sop's</li><li>Warehouse & warranty playbooks</li><li>Inventory lists</li><li>HR-Employment adds, interviewing sop's, Job -Descriptions</li><li>HVAC Forms & Templates</li><li>and more..</li></ul><br />* Speak with 3C Support for your personalized pricing",
  },
  [ConciseFeature.DispatchScheduling]: {
    text: 'Customizable Dispatch Scheduling',
    helper: 'Create custom schedule boards for individual users and teams',
  },
  [ConciseFeature.CustomerSiteManagement]: {
    text: 'Customer & Site Management',
    helper: "Keep a comprehensive roster of sites and systems under a customer's profile with complete history in one location",
  },
  [ConciseFeature.DigitalInvoices]: {
    text: 'Digital Invoices',
    helper:
      'Fully customize your paperless invoices. Default them to reflect a single company invoicing strategy, or personalize them to meet individual customer needs',
  },
  [ConciseFeature.DragNDropScheduling]: {
    text: 'Drag & Drop Scheduling',
    helper:
      'The scheduling dashboard saves you time by letting you easily locate technicians in the field, track their progress, and adjust their workload in real time',
  },
  [ConciseFeature.EquipmentHistory]: {
    text: 'Equipment History & Warranty Tracking',
    helper: "Add, edit, or update customers' warranty information, as well as their equipment and accessories list",
  },
  [ConciseFeature.TaskManager]: {
    text: 'Task Manager & Appointment Reminder',
    helper:
      'Includes marketing reminders, increases customer retention and revenue with detailed customer tracking and task management, and prevents frustrated customers and sales opportunities from falling through the cracks',
  },
  [ConciseFeature.TextNotifications]: {
    text: 'Text Notifications',
    helper: 'Text confirmations and customer communications',
  },
  [ConciseFeature.RoleAssignments]: {
    text: 'Admin Account Management',
    helper: 'Assign specific roles to each user, giving them access only to what they need',
  },
  [ConciseFeature.DetailedReportsManager]: {
    text: 'Detailed Reports Manager',
    helper: "Fully customizable and easy-to-use reporting to track what's important to you and your business",
  },
  [ConciseFeature.QuickBooks]: {
    text: 'Quickbooks Desktop / Online',
    helper:
      "Automatically create new QuickBooks customers or update existing ones, sync all invoice line items to the appropriate class, auto-sync payments with invoices, and, if needed, collect payments via your customers' invoices",
  },
  [ConciseFeature.PictureHistory]: {
    text: 'Pictures & Site History',
    helper: 'Attach pictures, findings, and recommendations to work orders for easy future access',
  },
  [ConciseFeature.AcceptCreditCardPayments]: {
    text: 'Mobile Credit Card Processing',
    helper:
      'Office and field users can securely collect payments from customers using our built-in credit card processing integration. Process payments online or send payment request links directly from the 3C Connect dashboard',
  },
  [ConciseFeature.PricingCalculator]: {
    text: 'Service Repair Pricing Calculator',
    helper:
      "Run non-stock repairs through 3C's in-app Field Pricing Calculator — ideal for products and services not covered by your flat rate pricing",
  },
  [ConciseFeature.FieldOfficeQuote]: {
    text: 'Field & Office Quote Builder',
    helper: 'Create custom quotes from both the 3C mobile app and office',
  },
  [ConciseFeature.GeographicZonePricing]: {
    text: 'Geographic Zone Pricing',
    helper: 'Assign customers to geographic zones and automate pricing based on travel time and distance',
  },
  [ConciseFeature.Walkthrough]: {
    text: 'Walkthrough Training Videos',
    helper:
      "Easily accessible walkthrough videos for each of 3C's web and admin settings sections, significantly reducing the learning curve for new and existing coworkers",
  },
  [ConciseFeature.DiagnosticVideos]: {
    text: 'Diagnostic Training Videos',
    helper: 'HVAC service technician training and diagnostic video support',
  },
  [ConciseFeature.SalesFinancing]: {
    text: 'Sales Financing Options',
    helper: "In-app access to your specific financing options, as well as on-the-spot loan applications through 3C's loan integration.",
  },
  [ConciseFeature.EmailWhitelisting]: {
    text: 'Email Whitelisting',
    helper: 'Email whitelisting ensures a "safe" and approved list of email addresses that are always allowed through spam filters',
  },
  [ConciseFeature.DiscountsAndRebates]: {
    text: 'Discounts & Rebates Manager',
    helper:
      "Customize by adding your company's approved discount and rebate line items, which will be accessible through 3C's flat rate pricing program",
  },
  [ConciseFeature.CustomizableFlatRate]: {
    text: 'Customizable Flat Rate Builder',
    helper: 'You have full access to completely customize your flat rate price books',
  },
  [ConciseFeature.InventoryReplenishment]: {
    text: 'Inventory Replenishment',
    helper:
      'Automated parts and materials replenishment sent directly to your own warehouse inventory or parts distributor(s) for immediate replenishment',
  },
  [ConciseFeature.InspectionSheets]: {
    text: 'Red, yellow, Green Inspection Reports',
    helper: 'Fully customizable red, yellow, green inspection and work performed sheets',
  },
  [ConciseFeature.TechnicianIncentive]: {
    text: 'Technician Incentive Tracker',
    helper: 'Generate technician incentive reports to reward and stimulate additional field sales',
  },
  [ConciseFeature.Leaderboards]: {
    text: 'Employee Leaderboards',
    helper: 'Create fully customizable leaderboards for data-driven management while producing a culture of competition',
  },
  [ConciseFeature.GpsLocationTracking]: {
    text: 'GPS Field Tracker',
    helper: "Monitor field users' locations as well as geographic scheduling efficiency",
  },
  [ConciseFeature.Tag]: {
    text: 'Opportunity Tagging',
    helper: 'Tag specific call opportunities to enhance and grow your marketing funnel',
  },
  [ConciseFeature.QuaterlyAccountReview]: {
    text: 'Quarterly Account Review',
    helper: 'Quarterly coaching and product usage reviews with your 3C product specialists',
    support: true,
  },
  [ConciseFeature.ProposalTemplates]: {
    text: 'Proposal Templates',
    helper: "Create and save your own Best, Better, and Good pricing templates through 3C's sales builder",
  },
  [ConciseFeature.ProposalManager]: {
    text: 'Proposal Manager',
    helper: 'Filter and manage proposals by type, salesperson, date, and status',
  },
  [ConciseFeature.MarketingReview]: {
    text: 'Marketing & Review Automation',
    helper:
      "Automated pre-arrival text messages sent out with field users' pictures and a short personal bio\n\nAutomated review invites to supercharge your social media reviews, company reputation, and branding\n\nSegment marketing for texting and email",
  },
  [ConciseFeature.Zapier]: {
    text: 'Zapier',
    helper: 'Zapier automates your work across 7,000+ app integrations, so you can focus on what matters',
  },
  [ConciseFeature.PhoneSupport]: {
    text: 'Account Rep. Phone Support',
    helper: "Escalated phone support is available when resolving an issue through 3C's support library, email, or chat is not sufficient",
    support: true,
  },
  [ConciseFeature.Onboarding]: {
    text: 'Do-It-For-You Onboarding',
    helper:
      "3C's DO-IT-FOR-YOU support and onboarding will provide the oversight and resources needed to ensure you get started quickly and efficiently.<br /><br />This includes:<ul><li>Dedicated onboarding support</li><li>Email whitelisting—avoiding spam folders</li><li>Personalizing your branding setup</li><li>Improved integrations</li><li>Enhanced training</li></ul>Guaranteed to save time (setup, training, and rollout) and money, as we fast-track your company's implementation and learning curve.<br /><br />* Speak with 3C Support for your personalized pricing",
  },
  [ConciseFeature.StarterResource]: {
    text: 'Starter Resource Center Access',
    helper:
      'Starter Resource center access<br /><ul><li>Partial access to 3C Academy</li><li>Unlocked Service & Sales Pricing Programs</li><li>Unlocked Job Cost Calculators</li><li>Unlocked Departmental forecasting tools</li><li>Unlocked Breakeven Tracker</li><li>Incoming & outgoing call scripts</li><li>Warehouse & warranty playbooks</li><li>Inventory lists</li><li>HVAC Forms & Templates</li><li>and more...</li></ul>',
    support: true,
    emphasize: true,
  },
  [ConciseFeature.ProResource]: {
    text: 'Pro Resource Center Access',
    helper:
      "Pro Resource center access<br /><ul><li>Access to 3C Academy</li><li>Unlocked Service & Sales Pricing Programs</li><li>Unlocked Job Cost Calculators</li><li>Unlocked Annual budget tools</li><li>Unlocked Departmental forecasting tools</li><li>Unlocked Breakeven Tracker</li><li>Incoming & outgoing call scripts</li><li>Sales & Service Training playbooks</li><li>Sales & installation Sop's</li><li>Warehouse & warranty playbooks</li><li>Inventory lists</li><li>HR-Employment adds, interviewing sop's, Job Descriptions</li><li>HVAC Forms & Templates</li><li>and more...</li></ul>",
    support: true,
    emphasize: true,
  },
  [ConciseFeature.EnterpriseResource]: {
    text: 'Enterprise Resource Center Access',
    helper:
      "Enterprise Resource center access<br /><ul><li>Access to 3C Academy</li><li>Monthly manager coaching sessions</li><li>Unlocked Service & Sales Pricing Programs</li><li>Unlocked Job Cost Calculators</li><li>Unlocked Annual budget tools</li><li>Unlocked Departmental forecasting tools</li><li>Unlocked Breakeven Tracker</li><li>Incoming & outgoing call scripts</li><li>Sales & Service Training playbooks</li><li>Sales & installation Sop's</li><li>Warehouse & warranty playbooks</li><li>Inventory lists</li><li>HR-Employment adds, interviewing sop's, Job Descriptions</li><li>HVAC Forms & Templates</li><li>and more...</li></ul>",
    support: true,
    emphasize: true,
  },
  [ConciseFeature.UserAddition]: {
    text: 'Per User Addition',
    helper: 'Per additional user',
  },
};
