import { type Address } from './address';
import { type ExtendedAgreementInformation } from './agreement';
import { type CallType } from './call-type';
import { type CallInformation } from './cards/call-information';
import { type HappyCall } from './cards/work-order-information';
import { type QuickBooksSynchronizationStatus } from './quick-books';
import { type RequiredTextResource, type CustomData, type OptionalTextStateResource } from './resource';

export interface DashboardViewData {
  call?: CallInformation;
}

export enum DateAnchor {
  start,
  end,
}

export interface NewEventInformation {
  start: Date;
  end: Date;
  technicianId: Id;
  dateAnchor: DateAnchor;
}

export enum ScheduleEventConfirmationStatus {
  NotSent = 0,
  Sent = 1,
  Delivered = 2,
  Confirmed = 3,
  NeedsAttention = 4,
  Resolved = 5,
  Errored = 6,
}

export enum BeaconStatus {
  NotSet = 0,
  Ignore = 1,
  LateArrival = 2,
  LateDeparture = 3,
  InChargeLateArrival = 4,
  InChargeLateDeparture = 5,
}

interface ScheduleEventInformationBase extends OptionalTextStateResource {
  start: Date;
  end: Date;
  withTime: boolean;
  technicianId: Id;
  notes: string | null;
  additionalTechnicianIds: Id[];

  repeatWeekDayFlag: Days | null;
  repeatOccurrences: number | null;
  repeatEnd: Date | null;

  // Not from the server
  highlight?: boolean;
  linkedTo?: Id;

  confirmationStatus: ScheduleEventConfirmationStatus;
}

export type ScheduleEventCallInformation = ScheduleEventInformationBase & { callDetails: CallDetails };
export type ScheduleEventBlockedInformation = ScheduleEventInformationBase & { callDetails: null };

export type ScheduleEventInformation = ScheduleEventCallInformation | ScheduleEventBlockedInformation;

export interface DashboardEvents {
  splitEvent: ScheduleEventInformation;
  mainEvent: ScheduleEventInformation;
}

export enum PhoneType {
  SiteHome = 0,
  SiteMobile,
  SiteWork,
  CustomerHome,
  CustomerMobile,
  CustomerWork,
}

export interface PhoneNumber {
  text: string;
  phone: string;
  type: PhoneType;
  allowTexting?: boolean;
}

export interface CallDetails extends RequiredTextResource {
  callType: CallType;

  callWorkOrderTypeId: Id | null;
  zone?: RequiredTextResource | null;
  agreements: ExtendedAgreementInformation[];

  workOrderId: Id | null;
  customerId: Id;
  siteId: Id;
  siteAddress: Address;

  partsToPickUp: boolean;

  /**
   * If true, the call has been released on the iPad.
   */
  sendToIPad: boolean;
  newCustomer: boolean;

  quickBooksStatus: QuickBooksSynchronizationStatus;

  callIncomplete: boolean;

  canSendToIPad: boolean;

  invoiceSent: boolean;
  debriefed: boolean;
  phoneNumbers: PhoneNumber[];
  arrivedDatetime: Date | null;
  happyCall: HappyCall;
  beaconStatus: BeaconStatus;
  inChargeTechnicianId: Id | null;
  inChargeDate: Date | null;

  customData?: CustomData;
}

export enum Days {
  Sunday = 1,
  Monday = 2,
  Tuesday = 4,
  Wednesday = 8,
  Thursday = 16,
  Friday = 32,
  Saturday = 64,
}

export function getRepeatDayNames(days: Days | null, pluralize: boolean): string[] {
  const list = [];

  if (days) {
    if (days & Days.Sunday) {
      list.push('Sunday');
    }

    if (days & Days.Monday) {
      list.push('Monday');
    }

    if (days & Days.Tuesday) {
      list.push('Tuesday');
    }

    if (days & Days.Wednesday) {
      list.push('Wednesday');
    }

    if (days & Days.Thursday) {
      list.push('Thursday');
    }

    if (days & Days.Friday) {
      list.push('Friday');
    }

    if (days & Days.Saturday) {
      list.push('Saturday');
    }
  }

  return list.map((m: string) => {
    if (pluralize) {
      return `${m}s`;
    }

    return m;
  });
}

export interface PopoverEventData {
  dashboardEvent: ScheduleEventInformation;
  button: HTMLElement;
}

export function isDashboardCallEvent(dashboardEvent: ScheduleEventInformation): boolean {
  return !!dashboardEvent.callDetails;
}

export function isScheduleEventCallInformation(dashboardEvent: ScheduleEventInformation): dashboardEvent is ScheduleEventCallInformation {
  return isDashboardCallEvent(dashboardEvent);
}

export function isDashboardBlockedEvent(dashboardEvent: ScheduleEventInformation): boolean {
  return !dashboardEvent.callDetails;
}

export function isScheduleEventBlockedInformation(
  dashboardEvent: ScheduleEventInformation
): dashboardEvent is ScheduleEventBlockedInformation {
  return isDashboardBlockedEvent(dashboardEvent);
}

export function isDashboardLinkedEvent(dashboardEvent: ScheduleEventInformation): boolean {
  return !!dashboardEvent.linkedTo;
}

const confirmedStatus = { url: '/assets/images/sms-confirmed.svg', text: 'Appointment Confirmed' };

export function getSmsIcon(
  phoneNumbers: PhoneNumber[],
  phoneType: PhoneType,
  status: ScheduleEventConfirmationStatus
): { url: string; text: string; special?: number } | null {
  // Confirm status takes precedence over anything
  if (status === ScheduleEventConfirmationStatus.Confirmed) {
    return confirmedStatus;
  }

  const mobilePhoneType = phoneNumbers.find(m => m.type === phoneType);

  if (mobilePhoneType) {
    if (!mobilePhoneType.allowTexting) {
      return { url: '/assets/images/sms-opted-out.svg', text: 'Mobile Opt Out' };
    }

    return getSmsConfirmation(status);
  }

  return { url: '/assets/images/sms-no-number.svg', text: 'No Mobile Number' };
}

export function getSmsConfirmation(status: ScheduleEventConfirmationStatus): { url: string; text: string; special?: number } | null {
  switch (status) {
    case ScheduleEventConfirmationStatus.Resolved:
    case ScheduleEventConfirmationStatus.Confirmed:
      return confirmedStatus;
    case ScheduleEventConfirmationStatus.Delivered:
      return { url: '/assets/images/sms-received.svg', text: 'SMS Received' };
    case ScheduleEventConfirmationStatus.NeedsAttention:
      return {
        url: '/assets/images/sms-needs-attention.svg',
        text: 'Needs Attention',
        special: 1,
      };
    case ScheduleEventConfirmationStatus.Sent:
      return { url: '/assets/images/sms-sent.svg', text: 'SMS Sent' };
    case ScheduleEventConfirmationStatus.Errored:
      return { url: '/assets/images/sms-needs-attention.svg', text: 'SMS Error', special: 2 }; // ?
  }

  return null;
}

export function getScheduleEventConfirmationStatusText(status: ScheduleEventConfirmationStatus): string {
  switch (status) {
    case ScheduleEventConfirmationStatus.Confirmed:
      return 'Confirmed';
    case ScheduleEventConfirmationStatus.Delivered:
      return 'Delivered';
    case ScheduleEventConfirmationStatus.Errored:
      return 'Error';
    case ScheduleEventConfirmationStatus.NeedsAttention:
      return 'Needs Attention';
    case ScheduleEventConfirmationStatus.NotSent:
      return 'Not Sent';
    case ScheduleEventConfirmationStatus.Resolved:
      return 'Resolved';
    case ScheduleEventConfirmationStatus.Sent:
      return 'Sent';
  }

  return '';
}

export function createEmpty(): ScheduleEventInformation {
  return {
    id: 0,
    text: '',
    start: new Date(),
    end: new Date(),
    withTime: true,
    technicianId: 0,
    repeatEnd: null,
    repeatOccurrences: null,
    repeatWeekDayFlag: null,
    notes: null,
    additionalTechnicianIds: [],
    callDetails: null,
    confirmationStatus: ScheduleEventConfirmationStatus.NotSent,
  };
}
