import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  type AfterViewInit,
  type ElementRef,
  type OnDestroy,
} from '@angular/core';
import {
  getAgreementBadgeTitle,
  getAgreementBadgeUrl,
  getAgreementLinkName,
  getAgreementNameFromAgreements,
  getDutyCompletionText,
  getEndDate,
  getVisitLeft,
  isADate,
  isNavigatable,
} from '@cards/agreements/agreement-site-system-list.component';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { RwTechnician2Component } from '@controls/rw-technician/rw-technician2.component';
import { type Address } from '@models/address';
import { CallType, getCallTypeText } from '@models/call-type';
import { type AgreementInformation } from '@models/cards/agreement-information';
import { type AgreementSiteSystemInformationForList, type AgreementVisit } from '@models/cards/agreement-site-system-information';
import { type TakeChargeModel } from '@models/cards/call-information';
import { type CustomerInformation } from '@models/cards/customer-information';
import { type LastVisitNoteInformation, type WorkPerformedInformation } from '@models/cards/last-visit-information';
import { type SiteInformation } from '@models/cards/site-information';
import {
  BeaconStatus,
  PhoneType,
  ScheduleEventConfirmationStatus,
  getRepeatDayNames,
  getScheduleEventConfirmationStatusText,
  getSmsIcon,
  isDashboardBlockedEvent,
  isDashboardCallEvent,
  type ScheduleEventInformation,
} from '@models/dashboard-event';
import { DIALOG_SERVICE_IMPL, POPOVER_DATA_TYPED, type Dialog2ServiceImpl, type PopoverButton, type PopoverRef } from '@models/dialog';
import { Mode } from '@models/form';
import { type RequiredTextResource } from '@models/resource';
import { SharedModule } from '@modules/shared.module';
import { AddressPipe } from '@pipes/address.pipe';
import { EmailPipe } from '@pipes/email.pipe';
import { EntityPipe } from '@pipes/entity.pipe';
import { PhonePipe } from '@pipes/phone.pipe';
import { RefPipe } from '@pipes/ref.pipe';
import { SiteSystemWordingPipe } from '@pipes/site-system-wording.pipe';
import { AppInsightsService } from '@services/app-insights.service';
import { AgreementsService } from '@services/live/agreements.service';
import { SiteSystemBehaviorService } from '@services/live/site-system-behavior.service';
import { unique } from '@utility/array';
import { getObjectKeys } from '@utility/object';
import { differenceInMilliseconds, format, formatDistanceStrict, formatDuration, intervalToDuration, isSameDay } from 'date-fns';
import { BehaviorSubject, type Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { CustomersService } from '../services/live/customers.service';
import { SitesService } from '../services/live/sites.service';
import { EventNoteMetadataComponent } from './event-note-metadata.component';
import { PopoverHelpComponent } from './popover-help.component';

export interface ExtraIcons {
  callType: CallType;
  statusText: string;
  sentToIPad?: boolean;
}

export interface PopoverData {
  title?: string;
  extraIcons?: ExtraIcons;

  defaultTab?: Tab;

  event?: {
    id: Id;
    dashboardEvent: ScheduleEventInformation;
    saveNote: (note: string) => Observable<void>;
  };

  call?: {
    id: Id;
    click: () => void;
    lateBeaconActive: boolean;
    dashboardEvent: ScheduleEventInformation;
    saveNote: (note: string) => Observable<void>;
    sendConfirmationAllowed: boolean;
    saveSmsConfirmationStatus: (status: ScheduleEventConfirmationStatus) => Observable<void>;
    takeCharge: () => Observable<TakeChargeModel | null>;
  };

  site?: {
    id: Id;
    click: () => void;
    saveNote: (note: string) => Observable<void>;
  };

  customer?: {
    id: Id;
    click: () => void;
    saveNote: (note: string) => Observable<void>;
  };

  siteHistory?: {
    id: Id;
    click: (workOrderId: Id) => void;
  };

  agreement?: {
    siteId: Id;
    click: (agreementSiteSystemId: Id) => void;
    visitClick: (agreementVisit: AgreementVisit) => void;
  };

  buttons: PopoverButton[];

  showActionButtons: boolean;
  allowNoteEditing: boolean;
  useSiteMobilePhone: boolean;

  close?: () => void;
}

export interface TabDataWithNotes {
  saved: boolean;
  editing: boolean;
  notes: string | null;
  saveNote: (note: string) => Observable<void>;
  notesStream: BehaviorSubject<string>;
}

interface TabDataBlocked {
  reason: string;

  repeatDays: string | null;
  repeatEndOccurrence: number | null;
  repeatEndDate: Date | null;

  noteMetadata: TabDataWithNotes;
}

interface TabDataCall {
  clientName?: string;
  siteAddress?: Address;
  humanizedDiff?: string;

  noteMetadata: TabDataWithNotes;
}

interface TabDataSite {
  siteInformation: SiteInformation;

  noteMetadata: TabDataWithNotes;
}

interface TabDataCustomer {
  customerInformation: CustomerInformation;

  noteMetadata: TabDataWithNotes;
}

interface TabData {
  blocked?: TabDataBlocked;

  call?: TabDataCall;

  site?: TabDataSite;

  customer?: TabDataCustomer;

  siteHistory?: {
    lastVisitNoteInformations: LastVisitNoteInformation[];
  };

  agreement?: {
    data: AgreementSiteSystemInformationForList[];
    agreements: AgreementInformation[];
  };
}

export enum Tab {
  Call = 1,
  Site,
  Customer,
  SiteHistory,
  Agreement,
  Blocked,
}

const injectionToken = POPOVER_DATA_TYPED<PopoverData>();

@Component({
  selector: 'wm-popover-event-info',
  templateUrl: 'popover-event-info.component.html',
  styleUrls: ['popover-event-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    RefPipe,
    EventNoteMetadataComponent,
    RwTechnician2Component,
    InputDropdownComponent,
    SiteSystemWordingPipe,
    PhonePipe,
    AddressPipe,
    EntityPipe,
    EmailPipe,
  ],
})
export class PopoverEventInfoComponent implements OnDestroy, AfterViewInit {
  readonly BeaconStatus = BeaconStatus;
  readonly Mode = Mode;
  readonly CallType = CallType;
  readonly Tab = Tab;
  readonly ah = {
    isNavigatable,
    isADate,
    getAgreementLinkName,
    getAgreementNameFromAgreements,
    getVisitLeft,
    getEndDate,
    getAgreementBadgeUrl,
    getAgreementBadgeTitle,
    getDutyCompletionText,
  };

  readonly data = inject(injectionToken);

  private readonly customersService = inject(CustomersService);
  private readonly sitesService = inject(SitesService);
  private readonly agreementsService = inject(AgreementsService);
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly appInsightsService = inject(AppInsightsService);
  private readonly siteSystemBehaviorService = inject(SiteSystemBehaviorService);

  tabData: TabData = {};
  private loadingTabData: Record<number, boolean> = {};

  datePipe: DatePipe;
  currentTab?: Tab;

  moreElement?: ElementRef;

  currentPopoverRef: Record<string, PopoverRef<any>> = {};

  readonly smsStatusOptions: RequiredTextResource[] = [
    {
      id: ScheduleEventConfirmationStatus.Confirmed,
      text: getScheduleEventConfirmationStatusText(ScheduleEventConfirmationStatus.Confirmed),
    },
    {
      id: ScheduleEventConfirmationStatus.Delivered,
      text: getScheduleEventConfirmationStatusText(ScheduleEventConfirmationStatus.Delivered),
    },
    {
      id: ScheduleEventConfirmationStatus.Errored,
      text: getScheduleEventConfirmationStatusText(ScheduleEventConfirmationStatus.Errored),
    },
    {
      id: ScheduleEventConfirmationStatus.NeedsAttention,
      text: getScheduleEventConfirmationStatusText(ScheduleEventConfirmationStatus.NeedsAttention),
    },
    {
      id: ScheduleEventConfirmationStatus.NotSent,
      text: getScheduleEventConfirmationStatusText(ScheduleEventConfirmationStatus.NotSent),
    },
    {
      id: ScheduleEventConfirmationStatus.Resolved,
      text: getScheduleEventConfirmationStatusText(ScheduleEventConfirmationStatus.Resolved),
    },
    {
      id: ScheduleEventConfirmationStatus.Sent,
      text: getScheduleEventConfirmationStatusText(ScheduleEventConfirmationStatus.Sent),
    },
  ];

  readonly siteSystemBehavior$ = this.siteSystemBehaviorService.getLocalizedSiteSystemBehavior();

  constructor() {
    this.datePipe = new DatePipe('en-US');
  }

  internalSaveStatus(): void {
    this.data.call?.saveSmsConfirmationStatus(this.data.call.dashboardEvent.confirmationStatus).subscribe();
  }

  internalTakeCharge(): void {
    const callObject = this.data.call;
    if (callObject) {
      this.data.call?.takeCharge().subscribe(takeChargeModel => {
        if (takeChargeModel) {
          if (!takeChargeModel.success) {
            this.appInsightsService.trackEvent('beacon-concurrency');
            alert('Someone else might have taken charge of this call.');
          }

          if (callObject.dashboardEvent.callDetails) {
            callObject.dashboardEvent.callDetails.inChargeTechnicianId = takeChargeModel.technicianId;
            callObject.dashboardEvent.callDetails.inChargeDate = takeChargeModel.date;
            callObject.dashboardEvent.callDetails.beaconStatus = takeChargeModel.beaconStatus;
            this.cd.markForCheck();
          }
        }
      });
    }
  }

  removeAllHelp(): void {
    getObjectKeys(this.currentPopoverRef).forEach(id => {
      this.currentPopoverRef[id]?.close();
    });
    this.currentPopoverRef = {};
  }

  hideHelp(id: string | undefined): void {
    if (id && this.currentPopoverRef[id]) {
      this.currentPopoverRef[id]?.close();
      delete this.currentPopoverRef[id];
    }
  }

  getSmsIcon(): { url: string; text: string; special?: number } | null {
    if (this.data?.call?.sendConfirmationAllowed) {
      const phoneType = this.data.useSiteMobilePhone ? PhoneType.SiteMobile : PhoneType.CustomerMobile;
      return getSmsIcon(
        this.data.call.dashboardEvent.callDetails?.phoneNumbers ?? [],
        phoneType,
        this.data.call.dashboardEvent.confirmationStatus
      );
    }

    return null;
  }

  getBeaconStatus(): BeaconStatus | null {
    if (this.data?.call?.dashboardEvent?.callDetails) {
      return this.data.call.dashboardEvent.callDetails.beaconStatus;
    }

    return null;
  }

  shouldShowBeacon(): boolean {
    if (this.data.call?.lateBeaconActive) {
      if (
        new Set<BeaconStatus | null>([
          BeaconStatus.InChargeLateArrival,
          BeaconStatus.InChargeLateDeparture,
          BeaconStatus.LateArrival,
          BeaconStatus.LateDeparture,
        ]).has(this.getBeaconStatus())
      ) {
        return true;
      }
    }

    return false;
  }

  shouldShowPersonInCharge(): boolean {
    return !!this.data.call?.dashboardEvent.callDetails?.inChargeTechnicianId;
  }

  canTakeCharge(): boolean {
    if (this.data?.call?.dashboardEvent.callDetails) {
      return !this.data.call.dashboardEvent.callDetails.inChargeTechnicianId;
    }

    return false;
  }

  showSmsHelp(smsIcon: { url: string; text: string; special?: number }, parent: HTMLElement): void {
    this.currentPopoverRef[parent.dataset.id ?? '0'] = PopoverHelpComponent.open(this.dialog2, parent, {
      text: smsIcon.text,
    });
  }

  openTab(tab: Tab): void {
    this.currentTab = tab;

    const dashboardEvent: ScheduleEventInformation | undefined = this.data.event
      ? this.data.event.dashboardEvent
      : this.data.call?.dashboardEvent;

    const saveNote = this.data.event ? this.data.event.saveNote : this.data.call?.saveNote;

    if (!dashboardEvent || !saveNote) {
      return;
    }

    let title = this.datePipe.transform(dashboardEvent.start, 'longDate');
    if (dashboardEvent.withTime) {
      title += ` at ${this.datePipe.transform(dashboardEvent.start, 'shortTime')}`;
    }

    if (isSameDay(dashboardEvent.end, dashboardEvent.start)) {
      const duration = intervalToDuration({
        start: dashboardEvent.start,
        end: dashboardEvent.end,
      });

      title += ` for ${formatDuration(duration)}`;
    } else {
      title += ` until ${this.datePipe.transform(dashboardEvent.end, 'longDate')}`;
      if (dashboardEvent.withTime) {
        title += ` at ${this.datePipe.transform(dashboardEvent.end, 'shortTime')}`;
      }
    }

    const noteMetadata: TabDataWithNotes = {
      saved: false,
      editing: false,
      notes: dashboardEvent.notes,
      saveNote,
      notesStream: new BehaviorSubject<string>(dashboardEvent.notes ?? ''),
    };
    this.data.title = title ?? '';

    if (isDashboardBlockedEvent(dashboardEvent)) {
      const repeatDays = getRepeatDayNames(dashboardEvent.repeatWeekDayFlag, false);
      this.data.extraIcons = {
        callType: CallType.Blocked,
        statusText: 'Blocked',
      };
      this.tabData.blocked = {
        reason: dashboardEvent.text ?? '',

        repeatDays: repeatDays.length > 0 ? repeatDays.join(', ') : null,
        repeatEndOccurrence: dashboardEvent.repeatOccurrences,
        repeatEndDate: dashboardEvent.repeatEnd,

        noteMetadata,
      };
      this.cd.markForCheck();
    } else if (isDashboardCallEvent(dashboardEvent) && dashboardEvent.callDetails) {
      this.data.extraIcons = {
        sentToIPad: dashboardEvent.callDetails.sendToIPad,
        callType: dashboardEvent.callDetails.callType,
        statusText: getCallTypeText(dashboardEvent.callDetails.callType),
      };
      switch (this.currentTab) {
        case Tab.Call:
          if (!this.loadingTabData[Tab.Call]) {
            this.loadingTabData[Tab.Call] = true;
            this.tabData.call = {
              siteAddress: dashboardEvent.callDetails.siteAddress,
              clientName: dashboardEvent.callDetails.text,
              humanizedDiff: this.humanizeFromTwoTimes(dashboardEvent.callDetails.arrivedDatetime, dashboardEvent.start) ?? '',
              noteMetadata,
            };

            this.cd.markForCheck();
          }
          break;
        case Tab.Site:
          {
            const site = this.data.site;
            if (!this.loadingTabData[Tab.Site] && site) {
              this.loadingTabData[Tab.Site] = true;
              this.sitesService.get(site.id).subscribe(m => {
                this.tabData.site = {
                  siteInformation: m,
                  noteMetadata: {
                    saved: false,
                    editing: false,
                    notes: m.notes,
                    saveNote: site.saveNote,
                    notesStream: new BehaviorSubject<string>(m.notes ?? ''),
                  },
                };

                this.cd.markForCheck();
              });
            }
          }
          break;
        case Tab.Customer:
          {
            const customer = this.data.customer;
            if (!this.loadingTabData[Tab.Customer] && customer) {
              this.loadingTabData[Tab.Customer] = true;
              this.customersService.get(customer.id).subscribe(m => {
                this.tabData.customer = {
                  customerInformation: m,
                  noteMetadata: {
                    saved: false,
                    editing: false,
                    notes: m.notes,
                    saveNote: customer.saveNote,
                    notesStream: new BehaviorSubject<string>(m.notes ?? ''),
                  },
                };

                this.cd.markForCheck();
              });
            }
          }
          break;
        case Tab.SiteHistory:
          {
            const siteHistory = this.data.siteHistory;
            if (!this.loadingTabData[Tab.SiteHistory] && siteHistory) {
              this.loadingTabData[Tab.SiteHistory] = true;
              this.sitesService.getLastVisits(siteHistory.id, 0).subscribe(m => {
                this.tabData.siteHistory = {
                  lastVisitNoteInformations: m,
                };
                this.cd.markForCheck();
              });
            }
          }
          break;
        case Tab.Agreement:
          {
            const agreement = this.data.agreement;
            if (!this.loadingTabData[Tab.Agreement] && agreement) {
              this.loadingTabData[Tab.Agreement] = true;
              this.sitesService
                .getAgreements(agreement.siteId)
                .pipe(
                  concatMap(agreementSiteSystemInformationForList => {
                    const requestedAgreementIds = unique(agreementSiteSystemInformationForList.map(m => m.data.agreementId));
                    return this.agreementsService.buildAgreementList$(requestedAgreementIds).pipe(
                      map(agreements => {
                        return {
                          agreementSiteSystemInformationForList,
                          agreements,
                        };
                      })
                    );
                  })
                )
                .subscribe(({ agreementSiteSystemInformationForList, agreements }) => {
                  this.tabData.agreement = {
                    data: agreementSiteSystemInformationForList,
                    agreements,
                  };
                  this.cd.markForCheck();
                });
            }
          }
          break;
      }
    }
  }

  // Similar as work order
  private humanizeFromTwoTimes(endTime: Date | null, startTime: Date | null): string | null {
    if (endTime) {
      const arrived = endTime;
      let text = format(arrived, 'P p');
      if (startTime) {
        const ms = differenceInMilliseconds(arrived, startTime);
        text += ' (';
        text += ms > 0 ? '+' : '-';
        text += formatDistanceStrict(startTime, arrived);
        text += ')';
      }

      return text;
    }

    return null;
  }

  ngOnDestroy(): void {
    if (this.data.close) {
      this.data.close();
    }
    this.removeAllHelp();
  }

  ngAfterViewInit(): void {
    if (this.data?.defaultTab) {
      this.openTab(this.data.defaultTab);
      this.cd.detectChanges();
    }
  }

  _buttonClick(button: PopoverButton): void {
    button.click();
  }

  getExtraButtons(): PopoverButton[] {
    if (this.data && this.data.buttons.length > 3) {
      return this.data.buttons.slice(2);
    }

    return [];
  }

  getNormalButtons(): PopoverButton[] {
    if (this.data) {
      if (this.data.buttons.length > 3) {
        return this.data.buttons.slice(0, 2).reverse();
      } else {
        return this.data.buttons.slice().reverse();
      }
    }

    return [];
  }

  moreActionClick(): void {
    if (this.moreElement) {
      this.dialog2.menu(this.getExtraButtons(), this.moreElement.nativeElement, 'below');
    }
  }

  getAcceptedWork(workPerformed: WorkPerformedInformation[]): WorkPerformedInformation[] {
    return workPerformed.filter(m => m.accepted);
  }

  getUnacceptedWork(workPerformed: WorkPerformedInformation[]): WorkPerformedInformation[] {
    if (workPerformed) {
      return workPerformed.filter(m => !m.accepted);
    }

    return [];
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  debounceClick(fnc: Function, ...args: any[]): void {
    if (this.data.showActionButtons) {
      fnc.apply(fnc, args);
    }
  }

  hasAnyTabs(popoverData: PopoverData): boolean {
    return !!popoverData?.call || !!popoverData?.site || !!popoverData?.customer || !!popoverData?.siteHistory || !!popoverData?.agreement;
  }

  static open(dialog: Dialog2ServiceImpl, element: HTMLElement, data: PopoverData) {
    return dialog.popover(PopoverEventInfoComponent, element, {
      popoverData: {
        injectionToken,
        data,
      },
    });
  }
}
