import { Injectable, inject } from '@angular/core';
import { type Mutable } from '@models/mutable';
import { BookType, type PriceBookCategoryInformation } from '@models/price-book-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select, type Action, type ActionCreator, type Creator } from '@ngrx/store';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { PartItemsService } from '@services/live/part-items.service';
import { PriceBooksService } from '@services/live/price-books.service';
import { indexBy } from '@utility/array';
import { clone } from '@utility/object';
import { from, of, type Observable } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as PriceBookDetailActions from './price-book-detail.actions';
import { type PriceBookDetailState } from './price-book-detail.reducer';
import * as PriceBookDetailSelectors from './price-book-detail.selectors';

@Injectable()
export class PriceBookDetailEffects {
  private readonly actions$ = inject(Actions<PriceBookDetailActions.Actions>);
  private readonly priceBooksService = inject(PriceBooksService);
  private readonly partItemsService = inject(PartItemsService);
  private readonly store = inject(Store<PriceBookDetailState>);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  loadPriceBookData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceBookDetailActions.loadPriceBookData),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(PriceBookDetailSelectors.priceBookId$, { group: action.group }))))
      ),
      map(([action, priceBookId]) => {
        if (!priceBookId && action.group) {
          // We select the first book.
          const firstBook = action.priceBooks.find(m => m.type === (action.bookType ?? BookType.Service));
          if (firstBook) {
            return PriceBookDetailActions.selectPriceBook({ id: firstBook.id, group: action.group });
          }
        }

        return PriceBookDetailActions.noop();
      })
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceBookDetailActions.save),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PriceBookDetailSelectors.priceBookId$(PriceBookDetailSelectors.GROUP_PAGE))),
            this.store.pipe(select(PriceBookDetailSelectors.priceBookWithCategories$(PriceBookDetailSelectors.GROUP_PAGE)))
          )
        )
      ),
      mergeMap(([action, priceBookId, { priceBookCategories }]) => {
        const categories = this.denormalizeCategories(priceBookCategories).filter(m => m.priceBookId === priceBookId);
        const x = this.priceBooksService.saveCategories(priceBookId as ANY, categories).pipe(
          map(savedCategories => PriceBookDetailActions.saveSuccess({ data: savedCategories, group: action.group })),
          tap(_ => {
            this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairCategories);
          }),
          catchError(error => of(PriceBookDetailActions.saveFailure({ error })))
        );

        return x;
      })
    )
  );

  saveSuccess1$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      PriceBookDetailActions.saveSuccess,
      action => select(PriceBookDetailSelectors.serviceRepairs$((action as any).group)),
      PriceBookDetailActions.noop2,
      PriceBookDetailActions.saveServiceRepair,
      false
    )
  );

  saveSuccess2$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      PriceBookDetailActions.saveSuccess,
      action => select(PriceBookDetailSelectors.partItems$((action as any).group)),
      PriceBookDetailActions.noop2,
      PriceBookDetailActions.savePartItem,
      false
    )
  );

  saveSuccess3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceBookDetailActions.saveSuccess, PriceBookDetailActions.savePartItemSuccess),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PriceBookDetailSelectors.priceBookCategoryPartItemIds$(action.group))),
            this.store.pipe(select(PriceBookDetailSelectors.priceBookCategories$(action.group)))
          )
        )
      ),
      mergeMap(([action, priceBookCategoryPartItemIds, priceBookCategories]) => {
        const actions: Action[] = [PriceBookDetailActions.noop()];
        for (const priceBookCategoryIdTemp of Object.keys(priceBookCategoryPartItemIds)) {
          const priceBookCategoryId = +priceBookCategoryIdTemp;
          const priceBookCategoryPartItemId = priceBookCategoryPartItemIds[priceBookCategoryId];
          if (priceBookCategoryPartItemId.updated) {
            if (priceBookCategories[priceBookCategoryId].partItemIds.some(n => n.id <= 0)) {
              return from([PriceBookDetailActions.noop()]);
            }

            actions.push(PriceBookDetailActions.savePriceBookCategoryPartItem({ id: priceBookCategoryId, group: action.group }));
          }
        }

        return from(actions);
      })
    )
  );

  saveSuccess4$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceBookDetailActions.saveSuccess, PriceBookDetailActions.saveServiceRepairSuccess),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PriceBookDetailSelectors.priceBookCategoryServiceRepairIds$(action.group))),
            this.store.pipe(select(PriceBookDetailSelectors.priceBookCategories$(action.group)))
          )
        )
      ),
      mergeMap(([action, priceBookCategoryServiceRepairIds, priceBookCategories]) => {
        const actions: Action[] = [PriceBookDetailActions.noop()];
        for (const priceBookCategoryIdTemp of Object.keys(priceBookCategoryServiceRepairIds)) {
          const priceBookCategoryId = +priceBookCategoryIdTemp;
          const priceBookCategoryServiceRepairId = priceBookCategoryServiceRepairIds[priceBookCategoryId];
          if (priceBookCategoryServiceRepairId.updated) {
            if (priceBookCategories[priceBookCategoryId].serviceRepairIds.some(n => n.id <= 0)) {
              return from([PriceBookDetailActions.noop()]);
            }

            actions.push(PriceBookDetailActions.savePriceBookCategoryServiceRepair({ id: priceBookCategoryId, group: action.group }));
          }
        }

        return from(actions);
      })
    )
  );

  savePartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceBookDetailActions.savePartItem),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PriceBookDetailSelectors.partItems$(action.group))).pipe(map(m => m[action.id])))
        )
      ),
      mergeMap(([action, resource]) => {
        if (resource.text) {
          return this.partItemsService.save(resource).pipe(
            map(newResource =>
              PriceBookDetailActions.savePartItemSuccess({ oldId: resource.id, newId: newResource.id, group: action.group })
            ),
            tap(_ => {
              this.applicationCacheService.clearCategory(UpdateCategory.PartItems);
            }),
            catchError(error => of(PriceBookDetailActions.savePartItemFailure({ error })))
          );
        }

        // We ignore this part item, we remove it from the list.
        return of(PriceBookDetailActions.savePartItemEmptySuccess({ id: resource.id, group: action.group }));
      })
    )
  );

  saveServiceRepair$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceBookDetailActions.saveServiceRepair),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PriceBookDetailSelectors.serviceRepairs$(action.group))).pipe(map(m => m[action.id])))
        )
      ),
      mergeMap(([action, resource]) =>
        this.priceBooksService.saveServiceRepair(resource.priceBookId, resource).pipe(
          map(newResource =>
            PriceBookDetailActions.saveServiceRepairSuccess({ oldId: resource.id, newId: newResource.id, group: action.group })
          ),
          tap(_ => {
            this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairs);
          }),
          catchError(error => of(PriceBookDetailActions.saveServiceRepairFailure({ error })))
        )
      )
    )
  );

  savePriceBookCategoryPartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceBookDetailActions.savePriceBookCategoryPartItem),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(PriceBookDetailSelectors.priceBookCategories$(action.group)))))
      ),
      mergeMap(([action, priceBookCategories]) => {
        return this.priceBooksService.setCategoriesPartItems(action.id, priceBookCategories[action.id].partItemIds as ANY).pipe(
          map(() => PriceBookDetailActions.savePriceBookCategoryPartItemSuccess({ id: action.id, group: action.group })),
          tap(_ => {
            this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairCategories);
          }),
          catchError(error => of(PriceBookDetailActions.savePriceBookCategoryPartItemFailure({ error })))
        );
      })
    )
  );

  savePriceBookCategoryServiceRepair$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceBookDetailActions.savePriceBookCategoryServiceRepair),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(PriceBookDetailSelectors.priceBookCategories$(action.group)))))
      ),
      mergeMap(([action, priceBookCategories]) => {
        return this.priceBooksService.setCategoriesServiceRepairs(action.id, priceBookCategories[action.id].serviceRepairIds as ANY).pipe(
          map(() => PriceBookDetailActions.savePriceBookCategoryServiceRepairSuccess({ id: action.id, group: action.group })),
          tap(_ => {
            this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairCategories);
          }),
          catchError(error => of(PriceBookDetailActions.savePriceBookCategoryServiceRepairFailure({ error })))
        );
      })
    )
  );

  private denormalizeCategories(categories: PriceBookCategoryInformation[]): PriceBookCategoryInformation[] {
    const clonedCategories = clone(categories);
    const categoriesById = indexBy(clonedCategories, 'id');
    const categoriesToRemove: Id[] = [];
    clonedCategories.forEach(category => (category.children = []));

    clonedCategories.forEach(category => {
      if (category.parentPriceBookCategoryId) {
        const parent = categoriesById[category.parentPriceBookCategoryId];

        if (parent) {
          (parent.children as ANY).push(category);
        }

        delete (category as ANY).parentPriceBookCategoryId;
        categoriesToRemove.push(category.id);
      }
    });

    return clonedCategories.filter(category => !categoriesToRemove.includes(category.id));
  }

  private triggerDeleteAndSaveActions(
    action: ActionCreator<string, Creator>,
    selector: (action: Action) => (source$: Observable<any>) => Observable<Record<number, { id: Id } & Mutable>>,
    deleteAction: ActionCreator<string, (props: { id: Id; group: string }) => { id: Id; group: string } & Action>,
    createOrUpdateAction: ActionCreator<string, (props: { id: Id; group: string }) => { id: Id; group: string } & Action>,
    triggerAnyway: boolean
  ) {
    return this.actions$.pipe(
      ofType(action) as ANY,
      concatMap((act: Action) => of(act).pipe(withLatestFrom(this.store.pipe(selector(act))))),
      mergeMap(([act, resourcesByIds]) => {
        const newActions: Action[] = [];
        const values = Object.values(resourcesByIds);
        newActions.push(...values.filter(m => m.deleted && m.id > 0).map(m => deleteAction({ id: m.id, group: (act as any).group })));
        newActions.push(
          ...values
            .filter(m => !m.deleted && (m.created || m.updated || triggerAnyway))
            .map(m => createOrUpdateAction({ id: m.id, group: (act as any).group }))
        );

        return from(newActions);
      })
    );
  }
}
