import { DatePipe } from '@angular/common';
import { Component, computed, effect, forwardRef, inject, input, signal, type Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { InputMomentPickerComponent } from '@controls/input-moment-picker/input-moment-picker.component';
import { InputTextComponent } from '@controls/input-text/input-text.component';
import { RadioButtonComponent, RadioGroupDirective } from '@controls/radio/radio.component';
import { isAbsolute, isRelative, type AbsoluteValue, type FancyFilterMagicDateRange, type RelativeValue } from '@models/filter-models';
import { Mode } from '@models/form';
import { type RequiredTextResource } from '@models/resource';
import { DynamicFilterService2 } from '@services/dynamic-filter.service';
import { TimeSpan } from '@utility/timespan';
import { addDays, addMonths, addYears, differenceInMilliseconds, startOfDay } from 'date-fns';
import endOfDay from 'date-fns/endOfDay';
import { combineLatest, type Observable } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';

const DAY = 1; // 86,400,000
const MONTH = 30;
const YEAR = 365;

const multiplierOptions: RequiredTextResource[] = [
  {
    id: DAY,
    text: 'days',
  },
  {
    id: MONTH,
    text: 'months',
  },
  {
    id: YEAR,
    text: 'years',
  },
];

const momentOptions: RequiredTextResource[] = [
  {
    id: -1,
    text: 'ago',
  },
  {
    id: 1,
    text: 'ahead',
  },
];

const toRelativeValue = (value: AbsoluteValue | RelativeValue | undefined, now: () => Date): RelativeValue | undefined => {
  if (value === undefined) {
    return value as ANY as undefined;
  }

  if (value instanceof Date) {
    const timeSpan = TimeSpan.fromMilliseconds(differenceInMilliseconds(value, now()));
    return {
      days: timeSpan.totalDays,
      months: null,
      years: null,
    };
  }

  return value;
};

const toAbsoluteValue = (value: AbsoluteValue | RelativeValue | undefined, now: () => Date): AbsoluteValue | undefined => {
  if (value === undefined) {
    return value as ANY as undefined;
  }

  if (value instanceof Date) {
    return value;
  }

  if (value.years) {
    return addYears(now(), value.years);
  } else if (value.months) {
    return addMonths(now(), value.months);
  } else if (value.days) {
    return addDays(now(), value.days);
  }
};

const getRelativeSignal = (
  value$: Observable<FancyFilterMagicDateRange>,
  key: keyof Omit<FancyFilterMagicDateRange, '$type'>,
  now$: Observable<() => Date>,
  yearOnly$: Observable<boolean>
): Signal<{ value: string; multiplier: number; moment: number }> => {
  return toSignal(
    combineLatest([value$, now$, yearOnly$]).pipe(
      map(([value, now, yearOnly]) => {
        let finalValue: number | null = null;
        let multiplier = yearOnly ? YEAR : DAY;
        if (value?.[key]) {
          if (isRelative(value)) {
            const v = value[key] as ANY;
            if (v.years) {
              finalValue = v.years;
              multiplier = YEAR;
            } else if (v.months) {
              finalValue = v.months;
              multiplier = MONTH;
            } else if (v.days) {
              finalValue = v.days;
              multiplier = DAY;
            }
          } else if (isAbsolute(value)) {
            if (yearOnly) {
              finalValue = (value[key] as ANY).getUTCFullYear() - now().getUTCFullYear();
              multiplier = YEAR;
            } else {
              // Finding if we are 1 month or 1 year difference is completed here.
              const { days } = toRelativeValue(value[key], now) as ANY;
              finalValue = days;
              multiplier = DAY;
            }
          }
        }

        return {
          value: finalValue ? Math.abs(finalValue).toString() : '',
          multiplier,
          moment: (finalValue as ANY) < 0 ? -1 : 1,
        };
      })
    )
  ) as ANY;
};

const getAbsoluteSignal = (
  value$: Observable<FancyFilterMagicDateRange>,
  key: keyof Omit<FancyFilterMagicDateRange, '$type'>,
  now$: Observable<() => Date>
): Signal<Date | undefined> => {
  return toSignal(
    combineLatest([value$, now$]).pipe(
      map(([value, now]) => {
        if (value?.[key]) {
          if (isRelative(value)) {
            return toAbsoluteValue(value[key], now);
          } else if (isAbsolute(value)) {
            return value[key];
          }
        }
      })
    )
  );
};

const getYearAbsoluteSignal = (
  value$: Observable<FancyFilterMagicDateRange>,
  key: keyof Omit<FancyFilterMagicDateRange, '$type'>,
  now$: Observable<() => Date>
): Signal<string | undefined> => {
  return toSignal(
    combineLatest([value$, now$]).pipe(
      map(([value, now]) => {
        if (value?.[key]) {
          if (isRelative(value)) {
            return (toAbsoluteValue(value[key], now) as ANY).getUTCFullYear().toString();
          } else if (isAbsolute(value)) {
            return (value[key] as ANY).getUTCFullYear().toString();
          }
        }
      })
    )
  );
};

const startOfYearUTC = (year: number) => new Date(Date.UTC(year));
const endOfYearUTC = (year: number) => new Date(Date.UTC(year, 11, 31, 23, 59, 59, 999));

// Angular 18
@Component({
  template: `<div class="wm-description">
    <div class="baseline" [class.far-apart]="!!label()">
      <label>{{ label() }}</label>
      <button class="as-link" (click)="changeWithValue(!useAbsolute())">{{ buttonText() }}</button>
    </div>
    <div class="read range magic-range">
      <div>
        <label>{{ fromLabel() }}</label>
        <div>
          <div>
            @if (useAbsolute()) {
              @if (yearOnly()) {
                <wm-input-text type="number" [ngModel]="fromYearAbsolute()" (ngModelChange)="setFromYear($event)" />
              } @else {
                <wm-input-moment-picker [showDate]="true" [ngModel]="fromAbsolute()" (ngModelChange)="setFromDate($event)" />
              }
            } @else {
              @if (fromRelative(); as relative) {
                <wm-input-text
                  class="value"
                  type="number"
                  [ngModel]="relative.value"
                  (ngModelChange)="setFromRelative($event, relative.multiplier, relative.moment)"
                />
                <wm-input-dropdown
                  class="multiplier"
                  [options]="multiplierOptions()"
                  [ngModel]="relative.multiplier"
                  (ngModelChange)="setFromRelative(relative.value, $event, relative.moment)"
                />
                <wm-input-dropdown
                  class="moment"
                  [options]="momentOptions"
                  [ngModel]="relative.moment"
                  (ngModelChange)="setFromRelative(relative.value, relative.multiplier, $event)"
                />
              }
            }
          </div>
          <div class="button-container"></div>
        </div>
      </div>
      <div>
        <label>{{ toLabel() }}</label>
        <div>
          <div>
            @if (useAbsolute()) {
              @if (yearOnly()) {
                <wm-input-text type="number" [ngModel]="toYearAbsolute()" (ngModelChange)="setToYear($event)" />
              } @else {
                <wm-input-moment-picker [showDate]="true" [ngModel]="toAbsolute()" (ngModelChange)="setToDate($event)" />
              }
            } @else {
              @if (toRelative(); as relative) {
                <wm-input-text
                  class="value"
                  type="number"
                  [ngModel]="relative.value"
                  (ngModelChange)="setToRelative($event, relative.multiplier, relative.moment)"
                />
                <wm-input-dropdown
                  class="multiplier"
                  [options]="multiplierOptions()"
                  [ngModel]="relative.multiplier"
                  (ngModelChange)="setToRelative(relative.value, $event, relative.moment)"
                />
                <wm-input-dropdown
                  class="moment"
                  [options]="momentOptions"
                  [ngModel]="relative.moment"
                  (ngModelChange)="setToRelative(relative.value, relative.multiplier, $event)"
                />
              }
            }
          </div>
        </div>
      </div>
    </div>
  </div>`,
  styleUrl: 'filter-dynamic-common.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDynamicMagicDateComponent),
      multi: true,
    },
    DatePipe,
  ],
  imports: [InputMomentPickerComponent, FormsModule, RadioButtonComponent, RadioGroupDirective, InputTextComponent, InputDropdownComponent],
  standalone: true,
})
export class FilterDynamicMagicDateComponent extends ElementBaseWithFormControl<FancyFilterMagicDateRange | null> {
  readonly Mode = Mode;
  readonly momentOptions = momentOptions;

  private readonly dynamicFilterService = inject(DynamicFilterService2);

  readonly filterId = input<string>('');
  readonly label = input<string>();
  readonly code = input<string>(''); // required
  readonly name = input<string>(''); // required
  readonly fromLabel = input('From');
  readonly toLabel = input('To');
  readonly yearOnly = input(false);

  readonly useAbsolute = signal(true);
  readonly buttonText = computed(() => (this.useAbsolute() ? 'Use relative date' : 'Use absolute date'));

  readonly startOf = toSignal(
    toObservable(this.yearOnly).pipe(
      map(m => (m ? (d: Date = new Date()) => startOfYearUTC(d.getUTCFullYear()) : (d: Date = new Date()) => startOfDay(d)))
    )
  );

  readonly endOf = toSignal(
    toObservable(this.yearOnly).pipe(
      map(m => (m ? (d: Date = new Date()) => endOfYearUTC(d.getUTCFullYear()) : (d: Date = new Date()) => endOfDay(d)))
    )
  );

  readonly fromAbsolute = getAbsoluteSignal(this.control.valueChanges as ANY, 'from', toObservable(this.startOf) as ANY);
  readonly toAbsolute = getAbsoluteSignal(this.control.valueChanges as ANY, 'to', toObservable(this.endOf) as ANY);
  readonly fromRelative = getRelativeSignal(
    this.control.valueChanges as ANY,
    'from',
    toObservable(this.startOf) as ANY,
    toObservable(this.yearOnly)
  );

  readonly toRelative = getRelativeSignal(
    this.control.valueChanges as ANY,
    'to',
    toObservable(this.endOf) as ANY,
    toObservable(this.yearOnly)
  );

  readonly fromYearAbsolute = getYearAbsoluteSignal(this.control.valueChanges as ANY, 'from', toObservable(this.startOf) as ANY);
  readonly toYearAbsolute = getYearAbsoluteSignal(this.control.valueChanges as ANY, 'to', toObservable(this.endOf) as ANY);

  readonly multiplierOptions = computed(() => {
    return multiplierOptions.filter(m => !this.yearOnly() || m.id === YEAR);
  });

  private readonly valueChanges = toSignal(this.control.valueChanges);
  private readonly serviceChanges = toSignal(
    combineLatest([toObservable(this.code), toObservable(this.name)]).pipe(
      filter(([code, name]) => !!code && !!name),
      concatMap(([code, name]) => this.dynamicFilterService.changes<FancyFilterMagicDateRange | null>(this.filterId(), code, name))
    ),
    { initialValue: null }
  );

  readonly dateControl = new FormControl<number>(0, { nonNullable: true });

  _ = effect(() => {
    this.dynamicFilterService.patch(this.filterId(), this.code(), this.name(), this.valueChanges());
  });

  __ = effect(
    () => {
      this.control.patchValue(this.serviceChanges());
    },
    { allowSignalWrites: true }
  );

  ___ = effect(
    () => {
      const $type = this.control.getRawValue()?.$type;
      if ($type === 'DateRangeFilter.Relative') {
        this.useAbsolute.set(false);
      }
    },
    { allowSignalWrites: true }
  );

  constructor() {
    super(() => null);
  }

  setFromDate(value: Date | null): void {
    const { to } = this.control.getRawValue() ?? {};
    this.control.patchValue({
      $type: 'DateRangeFilter.Absolute',
      from: value ? (this.startOf() as ANY)(value) : undefined,
      to: toAbsoluteValue(to, this.endOf() as ANY),
    });
  }

  setToDate(value: Date | null): void {
    const { from } = this.control.getRawValue() ?? {};
    this.control.patchValue({
      $type: 'DateRangeFilter.Absolute',
      from: toAbsoluteValue(from, this.startOf() as ANY),
      to: value ? (this.endOf() as ANY)(value) : undefined,
    });
  }

  setFromYear(value: string | null): void {
    const { to } = this.control.getRawValue() ?? {};
    const valueInt = parseInt(value as ANY, 10);
    this.control.patchValue({
      $type: 'DateRangeFilter.Absolute',
      from: value?.toString().length === 4 && !isNaN(valueInt) ? startOfYearUTC(valueInt) : undefined,
      to: toAbsoluteValue(to, this.endOf() as ANY),
    });
  }

  setToYear(value: string | null): void {
    const { from } = this.control.getRawValue() ?? {};
    const valueInt = parseInt(value as ANY, 10);
    this.control.patchValue({
      $type: 'DateRangeFilter.Absolute',
      from: toAbsoluteValue(from, this.startOf() as ANY),
      to: value?.toString().length === 4 && !isNaN(valueInt) ? endOfYearUTC(valueInt) : undefined,
    });
  }

  setFromRelative(value: string, multiplier: number, moment: number): void {
    const { to } = this.control.getRawValue() ?? {};
    const valueInt = value != null ? parseInt(value, 10) : NaN;
    const from = {
      days: !isNaN(valueInt) && multiplier === DAY ? valueInt * moment : null,
      months: !isNaN(valueInt) && multiplier === MONTH ? valueInt * moment : null,
      years: !isNaN(valueInt) && multiplier === YEAR ? valueInt * moment : null,
    } as RelativeValue;
    this.control.patchValue({
      $type: 'DateRangeFilter.Relative',
      from: from.days === null && from.months === null && from.years === null ? undefined : from,
      to: toRelativeValue(to, this.endOf() as ANY),
    });
  }

  setToRelative(value: string, multiplier: number, moment: number): void {
    const { from } = this.control.getRawValue() ?? {};
    const valueInt = value != null ? parseInt(value, 10) : NaN;
    const to = {
      days: !isNaN(valueInt) && multiplier === DAY ? valueInt * moment : null,
      months: !isNaN(valueInt) && multiplier === MONTH ? valueInt * moment : null,
      years: !isNaN(valueInt) && multiplier === YEAR ? valueInt * moment : null,
    } as RelativeValue;
    this.control.patchValue({
      $type: 'DateRangeFilter.Relative',
      from: toRelativeValue(from, this.startOf() as ANY),
      to: to.days === null && to.months === null && to.years === null ? undefined : to,
    });
  }

  changeWithValue(useAbsolute: boolean): void {
    this.useAbsolute.set(useAbsolute);
    if (useAbsolute) {
      this.setFromDate(this.fromAbsolute() as ANY);
      this.setToDate(this.toAbsolute() as ANY);
    } else {
      const fromRelative = this.fromRelative();
      const toRelative = this.toRelative();
      this.setFromRelative(fromRelative.value, fromRelative.multiplier, fromRelative.moment);
      this.setToRelative(toRelative.value, toRelative.multiplier, toRelative.moment);
    }
  }
}
