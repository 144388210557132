<div class="flex">
  <div class="wm-card2 section">
    <div class="wm-description contractor">
      <label>Contractor</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="contractorId" [options]="contractors" dropdownParent="body"> </wm-input-dropdown>
      </div>
    </div>
  </div>
</div>
