import { CommonModule } from '@angular/common';
import { Component, forwardRef, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgOptionHighlightModule } from '@directives/ng-option-highlight.module';
import { type OptionalTextExtraResource1, type OptionalTextResource1, type RequiredTextResource } from '@models/resource';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputResourceBase } from '../input-resource/input-resource.component';

// Angular 18
@Component({
  selector: 'wm-input-image',
  templateUrl: 'input-image.component.html',
  styleUrls: ['input-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputImageComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, NgOptionHighlightModule, FormsModule, NgSelectModule],
})
export class InputImageComponent<T> extends InputResourceBase<OptionalTextResource1<T>, T, OptionalTextExtraResource1<T>> {
  readonly showTextInItem = input(false);
  readonly imageCenter = input(false);
  readonly required = input(false);
  readonly clearable = input(false);
  readonly dropdownParent = input<'body' | undefined>(undefined);
  readonly bindValue = input('id');
  readonly bindLabel = input('text');

  trackByFn(item: RequiredTextResource): Id | null {
    return item?.id ?? null;
  }
}
