<div matDialogContent class="all-content">
  @for (section of data.sections; track $index) {
    <section>
      <div class="mat-mdc-dialog-title">
        <div class="title">{{ section.title }}</div>
      </div>
      <div class="mat-mdc-dialog-content">
        <div class="content">
          <div class="wm-card2">
            @if (section.text) {
              <div class="text">{{ section.text }}</div>
            }
            @if (section.links) {
              <ul class="links">
                @for (link of section.links; track $index) {
                  <li>
                    <a href="{{ link.url }}" [attr.target]="getTarget(link)">
                      @if (isExternal(link)) {
                        <img src="/assets/images/popout-window-sm-blue.svg" alt="" />
                      }
                      @if (isStorylane(link)) {
                        <mat-icon [inline]="true" fontSet="material-symbols-rounded">videocam</mat-icon>
                      }
                      <span class="text">{{ link.text }}</span>
                    </a>
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      </div>
    </section>
  }
</div>
<div matDialogActions>
  <div class="buttons">
    <button wm-button type="button" class="secondary" (click)="cancel()">Cancel</button>
  </div>
</div>
