<section class="top">
  <div class="text">
    <div class="title">{{ title }}</div>
  </div>
</section>
<section class="content">
  <wm-moment-picker
    [ngModel]="data.date"
    (ngModelChange)="_internalDateChange($event)"
    [showTime]="data.showTime!"
    [showDate]="data.showDate!"
    [minDate]="data.minDate!"
    [maxDate]="data.maxDate!"
  />
</section>
@if (data.showDate && data.showTime) {
  <section class="actions">
    <button wm-button type="button" (click)="okClick()">OK</button>
  </section>
}
