<button
  wm-button
  type="button"
  class="filter-button"
  (click)="filterClick()"
  [class.secondary]="!hasFilter"
  [class.primary]="hasFilter"
  [imageUrl]="imageUrl"
  [round]="true"
  [imageWidth]="12"
  [imageHeight]="12"
></button>
<span class="text floating">{{ amountString }}</span>
