@if (segments(); as segments) {
  @if (segments.available() !== undefined) {
    <div class="horizontally-spaced">
      <button #changeSegmentButton wm-button type="button" class="outline" (click)="changeSegments()">{{ currentSegmentName() }}</button>
      @if (segments.isDirty()) {
        <button class="as-link" (click)="saveSegment()">Save List</button>
      }
    </div>
  }
}
