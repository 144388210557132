import { Injectable, inject } from '@angular/core';
import { type GpsLocationInformation } from '@models/cards/gps-location-information';
import { type Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { type TechnicianGpsPosition } from '../../models/cards/location-information';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import { allowRetries } from '@utility/angular';

@Injectable()
export class LocationsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  list(): Observable<TechnicianGpsPosition[]> {
    return this.http.get<TechnicianGpsPosition[]>(this.url.locationTechnicianPositions).pipe(shareReplay(1));
  }

  getSiteLocations(siteIds: number[]): Observable<GpsLocationInformation[]> {
    return this.http.post<GpsLocationInformation[]>(this.url.locationSiteLocations, siteIds, allowRetries());
  }
}
