import { Injectable, inject } from '@angular/core';
import {
  type Leaderboard,
  type ReportIncentive,
  type ReportOverview,
  type ReportPerformance,
  type ReportRange,
} from '@models/report-models';
import { type RequiredTextResource } from '@models/resource';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class ReportsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getOverview(range: ReportRange | null = null): Observable<ReportOverview> {
    const url = this.executeReplace(this.url.reportsOverview, { range });

    return this.http.get<ReportOverview>(url);
  }

  getPerformance(
    technicianId: Id | null = null,
    range: ReportRange | null = null,
    appointmentStatus: number | null = null
  ): Observable<ReportPerformance> {
    const url = this.executeReplace(this.url.reportsPerformance, { technicianId, range, appointmentStatus });

    return this.http.get<ReportPerformance>(url, skipBubbleError());
  }

  getIncentive(technicianId: Id | null = null, range: ReportRange | null = null): Observable<ReportIncentive> {
    const url = this.executeReplace(this.url.reportsIncentive, { technicianId, range });

    return this.http.get<ReportIncentive>(url);
  }

  getLeaderboardAvailable(): Observable<RequiredTextResource[]> {
    return this.http.get<RequiredTextResource[]>(this.url.reportsLeaderboardAvailable);
  }

  getLeaderboardDetails(
    leaderboardCategoryId: Id,
    range1: ReportRange | null = null,
    range2: ReportRange | null = null
  ): Observable<Leaderboard> {
    const url = this.executeReplace(this.url.reportsLeaderboardDetails, { id: leaderboardCategoryId, range: range1, range2 });
    return this.http.get<Leaderboard>(url);
  }

  private executeReplace(
    str: string,
    object: {
      id?: Id | null;
      technicianId?: Id | null;
      range?: ReportRange | null;
      range2?: ReportRange | null;
      appointmentStatus?: number | null;
    }
  ): string {
    return str
      .replace('$id$', (object.id || '').toString())
      .replace('$technicianId$', (object.technicianId || '').toString())
      .replace('$from$', object.range?.from?.toJSON() || '')
      .replace('$to$', object.range?.to?.toJSON() || '')
      .replace('$from2$', object.range2?.from?.toJSON() || '')
      .replace('$to2$', object.range2?.to?.toJSON() || '')
      .replace('$appointmentStatus$', (object.appointmentStatus || '').toString());
  }
}
