import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';

// Angular 18
@Component({
  selector: 'wm-tag',
  templateUrl: 'tag.component.html',
  styleUrls: ['tag.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TagComponent {
  readonly editable = input(false);
  readonly special = input(false);
  readonly backgroundColor = input<'gray' | 'green' | 'editable' | 'living'>('gray');
  readonly deleteClick = output<void>();

  deleteClicked(): void {
    this.deleteClick.emit();
  }
}
