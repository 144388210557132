<div class="flex">
  <div class="section wm-card2">
    <div class="wm-description dates">
      <label>Date Range</label>
      <div class="read range">
        <div>
          <label>From</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateFrom"></wm-input-moment-picker>
        </div>
        <div>
          <label>End</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateEnd"></wm-input-moment-picker>
        </div>
      </div>
    </div>
  </div>
  <div class="section wm-card2">
    <div class="wm-description technician">
      <label>Technician</label>
      <div class="read">
        <wm-input-image [(ngModel)]="technician" [options]="technicians" dropdownParent="body"></wm-input-image>
      </div>
    </div>
    <div class="wm-description appointment-status">
      <label>Appointment Status</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="appointmentStatus" [options]="appointmentStatusOptions" dropdownParent="body"></wm-input-dropdown>
      </div>
    </div>
  </div>
</div>
