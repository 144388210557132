<div
  class="input dropdown"
  [class.invalid]="inputModel.invalid && (!inputModel.pristine || inputModel.touched)"
  [class.datepicker]="showDate()"
  [class.timepicker]="showTime()"
>
  <div class="content">
    <input
      #input
      #inputModel="ngModel"
      [attr.autocomplete]="autoComplete()"
      [attr.autocorrect]="autoCorrect()"
      [attr.autocapitalize]="autoCapitalize()"
      [autofocus]="autoFocus()"
      [disabled]="disabled()"
      [attr.list]="list()"
      [attr.max]="max()"
      [attr.maxlength]="maxLength()"
      [attr.min]="min()"
      [attr.minlength]="minLength()"
      [readonly]="readOnly()"
      [required]="required()"
      [spellcheck]="spellCheck()"
      [attr.step]="step()"
      [attr.tabindex]="tabIndex()"
      [type]="type()"
      [attr.name]="name()"
      (focus)="handleFocus($event)"
      (blur)="handleBlur($event)"
      [ngModel]="formattedValue()"
      (ngModelChange)="formattedValue.set($event)"
      (change)="handleChange($event)"
      [wmMomentDateValidator]="currentFormat()"
    />
    <div class="extra">
      <div class="mark" *ngIf="inputModel.invalid && (!inputModel.pristine || inputModel.touched)">!</div>
      <button type="button" class="picker" #pickerButton (click)="togglePopoverContent()">
        <img src="/assets/images/input-timepicker.svg" title="Time Picker" *ngIf="showTime() && !showDate()" />
        <img src="/assets/images/calendar-date-picker.svg" title="Date Picker" *ngIf="showDate()" />
      </button>
    </div>
  </div>
  @if (helperUI()) {
    <div class="input-helper">{{ helperUI() }}</div>
  }
</div>
