import { type AgreementSiteSystemInformation } from '@models/cards/agreement-site-system-information';
import { type CallInformation } from '@models/cards/call-information';
import { type CustomerInformation } from '@models/cards/customer-information';
import { type InspectionAnswerInformation } from '@models/cards/inspection-answer-information';
import { type NonStockItemInformation } from '@models/cards/non-stock-item-information';
import {
  type PhotoOrTempFile,
  type RecommendationInformation,
  type RecommendationPhotoInformation,
} from '@models/cards/recommendation-information';
import { type SiteInformation } from '@models/cards/site-information';
import { type SiteSystem, type SiteSystemInformation } from '@models/cards/site-system-information';
import { type WorkOrderAgreementInformation } from '@models/cards/work-order-agreement-information';
import { type WorkOrderDetailInformation } from '@models/cards/work-order-detail-information';
import {
  type WorkOrderDiscountInformation,
  type WorkOrderInformation,
  type WorkOrderRebateInformation,
} from '@models/cards/work-order-information';
import { type WorkOrderPaymentInformation } from '@models/cards/work-order-payment-information';
import { type WorkOrderRepairPartInformation } from '@models/cards/work-order-repair-part-information';
import { type WorkOrderSiteSystemInformation } from '@models/cards/work-order-site-system-information';
import { type WorkOrderBaseViewData } from '@models/cards/work-order-view-data';
import { type ScheduleEventInformation } from '@models/dashboard-event';
import { type DebriefAnswerInformation } from '@models/debrief-models';
import { type WorkOrderLoanApplicationInformation } from '@models/loans';
import { type TaxItemEntry } from '@models/pricing-models';
import { type SalesProposalInformation, type SalesProposalPackageInformation } from '@models/sales-proposal-models';
import { type TagEntityInformation } from '@models/tag-models';
import { createAction, props } from '@ngrx/store';
import {
  createSubmitAction,
  createSubmitFailAction,
  createSubmitInitialAction,
  createSubmitSuccessAction,
} from '../save-store/save.actions';
import { WORK_ORDER_STORE_KEY } from './work-order.selectors';

export const forceCreate = createAction('[WorkOrder] forceCreate');
export const loadCustomerInformation = createAction('[WorkOrder] loadCustomerInformation', props<{ data: CustomerInformation }>());
export const loadSiteInformation = createAction('[WorkOrder] loadSiteInformation', props<{ data: SiteInformation | null }>());
export const loadWorkOrderBaseViewData = createAction('[WorkOrder] loadWorkOrderBaseViewData', props<{ data: WorkOrderBaseViewData }>());
export const loadWorkOrder = createAction('[WorkOrder] loadWorkOrder', props<{ data: WorkOrderInformation }>());
export const updateWorkOrder = createAction('[WorkOrder] updateWorkOrder', props<{ patch: Partial<WorkOrderInformation> }>());
export const loadCall = createAction('[WorkOrder] loadCall', props<{ data: CallInformation | null }>());
export const updateCall = createAction('[WorkOrder] updateCall', props<{ patch: Partial<CallInformation> }>());
export const updateEvent = createAction('[WorkOrder] updateEvent', props<{ patch: Partial<ScheduleEventInformation> }>());
export const loadDetails = createAction('[WorkOrder] loadDetails', props<{ data: WorkOrderDetailInformation[] }>());
export const addDetail = createAction('[WorkOrder] addDetail', props<{ data: WorkOrderDetailInformation }>());
export const updateDetail = createAction('[WorkOrder] updateDetail', props<{ id: Id; patch: Partial<WorkOrderDetailInformation> }>());
export const deleteDetail = createAction('[WorkOrder] deleteDetail', props<{ id: Id }>());
export const loadRepairParts = createAction('[WorkOrder] loadRepairParts', props<{ data: WorkOrderRepairPartInformation[] }>());
export const loadNonStockItems = createAction('[WorkOrder] loadNonStockItems', props<{ data: NonStockItemInformation[] }>());
export const addRepairPart = createAction('[WorkOrder] addRepairPart', props<{ data: WorkOrderRepairPartInformation }>());
export const addNonStockItem = createAction('[WorkOrder] addNonStockItem', props<{ data: NonStockItemInformation }>());
export const updateRepairPart = createAction(
  '[WorkOrder] updateRepairPart',
  props<{ id: Id; patch: Partial<WorkOrderRepairPartInformation> }>()
);
export const deleteRepairPart = createAction('[WorkOrder] deleteRepairPart', props<{ id: Id }>());
export const loadDiscounts = createAction('[WorkOrder] loadDiscounts', props<{ data: WorkOrderDiscountInformation[] }>());
export const addDiscount = createAction('[WorkOrder] addDiscount', props<{ data: WorkOrderDiscountInformation }>());
export const updateNonStockItem = createAction(
  '[WorkOrder] updateNonStockItem',
  props<{ id: Id; patch: Partial<NonStockItemInformation> }>()
);
export const updateDiscount = createAction('[WorkOrder] updateDiscount', props<{ id: Id; patch: Partial<WorkOrderDiscountInformation> }>());
export const deleteDiscount = createAction('[WorkOrder] deleteDiscount', props<{ id: Id }>());
export const loadRebates = createAction('[WorkOrder] loadRebates', props<{ data: WorkOrderRebateInformation[] }>());
export const addRebate = createAction('[WorkOrder] addRebate', props<{ data: WorkOrderRebateInformation }>());
export const updateRebate = createAction('[WorkOrder] updateRebate', props<{ id: Id; patch: Partial<WorkOrderRebateInformation> }>());
export const deleteRebate = createAction('[WorkOrder] deleteRebate', props<{ id: Id }>());
export const loadPayments = createAction('[WorkOrder] loadPayments', props<{ data: WorkOrderPaymentInformation[] }>());

export const addPayment = createAction(
  '[WorkOrder] addPayment',
  props<{ data: WorkOrderPaymentInformation; isAlreadySavedOnServer: boolean }>()
);

export const updatePayment = createAction('[WorkOrder] updatePayment', props<{ id: Id; patch: Partial<WorkOrderPaymentInformation> }>());
export const deletePayment = createAction('[WorkOrder] deletePayment', props<{ id: Id }>());
export const loadRecommendations = createAction('[WorkOrder] loadRecommendations', props<{ data: RecommendationInformation[] }>());
export const addRecommendation = createAction('[WorkOrder] addRecommendation', props<{ data: RecommendationInformation }>());
export const updateRecommendation = createAction(
  '[WorkOrder] updateRecommendation',
  props<{ id: Id; patch: Partial<RecommendationInformation> }>()
);
export const deleteRecommendation = createAction('[WorkOrder] deleteRecommendation', props<{ id: Id }>());
export const loadRecommendationPhotos = createAction(
  '[WorkOrder] loadRecommendationPhotos',
  props<{ data: RecommendationPhotoInformation[] }>()
);
export const addRecommendationPhoto = createAction(
  '[WorkOrder] addRecommendationPhoto',
  props<{ data: { id: Id; guid: string; recommendationId: Id } }>()
);
export const updateRecommendationPhoto = createAction(
  '[WorkOrder] updateRecommendationPhoto',
  props<{ id: Id; patch: Partial<PhotoOrTempFile> }>()
);
export const deleteRecommendationPhoto = createAction('[WorkOrder] deleteRecommendationPhoto', props<{ id: Id }>());
export const loadDebriefAnswers = createAction('[WorkOrder] loadDebriefAnswers', props<{ data: DebriefAnswerInformation[] }>());
export const loadTagEntities = createAction('[WorkOrder] loadTagEntities', props<{ data: TagEntityInformation[] }>());
export const loadInspectionAnswers = createAction('[WorkOrder] loadInspectionAnswers', props<{ data: InspectionAnswerInformation[] }>());
export const loadSiteSystemInformations = createAction(
  '[WorkOrder] loadSiteSystemInformations',
  props<{ data: SiteSystemInformation[] }>()
);
export const loadSiteSystems = createAction('[WorkOrder] loadSiteSystems', props<{ data: SiteSystem[] }>());
export const loadWorkOrderSiteSystems = createAction(
  '[WorkOrder] loadWorkOrderSiteSystems',
  props<{ data: WorkOrderSiteSystemInformation[] }>()
);
export const addDebriefAnswer = createAction('[WorkOrder] addDebriefAnswer', props<{ data: DebriefAnswerInformation }>());
export const updateDebriefAnswer = createAction(
  '[WorkOrder] updateDebriefAnswer',
  props<{ id: Id; patch: Partial<DebriefAnswerInformation> }>()
);
export const deleteDebriefAnswer = createAction('[WorkOrder] deleteDebriefAnswer', props<{ id: Id }>());
export const setTags = createAction('[WorkOrder] setTags', props<{ tags: TagEntityInformation[] }>());
export const updateClassMappings = createAction('[WorkOrder] updateClassMappings', props<{ callDepartmentTypeId: Id | null }>());
export const loadSalesProposal = createAction('[WorkOrder] loadSalesProposal', props<{ data: SalesProposalInformation | null }>());
export const updateSalesProposal = createAction('[WorkOrder] updateSalesProposal', props<{ patch: Partial<SalesProposalInformation> }>());
export const loadSalesProposalPackage = createAction(
  '[WorkOrder] loadSalesProposalPackage',
  props<{ data: SalesProposalPackageInformation | null }>()
);
export const updateSalesProposalPackage = createAction(
  '[WorkOrder] updateSalesProposalPackage',
  props<{ patch: Partial<SalesProposalPackageInformation> }>()
);
export const loadLoanApplications = createAction(
  '[WorkOrder] loadLoanApplications',
  props<{ data: WorkOrderLoanApplicationInformation[] }>()
);
export const addLoanApplication = createAction('[WorkOrder] addLoanApplication', props<{ data: WorkOrderLoanApplicationInformation }>());
export const updateLoanApplication = createAction(
  '[WorkOrder] updateLoanApplication',
  props<{ id: Id; patch: Partial<WorkOrderLoanApplicationInformation> }>()
);
export const deleteLoanApplication = createAction('[WorkOrder] deleteLoanApplication', props<{ id: Id }>());
export const loadAgreements = createAction('[WorkOrder] loadAgreements', props<{ data: WorkOrderAgreementInformation[] }>());
export const addAgreement = createAction('[WorkOrder] addAgreement', props<WorkOrderAgreementInformation>());
export const updateAgreement = createAction(
  '[WorkOrder] updateAgreement',
  props<{ id: Id; patch: Partial<WorkOrderAgreementInformation> }>()
);
export const deleteAgreement = createAction('[WorkOrder] deleteAgreement', props<{ id: Id }>());
export const loadAgreementSiteSystems = createAction(
  '[WorkOrder] loadAgreementSiteSystems',
  props<{ data: AgreementSiteSystemInformation[] }>()
);
export const addSiteSystem = createAction('[WorkOrder] addSiteSystem', props<{ data: SiteSystem }>());
export const removeSiteSystem = createAction('[WorkOrder] removeSiteSystem', props<{ id: Id | null }>());
export const updateSiteSystem = createAction('[WorkOrder] updateSiteSystem', props<{ id: Id; patch: SiteSystemInformation }>());
export const updateTax = createAction('[WorkOrder] updateTax', props<{ taxItemEntry: TaxItemEntry }>());

export const noop = createAction('[WorkOrder] noop');

export const editMode = createAction('[WorkOrder] editMode');
export const cancel = createAction('[WorkOrder] cancel');
export const reset = createAction('[WorkOrder] reset');

export const updateWorkOrderProcess = createSubmitInitialAction(WORK_ORDER_STORE_KEY, '[WorkOrder] update process');
export const updateWorkOrderProcessSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] update process',
  props<{ processed: boolean }>()
);
export const updateWorkOrderProcessFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] update process',
  props<{ error: string }>()
);

export const saveWorkOrder = createSubmitInitialAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save] Work Order',
  props<{ customerId: Id; siteId: Id | null }>()
);

export const saveWorkOrderSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Work Order',
  props<{ resource: { id: Id } }>()
);
export const saveWorkOrderFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Work Order',
  props<{ error: string }>()
);

export const saveWorkOrderFinishUpdate = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Work Order Finish Update');
export const saveWorkOrderFinishUpdateSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Work Order Finish Update'
);
export const saveWorkOrderFinishUpdateFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Work Order Finish Update',

  props<{ error: string }>()
);

export const saveCall = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Call');
export const saveCallSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Call',
  props<{ resource: { id: Id } }>()
);
export const saveCallFail = createSubmitFailAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save][Fail] Call', props<{ error: string }>());
export const saveCallNoop = createSubmitSuccessAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save][SuccessNoop] Call');

export const saveSiteSystem = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Site System', props<{ id: Id }>());
export const saveSiteSystemSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Site System',
  props<{ oldId: Id; newId: Id }>()
);
export const saveSiteSystemFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Site System',
  props<{ error: string }>()
);
export const saveAllSiteSystems = createAction('[WorkOrder][Save] Site Systems');
export const saveAllSiteSystemsSuccess = createAction('[WorkOrder][Save][Success] Site Systems');

export const saveSalesProposal = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Sales Proposal');
export const saveSalesProposalSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Sales Proposal',
  props<{ resource: { id: Id } }>()
);
export const saveSalesProposalFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Sales Proposal',
  props<{ error: string }>()
);
export const saveSalesProposalNoop = createSubmitSuccessAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save][SuccessNoop] Sales Proposal');

export const saveSalesProposalPackage = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Sales Proposal Package');
export const saveSalesProposalPackageSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Sales Proposal Package',
  props<{ resource: { id: Id } }>()
);
export const saveSalesProposalPackageFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Sales Proposal Package',
  props<{ error: string }>()
);
export const saveSalesProposalPackageNoop = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][SuccessNoop] Sales Proposal Package'
);

export const saveLoanApplicationSave = createSubmitAction(
  WORK_ORDER_STORE_KEY,
  '[SalesProposal][Save] Loan Application',
  props<{ id: Id }>()
);
export const saveLoanApplicationDelete = createSubmitAction(
  WORK_ORDER_STORE_KEY,
  '[SalesProposal][Delete] Loan Application',
  props<{ id: Id }>()
);
export const saveLoanApplicationSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[SalesProposal][Save][Success] Loan Application',
  props<{ oldId: Id; newId: Id }>()
);
export const saveLoanApplicationDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[SalesProposal][Delete][Success] Loan Application',
  props<{ resource: { id: Id } }>()
);
export const saveLoanApplicationFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[SalesProposal][Save][Fail] Loan Application',
  props<{ error: string }>()
);

export const saveDiscountSave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Discount', props<{ id: Id }>());
export const saveDiscountDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Discount', props<{ id: Id }>());
export const saveDiscountSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Discount',
  props<{ oldId: Id; newId: Id }>()
);
export const saveDiscountDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Discount',
  props<{ resource: { id: Id } }>()
);
export const saveDiscountFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Discount',
  props<{ error: string }>()
);

export const saveRebateSave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Rebate', props<{ id: Id }>());
export const saveRebateDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Rebate', props<{ id: Id }>());
export const saveRebateSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Rebate',
  props<{ oldId: Id; newId: Id }>()
);
export const saveRebateDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Rebate',
  props<{ resource: { id: Id } }>()
);
export const saveRebateFail = createSubmitFailAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save][Fail] Rebate', props<{ error: string }>());

export const savePaymentSave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Payment', props<{ id: Id }>());
export const savePaymentDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Payment', props<{ id: Id }>());
export const savePaymentSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Payment',
  props<{ oldId: Id; newId: Id }>()
);
export const savePaymentDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Payment',
  props<{ resource: { id: Id } }>()
);
export const savePaymentFail = createSubmitFailAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save][Fail] Payment', props<{ error: string }>());

export const saveRecommendationSave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Recommendation', props<{ id: Id }>());
export const saveRecommendationDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Recommendation', props<{ id: Id }>());
export const saveRecommendationSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Recommendation',
  props<{ oldId: Id; newId: Id }>()
);
export const saveRecommendationDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Recommendation',
  props<{ resource: { id: Id } }>()
);
export const saveRecommendationFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Recommendation',
  props<{ error: string }>()
);

export const saveRecommendationPhotoSave = createSubmitAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save] RecommendationPhoto',
  props<{ id: Id }>()
);
export const saveRecommendationPhotoDelete = createSubmitAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete] RecommendationPhoto',
  props<{ id: Id }>()
);
export const saveRecommendationPhotoSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] RecommendationPhoto',
  props<{ oldId: Id; newId: Id }>()
);
export const saveRecommendationPhotoDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] RecommendationPhoto',
  props<{ resource: { id: Id } }>()
);
export const saveRecommendationPhotoFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] RecommendationPhoto',
  props<{ id: Id; error: string }>()
);

export const saveDebriefAnswerSave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Debrief Answer', props<{ id: Id }>());
export const saveDebriefAnswerDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Debrief Answer', props<{ id: Id }>());
export const saveDebriefAnswerSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Debrief Answer',
  props<{ oldId: Id; newId: Id }>()
);
export const saveDebriefAnswerDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Debrief Answer',
  props<{ resource: { id: Id } }>()
);
export const saveDebriefAnswerFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Debrief Answer',
  props<{ error: string }>()
);

export const saveTagEntitySave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Tag Entity', props<{ id: Id }>());
export const saveTagEntityDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Tag Entity', props<{ id: Id }>());
export const saveTagEntitySaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Tag Entity',
  props<{ oldId: Id; newId: Id }>()
);
export const saveTagEntityDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Tag Entity',
  props<{ resource: { id: Id } }>()
);
export const saveTagEntityFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Tag Entity',
  props<{ error: string }>()
);

export const saveDetailSave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Detail', props<{ id: Id }>());
export const saveDetailDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Detail', props<{ id: Id }>());
export const saveDetailSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Detail',
  props<{ oldId: Id; oldNonStockItemId: Id | null; workOrderDetail: WorkOrderDetailInformation }>()
);
export const saveDetailDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Detail',
  props<{ resource: { id: Id } }>()
);
export const saveDetailFail = createSubmitFailAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save][Fail] Detail', props<{ error: string }>());

export const saveRepairPartSave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Repair Part', props<{ id: Id }>());
export const saveRepairPartDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Repair Part', props<{ id: Id }>());
export const saveRepairPartSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Repair Part',
  props<{ oldId: Id; newId: Id }>()
);
export const saveRepairPartDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Repair Part',
  props<{ resource: { id: Id } }>()
);
export const saveRepairPartFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Repair Part',
  props<{ error: string }>()
);
export const saveWorkOrderAgreementSave = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Save] Agreement', props<{ id: Id }>());
export const saveWorkOrderAgreementDelete = createSubmitAction(WORK_ORDER_STORE_KEY, '[WorkOrder][Delete] Agreement', props<{ id: Id }>());
export const saveWorkOrderAgreementSaveSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Success] Agreement',
  props<{ oldId: Id; newId: Id; agreementSiteSystem?: AgreementSiteSystemInformation }>()
);
export const saveWorkOrderAgreementDeleteSuccess = createSubmitSuccessAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Delete][Success] Agreement',
  props<{ resource: { id: Id } }>()
);
export const saveWorkOrderAgreementFail = createSubmitFailAction(
  WORK_ORDER_STORE_KEY,
  '[WorkOrder][Save][Fail] Agreement',
  props<{ error: string }>()
);

export type Actions = ReturnType<
  | typeof forceCreate
  | typeof loadCustomerInformation
  | typeof loadSiteInformation
  | typeof loadWorkOrderBaseViewData
  | typeof loadWorkOrder
  | typeof updateWorkOrder
  | typeof loadCall
  | typeof updateCall
  | typeof updateEvent
  | typeof loadDetails
  | typeof addDetail
  | typeof updateDetail
  | typeof deleteDetail
  | typeof loadRepairParts
  | typeof loadNonStockItems
  | typeof addNonStockItem
  | typeof addRepairPart
  | typeof updateRepairPart
  | typeof addSiteSystem
  | typeof removeSiteSystem
  | typeof updateSiteSystem
  | typeof saveSiteSystem
  | typeof saveSiteSystemSuccess
  | typeof saveSiteSystemFail
  | typeof saveAllSiteSystems
  | typeof saveAllSiteSystemsSuccess
  | typeof deleteRepairPart
  | typeof loadDiscounts
  | typeof addDiscount
  | typeof updateDiscount
  | typeof updateNonStockItem
  | typeof deleteDiscount
  | typeof loadRebates
  | typeof addRebate
  | typeof updateRebate
  | typeof deleteRebate
  | typeof loadPayments
  | typeof addPayment
  | typeof updatePayment
  | typeof deletePayment
  | typeof loadRecommendations
  | typeof addRecommendation
  | typeof updateRecommendation
  | typeof deleteRecommendation
  | typeof loadRecommendationPhotos
  | typeof addRecommendationPhoto
  | typeof updateRecommendationPhoto
  | typeof deleteRecommendationPhoto
  | typeof loadDebriefAnswers
  | typeof addDebriefAnswer
  | typeof updateDebriefAnswer
  | typeof deleteDebriefAnswer
  | typeof loadTagEntities
  | typeof setTags
  | typeof updateClassMappings
  | typeof loadInspectionAnswers
  | typeof loadSiteSystemInformations
  | typeof loadSiteSystems
  | typeof loadWorkOrderSiteSystems
  | typeof loadSalesProposal
  | typeof updateSalesProposal
  | typeof loadSalesProposalPackage
  | typeof updateSalesProposalPackage
  | typeof loadLoanApplications
  | typeof addLoanApplication
  | typeof updateLoanApplication
  | typeof deleteLoanApplication
  | typeof loadAgreements
  | typeof addAgreement
  | typeof updateAgreement
  | typeof deleteAgreement
  | typeof loadAgreementSiteSystems
  | typeof updateTax
  | typeof noop
  | typeof editMode
  | typeof cancel
  | typeof reset
  | typeof updateWorkOrderProcess
  | typeof updateWorkOrderProcessSuccess
  | typeof updateWorkOrderProcessFail
  | typeof saveWorkOrder
  | typeof saveWorkOrderSuccess
  | typeof saveWorkOrderFail
  | typeof saveWorkOrderFinishUpdate
  | typeof saveWorkOrderFinishUpdateSuccess
  | typeof saveWorkOrderFinishUpdateFail
  | typeof saveCall
  | typeof saveCallSuccess
  | typeof saveCallFail
  | typeof saveCallNoop
  | typeof saveSalesProposal
  | typeof saveSalesProposalSuccess
  | typeof saveSalesProposalFail
  | typeof saveSalesProposalNoop
  | typeof saveSalesProposalPackage
  | typeof saveSalesProposalPackageSuccess
  | typeof saveSalesProposalPackageFail
  | typeof saveSalesProposalPackageNoop
  | typeof saveLoanApplicationSave
  | typeof saveLoanApplicationDelete
  | typeof saveLoanApplicationSaveSuccess
  | typeof saveLoanApplicationDeleteSuccess
  | typeof saveLoanApplicationFail
  | typeof saveDiscountSave
  | typeof saveDiscountDelete
  | typeof saveDiscountSaveSuccess
  | typeof saveDiscountDeleteSuccess
  | typeof saveDiscountFail
  | typeof saveRebateSave
  | typeof saveRebateDelete
  | typeof saveRebateSaveSuccess
  | typeof saveRebateDeleteSuccess
  | typeof saveRebateFail
  | typeof savePaymentSave
  | typeof savePaymentDelete
  | typeof savePaymentSaveSuccess
  | typeof savePaymentDeleteSuccess
  | typeof savePaymentFail
  | typeof saveRecommendationSave
  | typeof saveRecommendationDelete
  | typeof saveRecommendationSaveSuccess
  | typeof saveRecommendationDeleteSuccess
  | typeof saveRecommendationFail
  | typeof saveRecommendationPhotoSave
  | typeof saveRecommendationPhotoDelete
  | typeof saveRecommendationPhotoSaveSuccess
  | typeof saveRecommendationPhotoDeleteSuccess
  | typeof saveRecommendationPhotoFail
  | typeof saveDebriefAnswerSave
  | typeof saveDebriefAnswerDelete
  | typeof saveDebriefAnswerSaveSuccess
  | typeof saveDebriefAnswerDeleteSuccess
  | typeof saveDebriefAnswerFail
  | typeof saveTagEntitySave
  | typeof saveTagEntitySaveSuccess
  | typeof saveTagEntityDelete
  | typeof saveTagEntityDeleteSuccess
  | typeof saveTagEntityFail
  | typeof saveDetailSave
  | typeof saveDetailSaveSuccess
  | typeof saveDetailDelete
  | typeof saveDetailDeleteSuccess
  | typeof saveDetailFail
  | typeof saveRepairPartSave
  | typeof saveRepairPartDelete
  | typeof saveRepairPartSaveSuccess
  | typeof saveRepairPartDeleteSuccess
  | typeof saveRepairPartFail
  | typeof saveWorkOrderAgreementSave
  | typeof saveWorkOrderAgreementDelete
  | typeof saveWorkOrderAgreementSaveSuccess
  | typeof saveWorkOrderAgreementDeleteSuccess
  | typeof saveWorkOrderAgreementFail
>;
