import { CommonModule } from '@angular/common';
import { Component, TemplateRef, computed, contentChild, forwardRef, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { InputTagComponent } from '@controls/input-tag/input-tag.component';
import { Rw2Component } from '@controls/rw/rw2.component';
import { TagComponent } from '@controls/tag/tag.component';
import { Mode } from '@models/form';
import { type RequiredTextResource1 } from '@models/resource';
import { notEmpty } from '@utility/array';

// Angular 18
@Component({
  selector: 'wm-rw-tag2',
  templateUrl: 'rw-tag2.component.html',
  styleUrls: ['rw-tag.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RwTag2Component),
      multi: true,
    },
  ],
  exportAs: 'rwTag',
  standalone: true,
  host: {
    '[class.rw]': 'true',
  },
  imports: [CommonModule, ReactiveFormsModule, Rw2Component, InputTagComponent, TagComponent],
})
export class RwTag2Component<T> extends ElementBaseWithFormControl<T[] | null> {
  readonly Mode = Mode;

  readonly name = input<string>();
  readonly mode = input(Mode.Read);
  readonly options = input([], { transform: (v: RequiredTextResource1<T>[] | null) => v ?? [] });
  readonly optgroupField = input<string | undefined>(undefined);
  readonly optgroups = input<{ value: string; label: string }[] | undefined>(undefined);
  readonly closeOnSelection = input(false);

  private readonly signalValue = toSignal(this.innerValue);
  readonly selectedOptions = computed(() => {
    const signalValue = this.signalValue() ?? ([] as T[]);
    const options = this.options();
    return signalValue
      .map(v => {
        return options.find(n => n.id === v);
      })
      .filter(notEmpty);
  });

  readonly writeTemplate2 = contentChild('writeTemplate', { read: TemplateRef });
  readonly readTemplate2 = contentChild('readTemplate', { read: TemplateRef });
  readonly helperTemplate2 = contentChild('helperTemplate', { read: TemplateRef });

  constructor() {
    super(() => null);
  }
}

