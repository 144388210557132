<div class="flex">
  <div class="wm-card2 section">
    <!--
    <div class="wm-description next">
        <label>Next Tune-Up Between</label>
        <div class="read range">
            <div>
                <label>From</label>
                <wm-input-moment-picker [showDate]="true" [(ngModel)]="nextDateFrom"></wm-input-moment-picker>
            </div>
            <div>
                <label>End</label>
                <wm-input-moment-picker [showDate]="true" [(ngModel)]="nextDateEnd"></wm-input-moment-picker>
            </div>
        </div>
    </div>
        -->
    <div class="wm-description created">
      <label>Created Between</label>
      <div class="read range">
        <div>
          <label>From</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="createdDateFrom"></wm-input-moment-picker>
        </div>
        <div>
          <label>End</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="createdDateEnd"></wm-input-moment-picker>
        </div>
      </div>
    </div>
    <div class="wm-description status">
      <label>Status</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="status" [options]="statuses" dropdownParent="body"></wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description inactive-subscription">
      <label>Subscriptions</label>
      <div class="read">
        <wm-checkbox [(ngModel)]="recurring">Recurring Only</wm-checkbox>
      </div>
      <div class="read">
        <wm-checkbox [(ngModel)]="inactiveSubscriptions">Inactive Subscriptions</wm-checkbox>
      </div>
    </div>
  </div>
  <div class="wm-card2 section">
    <div class="wm-description assignee">
      <label>Agreement</label>
      <div class="read">
        <wm-input-image [(ngModel)]="agreementId" [options]="agreements" dropdownParent="body"> </wm-input-image>
      </div>
    </div>
    <div class="wm-description processed">
      <label>Processed</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="processed" [options]="processedOptions" dropdownParent="body"> </wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description expiration">
      <label>Expiration</label>
      <div class="read">
        <wm-checkbox [(ngModel)]="expiring">Expiring or Expired</wm-checkbox>
      </div>
    </div>
    <div class="wm-description deleted">
      <label>Deleted</label>
      <div class="read">
        <wm-checkbox [(ngModel)]="deleted">Show Deleted</wm-checkbox>
      </div>
    </div>
  </div>
</div>
