import { type HttpInterceptorFn } from '@angular/common/http';
import { UrlTransformInterceptor } from './url-transform.interceptor';
import { DataInterceptor } from './data.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { JWTInterceptor } from './jwt.interceptor';
import { RenewalInterceptor } from './renewal.interceptor';
import { RetryInterceptor } from './retry.interceptor';
import { AppVersionInterceptor } from './app-version.interceptor';

// Interceptors are executed in order
export const interceptors: HttpInterceptorFn[] = [
  JWTInterceptor,
  UrlTransformInterceptor,
  ErrorInterceptor,
  RetryInterceptor,
  DataInterceptor,
  AppVersionInterceptor,
  RenewalInterceptor,
];

