<div class="flex">
  <div class="section wm-card2">
    <div class="wm-description dates">
      <label>Date Range</label>
      <div class="read range">
        <div>
          <label>From</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateFrom"></wm-input-moment-picker>
        </div>
        <div>
          <label>End</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateEnd"></wm-input-moment-picker>
        </div>
      </div>
    </div>
    <div class="wm-description technician">
      <label>Technician</label>
      <div class="read">
        <wm-input-image [(ngModel)]="technician" [options]="technicians" dropdownParent="body" [clearable]="true"> </wm-input-image>
      </div>
    </div>
    <div class="wm-description agreement-sold">
      <label>Agreement Sold</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="agreementSold" [options]="agreementSoldOptions" dropdownParent="body"> </wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description balance-type">
      <label>Balance Type</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="balanceType" [options]="balanceTypeOptions" dropdownParent="body"> </wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description happy-call">
      <label>Happy Call</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="happyCall" [options]="happyCallOptions" dropdownParent="body"> </wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description call-work-order-type">
      <label>Type of Appointment</label>
      <div class="read">
        <wm-rw-dropdown2 [options]="callWorkOrderTypes" [(ngModel)]="callWorkOrderType" dropdownParent="body" />
      </div>
    </div>
  </div>
  <div class="section wm-card2">
    <div class="wm-description call-department-type">
      <label>Department</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="callDepartmentType" [options]="callDepartmentTypes" dropdownParent="body"></wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description invoice">
      <label>Invoice Status</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="invoice" [options]="invoiceOptions" dropdownParent="body"> </wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description recurring-invoice">
      <label>Recurring Invoice</label>
      <div class="read">
        <wm-checkbox [ngModel]="!excludeRecurringInvoice" (ngModelChange)="excludeRecurringInvoice = !$event"
          >Show Recurring Invoices</wm-checkbox
        >
      </div>
    </div>
    <div class="wm-description debrief">
      <label>Debrief Status</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="debrief" [options]="debriefOptions" dropdownParent="body"> </wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description completion">
      <label>Completion Status</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="completion" [options]="completionOptions" dropdownParent="body"> </wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description status">
      <label>Status</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="status" [options]="statuses" dropdownParent="body"></wm-input-dropdown>
      </div>
    </div>
  </div>
</div>
