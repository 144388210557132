type HighlightableString = string;

export interface Section {
  type: SearchType;
  count: number;
}

export enum SecondaryType {
  NameAndPhone,
  Address,
  Count,
}

export interface Secondary {
  type: SecondaryType;
  data1?: HighlightableString;
  data2?: HighlightableString;
  data3?: HighlightableString;
  data4?: HighlightableString;
}

export interface Result {
  icon: string;
  primary: HighlightableString;
  secondaries: Secondary[];
  link: any[]; // TODO rename routerCommand
}

export enum SearchType {
  All,
  Customers,
  Sites,
  WorkOrders,
}

export function getSearchTypeString(type: SearchType): string {
  switch (type) {
    case SearchType.Customers:
      return 'Customers';
    case SearchType.Sites:
      return 'Sites';
    case SearchType.WorkOrders:
      return 'Work Orders';
  }

  return '';
}
