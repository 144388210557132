<div *ngIf="abstractControl || _allowEmptyAbstractControl">
  <ng-container *ngIf="labelTemplate2; else defaultLabel" [ngTemplateOutlet]="labelTemplate2"></ng-container>
  <ng-template #defaultLabel>
    <label *ngIf="name">{{ name }}</label>
  </ng-template>
  <div class="helper" *ngIf="helperTemplate">
    <ng-container [ngTemplateOutlet]="helperTemplate" [ngTemplateOutletContext]="{ $implicit: abstractControl }"> </ng-container>
  </div>
  <div class="rw-container" [@rwContainer]="{ value: modeStr, params: { expanded_size: expandedSize } }">
    <div
      class="write"
      *ngIf="mode === Mode.Write"
      [@wContainer]="modeStr"
      [class.ready]="isReady"
      (@wContainer.start)="isReady = false"
      (@wContainer.done)="isReady = true"
    >
      <ng-container [ngTemplateOutlet]="writeTemplate" [ngTemplateOutletContext]="{ $implicit: abstractControl }"> </ng-container>
    </div>
    <div class="read" *ngIf="mode === Mode.Read" [@rContainer]="modeStr">
      <ng-container [ngTemplateOutlet]="readTemplate" [ngTemplateOutletContext]="{ $implicit: abstractControl }"> </ng-container>
    </div>
  </div>
</div>
