<div class="flex">
  <div class="section wm-card2">
    <div class="wm-description dates">
      <label>Date Range</label>
      <div class="read range">
        <div>
          <label>From</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateFrom"></wm-input-moment-picker>
        </div>
        <div>
          <label>End</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateEnd"></wm-input-moment-picker>
        </div>
      </div>
    </div>
    <div class="wm-description technician">
      <label>Technician</label>
      <div class="read">
        <wm-input-image [(ngModel)]="technician" [options]="technicians" dropdownParent="body" [clearable]="true"> </wm-input-image>
      </div>
    </div>
  </div>
  <div class="section wm-card2">
    <div class="wm-description status">
      <label>Status</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="status" [options]="statuses" dropdownParent="body"></wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description deleted">
      <label>Deleted</label>
      <div class="read">
        <wm-checkbox [(ngModel)]="deleted">Show Deleted</wm-checkbox>
      </div>
    </div>
  </div>
</div>
