import { ChangeDetectorRef, Injectable, Pipe, type EventEmitter, type OnDestroy, type PipeTransform } from '@angular/core';
import { type Observable, type Subscribable, type Unsubscribable } from 'rxjs';

// https://github.com/angular/angular/blob/c2edcce4369e3573d9863ae93ed737bf9f179845/packages/core/src/util/lang.ts

/**
 * Determine if the argument is shaped like a Promise
 */
export function isPromise<T = any>(obj: any): obj is Promise<T> {
  // allow any Promise/A+ compliant thenable.
  // It's up to the caller to ensure that obj.then conforms to the spec
  return !!obj && typeof obj.then === 'function';
}

/**
 * Determine if the argument is a Subscribable
 */
export function isSubscribable<T>(obj: any | Subscribable<T>): obj is Subscribable<T> {
  return !!obj && typeof obj.subscribe === 'function';
}

// https://github.com/angular/angular/blob/5ac8ca4f55b4d2901238f49ffff7a7970f6fe7f0/packages/common/src/pipes/async_pipe.ts
class SubscribableStrategy implements SubscriptionStrategy<Subscribable<any>, Unsubscribable> {
  createSubscription(async: Subscribable<any>, updateLatestValue: any): Unsubscribable {
    return async.subscribe({
      next: updateLatestValue,
      error: (e: any) => {
        throw e;
      },
    });
  }

  dispose(subscription: Unsubscribable): void {
    subscription.unsubscribe();
  }
}

class PromiseStrategy implements SubscriptionStrategy<Promise<any>, Promise<any>> {
  async createSubscription(async: Promise<any>, updateLatestValue: (v: any) => any): Promise<any> {
    return await async.then(updateLatestValue, e => {
      throw e;
    });
  }

  dispose(_subscription: Promise<any>): void {}
}

const _promiseStrategy = new PromiseStrategy();
const _subscribableStrategy = new SubscribableStrategy();

interface SubscriptionStrategy<TSubscribable, TUnsubscribable> {
  createSubscription: (async: TSubscribable, updateLatestValue: any) => TUnsubscribable;
  dispose: (subscription: TUnsubscribable) => void;
}

const NEVER_RETURNED = Symbol('NEVER_RETURNED');

@Pipe({
  name: 'asyncForce',
  pure: false,
  standalone: true,
})
@Injectable()
export class AsyncForcePipe implements OnDestroy, PipeTransform {
  private _ref: ChangeDetectorRef | null = null;
  private _latestValue: any = NEVER_RETURNED;

  private _subscription: Unsubscribable | Promise<any> | null = null;
  private _obj: Subscribable<any> | Promise<any> | EventEmitter<any> | null = null;
  private _strategy: SubscriptionStrategy<any, any> | null = null;

  constructor(ref: ChangeDetectorRef) {
    // Assign `ref` into `this._ref` manually instead of declaring `_ref` in the constructor
    // parameter list, as the type of `this._ref` includes `null` unlike the type of `ref`.
    this._ref = ref;
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._dispose();
    }
    // Clear the `ChangeDetectorRef` and its association with the view data, to mitigate
    // potential memory leaks in Observables that could otherwise cause the view data to
    // be retained.
    // https://github.com/angular/angular/issues/17624
    this._ref = null;
  }

  transform<T>(obj: Observable<T> | Subscribable<T> | Promise<T>): T;
  transform(obj: null | undefined): null;
  transform<T>(obj: Observable<T> | Subscribable<T> | Promise<T> | null | undefined): T;
  transform<T>(obj: Observable<T> | Subscribable<T> | Promise<T> | null | undefined): T {
    if (!this._obj) {
      if (obj) {
        this._subscribe(obj);
      }
      return this.returnOrDefault(this._latestValue);
    }

    if (obj !== this._obj) {
      this._dispose();
      return this.transform(obj);
    }

    return this.returnOrDefault(this._latestValue);
  }

  private returnOrDefault<T>(value: T | undefined): T {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return value!;
  }

  private _subscribe(obj: Subscribable<any> | Promise<any> | EventEmitter<any>): void {
    this._obj = obj;
    this._strategy = this._selectStrategy(obj);
    // eslint-disable-next-line @typescript-eslint/ban-types
    this._subscription = this._strategy.createSubscription(obj, (value: Object) => {
      this._updateLatestValue(obj, value);
    });
  }

  private _selectStrategy(obj: Subscribable<any> | Promise<any> | EventEmitter<any>): SubscriptionStrategy<any, any> {
    if (isPromise(obj)) {
      return _promiseStrategy;
    }

    if (isSubscribable(obj)) {
      return _subscribableStrategy;
    }

    throw new Error('Invalid Argument');
  }

  private _dispose(): void {
    // Note: `dispose` is only called if a subscription has been initialized before, indicating
    // that `this._strategy` is also available.
    this._strategy?.dispose(this._subscription);
    this._latestValue = null;
    this._subscription = null;
    this._obj = null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private _updateLatestValue(async: any, value: Object): void {
    if (async === this._obj) {
      this._latestValue = value;
      // Note: `this._ref` is only cleared in `ngOnDestroy` so is known to be available when a
      // value is being updated.
      this._ref?.markForCheck();
    }
  }
}
