import { Injectable, inject } from '@angular/core';
import { type AvatarInformation } from '@models/avatar';
import { type AgreementConfiguration } from '@models/cards/agreement-information';
import { type PartDeliveryMethod, type ReplenishmentTypeInformation } from '@models/cards/distributor-information';
import { type QuickBooksMapping } from '@models/cards/quick-books-mapping';
import { type ContractorLaborPricing } from '@models/contractor-labor-pricing';
import { type ServiceRepairInformation } from '@models/price-book-models';
import {
  type RequiredTextOrderedResource,
  type RequiredTextResource1,
  type RequiredTextResourceWithChildren,
  type RequiredTextStateResource,
} from '@models/resource';
import { type StaticDropdown } from '@models/static-dropdown';
import { type TechnicianInformation } from '@models/technician';
import { skipBubbleError } from '@utility/angular';
import { sort, sortAlphabetically, sortWithChildren } from '@utility/observable';
import { type Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { ApplicationCacheService, UpdateCategory } from './application-cache.service';
import { HttpClientService } from './http-client.service';
import { UrlService } from './url.service';

@Injectable()
export class StaticDataService {
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly url = inject(UrlService);
  private readonly http = inject(HttpClientService);

  getPartServiceTypes(): Observable<RequiredTextStateResource[]> {
    return this.applicationCacheService.get<RequiredTextStateResource[]>(this.url.partServiceTypesUrl).pipe(sortAlphabetically());
  }

  getPropertyTypes(id?: Id): Observable<RequiredTextStateResource[]> {
    return this.applicationCacheService.getWithId<RequiredTextStateResource>(this.url.propertyTypesUrl, id).pipe(sortAlphabetically());
  }

  getRateTypes(id?: Id): Observable<RequiredTextStateResource[]> {
    return this.applicationCacheService.getWithId<RequiredTextStateResource>(this.url.rateTypesUrl, id).pipe(sortAlphabetically());
  }

  getTimeZones(): Observable<RequiredTextResource1<string>[]> {
    return this.applicationCacheService.get<RequiredTextResource1<string>[]>(this.url.timeZonesUrl).pipe(sortAlphabetically());
  }

  getSurveys(): Observable<RequiredTextResourceWithChildren<RequiredTextOrderedResource>[]> {
    return this.applicationCacheService.getWithId<RequiredTextStateResource>(this.url.surveysUrl).pipe(sortWithChildren());
  }

  getQuickBooksSettings(): Observable<QuickBooksMapping> {
    return this.applicationCacheService.get(this.url.quickBooksSettings);
  }

  getServiceRepairs(): Observable<ServiceRepairInformation[]> {
    return this.applicationCacheService.get<ServiceRepairInformation[]>(this.url.serviceRepairsUrl).pipe(sort());
  }

  getServiceRepair(serviceRepairId: Id): Observable<ServiceRepairInformation | undefined> {
    return this.getServiceRepairs().pipe(map(x => x.find(y => y.id === serviceRepairId)));
  }

  getContractorLaborPricings(): Observable<ContractorLaborPricing[]> {
    return this.applicationCacheService.get(this.url.contractorLaborPricingsUrl);
  }

  /**
   * @deprecated This method does not return everything properly. Use the TechniciansService.
   */
  getTechnicians(id?: Id): Observable<TechnicianInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.Technicians, this.url.techniciansUrl, id);
  }

  getAvatars(): Observable<AvatarInformation[]> {
    return this.applicationCacheService.getWithId<AvatarInformation>(this.url.avatarsUrl).pipe(sortAlphabetically());
  }

  getAgreementConfigurations(): Observable<AgreementConfiguration> {
    return this.applicationCacheService.get(this.url.agreementConfigurationsUrl);
  }

  getPartDeliveryMethods(): Observable<PartDeliveryMethod[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.PartDeliveryMethods, this.url.partDeliveryMethods);
  }

  getReplenishmentTypes(): Observable<ReplenishmentTypeInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.ReplenishmentTypes, this.url.replenishmentTypes);
  }

  getDropdowns(): Observable<StaticDropdown[]> {
    return this.http.get<StaticDropdown[]>(this.url.dropdownsList);
  }

  saveDropdown(dropdown: StaticDropdown): Observable<void> {
    return this.http.post<void>(this.url.dropdownSave, dropdown, skipBubbleError()).pipe(
      tap(_ => {
        this.clearCache(dropdown.updateCategory);
      }),
      share()
    );
  }

  getDefaultSystems(): Observable<RequiredTextOrderedResource[]> {
    return this.http.get<RequiredTextOrderedResource[]>(this.url.staticDefaultSystems);
  }

  private clearCache(updateCategory: UpdateCategory): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(updateCategory);
    }
  }
}
