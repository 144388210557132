<div class="container">
  @if (isLoading) {
    <div class="loading">
      <span class="spinner"></span>
    </div>
  }

  <table [hidden]="!(!!rows.length || (!rows.length && !noResultsTemplate))">
    @if (includeColgroup) {
      <colgroup>
        @if (!disabled && draggable) {
          <col class="row-drag" />
        }
        @if (!disabled && rowSelection) {
          <col class="row-selection" />
        }
        <ng-container [ngTemplateOutlet]="tableColgroupTemplate"></ng-container>
        @if (!disabled && rowDelete) {
          <col class="row-delete" />
        }
      </colgroup>
    }
    @if (withThead) {
      <thead>
        <tr>
          @if (!disabled && draggable) {
            <th class="row-drag"></th>
          }
          @if (!disabled && rowSelection) {
            <th class="row-selection">
              @if (!disableAllRowSelection) {
                <wm-checkbox [(ngModel)]="allSelected"></wm-checkbox>
              }
            </th>
          }
          <ng-container [ngTemplateOutlet]="tableHeaderTemplate"></ng-container>
          @if (!disabled && rowDelete) {
            <th class="row-delete"></th>
          }
        </tr>
      </thead>
    }
    <tbody #tbody>
      @for (row of currentViewRows; track _idTracking; let index = $index) {
        <tr
          [attr.tn-table-row-selected]="isSelected(row)"
          [ngClass]="getRowClass(row)"
          [attr.data-entityid]="idTracking(row)"
          [attr.data-testid]="getRowTestId(row)"
          [attr.data-row-id]="isRowResource(row) && row.id"
        >
          @if (!disabled && draggable) {
            <td class="row-drag">
              <div class="arrows">
                <button type="button" class="as-link">
                  <img src="/assets/images/drag-to-order-handle.svg" alt="" />
                </button>
              </div>
            </td>
          }
          @if (!disabled && rowSelection && shouldIncludeRowSelection(row) && isRowResource(row)) {
            <td class="row-selection" [rowSpan]="rowSelectionRowSpan(row)">
              @if (shouldShowCheckbox(row)) {
                <wm-checkbox
                  data-testid="row-checkbox"
                  [disabled]="isRowSelectionDisabled(row)"
                  [ngModel]="selectionModel[row.id]"
                  (ngModelChange)="selectionModel[row.id] = $event; checkAllSelection()"
                />
              }
            </td>
          }
          <ng-container *ngTemplateOutlet="tableBodyTemplate; context: { $implicit: row, row, index: index }"></ng-container>
          @if (!disabled && rowDelete && shouldIncludeRowDelete(row)) {
            <td class="row-delete cell-image-centered" data-testid="row-delete">
              <button type="button" [disabled]="isRowDeleteDisabled(row)" (click)="rowDeleteClick.emit(row)" [attr.title]="removeText">
                <img src="/assets/images/file-delete.svg" alt="" />
              </button>
            </td>
          }
        </tr>
      }
    </tbody>
  </table>

  @if (!disabled && rowAdd) {
    <div wm-table-footer>
      @if (getAddTemplateContext(); as addContext) {
        <div wm-table-add-row>
          @if (addTemplate) {
            <ng-container [ngTemplateOutlet]="addTemplate" [ngTemplateOutletContext]="{ $implicit: addContext }" />
          } @else {
            <button type="button" class="as-link" (click)="addContext.rowAdd()">
              <img src="/assets/images/plus-expand-header.svg" alt="" />{{ rowAddText }}
            </button>
          }
        </div>
      }
    </div>
  }

  @if (footerTemplate && !!rows.length) {
    <div wm-table-footer>
      <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
    </div>
  }

  @if (noResultsTemplate && !rows.length && !isLoading) {
    <div>
      <ng-container [ngTemplateOutlet]="noResultsTemplate"></ng-container>
    </div>
  }
</div>

