import { Injectable, SecurityContext, inject, type OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { type WorkOrderAgreementInformation } from '@models/cards/work-order-agreement-information';
import { ActionType, NotificationStatus, type ActionSmsReceived, type Alert, type NotificationMessage } from '@models/notification';
import Autolinker from 'autolinker';
import { BehaviorSubject, type Observable, type Subscription } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { SignalRService } from './signalr.service';
import { WindowRefService } from './window-ref.service';

const supportedActionTypes: ActionType[] = [
  ActionType.SmsReceived,
  ActionType.QuickBooksOnlineAuthorizationFailed,
  ActionType.WorkOrderInformation,
  ActionType.PaymentReceived,
  ActionType.AdminMessage,
];

type converter = (notificationMessage: NotificationMessage) => Alert;
const handlers: Record<number, converter> = {};
handlers[ActionType.SmsReceived] = notificationMessage => {
  return {
    id: notificationMessage.notificationId,
    text: "You've received a new SMS message.",
    actionText: 'View SMS',
    icon: '/assets/images/alert-text.svg',
    status: notificationMessage.userStatus,
    createdDate: notificationMessage.createdDate,
    notificationMessage,
  } satisfies Alert;
};

handlers[ActionType.QuickBooksOnlineAuthorizationFailed] = notificationMessage => {
  return {
    id: notificationMessage.notificationId,
    text: 'QuickBooks Online has been disconnected. You need to re-new the connection if you wish to synchronize again.',
    actionText: 'Reconnect QuickBooks Online',
    icon: '/assets/images/alert-general.svg',
    status: notificationMessage.userStatus,
    createdDate: notificationMessage.createdDate,
    notificationMessage,
  } satisfies Alert;
};

handlers[ActionType.WorkOrderInformation] = notificationMessage => {
  return {
    id: notificationMessage.notificationId,
    text: notificationMessage.text,
    actionText: 'View Work Order',
    icon: '/assets/images/alert-general.svg',
    status: notificationMessage.userStatus,
    createdDate: notificationMessage.createdDate,
    notificationMessage,
  } satisfies Alert;
};

handlers[ActionType.PaymentReceived] = notificationMessage => {
  return {
    id: notificationMessage.notificationId,
    text: notificationMessage.text,
    actionText: 'View Work Order',
    icon: '/assets/images/alert-payment.svg',
    status: notificationMessage.userStatus,
    createdDate: notificationMessage.createdDate,
    notificationMessage,
  } satisfies Alert;
};

handlers[ActionType.AdminMessage] = notificationMessage => {
  return {
    id: notificationMessage.notificationId,
    text: `[Admin Message]\n${notificationMessage.text}`,
    icon: '/assets/images/alert-text.svg',
    status: notificationMessage.userStatus,
    createdDate: notificationMessage.createdDate,
    notificationMessage,
  } satisfies Alert;
};

@Injectable()
export class AlertsService implements OnDestroy {
  private readonly signalRService = inject(SignalRService);
  private readonly windowRefService = inject(WindowRefService);
  private readonly router = inject(Router);
  private readonly domSanitizer = inject(DomSanitizer);

  public alertList$ = new BehaviorSubject<Alert[]>([]);
  private guidListening?: string;
  public alertAmount$: Observable<number> = this.alertList$.pipe(
    map(alerts => alerts.length),
    shareReplay(1)
  );

  public alertHasNew$: Observable<boolean> = this.alertList$.pipe(
    map(alerts => alerts.some(alert => alert.status === NotificationStatus.Sent || alert.status === NotificationStatus.Received)),
    shareReplay(1)
  );

  private subscription?: Subscription;

  ngOnDestroy(): void {
    this.stop();
  }

  start(): void {
    if (!this.guidListening) {
      this.guidListening = this.signalRService.start();
      this.subscription = this.signalRService.message$
        .pipe(
          filter(notificationMessage => {
            return supportedActionTypes.includes(notificationMessage.actionType);
          })
        )
        .subscribe(notificationMessage => {
          let alerts = this.alertList$.getValue().slice(0);

          let notification = handlers[notificationMessage.actionType](notificationMessage);
          notification = this.improveNotification(notification);

          // We remove the existing notification if we have one.
          alerts = alerts.filter(m => m.id !== notification.id);
          alerts.push(notification);
          this.alertList$.next(alerts);
        });
    }
  }

  stop(): void {
    this.alertList$.next([]);
    if (this.guidListening) {
      this.signalRService.stop(this.guidListening);
      this.guidListening = undefined;
    }

    this.unsubscribe();
  }

  dismiss(alert: Alert): void {
    this.signalRService.remove(alert.id).subscribe();
    const list = this.alertList$.getValue().slice(0);
    const pos = list.indexOf(alert);
    if (pos >= 0) {
      list.splice(pos, 1);
    }

    this.alertList$.next(list);
  }

  async actionClick(alert: Alert): Promise<void> {
    this.dismiss(alert);
    switch (alert.notificationMessage?.actionType) {
      case ActionType.SmsReceived:
        {
          const extraJson = alert.notificationMessage?.extraJson as ActionSmsReceived | undefined;
          const url = 'https://my.textmagic.com/online/messages/chat/' + extraJson?.phone;
          this.windowRefService.nativeWindow.open(url);
        }
        break;
      case ActionType.QuickBooksOnlineAuthorizationFailed:
        await this.router.navigate(['/settings/quickbooks/online/initial']);
        break;
      case ActionType.WorkOrderInformation:
      case ActionType.PaymentReceived:
        {
          const extraJson = alert.notificationMessage?.extraJson as WorkOrderAgreementInformation | undefined;
          await this.router.navigate(['/workorders', extraJson?.workOrderId]);
        }
        break;
    }
  }

  dismissAll(alerts: Alert[]): void {
    const list = this.alertList$.getValue().slice(0);
    alerts.forEach(alert => {
      this.signalRService.remove(alert.id).subscribe();
      const pos = list.indexOf(alert);
      if (pos >= 0) {
        list.splice(pos, 1);
      }
    });

    this.alertList$.next(list);
  }

  acknowledge(): void {
    const list = this.alertList$.getValue().filter(m => m.status !== NotificationStatus.Acknowledged);

    list.forEach(alert => {
      alert.status = NotificationStatus.Acknowledged;
      this.signalRService.acknowledge(alert.id).subscribe();
    });

    this.alertList$.next(this.alertList$.getValue().slice(0));
  }

  private unsubscribe(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  private improveNotification(notification: Alert): Alert {
    let text = this.domSanitizer.sanitize(SecurityContext.HTML, notification.text ?? '') ?? '';
    text = Autolinker.link(text, { email: false });
    return {
      ...notification,
      text,
    };
  }
}
