<div [attr.data-testid]="dataTestId">
  <div matDialogTitle>
    <img *ngIf="type" class="type" src="/assets/images/dialog-{{ dialogImage }}.svg" alt="" />
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="subtitle" *ngIf="text" [innerHTML]="text"></div>
    </div>
    <button *ngIf="false && !disableExit" type="button" (click)="close()" class="as-link close">
      <img src="/assets/images/tag-remove.svg" title="Close" alt="Close" />
    </button>
  </div>
  <div matDialogContent>
    <div class="content" (enter)="enterPressed()">
      <ng-template #portal="cdkPortalOutlet" [cdkPortalOutlet]="intakePortal"></ng-template>
    </div>
  </div>
  <div matDialogActions *ngIf="buttons?.length">
    <div class="buttons">
      <button
        *ngFor="let button of buttons"
        wm-button
        type="button"
        [ngClass]="button.secondary"
        (click)="internalButtonClick(button)"
        [disabled]="getButtonDisabled$(button) | asyncSafe: false"
        [attr.cdkFocusInitial]="button.focusInitial ? true : null"
      >
        {{ getButtonText$(button) | async }}
      </button>
    </div>
  </div>
</div>
