import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { type RequiredTextResource1 } from '@models/resource';
import { InputResourceBase } from '../input-resource/input-resource.component';

// Angular 18
@Component({
  selector: 'wm-input-tag',
  templateUrl: 'input-tag.component.html',
  styleUrls: ['input-tag.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTagComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, InputDropdownComponent, CommonModule],
})
export class InputTagComponent<T> extends InputResourceBase<RequiredTextResource1<T>[], T, RequiredTextResource1<T>> {
  readonly allowCreate = input(false);
  readonly bindValue = input('id');
  readonly optgroupField = input<string | undefined>(undefined);
  readonly optgroups = input<{ value: string; label: string }[] | undefined>(undefined);
}

