import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RwDropdown2Component } from '@controls/rw-dropdown/rw-dropdown2.component';
import { RwMoney2Component } from '@controls/rw-money/rw-money2.component';
import { type DialogIntakeComponent } from '@dialogs/dialog-intake.component';
import { DialogType, INTAKE_DIALOG_DATA_TYPED, buttons, type Dialog2ServiceImpl, type DialogIntakeImpl } from '@models/dialog';
import { Ownership, PropertyType } from '@models/ener-bank';
import { Mode } from '@models/form';
import { type RequiredTextResource1 } from '@models/resource';
import { SharedModule } from '@modules/shared.module';
import { BehaviorSubject, of, type Observable } from 'rxjs';

export interface EnerBankProjectInfoData {
  ownership?: Ownership;
  propertyType?: PropertyType;
  monthlyMortgagePayment?: Money | null;
}

const injectionToken = INTAKE_DIALOG_DATA_TYPED<EnerBankProjectInfoData>();

@Component({
  selector: 'wm-ener-bank-project-info',
  templateUrl: 'ener-bank-project-info.component.html',
  styleUrls: ['ener-bank-project-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, RwDropdown2Component, RwMoney2Component],
})
export class EnerBankProjectInfoComponent implements DialogIntakeImpl {
  readonly Mode = Mode;

  private readonly data = inject(injectionToken);
  private readonly dialogRef = inject(MatDialogRef<DialogIntakeComponent<EnerBankProjectInfoComponent>>);

  readonly disableSave$ = new BehaviorSubject<boolean>(true);

  readonly ownershipOptions: RequiredTextResource1<string>[] = [
    {
      id: '',
      text: '',
    },
    {
      id: Ownership.Owner,
      text: 'Owner',
    },
    {
      id: Ownership.Occupant,
      text: 'Occupant',
    },
  ];

  readonly propertyTypeOptions: RequiredTextResource1<string>[] = [
    {
      id: '',
      text: '',
    },
    {
      id: PropertyType.SingleFamily,
      text: 'Single Family',
    },
    {
      id: PropertyType.CondominiumOrTownhouse,
      text: 'Condo / Town Home',
    },
    {
      id: PropertyType.MultiFamily,
      text: 'Multi-Family (6 or Fewer Units)',
    },
    {
      id: PropertyType.Mobile,
      text: 'Mobile Home',
    },
  ];

  ownership?: Ownership = this.data.ownership;
  propertyType?: PropertyType = this.data.propertyType;
  monthlyMortgagePayment?: Money | string | null = this.data.monthlyMortgagePayment;

  save(): void {
    this.dialogRef.close({
      ownership: this.ownership,
      propertyType: this.propertyType,
      monthlyMortgagePayment: this.monthlyMortgagePayment,
    });
  }

  internalModelChange() {
    if (!!this.ownership && !!this.propertyType) {
      this.disableSave$.next(false);
    } else {
      this.disableSave$.next(true);
    }
  }

  getButtonDisabled$(id: string): Observable<boolean> {
    if (id === 'save') {
      return this.disableSave$;
    }

    return of(false);
  }

  static open(dialog: Dialog2ServiceImpl, data: EnerBankProjectInfoData) {
    return dialog
      .intake<EnerBankProjectInfoComponent, EnerBankProjectInfoData, EnerBankProjectInfoData>(EnerBankProjectInfoComponent, {
        disableClose: false,
        width: '400px',
        intake: {
          type: DialogType.Question,
          title: 'Project Information',
          text: 'We need more information about this project.',
          buttons: buttons.confirmButtons({
            withCancel: true,
            text: 'Save',
          }),
          intakeData: {
            injectionToken,
            data,
          },
          disableExit: true,
        },
      })
      .afterClosed();
  }
}
