<section class="top">
  <div class="text">
    <div class="title">{{ data.title }}</div>
    <div class="right-aligned" *ngIf="data.extraIcons">
      <div class="icons">
        <ul>
          <li>
            <span class="call-type" [ngClass]="CallType[data.extraIcons.callType]">{{ data.extraIcons.statusText }}</span>
          </li>
          <li
            class="icon-sms"
            data-id="icon-sms"
            #sms
            *ngIf="getSmsIcon() as smsIcon"
            (mouseenter)="showSmsHelp(smsIcon, sms)"
            (mouseleave)="hideHelp(sms.dataset.id)"
          >
            <img src="{{ smsIcon.url }}" alt="" />
          </li>
          <li *ngIf="data.extraIcons.sentToIPad === true || data.extraIcons.sentToIPad === false">
            <span class="send-to-ipad">
              <img *ngIf="data.extraIcons.sentToIPad === true" src="/assets/images/dashboard-popover-ipad-sent.svg" alg="" />
              <img *ngIf="data.extraIcons.sentToIPad === false" src="/assets/images/dashboard-popover-ipad-unsent.svg" alg="" />
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section class="content">
  <div class="tabs" *ngIf="hasAnyTabs(data)">
    <ul>
      <li *ngIf="data.call" [class.selected]="currentTab === Tab.Call">
        <button type="button" class="as-link" (click)="openTab(Tab.Call)">Appointment</button>
      </li>
      <li *ngIf="data.site && data.site.id" [class.selected]="currentTab === Tab.Site">
        <button type="button" class="as-link" (click)="openTab(Tab.Site)">Site</button>
      </li>
      <li *ngIf="data.customer && data.customer.id" [class.selected]="currentTab === Tab.Customer">
        <button type="button" class="as-link" (click)="openTab(Tab.Customer)">Customer</button>
      </li>
      <li *ngIf="data.siteHistory && data.siteHistory.id" [class.selected]="currentTab === Tab.SiteHistory">
        <button type="button" class="as-link" (click)="openTab(Tab.SiteHistory)">Site History</button>
      </li>
      <li *ngIf="data.agreement" [class.selected]="currentTab === Tab.Agreement">
        <button type="button" class="as-link" (click)="openTab(Tab.Agreement)">Agreement</button>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    <div *ngIf="data.event" class="tab blocked" [class.selected]="currentTab === Tab.Blocked">
      <div *ngIf="!tabData || !tabData.blocked" class="loading">
        <span class="spinner"></span>
      </div>
      <div *ngIf="tabData && tabData.blocked">
        <div class="body">
          <div class="wm-description reason" *ngIf="tabData.blocked.reason">
            <label>Reason</label>
            <div class="read">
              {{ tabData.blocked.reason }}
            </div>
          </div>
          <div class="wm-description repeat-days" *ngIf="tabData.blocked.repeatDays">
            <label>Repeat Every</label>
            <div class="read">
              {{ tabData.blocked.repeatDays }}
            </div>
          </div>
          <div class="wm-description repeat-occurrence" *ngIf="tabData.blocked.repeatEndOccurrence">
            <label>Ends After</label>
            <div class="read">
              {{ tabData.blocked.repeatEndOccurrence }}
              {tabData.blocked.repeatEndOccurrence, plural, =1 {occurrence} other {occurrences}}
            </div>
          </div>
          <div class="wm-description repeat-occurrence" *ngIf="!tabData.blocked.repeatEndOccurrence && tabData.blocked.repeatEndDate">
            <label>Ends On</label>
            <div class="read">
              {{ tabData.blocked.repeatEndDate | date: 'shortDate' }}
            </div>
          </div>
          <wm-event-note-metadata [allowNoteEditing]="data.allowNoteEditing" [noteMetadata]="tabData.blocked.noteMetadata" />
        </div>
      </div>
    </div>
    <div *ngIf="data.call" class="tab call" [class.selected]="currentTab === Tab.Call">
      <div *ngIf="!tabData || !tabData.call" class="loading">
        <span class="spinner"></span>
      </div>
      <div *ngIf="tabData && tabData.call">
        <div class="body">
          <div class="beacon-box" *ngIf="shouldShowBeacon()">
            <div class="content">
              <div>The technician is running late for this appointment.</div>
              <div class="center-aligned take-charge" *ngIf="canTakeCharge()">
                <button wm-button type="button" (click)="internalTakeCharge()" class="danger">Take Charge</button>
              </div>
            </div>
          </div>
          <div class="wm-description" *ngIf="shouldShowPersonInCharge() && data.call.dashboardEvent.callDetails">
            <wm-rw-technician2
              name="Person in Charge"
              [mode]="Mode.Read"
              [(ngModel)]="data.call.dashboardEvent.callDetails.inChargeTechnicianId"
            >
              <ng-template #helperTemplate>This is the person who will take charge of mitigating the late appointment.</ng-template>
            </wm-rw-technician2>
          </div>

          <div class="wm-description arrived-date" *ngIf="tabData.call.humanizedDiff">
            <label>Arrived Date</label>
            <div class="read">
              {{ tabData.call.humanizedDiff }}
            </div>
          </div>
          <div class="wm-description info">
            <label>Site Information</label>
            <div class="name read">
              <button type="button" class="as-link" (click)="debounceClick(data.call.click)">{{ tabData.call.clientName }}</button>
            </div>
            <div class="address read">
              {{ tabData.call.siteAddress ?? null | address }}
            </div>
          </div>
          <div class="wm-description sms" *ngIf="data.call.sendConfirmationAllowed">
            <label>SMS Confirmation Status</label>
            <div class="sms write">
              <wm-input-dropdown
                [(ngModel)]="data.call.dashboardEvent.confirmationStatus"
                (ngModelChange)="internalSaveStatus()"
                [options]="smsStatusOptions"
                [dropdownParent]="'body'"
              ></wm-input-dropdown>
            </div>
          </div>
          <wm-event-note-metadata [allowNoteEditing]="data.allowNoteEditing" [noteMetadata]="tabData.call.noteMetadata" />
        </div>
      </div>
    </div>
    <div *ngIf="data.site && data.site.id" class="tab site" [class.selected]="currentTab === Tab.Site">
      <div *ngIf="!tabData || !tabData.site" class="loading">
        <span class="spinner"></span>
      </div>
      <div *ngIf="tabData && tabData.site">
        <div class="body">
          <div class="wm-description info">
            <label>Site Information</label>
            <div class="name read">
              <button type="button" class="as-link" (click)="debounceClick(data.site.click)">
                {{ tabData.site.siteInformation.entity | entity }}
              </button>
            </div>
            <div class="address read">
              {{ tabData.site.siteInformation.address | address }}
            </div>
          </div>
          <div *ngIf="tabData.site.siteInformation.mainPhone" class="wm-description main-phone">
            <label>Main Phone</label>
            <div class="read">
              <span class="phone">{{ tabData.site.siteInformation.mainPhone | phone }}</span>
              <span class="label" *ngIf="tabData.site.siteInformation.mainPhoneLabel">
                ({{ tabData.site.siteInformation.mainPhoneLabel }})
              </span>
            </div>
          </div>
          <div *ngIf="tabData.site.siteInformation.mobilePhone" class="wm-description mobile-phone">
            <label>Mobile Phone</label>
            <div class="read">
              <span class="phone">{{ tabData.site.siteInformation.mobilePhone | phone }}</span>
              <span class="label" *ngIf="tabData.site.siteInformation.mobilePhoneLabel">
                ({{ tabData.site.siteInformation.mobilePhoneLabel }})
              </span>
            </div>
          </div>
          <div *ngIf="tabData.site.siteInformation.workPhone" class="wm-description work-phone">
            <label>Work Phone</label>
            <div class="read">
              <span class="phone">{{ tabData.site.siteInformation.workPhone | phone }}</span>
              <span class="label" *ngIf="tabData.site.siteInformation.workPhoneLabel">
                ({{ tabData.site.siteInformation.workPhoneLabel }})
              </span>
            </div>
          </div>
          <div *ngIf="tabData.site.siteInformation.email" class="wm-description email">
            <label>Email</label>
            <div class="read">
              {{ tabData.site.siteInformation.email | email }}
            </div>
          </div>
          <wm-event-note-metadata [allowNoteEditing]="data.allowNoteEditing" [noteMetadata]="tabData.site.noteMetadata" />
        </div>
      </div>
    </div>
    <div *ngIf="data.customer && data.customer.id" class="tab customer" [class.selected]="currentTab === Tab.Customer">
      <div *ngIf="!tabData || !tabData.customer" class="loading">
        <span class="spinner"></span>
      </div>
      <div *ngIf="tabData && tabData.customer">
        <div class="body">
          <div class="wm-description info">
            <label>Customer Information</label>
            <div class="name read">
              <button type="button" class="as-link" (click)="debounceClick(data.customer.click)">
                {{ tabData.customer.customerInformation.entity | entity }}
              </button>
            </div>
            <div class="address read">
              {{ tabData.customer.customerInformation.address | address }}
            </div>
          </div>
          <div *ngIf="tabData.customer.customerInformation.mainPhone" class="wm-description main-phone">
            <label>Main Phone</label>
            <div class="read">
              <span class="phone">{{ tabData.customer.customerInformation.mainPhone | phone }}</span>
              <span class="label" *ngIf="tabData.customer.customerInformation.mainPhoneLabel">
                ({{ tabData.customer.customerInformation.mainPhoneLabel }})
              </span>
            </div>
          </div>
          <div *ngIf="tabData.customer.customerInformation.mobilePhone" class="wm-description mobile-phone">
            <label>Mobile Phone</label>
            <div class="read">
              <span class="phone">{{ tabData.customer.customerInformation.mobilePhone | phone }}</span>
              <span class="label" *ngIf="tabData.customer.customerInformation.mobilePhoneLabel">
                ({{ tabData.customer.customerInformation.mobilePhoneLabel }})
              </span>
            </div>
          </div>
          <div *ngIf="tabData.customer.customerInformation.workPhone" class="wm-description work-phone">
            <label>Work Phone</label>
            <div class="read">
              <span class="phone">{{ tabData.customer.customerInformation.workPhone | phone }}</span>
              <span class="label" *ngIf="tabData.customer.customerInformation.workPhoneLabel">
                ({{ tabData.customer.customerInformation.workPhoneLabel }})
              </span>
            </div>
          </div>
          <div *ngIf="tabData.customer.customerInformation.email" class="wm-description email">
            <label>Email</label>
            <div class="read">
              {{ tabData.customer.customerInformation.email | email }}
            </div>
          </div>
          <wm-event-note-metadata [allowNoteEditing]="data.allowNoteEditing" [noteMetadata]="tabData.customer.noteMetadata" />
        </div>
      </div>
    </div>
    <div
      *ngIf="data.siteHistory && data.siteHistory.id"
      class="tab tab-puny site-history"
      [class.selected]="currentTab === Tab.SiteHistory"
    >
      <div *ngIf="!tabData || !tabData.siteHistory" class="loading">
        <span class="spinner"></span>
      </div>
      <div *ngIf="tabData && tabData.siteHistory">
        <div *ngFor="let lastVisit of tabData.siteHistory.lastVisitNoteInformations" class="section">
          <div class="wm-card2">
            <div>
              <div class="title">
                {{ lastVisit.scheduledDatetime | date: 'short' }}
              </div>
              <div class="wm-description flex">
                <div class="work-order">
                  <label>Work Order</label>
                  <div class="read">
                    <button type="button" class="as-link" (click)="debounceClick(data.siteHistory.click, lastVisit.workOrderId)">
                      #{{ lastVisit.workOrderLocalId }}
                    </button>
                  </div>
                </div>
                <div class="technician">
                  <label>Main Technician</label>
                  <div class="read">{{ lastVisit.technicianId | ref: 'technician' }}</div>
                </div>
              </div>
              <div *ngIf="lastVisit.additionalInformation" class="wm-description additional-information">
                <label>Additional Information</label>
                <div class="read">{{ lastVisit.additionalInformation }}</div>
              </div>
            </div>
            <div class="systems" *ngFor="let systemNote of lastVisit.systemNotes">
              <div *ngIf="systemNote.siteSystemId" class="title site-system">
                <div class="text">
                  <img class="medium-icon" src="/assets/images/system-sm.svg" alt="" />
                  <h3>{{ siteSystemBehavior$ | async | siteSystemWording }} #{{ systemNote.siteSystemLocalId }}</h3>
                </div>
              </div>
              <div *ngIf="systemNote.recommendation" class="wm-description recommendation">
                <label>Findings & Recommendations</label>
                <div class="read template-text">{{ systemNote.recommendation }}</div>
              </div>
              <div class="wm-description work-performed-accepted" *ngIf="getAcceptedWork(systemNote.workPerformed).length">
                <label>Completed Work</label>
                <ul>
                  <li *ngFor="let workPerformed of getAcceptedWork(systemNote.workPerformed)">
                    {{ workPerformed.text }}
                  </li>
                </ul>
              </div>
              <div class="wm-description work-performed-unccepted" *ngIf="getUnacceptedWork(systemNote.workPerformed).length">
                <label>Unaccepted Work</label>
                <ul>
                  <li *ngFor="let workPerformed of getUnacceptedWork(systemNote.workPerformed)">
                    {{ workPerformed.text }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.agreement" class="tab agreement" [class.selected]="currentTab === Tab.Agreement">
      <div *ngIf="!tabData || !tabData.agreement" class="loading">
        <span class="spinner"></span>
      </div>
      <div *ngIf="tabData && tabData.agreement">
        <div *ngFor="let line of tabData.agreement.data" class="section">
          <div class="title">
            <div class="text">
              <h2>
                {{ line.siteSystemType }}
              </h2>
            </div>
          </div>
          <div class="body">
            <div class="wm-table vertical-table">
              <table wm-table>
                <tbody>
                  <tr>
                    <th>Next Tune-Up</th>
                    <td>
                      <button
                        *ngIf="ah.isNavigatable(line.data)"
                        type="button"
                        class="as-link"
                        (click)="debounceClick(data.agreement.click, line.data.id)"
                      >
                        <ng-template [ngIf]="ah.isADate(ah.getAgreementLinkName(line))">
                          {{ ah.getAgreementLinkName(line) | date: 'shortDate' }}
                        </ng-template>
                        <ng-template [ngIf]="!ah.isADate(ah.getAgreementLinkName(line))">
                          {{ ah.getAgreementLinkName(line) }}
                        </ng-template>
                      </button>
                      <div *ngIf="!ah.isNavigatable(line.data)">
                        <span>Not Ready.</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Name/Type</th>
                    <td>{{ line.siteSystemType }}</td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{{ line.locations.join(', ') }}</td>
                  </tr>
                  <tr>
                    <th>Agreement</th>
                    <td>{{ ah.getAgreementNameFromAgreements(line.data, tabData.agreement.agreements) }}</td>
                  </tr>
                  <tr>
                    <th>Remaining Tune-Ups</th>
                    <td>
                      <ng-template [ngIf]="ah.getVisitLeft(line) !== null">
                        <span class="status-dot">
                          <img src="{{ ah.getAgreementBadgeUrl(line) }}" alt="" title="{{ ah.getAgreementBadgeTitle(line) }}" />
                        </span>
                        {{ ah.getVisitLeft(line) }} left
                      </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <th>Expiration</th>
                    <td>
                      {{ ah.getEndDate(line, tabData.agreement.agreements) | date: 'shortDate' }}
                      <img
                        *ngIf="line.data.endDate != null"
                        class="small-icon"
                        src="/assets/images/agreement-edited.svg"
                        alt=""
                        title="The benefits end date has been manually changed."
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Visits</th>
                    <td class="agreement-visits">
                      <ul>
                        <li *ngFor="let agreementVisit of line.agreementVisits" class="badge">
                          <span class="status">{{ ah.getDutyCompletionText(agreementVisit.status) }}</span>
                          <button type="button" class="as-link date" (click)="debounceClick(data.agreement.visitClick, agreementVisit)">
                            <span *ngIf="agreementVisit.dueDate">{{ agreementVisit.dueDate | date: 'shortDate' }}</span>
                            <span *ngIf="!agreementVisit.dueDate">No Due Date</span>
                          </button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="actions" *ngIf="data?.showActionButtons">
  <div class="buttons">
    <button
      *ngIf="getExtraButtons().length"
      wm-button
      type="button"
      class="more secondary"
      (init)="moreElement = $event"
      (click)="moreActionClick()"
    >
      <span class="text">More Actions</span>
      <span class="btn-arrow">
        <img src="/assets/images/dropdown-arrow.svg" alt="" />
      </span>
    </button>
    <button *ngFor="let button of getNormalButtons()" wm-button type="button" (click)="_buttonClick(button)">
      <span class="text">{{ button.text }}</span>
    </button>
  </div>
</section>
