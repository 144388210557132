import { InjectionToken } from '@angular/core';
import { type SafeHtml } from '@angular/platform-browser';
import { type PaymentGateway } from '@models/payments';
import { type SubscriptionDetail } from '@models/subscriptions';

export interface PaymentGatewayHandler {
  readonly paymentGateway: PaymentGateway;
  hasCustomConfirmationMessage: () => boolean;
  getConfirmationMessage: (confirmation: string) => SafeHtml;
  getErrorMessage: (confirmation: string | null) => string | null;
  getSubscriptionInformation: (subscriptionExtra: string | null) => SubscriptionDetail | null;
}

export const PAYMENT_GATEWAY_HANDLER = new InjectionToken<PaymentGatewayHandler[]>('PAYMENT_GATEWAY_HANDLER');
