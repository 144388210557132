import { Injectable, inject } from '@angular/core';
import { type RecommendationPhotoInformation } from '@models/cards/recommendation-information';
import { isMiscellaneousSiteSystem } from '@models/cards/site-system-information';
import { type WorkOrderInformation } from '@models/cards/work-order-information';
import { DeveloperError, httpErrorHandler } from '@models/error-models';
import { type Creatable, type Deletable, type Mutable, type Updatable } from '@models/mutable';
import { type TempFile } from '@models/photo';
import { convertTaxItemEntry } from '@models/pricing-models';
import { TagEntityType } from '@models/tag-models';
import { BlobContainer } from '@models/upload';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select, type Action, type ActionCreator } from '@ngrx/store';
import { type NotAllowedCheck, type TypedAction } from '@ngrx/store/src/models';
import { AzureStorageService } from '@services/azure-storage.service';
import { AgreementSiteSystemsService } from '@services/live/agreement-site-systems.service';
import { CallsService } from '@services/live/calls.service';
import { DebriefAnswersService } from '@services/live/debrief-answers.service';
import { EventsService } from '@services/live/events.service';
import { LoansService } from '@services/live/loans.service';
import { RecommendationPhotosService } from '@services/live/recommendation-photos.service';
import { RecommendationsService } from '@services/live/recommendations.service';
import { SalesProposalsService } from '@services/live/sales-proposals.service';
import { SiteSystemsService } from '@services/live/site-systems.service';
import { TagEntitiesService, saveTags } from '@services/live/tag-entities.service';
import { WorkOrdersService } from '@services/live/work-orders.service';
import { EMPTY, forkJoin, from, iif, of, type Observable } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as WorkOrderActions from './work-order.actions';
import { type WorkOrderState } from './work-order.reducer';
import * as WorkOrderSelectors from './work-order.selectors';

@Injectable()
export class WorkOrderEffects {
  private readonly actions$ = inject(Actions<WorkOrderActions.Actions>);
  private readonly store = inject(Store<WorkOrderState>);
  private readonly agreementSiteSystemsService = inject(AgreementSiteSystemsService);
  private readonly siteSystemsService = inject(SiteSystemsService);
  private readonly loansService = inject(LoansService);
  private readonly salesProposalsService = inject(SalesProposalsService);
  private readonly workOrdersService = inject(WorkOrdersService);
  private readonly debriefAnswersService = inject(DebriefAnswersService);
  private readonly tagEntitiesService = inject(TagEntitiesService);
  private readonly callsService = inject(CallsService);
  private readonly eventsService = inject(EventsService);
  private readonly recommendationsService = inject(RecommendationsService);
  private readonly recommendationPhotosService = inject(RecommendationPhotosService);
  private readonly azureStorageService = inject(AzureStorageService);

  updateWorkOrderProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.updateWorkOrderProcess),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WorkOrderSelectors.workOrder$)),
            this.store.pipe(select(WorkOrderSelectors.agreementSiteSystemArray$))
          )
        )
      ),
      mergeMap(([, workOrder, existingAgreementSiteSystems]) =>
        this.workOrdersService.toggleProcess(workOrder.id, !workOrder.processed).pipe(
          concatMap(processed => {
            return forkJoin(existingAgreementSiteSystems.map(ass => this.agreementSiteSystemsService.get(ass.id)))
              .pipe(defaultIfEmpty([]))
              .pipe(
                map(newAgreementSiteSystems => ({
                  agreementSiteSystems: newAgreementSiteSystems,
                  processed,
                }))
              );
          }),
          concatMap(({ agreementSiteSystems, processed }) => {
            return from([
              WorkOrderActions.loadAgreementSiteSystems({
                data: agreementSiteSystems,
              }),
              WorkOrderActions.updateWorkOrderProcessSuccess({ processed }),
            ]);
          }),
          catchError(error => of(WorkOrderActions.updateWorkOrderProcessFail({ error })))
        )
      )
    )
  );

  updateClassMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.updateWorkOrder),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderBaseViewData$))))),
      map(([action, workOrderBaseViewData]) => {
        const { callDepartmentTypeId } = action.patch;

        if (workOrderBaseViewData.classMappingPerDetail && callDepartmentTypeId !== undefined) {
          return WorkOrderActions.updateClassMappings({ callDepartmentTypeId });
        }

        return WorkOrderActions.noop();
      })
    )
  );

  saveWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveWorkOrder),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WorkOrderSelectors.hasVisibleData$)),
            this.store.pipe(select(WorkOrderSelectors.workOrderAndTaxItemEntry)),
            this.store.pipe(select(WorkOrderSelectors.entitiesSnapshot$))
          )
        )
      ),
      mergeMap(([, hasVisibleData, { workOrder, taxItemEntry }, entitiesSnapshot]) => {
        const getPayload = (): WorkOrderInformation => {
          const newTaxItemEntry = taxItemEntry?.updated ? convertTaxItemEntry(taxItemEntry) || {} : {};
          return { ...workOrder, ...newTaxItemEntry };
        };

        let saveWorkOrder$: Observable<{ id: Id }> = of(workOrder);

        const workOrderExists = workOrder.id && workOrder.id > 0;

        const payload = getPayload();
        if (!workOrderExists && (hasVisibleData || workOrder.created)) {
          saveWorkOrder$ = this.workOrdersService.create(payload);
        } else if (workOrderExists && (workOrder.updated || taxItemEntry?.updated)) {
          saveWorkOrder$ = this.workOrdersService.save(payload);
        }

        return saveWorkOrder$.pipe(
          map(({ id }) => ({ ...payload, id })),
          saveTags(this.tagEntitiesService, entitiesSnapshot?.workOrder.tagEntityInformations ?? [], TagEntityType.WorkOrder),
          map(newWorkOrder => WorkOrderActions.saveWorkOrderSuccess({ resource: newWorkOrder })),
          catchError(error => of(WorkOrderActions.saveWorkOrderFail({ error })))
        );
      })
    )
  );

  checkWorkOrderComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...Object.values(WorkOrderActions).filter(
          x =>
            x !== WorkOrderActions.saveWorkOrderFinishUpdate &&
            x !== WorkOrderActions.saveWorkOrderFinishUpdateSuccess &&
            x !== WorkOrderActions.saveWorkOrderFinishUpdateFail
        )
      ),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WorkOrderSelectors.cacheCorrelationId$)),
            this.store.pipe(select(WorkOrderSelectors.hasFinishedUpdate$)),
            this.store.pipe(select(WorkOrderSelectors.workOrder$))
          )
        )
      ),
      mergeMap(([, cacheCorrelationId, hasFinishedUpdate, workOrder]) =>
        iif(
          () => {
            const workOrderExists = workOrder?.id && workOrder.id > 0;
            return !!workOrderExists && !!cacheCorrelationId && hasFinishedUpdate;
          },
          of(WorkOrderActions.saveWorkOrderFinishUpdate()),
          EMPTY
        )
      )
    )
  );

  saveWorkOrderComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveWorkOrderFinishUpdate),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrder$))))),
      mergeMap(([, workOrder]) =>
        this.workOrdersService.finishUpdate(workOrder.id).pipe(
          map(() => WorkOrderActions.saveWorkOrderFinishUpdateSuccess()),
          catchError(error => of(WorkOrderActions.saveWorkOrderFinishUpdateFail({ error })))
        )
      )
    )
  );

  saveWorkOrderSuccess1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveWorkOrderSuccess),
      map(_ => WorkOrderActions.saveSalesProposal())
    )
  );

  saveWorkOrderSuccess2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveWorkOrderSuccess),
      map(_ => WorkOrderActions.saveSalesProposalPackage())
    )
  );

  saveWorkOrderSuccess3$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveWorkOrderSuccess,
      () => select(WorkOrderSelectors.workOrderDiscounts$),
      WorkOrderActions.saveDiscountDelete,
      WorkOrderActions.saveDiscountSave,
      false
    )
  );

  saveWorkOrderSuccess4$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveWorkOrderSuccess,
      () => select(WorkOrderSelectors.workOrderRebates$),
      WorkOrderActions.saveRebateDelete,
      WorkOrderActions.saveRebateSave,
      false
    )
  );

  saveWorkOrderSuccess5$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveWorkOrderSuccess,
      () => select(WorkOrderSelectors.workOrderPayments$),
      WorkOrderActions.savePaymentDelete,
      WorkOrderActions.savePaymentSave,
      false
    )
  );

  saveWorkOrderSuccess6$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveWorkOrderSuccess,
      () => select(WorkOrderSelectors.workOrderLoanApplications$),
      WorkOrderActions.saveLoanApplicationDelete,
      WorkOrderActions.saveLoanApplicationSave,
      false
    )
  );

  saveWorkOrderSuccess7$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveWorkOrderSuccess),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.siteSystems$))))),
      map(([, siteSystems]) => {
        // No site system updates
        if (Object.values(siteSystems).every(x => x.id === null || (!x.created && !x.updated && !x.deleted))) {
          return WorkOrderActions.saveAllSiteSystemsSuccess();
        }

        return WorkOrderActions.saveAllSiteSystems();
      })
    )
  );

  saveWorkOrderSuccess8$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveAllSiteSystems,
      () => select(WorkOrderSelectors.siteSystems$),
      null,
      WorkOrderActions.saveSiteSystem,
      false
    )
  );

  saveWorkOrderSuccess9$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveWorkOrderSuccess,
      () => select(WorkOrderSelectors.debriefAnswers$),
      WorkOrderActions.saveDebriefAnswerDelete,
      WorkOrderActions.saveDebriefAnswerSave,
      false
    )
  );

  saveWorkOrderSuccess10$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveWorkOrderSuccess),
      map(_ => WorkOrderActions.saveCall())
    )
  );

  saveWorkOrderSuccess11$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveWorkOrderSuccess,
      () => select(WorkOrderSelectors.recommendations$),
      WorkOrderActions.saveRecommendationDelete,
      WorkOrderActions.saveRecommendationSave,
      true
    )
  );

  saveWorkOrderSuccess12$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveWorkOrderSuccess,
      () => select(WorkOrderSelectors.tagEntities$),
      WorkOrderActions.saveTagEntityDelete,
      WorkOrderActions.saveTagEntitySave,
      false
    )
  );

  saveAllSiteSystemsSuccess1$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveAllSiteSystemsSuccess,
      () => select(WorkOrderSelectors.workOrderDetails$),
      WorkOrderActions.saveDetailDelete,
      WorkOrderActions.saveDetailSave,
      true
    )
  );

  saveAllSiteSystemsSuccess2$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveAllSiteSystemsSuccess,
      () => select(WorkOrderSelectors.workOrderAgreements$),
      WorkOrderActions.saveWorkOrderAgreementDelete,
      WorkOrderActions.saveWorkOrderAgreementSave,
      false
    )
  );

  saveRecommendationSaveSuccess1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRecommendationSaveSuccess),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.recommendationPhotos$))))),
      mergeMap(([action, recommendationPhotos]) => {
        const values = Object.values(recommendationPhotos).filter(m => m.recommendationId === action.newId);

        const newActions: Action[] = [];
        newActions.push(
          ...values.filter(m => (m as Deletable).deleted && m.id > 0).map(m => WorkOrderActions.saveRecommendationPhotoDelete({ id: m.id }))
        );
        newActions.push(
          ...values
            .filter(m => !(m as Deletable).deleted && ((m.created && m.error !== 'The file is too large.') || (m as Updatable).updated))
            .map(m => WorkOrderActions.saveRecommendationPhotoSave({ id: m.id }))
        );

        const z = from(newActions);
        return z;
      })
    )
  );

  saveSiteSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveSiteSystem),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.siteSystem$(action.id)))))),
      mergeMap(([, siteSystem]) => {
        if (isMiscellaneousSiteSystem(siteSystem)) {
          return of(
            WorkOrderActions.saveSiteSystemSuccess({
              oldId: siteSystem.id,
              newId: siteSystem.id,
            })
          );
        }

        return this.siteSystemsService.save(siteSystem).pipe(
          map(newResource =>
            WorkOrderActions.saveSiteSystemSuccess({
              oldId: siteSystem.id,
              newId: newResource.id,
            })
          ),
          catchError(error => of(WorkOrderActions.saveSiteSystemFail({ error })))
        );
      })
    )
  );

  allSiteSystemsSaved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveSiteSystemSuccess, WorkOrderActions.saveSiteSystemFail),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.siteSystems$))))),
      map(([, siteSystems]) => {
        if (Object.values(siteSystems).every(x => !x.created && !x.updated && !x.deleted)) {
          return WorkOrderActions.saveAllSiteSystemsSuccess();
        }

        return WorkOrderActions.noop();
      })
    )
  );

  saveCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveCall),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.call$))))),
      mergeMap(([, call]) => {
        if (call?.updated) {
          return this.callsService.patch(call.id, call).pipe(
            concatMap(() => this.eventsService.patch(call.event.id, call.event)),
            map(() => WorkOrderActions.saveCallSuccess({ resource: call })),
            catchError(error => of(WorkOrderActions.saveCallFail({ error })))
          );
        }

        return of(WorkOrderActions.saveCallNoop());
      })
    )
  );

  saveSalesProposal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveSalesProposal),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.salesProposal$))))),
      mergeMap(([, salesProposal]) => {
        if (salesProposal?.updated) {
          return this.salesProposalsService.save(salesProposal).pipe(
            map(newSalesProposal =>
              WorkOrderActions.saveSalesProposalSuccess({
                resource: newSalesProposal,
              })
            ),
            catchError(error => of(WorkOrderActions.saveSalesProposalFail({ error })))
          );
        }

        return of(WorkOrderActions.saveSalesProposalNoop());
      })
    )
  );

  saveSalesProposalPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveSalesProposalPackage),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.salesProposalPackage$))))),
      mergeMap(([, salesProposalPackage]) => {
        if (salesProposalPackage?.updated) {
          return this.salesProposalsService.savePackage(salesProposalPackage.salesProposalId, salesProposalPackage).pipe(
            map(newSsalesProposalPackage =>
              WorkOrderActions.saveSalesProposalPackageSuccess({
                resource: newSsalesProposalPackage,
              })
            ),
            catchError(error => of(WorkOrderActions.saveSalesProposalPackageFail({ error })))
          );
        }

        return of(WorkOrderActions.saveSalesProposalPackageNoop());
      })
    )
  );

  saveDiscountDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveDiscountDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderDiscountsithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) =>
        this.workOrdersService.hideDiscount(workOrderId, resource.id).pipe(
          map(() => WorkOrderActions.saveDiscountDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.saveDiscountFail({ error })))
        )
      )
    )
  );

  saveDiscountSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveDiscountSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderDiscountsithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) =>
        this.workOrdersService.saveDiscount(workOrderId, resource).pipe(
          map(newResource =>
            WorkOrderActions.saveDiscountSaveSuccess({
              oldId: resource.id,
              newId: newResource.id,
            })
          ),
          catchError(error => of(WorkOrderActions.saveDiscountFail({ error })))
        )
      )
    )
  );

  saveRebateDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRebateDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderRebateWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) =>
        this.workOrdersService.hideRebate(workOrderId, resource.id).pipe(
          map(() => WorkOrderActions.saveRebateDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.saveRebateFail({ error })))
        )
      )
    )
  );

  saveRebateSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRebateSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderRebateWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) =>
        this.workOrdersService.saveRebate(workOrderId, resource).pipe(
          map(newResource =>
            WorkOrderActions.saveRebateSaveSuccess({
              oldId: resource.id,
              newId: newResource.id,
            })
          ),
          catchError(error => of(WorkOrderActions.saveRebateFail({ error })))
        )
      )
    )
  );

  savePaymentDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.savePaymentDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderPaymentWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) =>
        this.workOrdersService.hidePayment(workOrderId, resource.id).pipe(
          map(() => WorkOrderActions.savePaymentDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.savePaymentFail({ error })))
        )
      )
    )
  );

  savePaymentSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.savePaymentSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderPaymentWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) => {
        const payment = { ...resource };
        return this.workOrdersService.savePayment(workOrderId, payment).pipe(
          map(newResource =>
            WorkOrderActions.savePaymentSaveSuccess({
              oldId: resource.id,
              newId: newResource.id,
            })
          ),
          catchError(error => of(WorkOrderActions.savePaymentFail({ error })))
        );
      })
    )
  );

  saveRecommendationDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRecommendationDelete),
      mergeMap(action =>
        this.recommendationsService.hide(action.id).pipe(
          map(() => WorkOrderActions.saveRecommendationDeleteSuccess({ resource: action })),
          catchError(error => of(WorkOrderActions.saveRecommendationFail({ error })))
        )
      )
    )
  );

  saveRecommendationSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRecommendationSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.recommendationWithParentId$(action.id)))))
      ),
      mergeMap(([, { resource }]) =>
        (resource.updated || resource.created ? this.recommendationsService.save(resource) : of(resource)).pipe(
          map(newResource =>
            WorkOrderActions.saveRecommendationSaveSuccess({
              oldId: resource.id,
              newId: newResource.id,
            })
          ),
          catchError(error => of(WorkOrderActions.saveRecommendationFail({ error })))
        )
      )
    )
  );

  saveRecommendationPhotoDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRecommendationPhotoDelete),
      mergeMap(action =>
        this.recommendationPhotosService.hide(action.id).pipe(
          map(() => WorkOrderActions.saveRecommendationPhotoDeleteSuccess({ resource: action })),
          catchError(error => of(WorkOrderActions.saveRecommendationPhotoFail({ id: action.id, error })))
        )
      )
    )
  );

  saveRecommendationPhotoSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRecommendationPhotoSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.recommendationPhotoWithParentId$(action.id)))))
      ),
      mergeMap(([, { recommendationId, resource }]) => {
        if (this.isTempFile(resource)) {
          return this.azureStorageService
            .validateFile(resource.guid, {
              blobContainer: BlobContainer.WorkOrderPhoto,
            })
            .pipe(
              concatMap(fileInformation => {
                if (typeof recommendationId === 'undefined') {
                  throw new DeveloperError();
                }

                return this.recommendationPhotosService.save({
                  id: resource.id,
                  photo: null,
                  recommendationId,
                  fileId: fileInformation.id,
                  text: resource.text,
                });
              }),
              map(newResource =>
                WorkOrderActions.saveRecommendationPhotoSaveSuccess({
                  oldId: resource.id,
                  newId: newResource.id,
                })
              ),
              catchError(httpErrorHandler(error => of(WorkOrderActions.saveRecommendationPhotoFail({ id: resource.id, error }))))
            );
        } else {
          return this.recommendationPhotosService.save(resource).pipe(
            map(newResource =>
              WorkOrderActions.saveRecommendationPhotoSaveSuccess({
                oldId: resource.id,
                newId: newResource.id,
              })
            ),
            catchError(httpErrorHandler(error => of(WorkOrderActions.saveRecommendationPhotoFail({ id: resource.id, error }))))
          );
        }
      })
    )
  );

  saveDebriefAnswerDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveDebriefAnswerDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderDebriefAnswerWithParentId$(action.id)))))
      ),
      mergeMap(([, { resource }]) =>
        this.debriefAnswersService.delete(resource.id).pipe(
          map(() => WorkOrderActions.saveDebriefAnswerDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.saveDebriefAnswerFail({ error })))
        )
      )
    )
  );

  saveDebriefAnswerSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveDebriefAnswerSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderDebriefAnswerWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) => {
        resource.workOrderId = workOrderId;
        return this.debriefAnswersService.save(resource).pipe(
          map(newResource =>
            WorkOrderActions.saveDebriefAnswerSaveSuccess({
              oldId: resource.id,
              newId: newResource.id,
            })
          ),
          catchError(error => of(WorkOrderActions.saveDebriefAnswerFail({ error })))
        );
      })
    )
  );

  saveTagEntityDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveTagEntityDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderTagEntityWithParentId$(action.id)))))
      ),
      mergeMap(([, { resource }]) =>
        this.tagEntitiesService.delete(resource.id).pipe(
          map(() => WorkOrderActions.saveTagEntityDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.saveTagEntityFail({ error })))
        )
      )
    )
  );

  saveTagEntitySave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveTagEntitySave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderTagEntityWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) => {
        resource.entityId = workOrderId;
        resource.entityType = TagEntityType.WorkOrder;

        return this.tagEntitiesService.save(resource).pipe(
          map(newResource =>
            WorkOrderActions.saveTagEntitySaveSuccess({
              oldId: resource.id,
              newId: newResource.id,
            })
          ),
          catchError(error => of(WorkOrderActions.saveTagEntityFail({ error })))
        );
      })
    )
  );

  saveDetailDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveDetailDelete),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderDetailWithParentId$({ workOrderDetailId: action.id }))))
        )
      ),
      mergeMap(([, { workOrderId, resource }]) =>
        this.workOrdersService.hideDetail(workOrderId, resource.id).pipe(
          map(() => WorkOrderActions.saveDetailDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.saveDetailFail({ error })))
        )
      )
    )
  );

  saveDetailSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveDetailSave),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderDetailWithParentId$({ workOrderDetailId: action.id }))))
        )
      ),
      mergeMap(([, { workOrderId, resource, nonStockItem }]) => {
        return (
          nonStockItem && !nonStockItem.deleted && (nonStockItem.created || nonStockItem.updated)
            ? this.workOrdersService.saveNonStockItem(workOrderId, nonStockItem).pipe(map(({ id }) => ({ ...nonStockItem, id })))
            : of(nonStockItem)
        ).pipe(
          concatMap(nonStockItem => {
            const workOrderDetail: typeof resource = {
              ...resource,
              nonStockItemId: nonStockItem?.id ?? null,
              siteSystemId: resource.siteSystemId === 0 ? null : resource.siteSystemId,
            };

            return workOrderDetail.updated || workOrderDetail.created
              ? this.workOrdersService.saveDetail(workOrderId, workOrderDetail).pipe(map(x => ({ ...workOrderDetail, ...x })))
              : of(workOrderDetail);
          }),
          map(workOrderDetail =>
            WorkOrderActions.saveDetailSaveSuccess({
              oldId: resource.id,
              oldNonStockItemId: nonStockItem?.id ?? null,
              workOrderDetail,
            })
          ),
          catchError(error => of(WorkOrderActions.saveDetailFail({ error })))
        );
      })
    )
  );

  saveDetailSaveSuccess$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      WorkOrderActions.saveDetailSaveSuccess,
      action => select(WorkOrderSelectors.workOrderRepairPartsByDetail$({ workOrderDetailId: action.workOrderDetail.id })),
      WorkOrderActions.saveRepairPartDelete,
      WorkOrderActions.saveRepairPartSave,
      false
    )
  );

  saveRepairPartDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRepairPartDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderRepairPartWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, detailId, resource }]) =>
        this.workOrdersService.hideRepairPart(workOrderId, detailId, resource.id).pipe(
          map(() => WorkOrderActions.saveRepairPartDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.saveRepairPartFail({ error })))
        )
      )
    )
  );

  saveRepairPartSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveRepairPartSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderRepairPartWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, detailId, resource }]) =>
        this.workOrdersService.saveRepairPart(workOrderId, detailId, resource).pipe(
          map(newResource =>
            WorkOrderActions.saveRepairPartSaveSuccess({
              oldId: resource.id,
              newId: newResource.id,
            })
          ),
          catchError(error => of(WorkOrderActions.saveRepairPartFail({ error })))
        )
      )
    )
  );

  saveLoanApplicationDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveLoanApplicationDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderLoanApplicationWithParentId$(action.id)))))
      ),
      mergeMap(([, { resource }]) =>
        this.loansService.hideWorkOrderLoanApplication(resource.id).pipe(
          map(() => WorkOrderActions.saveLoanApplicationDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.saveLoanApplicationFail({ error })))
        )
      )
    )
  );

  saveLoanApplicationSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveLoanApplicationSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderLoanApplicationWithParentId$(action.id)))))
      ),
      map(() => WorkOrderActions.saveLoanApplicationSaveSuccess({ oldId: 0, newId: 0 })) // Not supported yet.
    )
  );

  saveWorkOrderAgreementDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveWorkOrderAgreementDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderAgreementByIdWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) =>
        this.workOrdersService.deleteAgreement(workOrderId, resource.id).pipe(
          map(() => WorkOrderActions.saveWorkOrderAgreementDeleteSuccess({ resource })),
          catchError(error => of(WorkOrderActions.saveWorkOrderAgreementFail({ error })))
        )
      )
    )
  );

  saveWorkOrderAgreementSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.saveWorkOrderAgreementSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(WorkOrderSelectors.workOrderAgreementByIdWithParentId$(action.id)))))
      ),
      mergeMap(([, { workOrderId, resource }]) =>
        this.workOrdersService.saveAgreement(workOrderId, resource).pipe(
          map(newResource =>
            WorkOrderActions.saveWorkOrderAgreementSaveSuccess({
              oldId: resource.id,
              newId: newResource.id,
              agreementSiteSystem: newResource.agreementSiteSystem,
            })
          ),
          catchError(error => of(WorkOrderActions.saveWorkOrderAgreementFail({ error })))
        )
      )
    )
  );

  private isTempFile(obj: (RecommendationPhotoInformation & Mutable) | (TempFile & Creatable)): obj is TempFile {
    return !!(obj as TempFile)?.guid;
  }

  private triggerDeleteAndSaveActions<T extends string, P extends object>(
    action: ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & TypedAction<T>>,
    selector: (action: TypedAction<T> & P) => (source$: Observable<any>) => Observable<Record<number, { id: Id } & Mutable>>,
    deleteAction: ActionCreator<string, (props: { id: Id }) => { id: Id } & Action> | null,
    createOrUpdateAction: ActionCreator<string, (props: { id: Id }) => { id: Id } & Action>,
    triggerAnyway: boolean
  ): Observable<Action> {
    return this.actions$.pipe(
      ofType(action),
      concatMap(act => of(act).pipe(withLatestFrom(this.store.pipe(selector(act))))),
      mergeMap(([, resourcesByIds]) => {
        const newActions: Action[] = [];
        const values = Object.values(resourcesByIds);

        if (deleteAction !== null) {
          newActions.push(...values.filter(m => m.deleted && m.id > 0).map(m => deleteAction({ id: m.id })));
        }

        newActions.push(
          ...values.filter(m => !m.deleted && (m.created || m.updated || triggerAnyway)).map(m => createOrUpdateAction({ id: m.id }))
        );

        const z = from(newActions);
        return z;
      })
    );
  }
}
