import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type SalesProposalSettings, type SettingSalesProposalsViewData } from '@models/cards/setting-view-data';
import {
  type Filter,
  type Filter2,
  type PagedResult,
  type SupportedColumns,
  type SupportedFilters,
  type TableQueryResult,
  type TableSegment,
} from '@models/filter-models';
import { type supportedSalesProposalColumns, type supportedSalesProposalFilterTypes } from '@models/filter-sales-proposal-models';
import {
  type PackageSelectionOption,
  type SalesProposalBaseViewData,
  type SalesProposalInformation,
  type SalesProposalInformationForList,
  type SalesProposalLoanApplicationInformation,
  type SalesProposalPackageDetailInformation,
  type SalesProposalPackageDiscountInformation,
  type SalesProposalPackageInformation,
  type SalesProposalPackageRebateInformation,
  type SalesProposalPaymentInformation,
  type SalesProposalViewData,
} from '@models/sales-proposal-models';
import { HttpClientService } from '@services/http-client.service';
import { type ExportToFileResponse } from '@services/pager.service';
import { UrlService } from '@services/url.service';
import { allowRetries, skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';

export type PossibleFilter = SupportedFilters<typeof supportedSalesProposalFilterTypes>;
export type PossibleColumns = SupportedColumns<typeof supportedSalesProposalColumns>;

@Injectable()
export class SalesProposalsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getBaseViewData(): Observable<SalesProposalBaseViewData> {
    return this.http.get<SalesProposalBaseViewData>(this.url.salesProposalCreateViewData);
  }

  list(filter: Filter<any>): Observable<PagedResult<SalesProposalInformationForList>> {
    return this.http.post<PagedResult<SalesProposalInformationForList>>(this.url.salesProposalsList, filter, allowRetries());
  }

  tableList(filter: Filter2<PossibleFilter, PossibleColumns>): Observable<TableQueryResult<SalesProposalInformationForList>> {
    return this.http.post<TableQueryResult<SalesProposalInformationForList>>(this.url.salesProposalsTableList, filter, allowRetries());
  }

  exportToFile(filter: Filter2<PossibleFilter, PossibleColumns>): Observable<ExportToFileResponse> {
    return this.http.post<ExportToFileResponse>(this.url.salesProposalsExport, filter);
  }

  getTableSegments(): Observable<TableSegment<PossibleFilter, PossibleColumns>[]> {
    return this.http.get<TableSegment<PossibleFilter, PossibleColumns>[]>(this.url.salesProposalsGetTableSegments);
  }

  saveTableSegment(tableSegment: TableSegment<PossibleFilter, PossibleColumns>): Observable<Id> {
    return this.http.post<Id>(this.url.salesProposalsSaveTableSegment, tableSegment, allowRetries());
  }

  deleteTableSegment(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.customerDeleteTableSegment.replace('$0', id.toString()));
  }

  getSettings(): Observable<SettingSalesProposalsViewData> {
    return this.http.get<SettingSalesProposalsViewData>(this.url.settingsGetSalesProposalsSettings);
  }

  getTemplateList(): Observable<SalesProposalInformation[]> {
    return this.http.post<SalesProposalInformation[]>(this.url.salesProposalsTemplateList, null, allowRetries());
  }

  get(id: Id): Observable<SalesProposalInformation> {
    return this.http.get<SalesProposalInformation>(this.url.salesProposalsGet.replace('$0', id.toString()));
  }

  getViewData(id: Id): Observable<SalesProposalViewData> {
    return this.http.get<SalesProposalViewData>(this.url.salesProposalsGetViewData.replace('$0', id.toString()));
  }

  save(salesProposalInformation: SalesProposalInformation): Observable<{ id: Id }> {
    return this._saveProposal(salesProposalInformation, this.url.salesProposalSave);
  }

  private _saveProposal(salesProposalInformation: SalesProposalInformation, url: string): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(salesProposalInformation, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(url, obj);
  }

  saveSettings(settings: SalesProposalSettings): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveSalesProposalsSettings, { ...settings }, skipBubbleError());
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.salesProposalDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.salesProposalRestore.replace('$0', id.toString()), null);
  }

  getLoanApplications(salesProposalId: Id): Observable<SalesProposalLoanApplicationInformation[]> {
    return this.http.get<SalesProposalLoanApplicationInformation[]>(
      this.url.salesProposalGetLoanApplications.replace('$0', salesProposalId.toString())
    );
  }

  saveLoanApplication(
    salesProposalId: Id,
    salesProposalLoanApplicationInformation: SalesProposalLoanApplicationInformation
  ): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(
      this.url.salesProposalSaveLoanApplications.replace('$0', salesProposalId.toString()),
      salesProposalLoanApplicationInformation
    );
  }

  hideLoanApplication(salesProposalId: Id, loanApplicationId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.salesProposalDeleteLoanApplications.replace('$0', salesProposalId.toString()).replace('$1', loanApplicationId.toString())
    );
  }

  getPackages(salesProposalId: Id): Observable<SalesProposalPackageInformation[]> {
    return this.http.get<SalesProposalPackageInformation[]>(this.url.salesProposalGetPackages.replace('$0', salesProposalId.toString()));
  }

  savePackage(salesProposalId: Id, salesProposalPackageInformation: SalesProposalPackageInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(
      this.url.salesProposalSavePackages.replace('$0', salesProposalId.toString()),
      salesProposalPackageInformation
    );
  }

  hidePackage(salesProposalId: Id, packageId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.salesProposalDeletePackages.replace('$0', salesProposalId.toString()).replace('$1', packageId.toString())
    );
  }

  getPackageDetails(salesProposalId: Id, salesProposalPackageId: Id): Observable<SalesProposalPackageDetailInformation[]> {
    return this.http.get<SalesProposalPackageDetailInformation[]>(
      this.url.salesProposalGetPackageDetails.replace('$0', salesProposalId.toString()).replace('$1', salesProposalPackageId.toString())
    );
  }

  savePackageDetail(
    salesProposalId: Id,
    salesProposalPackageId: Id,
    salesProposalPackageDetailInformation: SalesProposalPackageDetailInformation
  ): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(
      this.url.salesProposalSavePackageDetails.replace('$0', salesProposalId.toString()).replace('$1', salesProposalPackageId.toString()),
      salesProposalPackageDetailInformation
    );
  }

  hidePackageDetail(salesProposalId: Id, packageId: Id, detailId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.salesProposalDeletePackageDetails
        .replace('$0', salesProposalId.toString())
        .replace('$1', packageId.toString())
        .replace('$2', detailId.toString())
    );
  }

  getPackageDiscounts(salesProposalId: Id, packageId: Id): Observable<SalesProposalPackageDiscountInformation[]> {
    return this.http.get<SalesProposalPackageDiscountInformation[]>(
      this.url.salesProposalGetPackageDiscounts.replace('$0', salesProposalId.toString()).replace('$1', packageId.toString())
    );
  }

  savePackageDiscount(
    salesProposalId: Id,
    packageId: Id,
    salesProposalPackageDiscountInformation: SalesProposalPackageDiscountInformation
  ): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(
      this.url.salesProposalSaveDiscounts.replace('$0', salesProposalId.toString()).replace('$1', packageId.toString()),
      salesProposalPackageDiscountInformation,
      skipBubbleError()
    );
  }

  hidePackageDiscount(salesProposalId: Id, packageId: Id, discountId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.salesProposalDeletePackageDiscounts
        .replace('$0', salesProposalId.toString())
        .replace('$1', packageId.toString())
        .replace('$2', discountId.toString()),
      skipBubbleError()
    );
  }

  getPackageRebates(salesProposalId: Id, packageId: Id): Observable<SalesProposalPackageRebateInformation[]> {
    return this.http.get<SalesProposalPackageRebateInformation[]>(
      this.url.salesProposalGetPackageRebates.replace('$0', salesProposalId.toString()).replace('$1', packageId.toString())
    );
  }

  savePackageRebate(
    salesProposalId: Id,
    packageId: Id,
    salesProposalPackageRebateInformation: SalesProposalPackageRebateInformation
  ): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(
      this.url.salesProposalSavePackageRebates.replace('$0', salesProposalId.toString()).replace('$1', packageId.toString()),
      salesProposalPackageRebateInformation,
      skipBubbleError()
    );
  }

  hidePackageRebate(salesProposalId: Id, packageId: Id, rebateId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.salesProposalDeletePackageRebates
        .replace('$0', salesProposalId.toString())
        .replace('$1', packageId.toString())
        .replace('$2', rebateId.toString()),
      skipBubbleError()
    );
  }

  getPayments(salesProposalId: Id): Observable<SalesProposalPaymentInformation[]> {
    return this.http.get<SalesProposalPaymentInformation[]>(this.url.salesProposalGetPayments.replace('$0', salesProposalId.toString()));
  }

  savePayment(salesProposalId: Id, salesProposalPayment: SalesProposalPaymentInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.salesProposalSavePayments.replace('$0', salesProposalId.toString()), salesProposalPayment);
  }

  hidePayment(salesProposalId: Id, paymentId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.salesProposalDeletePayments.replace('$0', salesProposalId.toString()).replace('$1', paymentId.toString())
    );
  }

  // Returns the new work order id.
  selectPackage(salesProposalId: Id, salesProposalPackageId: Id, options: PackageSelectionOption): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(
      this.url.salesProposalSelectPackage.replace('$0', salesProposalId.toString()).replace('$1', salesProposalPackageId.toString()),
      options,
      skipBubbleError()
    );
  }

  preview(salesProposalId: Id): Observable<string> {
    return this.http.post<string>(this.url.salesProposalPreview.replace('$0', salesProposalId.toString()), null, allowRetries());
  }

  addFile(id: Id, fileId: Id): Observable<void> {
    return this.http.post<void>(this.url.salesProposalAddFile.replace('$0', id.toString()).replace('$1', fileId.toString()), null);
  }

  deleteFile(id: Id, fileId: Id): Observable<void> {
    return this.http.delete<void>(this.url.salesProposalDeleteFile.replace('$0', id.toString()).replace('$1', fileId.toString()));
  }

  deleteFiles(id: Id, fileIds: Id[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: fileIds,
    };

    return this.http.delete<void>(this.url.salesProposalDeleteFiles.replace('$0', id.toString()), options);
  }
}
