<wm-rw [name]="name()" [formControl]="control" [mode]="mode()">
  @if (helperTemplate2(); as helperTemplate2) {
    <ng-template #helperTemplate let-control>
      <ng-container [ngTemplateOutlet]="helperTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    </ng-template>
  }

  <ng-template #writeTemplate let-control>
    @if (writeTemplate2(); as writeTemplate2) {
      <ng-container [ngTemplateOutlet]="writeTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    } @else {
      @if (hasImage()) {
        <wm-input-image
          [formControl]="control"
          [showTextInItem]="true"
          [options]="options()"
          [required]="required()"
          [dropdownParent]="dropdownParent()"
          [changeEmptyTo]="changeEmptyTo()"
          [clearable]="clearable()"
          [bindValue]="bindValue()"
          [bindLabel]="bindLabel()"
          [imageCenter]="imageCenter()"
        />
      } @else {
        <wm-input-dropdown
          [formControl]="control"
          [options]="options()"
          [required]="required()"
          [dropdownParent]="dropdownParent()"
          [changeEmptyTo]="changeEmptyTo()"
          [clearable]="clearable()"
          [bindValue]="bindValue()"
          [bindLabel]="bindLabel()"
          [searchFn]="searchFn()"
          [placeholder]="placeholder()"
          [optgroups]="optgroups()"
          [optgroupField]="optgroupField()"
        />
      }
    }
  </ng-template>
  <ng-template #readTemplate let-control>
    @if (readTemplate2(); as readTemplate2) {
      <ng-container [ngTemplateOutlet]="readTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    } @else {
      @if (selectedOption(); as option) {
        <span class="template">
          @if (getImage(option); as image) {
            <img src="{{ image }}" alt="" />
          }
          <span>{{ option.text }}</span>
        </span>
      }
    }
  </ng-template>
</wm-rw>

