import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class SquareService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getLocationId(contractorId?: Id): Observable<string> {
    return this.http.get<string>(this.url.squareGetLocationId.replace('$0', contractorId?.toString() ?? ''));
  }
}
