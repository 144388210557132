import { Injectable, inject } from '@angular/core';
import { type DebriefAnswerInformation } from '@models/debrief-models';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class DebriefAnswersService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get(id: Id): Observable<void> {
    return this.http.get<void>(this.url.debriefAnswerGet.replace('$0', id.toString()));
  }

  save(debriefAnswer: DebriefAnswerInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.debriefAnswerPost, debriefAnswer, skipBubbleError());
  }

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.debriefAnswerDelete.replace('$0', id.toString()));
  }
}
