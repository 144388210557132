<div>
  @if (labelTemplate2(); as labelTemplate2) {
    <ng-container *ngIf="labelTemplate2" [ngTemplateOutlet]="labelTemplate2" />
  } @else {
    <label *ngIf="name">{{ name() }}</label>
  }

  @if (helperTemplate(); as helperTemplate) {
    <div class="helper">
      <ng-container [ngTemplateOutlet]="helperTemplate" [ngTemplateOutletContext]="{ $implicit: control }"> </ng-container>
    </div>
  }

  <div class="rw-container" [@rwContainer]="{ value: modeStr(), params: { expanded_size: expandedSize() } }">
    <div
      class="write"
      *ngIf="mode() === Mode.Write"
      [@wContainer]="modeStr()"
      [class.ready]="isReady"
      (@wContainer.start)="isReady = false"
      (@wContainer.done)="isReady = true"
    >
      <ng-container [ngTemplateOutlet]="writeTemplate()" [ngTemplateOutletContext]="{ $implicit: control }"> </ng-container>
    </div>
    <div class="read" *ngIf="mode() === Mode.Read" [@rContainer]="modeStr()">
      <ng-container [ngTemplateOutlet]="readTemplate()" [ngTemplateOutletContext]="{ $implicit: control }"> </ng-container>
    </div>
  </div>
</div>
