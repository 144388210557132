<div class="wm-card2">
  <img class="clock" src="/assets/images/time-picker-clock.svg" alt="" />
  <div class="timepicker" #timepicker>
    <div class="hour">
      <button type="button" (click)="changeHour(1)" class="plus" title="Next Hour"></button>
      <input type="number" [ngModel]="hour()" (ngModelChange)="hour.set($event)" (blur)="hourChange(hour())" />
      <button type="button" (click)="changeHour(-1)" class="minus" title="Previous Hour"></button>
    </div>
    <div class="separator">:</div>
    <div class="minute">
      <button type="button" (click)="changeMinute(15)" class="plus" title="Next Minute"></button>
      <input type="number" [ngModel]="minute()" (ngModelChange)="minute.set($event)" (blur)="minuteChange(minute())" />
      <button type="button" (click)="changeMinute(-15)" class="minus" title="Previous Minute"></button>
    </div>
    <div class="ampm">
      <button type="button" (click)="changeAmPm()" class="plus" title="Next"></button>
      <input type="text" [ngModel]="ampm()" (ngModelChange)="ampm.set($event)" (blur)="ampmChange(ampm())" />
      <button type="button" (click)="changeAmPm()" class="minus" title="Previous"></button>
    </div>
  </div>
</div>
