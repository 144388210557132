import { DatePipe } from '@angular/common';
import { inject, type Type } from '@angular/core';
import { FilterDynamicDropdownComponent } from '@controls/filter-dynamic/filter-dynamic-dropdown.component';
import { FilterDynamicMagicDateComponent } from '@controls/filter-dynamic/filter-dynamic-magicdate.component';
import { FilterDynamicTextComponent } from '@controls/filter-dynamic/filter-dynamic-text.component';
import { TableDynamicNodeDeletedComponent } from '@controls/table-dynamic/table-dynamic-node-deleted.component';
import { TableDynamicNodeLinkComponent } from '@controls/table-dynamic/table-dynamic-node-link.component';
import { TableDynamicNodeTextComponent } from '@controls/table-dynamic/table-dynamic-node-text.component';
import { EntityPipe } from '@pipes/entity.pipe';
import { StaticDataService } from '@services/static-data.service';
import { notEmpty } from '@utility/array';
import { firstValueFrom } from 'rxjs';
import {
  combineData,
  getReversedFilterConfig,
  LogicalOperator,
  magicDateTransform,
  type FancyFilterConfig,
  type FancyFilterImpl,
  type FancyFilterMagicDateRange,
  type LogicalOperatorData,
  type TableColumnDefinition,
} from './filter-models';
import { type RequiredTextExtraResource1 } from './resource';
import { getSalesProposalStatus, SalesProposalStatus, type SalesProposalInformationForList } from './sales-proposal-models';
import { type TechnicianInformation } from './technician';
import { FilterTag } from './filters/filterTag';
import { TagEntityType } from './tag-models';

export enum FilterSalesProposalDeletedData {
  NotSet = 0,
  DeletedOnly = 1,
  IncludesDeleted = 2,
}

interface FilterSalesProposalData {
  date?: FancyFilterMagicDateRange;
  name?: { text: string };
  entityStatus: FilterSalesProposalDeletedData | null;
}

export class FilterSalesProposal implements FancyFilterImpl<FilterSalesProposalData, FilterSalesProposalDeletedData> {
  private readonly datePipe = inject(DatePipe);

  readonly code = 'SalesProposal:SalesProposal';

  getUIConfig(): FancyFilterConfig<FilterSalesProposalData, any>[] {
    const config1: FancyFilterConfig<FilterSalesProposalData, FilterSalesProposalDeletedData> = {
      get: controlValue => {
        return controlValue?.entityStatus ?? null;
      },
      set: (value, context) => {
        return combineData('entityStatus', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Status',
          code: this.code,
          name: `${this.code}-status`,
          options: [
            { id: 0, text: 'Active Sales Proposals Only' },
            { id: 1, text: 'Deleted Sales Proposals Only' },
            { id: 2, text: 'Includes Deleted Sales Proposals' },
          ],
        },
      },
    };

    const config2: FancyFilterConfig<FilterSalesProposalData, string> = {
      get: controlValue => {
        return controlValue?.name?.text ?? null;
      },
      set: (value, context) => {
        return combineData('name', value ? { text: value } : null, context);
      },
      ui: {
        type: FilterDynamicTextComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Name',
          code: this.code,
          name: `${this.code}-name`,
        },
      },
    };
    const config3: FancyFilterConfig<FilterSalesProposalData, FancyFilterMagicDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value?.from == null && value?.to == null ? null : value, context);
      },
      ui: {
        type: FilterDynamicMagicDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Created Date',
          code: this.code,
          name: `${this.code}-created`,
          fromLabel: 'After',
          toLabel: 'Before',
        },
      },
    };
    return [config1, config2, config3];
  }

  getUISummary(value: FilterSalesProposalData | null): string {
    let createdMoment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        createdMoment = `created between ${magicDateTransform(value.date, 'from', false, this.datePipe)} and ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      } else if (value?.date.from) {
        createdMoment = `created after ${magicDateTransform(value.date, 'from', false, this.datePipe)}`;
      } else if (value?.date.to) {
        createdMoment = `created before ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      }
    }

    let name: string | null = null;
    if (value?.name) {
      name = `name containing "${value.name.text}"`;
    }

    let entityStatus = '';
    switch (value?.entityStatus) {
      case FilterSalesProposalDeletedData.DeletedOnly:
        entityStatus = 'Sales Proposals are deleted';
        break;
      case FilterSalesProposalDeletedData.IncludesDeleted:
        entityStatus = 'Sales Proposals are active or deleted';
        break;
    }

    if (!entityStatus) {
      entityStatus = 'Sales Proposals are active';
    }

    return [entityStatus, name, createdMoment].filter(notEmpty).join(', ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Active/Deleted Sales Proposals',
    };
  }

  getIcon() {
    return { matIcon: 'attach_money' };
  }
}

interface FilterTechnicianData extends LogicalOperatorData {
  value: Id;
}

export class FilterSalesProposalTechnician implements FancyFilterImpl<FilterTechnicianData> {
  readonly staticDataService = inject(StaticDataService);

  readonly code = 'SalesProposal:Technician';

  private options: TechnicianInformation[] = [];

  async load(): Promise<void> {
    const technicians = await firstValueFrom(this.staticDataService.getTechnicians());
    this.options = technicians;
  }

  getUIConfig(): FancyFilterConfig<FilterTechnicianData, any>[] {
    const config1: FancyFilterConfig<FilterTechnicianData, Id> = {
      get: controlValue => {
        return controlValue?.value ?? null;
      },
      set: (value, context) => {
        return combineData('value', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: true,
        inputs: {
          code: this.code,
          name: `${this.code}`,
          clearable: true,
          options: this.options.map(option => {
            return {
              id: option.id,
              text: option.text,
              image: option.image,
            };
          }),
        },
      },
    };
    return [config1, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterTechnicianData | null): string {
    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';
    if (value?.value === -1) {
      return `Sales proposal has no technician`;
    } else if (value?.value) {
      return `Sales proposal technician is ${notWord}${this.options.find(m => m.id === value.value)?.text ?? ''}`;
    }

    return 'Sales proposal technician is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Technician',
    };
  }

  getIcon() {
    return { matIcon: 'person' };
  }
}

export class FilterSalesProposalTag extends FilterTag {
  constructor() {
    super('SalesProposal:Tag', [TagEntityType.SalesProposal, TagEntityType.Customer, TagEntityType.Site, TagEntityType.SiteSystem]);
  }
}

interface FilterSalesProposalStatusData extends LogicalOperatorData {
  value: SalesProposalStatus;
}

export class FilterSalesProposalStatus implements FancyFilterImpl<FilterSalesProposalStatusData> {
  readonly code = 'SalesProposal:Status';

  getUIConfig(): FancyFilterConfig<FilterSalesProposalStatusData, any>[] {
    const config1: FancyFilterConfig<FilterSalesProposalStatusData, SalesProposalStatus> = {
      get: controlValue => {
        return controlValue?.value ?? null;
      },
      set: (value, context) => {
        return combineData('value', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Status',
          code: this.code,
          name: `${this.code}`,
          options: [
            { id: SalesProposalStatus.Created, text: getSalesProposalStatus(SalesProposalStatus.Created) },
            { id: SalesProposalStatus.Ready, text: getSalesProposalStatus(SalesProposalStatus.Ready) },
            { id: SalesProposalStatus.Finalized, text: getSalesProposalStatus(SalesProposalStatus.Finalized) },
          ],
        },
      },
    };
    return [config1, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterSalesProposalStatusData | null): string {
    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    let entityStatus = '';
    switch (value?.value) {
      case SalesProposalStatus.Created:
        entityStatus = `Sales proposals that are ${notWord}created`;
        break;
      case SalesProposalStatus.Ready:
        entityStatus = `Sales proposals that are ${notWord}ready`;
        break;
      case SalesProposalStatus.Finalized:
        entityStatus = `Sales proposals that are ${notWord}finalized`;
        break;
    }

    if (!entityStatus) {
      entityStatus = 'Sales proposals with any status';
    }

    return entityStatus;
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Status',
    };
  }

  getIcon() {
    return { matIcon: 'check_circle' };
  }
}

export class ColumnSalesProposalNumber implements TableColumnDefinition<SalesProposalInformationForList> {
  readonly id = 'number';

  readonly text = 'Proposal №';

  readonly required = true;

  readonly order = Number.NEGATIVE_INFINITY;

  readonly classes = ['col-id', 'col-sales-proposal-number'];

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        routerLink: ['/salesproposals', item.id],
        text: `#${item.localId}`,
      },
    };
  }
}

export class ColumnSalesProposalName implements TableColumnDefinition<SalesProposalInformationForList> {
  private readonly entity = inject(EntityPipe);

  readonly id = 'name';

  readonly text = 'Name';

  readonly dataTestId = 'sales-proposal-name';

  readonly required = true;

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: item.text,
      },
    };
  }
}

export class ColumnSalesProposalLinkedName implements TableColumnDefinition<SalesProposalInformationForList> {
  private readonly entity = inject(EntityPipe);

  readonly id = 'name';

  readonly text = 'Name';

  readonly dataTestId = 'sales-proposal-name';

  readonly required = true;

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        text: item.text,
        routerLink: ['/settings/salesproposals', item.id],
      },
    };
  }
}

export class ColumnSalesProposalCompletion implements TableColumnDefinition<SalesProposalInformationForList> {
  readonly id = 'completion';

  readonly text = 'Completion';

  readonly classes = ['col-completion'];

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: getSalesProposalStatus(item.status),
      },
    };
  }
}

export class ColumnSalesProposalCustomer implements TableColumnDefinition<SalesProposalInformationForList> {
  private readonly entity = inject(EntityPipe);

  readonly id = 'customer';

  readonly text = 'Customer';

  readonly classes = ['col-customer', 'col-200'];

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        text: item.customerName,
        routerLink: ['/customers', item.customerId],
      },
    };
  }
}

export class ColumnSalesProposalSite implements TableColumnDefinition<SalesProposalInformationForList> {
  readonly id = 'site';

  readonly text = 'Site';

  readonly classes = ['col-site'];

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        text: item.siteName || 'No Address',
        routerLink: item.siteId ? ['/customers', item.customerId, 'sites', item.siteId] : [],
      },
    };
  }
}

export class ColumnSalesProposalCreatedDate implements TableColumnDefinition<SalesProposalInformationForList> {
  private readonly datePipe = inject(DatePipe);

  readonly id = 'createdDate';

  readonly text = 'Created Date';

  readonly dataTestId = 'created-date';

  readonly classes = ['col-date'];

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: this.datePipe.transform(item.createdDate, 'short'),
      },
    };
  }
}

export class ColumnSalesProposalUpdatedDate implements TableColumnDefinition<SalesProposalInformationForList> {
  private readonly datePipe = inject(DatePipe);

  readonly id = 'updatedDate';

  readonly text = 'Updated Date';

  readonly dataTestId = 'updated-date';

  readonly classes = ['col-date'];

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: this.datePipe.transform(item.updatedDate, 'short'),
      },
    };
  }
}

export class ColumnSalesProposalTechnician implements TableColumnDefinition<SalesProposalInformationForList> {
  readonly id = 'assignee';

  readonly text = 'Assignee';

  readonly classes = ['col-assignee'];

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: item.technicianName ? item.technicianName : 'Unassigned',
      },
    };
  }
}

export class ColumnSalesProposalDeleted implements TableColumnDefinition<SalesProposalInformationForList> {
  readonly id = 'deleted';

  readonly text = 'Status';

  readonly classes = ['col-status'];

  readonly required = true;

  readonly order = Number.POSITIVE_INFINITY;

  renderComponent(item: SalesProposalInformationForList) {
    return {
      component: TableDynamicNodeDeletedComponent,
      inputs: {
        show: item.hidden,
      },
    };
  }
}

export const supportedSalesProposalFilterTypes: readonly Type<FancyFilterImpl<any>>[] = [
  FilterSalesProposal,
  FilterSalesProposalTechnician,
  FilterSalesProposalStatus,
  FilterSalesProposalTag,
] as const;

export const supportedSalesProposalColumns: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnSalesProposalNumber,
  ColumnSalesProposalName,
  ColumnSalesProposalCompletion,
  ColumnSalesProposalCustomer,
  ColumnSalesProposalSite,
  ColumnSalesProposalCreatedDate,
  ColumnSalesProposalTechnician,
] as const;

export const supportedSalesProposalTemplateColumns: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnSalesProposalLinkedName,
  ColumnSalesProposalCreatedDate,
  ColumnSalesProposalUpdatedDate,
  ColumnSalesProposalTechnician,
] as const;

export const providers = [...supportedSalesProposalFilterTypes, ...supportedSalesProposalColumns, ...supportedSalesProposalTemplateColumns];

