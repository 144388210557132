interface Sortable {
  order?: number;
}

export interface SortableHidden extends Sortable {
  hidden?: boolean;
}

export function assignNewOrder<T extends SortableHidden>(array: T[]): { items: T[]; updatedItems: T[] } {
  let order = 0;
  const items = array.sort(sortFnc(false, 'order'));

  const updatedItems: T[] = [];

  for (const updatedEntry of items) {
    if (updatedEntry.hidden) {
      continue;
    }

    const previousOrder = updatedEntry.order;
    if (previousOrder !== order) {
      updatedItems.push({
        ...updatedEntry,
        order,
      });
    }

    order++;
  }

  return { items, updatedItems };
}

export function sortFnc<T>(reverse: boolean = false, orderKey: keyof T): (item1: T, item2: T) => -1 | 0 | 1 {
  function ret(val: number): -1 | 0 | 1 {
    return (reverse ? val * -1 : val) as -1 | 0 | 1;
  }

  function naturalSort(item1: string, item2: string): -1 | 0 | 1 {
    const a1 = typeof item1;
    const b1 = typeof item2;
    return a1 < b1 ? -1 : a1 > b1 ? 1 : item1 < item2 ? -1 : item1 > item2 ? 1 : 0;
  }

  function objectSorting(item1: T, item2: T): -1 | 0 | 1 {
    if (item1[orderKey] === item2[orderKey]) {
      return 0;
    }

    if (typeof item1[orderKey] === 'undefined') {
      return -1;
    }

    if (typeof item2[orderKey] === 'undefined') {
      return 1;
    }

    return item1[orderKey] < item2[orderKey] ? -1 : 1;
  }

  return (item1: Sortable | any, item2: Sortable | any): -1 | 0 | 1 => {
    return ret(typeof item1 === 'string' || typeof item2 === 'number' ? naturalSort(item1, item2) : objectSorting(item1, item2));
  };
}

// Based off https://github.com/Teun/thenBy.js
export interface ThenBy<T> {
  (a: T, b: T): number;

  thenBy: ((func: (a: T, b: T) => number, opt?: SortOrder | ThenByOption) => ThenBy<T>) &
    (<T, U>(select: (v: T) => U, opt?: SortOrder | ThenByOption) => ThenBy<T>) &
    (<T>(byPropertyName: keyof T, direction?: SortOrder | ThenByOption) => ThenBy<T>);
}

export interface ThenByOption {
  ignoreCase?: boolean;
  direction?: SortOrder;
}

function identity(v: any): any {
  return v;
}
function ignoreCase(v: string | any): string {
  return typeof v === 'string' ? v.toLowerCase() : v;
}
function makeCompareFunction<T>(
  f: ((a: T) => number) | ((a: T, b: T) => number) | keyof T,
  opt?: SortOrder | ThenByOption
): (a: T, b: T) => number {
  opt = typeof opt === 'number' ? { direction: opt } : opt || {};
  if (typeof f !== 'function') {
    const prop = f;
    // make unary function
    f = function (v1: T): any {
      return v1[prop] ? v1[prop] : '';
    };
  }

  if (f.length === 1) {
    // f is a unary function mapping a single item to its sort score
    const uf = f as (a: T) => number;
    const preprocess = opt.ignoreCase ? ignoreCase : identity;
    f = function (v1: T, v2: T): number {
      return preprocess(uf(v1)) < preprocess(uf(v2)) ? -1 : preprocess(uf(v1)) > preprocess(uf(v2)) ? 1 : 0;
    };
  }

  if (opt.direction === -1) {
    const uf = f as (a: T, b: T) => number;
    return function (v1: T, v2: T): number {
      return -uf(v1, v2);
    };
  }

  return f;
}

type SortOrder = -1 | 1;
function tb<T>(func: (a: T, b: T) => number, opt?: SortOrder | ThenByOption): ThenBy<T>;
function tb<T, U>(select: (a: T) => U, opt?: SortOrder | ThenByOption): ThenBy<T>;
function tb<T>(byPropertyName: keyof T, opt?: SortOrder | ThenByOption): ThenBy<T>;
function tb<T>(this: ((a: T, b: T) => number) | never, func: any, opt?: SortOrder | ThenByOption): ThenBy<T> {
  const x = typeof this === 'function' ? this : false; // This is for the first call
  const y = makeCompareFunction(func, opt);
  const fnc = x
    ? function (a: T, b: T) {
        return x(a, b) || y(a, b);
      }
    : y;

  const f = fnc as ThenBy<T>; // Until we assign thenBy
  f.thenBy = tb;
  return f;
}

export function normalOrder(): ThenBy<{ id: any }> {
  return tb('order').thenBy('id');
}

export function naturalOrder(): ThenBy<{ id: any }> {
  return tb('order').thenBy('text').thenBy('id');
}

export { tb as firstBy };
