import { type Action, type ActionCreator } from '@ngrx/store';
import { type ActionReducer, type Creator, type FunctionWithParametersType, type TypedAction } from '@ngrx/store/src/models';
import { newGuid } from '@utility/string';

export interface SaveState {
  _submitCounter?: number;
  _cacheCorrelationId?: string;
  _correlationId?: string;
  _error?: any;
}

export enum OperationType {
  SendingToServerInitial = 1,
  SendingToServer,
  ServerRespondsSuccess,
  ServerRespondsFail,
  ServerFinished,
}

export interface SaveAction<T extends string> extends TypedAction<T> {
  readonly operationType: OperationType;
  readonly correlationId?: string;
  readonly error?: any;
  readonly storeCode: string;
}

function defineType(type: string, creator: Creator): Creator {
  return Object.defineProperty(creator, 'type', {
    value: type,
    writable: false,
  });
}

function createWithOperationType<T extends string, C extends Creator>(
  obj: { operationType: OperationType; storeCode: string; correlationId?: string },
  type: T,
  config?: { _as: 'props' } | C
) {
  if (typeof config === 'function') {
    return defineType(type, (...args: any[]) => ({
      ...config(...args),
      type,
      ...obj,
    }));
  }
  const as = config ? config._as : 'empty';
  switch (as) {
    case 'empty':
      return defineType(type, () => ({ type, ...obj }));
    case 'props':
      return defineType(type, (props: object) => ({
        ...props,
        type,
        ...obj,
      }));
    default:
      throw new Error('Unexpected config.');
  }
}

export function createSubmitInitialAction<T extends string>(
  storeCode: string,
  type: T
): ActionCreator<T, () => SaveAction<T> & { operationType: OperationType.SendingToServerInitial }>;
export function createSubmitInitialAction<T extends string, P extends object>(
  storeCode: string,
  type: T,
  config: { _as: 'props'; _p: P }
): ActionCreator<T, (props: P) => P & SaveAction<T> & { operationType: OperationType.SendingToServerInitial }>;
export function createSubmitInitialAction<T extends string, P extends any[], R extends object>(
  storeCode: string,
  type: T,
  creator: Creator<P, R>
): FunctionWithParametersType<P, R & SaveAction<T> & { operationType: OperationType.SendingToServerInitial }> &
  SaveAction<T> & { operationType: OperationType.SendingToServerInitial };
export function createSubmitInitialAction<T extends string, C extends Creator>(
  storeCode: string,
  type: T,
  config?: { _as: 'props' } | C
): Creator {
  return createWithOperationType(
    {
      operationType: OperationType.SendingToServerInitial,
      correlationId: newGuid(),
      storeCode,
    },
    type,
    config
  );
}

export function createSubmitAction<T extends string>(
  storeCode: string,
  type: T
): ActionCreator<T, () => SaveAction<T> & { operationType: OperationType.SendingToServer }>;
export function createSubmitAction<T extends string, P extends object>(
  storeCode: string,
  type: T,
  config: { _as: 'props'; _p: P }
): ActionCreator<T, (props: P) => P & SaveAction<T> & { operationType: OperationType.SendingToServer }>;
export function createSubmitAction<T extends string, P extends any[], R extends object>(
  storeCode: string,
  type: T,
  creator: Creator<P, R>
): FunctionWithParametersType<P, R & SaveAction<T> & { operationType: OperationType.SendingToServer }> &
  SaveAction<T> & { operationType: OperationType.SendingToServer };
export function createSubmitAction<T extends string, C extends Creator>(
  storeCode: string,
  type: T,
  config?: { _as: 'props' } | C
): Creator {
  return createWithOperationType(
    {
      operationType: OperationType.SendingToServer,
      storeCode,
    },
    type,
    config
  );
}

export function createSubmitSuccessAction<T extends string>(
  storeCode: string,
  type: T
): ActionCreator<T, () => SaveAction<T> & { operationType: OperationType.ServerRespondsSuccess }>;
export function createSubmitSuccessAction<T extends string, P extends object>(
  storeCode: string,
  type: T,
  config: { _as: 'props'; _p: P }
): ActionCreator<T, (props: P) => P & SaveAction<T> & { operationType: OperationType.ServerRespondsSuccess }>;
export function createSubmitSuccessAction<T extends string, P extends any[], R extends object>(
  storeCode: string,
  type: T,
  creator: Creator<P, R>
): FunctionWithParametersType<P, R & SaveAction<T> & { operationType: OperationType.ServerRespondsSuccess }> &
  SaveAction<T> & { operationType: OperationType.ServerRespondsSuccess };
export function createSubmitSuccessAction<T extends string, C extends Creator>(
  storeCode: string,
  type: T,
  config?: { _as: 'props' } | C
): Creator {
  return createWithOperationType(
    {
      operationType: OperationType.ServerRespondsSuccess,
      storeCode,
    },
    type,
    config
  );
}

export function createSubmitFailAction<T extends string>(
  storeCode: string,
  type: T
): ActionCreator<T, () => SaveAction<T> & { operationType: OperationType.ServerRespondsFail }>;
export function createSubmitFailAction<T extends string, P extends object>(
  storeCode: string,
  type: T,
  config: { _as: 'props'; _p: P }
): ActionCreator<T, (props: P) => P & SaveAction<T> & { operationType: OperationType.ServerRespondsFail }>;
export function createSubmitFailAction<T extends string, P extends any[], R extends object>(
  storeCode: string,
  type: T,
  creator: Creator<P, R>
): FunctionWithParametersType<P, R & SaveAction<T> & { operationType: OperationType.ServerRespondsFail }> &
  SaveAction<T> & { operationType: OperationType.ServerRespondsFail };
export function createSubmitFailAction<T extends string, C extends Creator>(
  storeCode: string,
  type: T,
  config?: { _as: 'props' } | C
): Creator {
  return createWithOperationType(
    {
      operationType: OperationType.ServerRespondsFail,
      storeCode,
    },
    type,
    config
  );
}

export function createSubmitFinishAction<T extends string>(
  storeCode: string
): ActionCreator<T, () => SaveAction<T> & { operationType: OperationType.ServerFinished }>;
export function createSubmitFinishAction(storeCode: string): Creator {
  return createWithOperationType(
    {
      operationType: OperationType.ServerFinished,
      storeCode,
    },
    '[Finish Server]',
    null as ANY
  );
}

export function isSaveAction(action: Action): action is SaveAction<string> {
  if (typeof (action as any).operationType === 'number') {
    return true;
  }

  return false;
}

export function saveReducerWithCode(storeCode: string) {
  return (reducer: ActionReducer<SaveState>): ActionReducer<any> => {
    return function (state, action) {
      const newState = reducer(state, action);

      if (isSaveAction(action)) {
        if (action.storeCode === storeCode) {
          switch (action.operationType) {
            case OperationType.SendingToServer:
              return {
                ...newState,
                _submitCounter: (newState._submitCounter || 0) + 1,
              };
            case OperationType.ServerRespondsSuccess:
              return {
                ...newState,
                _submitCounter: (newState._submitCounter || 0) - 1,
              };
            case OperationType.ServerRespondsFail:
              return {
                ...newState,
                _error: action.error,
                _submitCounter: (newState._submitCounter || 0) - 1,
              };
            case OperationType.SendingToServerInitial:
              return {
                ...newState,
                _submitCounter: 1,
                _error: null,
                _cacheCorrelationId: action.correlationId,
                _correlationId: null,
              };
            case OperationType.ServerFinished:
              return {
                ...newState,
                _correlationId: newState._cacheCorrelationId,
                _cacheCorrelationId: null,
              };
          }
        }
      }

      return newState;
    };
  };
}
