import { type OverlayRef } from '@angular/cdk/overlay';
import { type ComponentType } from '@angular/cdk/portal';
import { InjectionToken, type ElementRef, type Injector, type TemplateRef } from '@angular/core';
import { type MatDialogConfig, type MatDialogRef } from '@angular/material/dialog';
import { type DialogFilterComponent } from '@dialogs/dialog-filter.component';
import { Subject, type Observable } from 'rxjs';
import { type DialogFilter2Impl } from './dialog-models';
import { type DynamicFilterData, type FilterData, type FilterResult } from './filter-models';
import { type RequiredTextExtraResource1, type ClickableResource1, type RequiredTextResource1 } from './resource';

export interface DialogButton<T> {
  text: string;
  class?: string;
  id?: string;
  click?: (component: T) => void;
}

export enum DialogType {
  Question = 1,
  Success,
  Information,
  DeleteUser,
  Instances,
  Warning,
  PaymentLink,
}

export interface DialogOptions<T extends any /* Component */> {
  title?: string;
  type?: DialogType;
  buttons?: DialogButton<T>[];
  closeButton?: boolean;
  allowEscape?: boolean;
  escape?: () => void;
  closeClick?: (event: Event) => void;
  width?: string;
}

export const dialogTypeToImage = {
  [DialogType.Question]: 'question',
  [DialogType.Success]: 'success',
  [DialogType.Information]: 'information',
  [DialogType.DeleteUser]: 'delete-user',
  [DialogType.Instances]: 'instances',
  [DialogType.Warning]: 'warning',
  [DialogType.PaymentLink]: 'payment-link',
};

export interface PopoverProperties {
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  show?: boolean;
  forceHide?: boolean;
}

export interface PopoverButtonProperties extends PopoverProperties {
  buttons: PopoverButton[];
}

export interface BackdropData {
  isLogin: boolean;
}

/** @deprecated */
export const FILTER_DIALOG_DATA = new InjectionToken<any>('FILTER_DIALOG_DATA');
/** @deprecated */
export const FILTER_DIALOG_REF = new InjectionToken<any>('FILTER_DIALOG_REF');
export const POPOVER_OVERLAY_DATA = new InjectionToken<PopoverOverlayData>('POPOVER_OVERLAY_DATA');
export const BACKDROP_DATA = new InjectionToken<BackdropData>('BACKDROP_DATA');

export interface MenuOverlayData {
  buttons: PopoverButton[];
  backdrop?: BackdropType;
  groups?: {
    code: string;
    name: string;
    order?: number;
  }[];
}

export const MENU_OVERLAY_DATA = new InjectionToken<MenuOverlayData>('MENU_OVERLAY_DATA');

export const INTAKE_DIALOG_DATA_TYPED = <T>() => new InjectionToken<T>('INTAKE_DIALOG_DATA_TYPED');
export const POPOVER_DATA_TYPED = <T>() => new InjectionToken<T>('POPOVER_DATA_TYPED');
export const DIALOG_DATA_TYPED = <T>() => new InjectionToken<T>('DIALOG_DATA_TYPED');

export interface PopoverButton extends RequiredTextResource1<unknown> {
  click: () => void;
  title?: string;
  groupCode?: string;
  selected?: boolean;
}

export interface DialogIntakeDataInternal<T> extends DialogIntakeData {
  component: ComponentType<T>;
}

export interface DialogFilterDataInternal<FILTER_TYPE, FILTER_DATA_TYPE> extends DialogFilterData<FILTER_DATA_TYPE> {
  component: ComponentType<FILTER_TYPE>;
}

export interface IntakeButtons extends RequiredTextExtraResource1<string> {
  focusInitial?: boolean;
}

export interface ConfirmButtons extends ClickableResource1<string, MatDialogRef<unknown, boolean>> {
  focusInitial?: boolean;
}

export interface DialogIntakeData<D = unknown> {
  title: string;
  text?: string;
  type?: DialogType;
  buttons: IntakeButtons[];
  disableExit?: boolean; // Not in used here.
  disableEnter?: boolean;
  intakeData?: {
    injectionToken: InjectionToken<D>;
    data: D | null;
  };
  dataTestId?: string;
}

export interface DialogFilterData<DATA_TYPE> {
  data?: DATA_TYPE;
}

export interface DialogConfirmData {
  title: string;
  text?: string;
  type?: DialogType;
  buttons?: ConfirmButtons[];
  disableExit?: boolean; // Not in used here.
  loading?: boolean;
}

export interface DialogIntakeConfig<D = unknown> extends Omit<MatDialogConfig<unknown>, 'data'> {
  intake: DialogIntakeData<D>;
}

export interface DialogConfig<D = unknown> extends Omit<MatDialogConfig<unknown>, 'data'> {
  dialogData?: {
    injectionToken: InjectionToken<D>;
    data: D | null;
  };
}

export interface DialogFilterConfig<T> extends MatDialogConfig<any> {
  filter: DialogFilterData<T>;
}

export interface DialogConfirmConfig extends MatDialogConfig<any> {
  confirm: DialogConfirmData;
}

export interface DialogIntakeImpl {
  back?: () => void;
  close?: () => void;
  getStepTitles?: () => string[];
  getButtonDisabled$?: (id: string) => Observable<boolean>;
  getButtonText$?: (id: string) => Observable<string>;
  buttonClicked?: (name: string) => void;
  save: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DialogFilterImpl {}

export interface PopoverOverlayData {
  component: ComponentType<unknown>;
  overlayRef: OverlayRef;
  requestClose?: Observable<boolean>;
  origin: ElementRef<any> | HTMLElement;
}

export const DIALOG_SERVICE_IMPL = new InjectionToken<Dialog2ServiceImpl>('DIALOG_SERVICE_IMPL');

export type TooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after';

export enum BackdropType {
  None = 0,
  Gray = 1,
  Transparent = 2,
}

export interface PopoverConfig<D = unknown> {
  /**
   * Positions the popover around the object.
   * If not passed in, will be 'below'.
   */
  position?: TooltipPosition;

  /**
   * Optionally pass in data to the popover.
   */
  popoverData?: {
    injectionToken: InjectionToken<D>;
    data: D | null;
  };

  /**
   * Indicates if we have a backdrop.
   * If we do, clicking on it will dismiss the popover.
   * If you don't have a backdrop, you have to handle closing the popover yourself.
   * Default value is Backdrop.Gray.
   */
  backdrop?: BackdropType;

  /**
   * Indicates if we want to close the popover.
   * If you pass in an observable
   *  - Emit false then we will never close the popover.
   *  - Emit true and we will close the popover when we can.
   */
  requestClose?: Observable<boolean>;

  /**
   * Indicates which injector to use. If none, will use the root injector.
   */
  injector?: Injector;
}

export interface Dialog2ServiceImpl {
  /**
   * Opens a menu with the buttons close the the element.
   *
   * @param buttons The buttons to display in the menu.
   * @param elementRef The anchor where the menu is open.
   * @param position The position around the element.
   * @param backdrop The backdrop type.
   * @returns The reference of the menu.
   */
  menu: (
    buttons: PopoverButton[],
    elementRef: ElementRef<any> | HTMLElement,
    position: TooltipPosition,
    backdrop?: BackdropType,
    groups?: MenuOverlayData['groups']
  ) => PopoverRef<any, any>;

  /**
   * Opens a popover.
   *
   * @param componentRef The type of popover to open.
   * @param elementRef The anchor where the popover is open.
   * @param popoverConfig The config of the popover.
   * @returns The reference of the popover.
   */
  popover: <T, D = any, R = any>(
    componentRef: ComponentType<T>,
    elementRef: ElementRef<any> | HTMLElement,
    popoverConfig?: PopoverConfig<D>
  ) => PopoverRef<T, R>;

  /**
   * Opens a confirm window.
   *
   * @param config The config for the confirm window.
   * @returns The reference of the confirm window.
   */
  confirm: <R = any>(config: DialogConfirmConfig) => MatDialogRef<any, R>;

  /**
   * Opens an intake window.
   *
   * @param componentOrTemplateRef The type of the intake to open.
   * @param config The config of the window.
   * @returns The reference of the intake window.
   */
  intake: <T extends DialogIntakeImpl, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: DialogIntakeConfig<D>
  ) => MatDialogRef<any, R>;

  /**
   * @deprecated
   */
  filter: <T extends DialogFilterImpl>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: DialogFilterConfig<FilterData>
  ) => MatDialogRef<DialogFilterComponent<T, FilterData>, FilterResult<FilterData>>;

  /**
   * @deprecated
   */
  filter2: <FILTER_WINDOW_TYPE extends DialogFilter2Impl<FILTER_OBJECT>, FILTER_OBJECT>(
    componentOrTemplateRef: ComponentType<FILTER_WINDOW_TYPE> | TemplateRef<FILTER_WINDOW_TYPE>,
    config: DialogFilterConfig<DynamicFilterData<FILTER_OBJECT>>
  ) => MatDialogRef<DialogFilterComponent<FILTER_WINDOW_TYPE, FILTER_OBJECT>, FilterResult<DynamicFilterData<FILTER_OBJECT>>>;

  /**
   * Opens a basic window.
   *
   * @param componentOrTemplateRef The type of the window to open.
   * @param config The config of the window.
   * @returns The reference of the window.
   */
  open: <T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: DialogConfig<D>) => MatDialogRef<T, R>;
}

export interface DialogIntakeServiceImpl {
  // I wish this was not here, but I don't know how to have a token and a provide:Class to use the same object
  // Every time a dialog intake is created.
  requestToClose: Observable<any>;
  requestToReposition: Observable<void>;
  close: (value?: any) => void;
  reposition: () => void;
}

export enum PopoverPosition {
  Top = 1,
  Right,
  Bottom,
  Left,

  /**
   * @deprecated
   */
  Center,
}

export enum PopoverAlignment {
  LeftEdge = 1,
  RightEdge,
  Center,
}

export class PopoverRef<T, R = unknown> {
  /** Subject for notifying the user that the dialog has finished closing. */
  private readonly _afterClosed = new Subject<R | undefined>();

  readonly _afterOpen = new Subject<void>();

  /** Result to be passed to afterClosed. */
  private _result: R | undefined;

  componentInstance: T | null = null;

  constructor(private readonly _overlayRef: OverlayRef) {
    _overlayRef.detachments().subscribe(() => {
      this._afterClosed.next(this._result);
      this._afterClosed.complete();
      this.componentInstance = null;
      this._overlayRef.dispose();
    });
  }

  afterOpen(): Observable<void> {
    return this._afterOpen.asObservable();
  }

  afterClosed(): Observable<R | undefined> {
    return this._afterClosed.asObservable();
  }

  close(popoverResult?: R): void {
    this._result = popoverResult;
    this._overlayRef.dispose();
  }
}

interface ConfirmButtonCreation {
  withCancel?: boolean;
  withDelete?: boolean;
  text?: string;
  secondary?: string;
  focusInitial?: boolean;
}

export const buttonIds = {
  cancel: 'cancel',
  save: 'save',
};

export const buttons = {
  confirmButtons: (args: ConfirmButtonCreation): ConfirmButtons[] => {
    const arr: ConfirmButtons[] = [];
    if (args.withCancel) {
      arr.push({
        id: buttonIds.cancel,
        text: 'Cancel',
        secondary: 'secondary',
        click: dialogRef => {
          dialogRef.close(false);
        },
        focusInitial: !args.focusInitial,
      });
    }

    if (args.withDelete) {
      arr.push({
        id: buttonIds.save,
        text: 'Delete',
        secondary: 'danger',
        click: dialogRef => {
          dialogRef.close(true);
        },
      });
    }

    if (args.text) {
      arr.push({
        id: buttonIds.save,
        text: args.text,
        secondary: args.secondary ?? 'primary',

        // We focus this if we don't have the cancel button and we didn't force the focus.
        focusInitial: args.focusInitial ?? !args.withCancel,
        click: dialogRef => {
          dialogRef.close(true);
        },
      });
    }

    return arr;
  },
};
