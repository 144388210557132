import { getHashCode as strGetHashCode } from './string';

export function clone<T>(object: T): T {
  if (object === undefined) {
    return object;
  }

  return JSON.parse(JSON.stringify(object));
}

export function deepFreeze<T>(object: T): T {
  if (Object.isFrozen(object)) {
    return object;
  }

  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object) as (keyof T)[];

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name];

    object[name] = value && typeof value === 'object' ? deepFreeze(value) : value;
  }

  return Object.freeze(object);
}

export function unfreezeIfNecessary<T>(object: T): T {
  if (object) {
    if (
      Object.isFrozen(object) ||
      // We check the first one and second one because sometimes we have the "Select a value" at the index=0
      (Array.isArray(object) && (Object.isFrozen(object[0]) || (object.length > 1 && Object.isFrozen(object[1]))))
    ) {
      return clone(object);
    }
  }

  return object;
}

export function getHashCode(obj: object): number {
  return strGetHashCode(JSON.stringify(obj));
}

export function getObjectKeys<T extends {}>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function getObjectValues<T extends object>(obj: T): T[keyof T][] {
  return Object.values(obj) as T[keyof T][];
}
