import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type ExtendedAgreementInformation } from '@models/agreement';
import { type AgreementSiteSystemInformationForList } from '@models/cards/agreement-site-system-information';
import { type CallInformationForList } from '@models/cards/call-information';
import { type LastVisitNoteInformation } from '@models/cards/last-visit-information';
import { type SiteInformation } from '@models/cards/site-information';
import { type SiteSystemInformationForList } from '@models/cards/site-system-information';
import { type WorkOrderInformationForList } from '@models/cards/work-order-information';
import { type DutyInformationForList } from '@models/duty-models';
import { type SalesProposalInformationForList } from '@models/sales-proposal-models';
import { type FileInformation } from '@models/upload';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { HttpClientService, type ExtraOptionsJson } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class SitesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get(id: Id): Observable<SiteInformation> {
    return this.http.get<SiteInformation>(this.url.siteSingle.replace('$0', id.toString()));
  }

  save(siteInformation: SiteInformation): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(siteInformation, 'quickBooksStatus', 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.siteSave, obj);
  }

  patch(id: Id, obj: Partial<SiteInformation>): Observable<void> {
    obj = this.http.setEmptyStringToNull(obj);

    return this.http.patch<void>(this.url.sitePatch.replace('$0', id.toString()), obj);
  }

  move(id: Id, customerId: Id): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<void>(this.url.siteMove.replace('$0', id.toString()), customerId, options);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.siteDelete.replace('$0', id.toString()), skipBubbleError());
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.siteRestore.replace('$0', id.toString()), null);
  }

  getSiteSystems(id: Id): Observable<SiteSystemInformationForList[]> {
    return this.http.get<SiteSystemInformationForList[]>(this.url.siteSiteSystems.replace('$0', id.toString()));
  }

  getLastVisits(id: Id, skip: number): Observable<LastVisitNoteInformation[]> {
    return this.http.get<LastVisitNoteInformation[]>(this.url.siteLastVisits.replace('$0', id.toString()).replace('$1', skip.toString()));
  }

  getExtendedAgreements(id: Id): Observable<ExtendedAgreementInformation[]> {
    return this.http.get<ExtendedAgreementInformation[]>(this.url.siteExtendedAgreements.replace('$0', id.toString()));
  }

  getAgreements(id: Id): Observable<AgreementSiteSystemInformationForList[]> {
    return this.http.get<AgreementSiteSystemInformationForList[]>(this.url.siteAgreements.replace('$0', id.toString()));
  }

  getDuties(id: Id): Observable<DutyInformationForList[]> {
    return this.http.get<DutyInformationForList[]>(this.url.siteDuties.replace('$0', id.toString()));
  }

  getWorkOrders(id: Id): Observable<WorkOrderInformationForList[]> {
    return this.http.get<WorkOrderInformationForList[]>(this.url.siteWorkOrders.replace('$0', id.toString()));
  }

  getSalesProposals(id: Id): Observable<SalesProposalInformationForList[]> {
    return this.http.get<SalesProposalInformationForList[]>(this.url.siteSalesProposals.replace('$0', id.toString()));
  }

  getCalls(id: Id): Observable<CallInformationForList[]> {
    return this.http.get<CallInformationForList[]>(this.url.siteCalls.replace('$0', id.toString()));
  }

  getFiles(id: Id): Observable<FileInformation[]> {
    return this.http.get<FileInformation[]>(this.url.siteListFiles.replace('$0', id.toString()));
  }

  addFile(id: Id, fileId: Id): Observable<void> {
    return this.http.post<void>(this.url.siteAddFile.replace('$0', id.toString()).replace('$1', fileId.toString()), null);
  }

  deleteFile(id: Id, fileId: Id): Observable<void> {
    return this.http.delete<void>(this.url.siteDeleteFile.replace('$0', id.toString()).replace('$1', fileId.toString()));
  }

  deleteFiles(id: Id, fileIds: Id[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: fileIds,
    };

    return this.http.delete<void>(this.url.siteDeleteFiles.replace('$0', id.toString()), options);
  }

  copyFromCustomer(id: Id, copyNotes: boolean, extraOptions?: ExtraOptionsJson): Observable<void> {
    return this.http.post<void>(this.url.siteCopyCustomer.replace('$0', id.toString()), { input: copyNotes }, extraOptions);
  }
}
