import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ButtonComponent } from '@controls/button/button.component';
import { BackdropType, POPOVER_DATA_TYPED, type Dialog2ServiceImpl, type PopoverButton, type TooltipPosition } from '@models/dialog';
import { type Observable } from 'rxjs';

export interface PopoverHelpData {
  title?: string | null;
  text?: string;
  html?: string;
  button?: PopoverButton;
  isNotes?: boolean;
  ulAreDisplayed?: boolean;
}

const injectionToken = POPOVER_DATA_TYPED<PopoverHelpData>();

@Component({
  selector: 'wm-popover-help',
  templateUrl: 'popover-help.component.html',
  styleUrls: ['popover-help.component.scss'],
  host: {
    '[class.is-notes]': '_isNotes',
    '[class.ul-are-displayed]': 'ulAreDisplayed',
  },
  standalone: true,
  imports: [CommonModule, ButtonComponent],
})
export class PopoverHelpComponent {
  readonly popoverData = inject(injectionToken);

  get ulAreDisplayed(): boolean {
    return !!this.popoverData.ulAreDisplayed;
  }

  get _isNotes(): boolean {
    return this.popoverData.isNotes ?? false;
  }

  buttonClick(button: PopoverButton): void {
    button.click();
  }

  static open(
    dialog: Dialog2ServiceImpl,
    element: HTMLElement,
    data: PopoverHelpData,
    args?: { position?: TooltipPosition; requestClose?: Observable<boolean> }
  ) {
    return dialog.popover(PopoverHelpComponent, element, {
      backdrop: BackdropType.None,
      position: args?.position,
      requestClose: args?.requestClose,
      popoverData: {
        injectionToken,
        data,
      },
    });
  }
}
