import { type Mutable } from '@models/mutable';
import { type TaxItemEntry } from '@models/pricing-models';
import { type SalesProposalInformation } from '@models/sales-proposal-models';
import { type TaxItem } from '@models/tax-item';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy, indexBy } from '@utility/array';
import { normalOrder } from '@utility/sort-fnc';
import { Decimal } from 'decimal.js';
import {
  type FileInformationState,
  type SalesProposalDetailState,
  type SalesProposalDiscountState,
  type SalesProposalLoanApplicationState,
  type SalesProposalPackageState,
  type SalesProposalPaymentState,
  type SalesProposalRebateState,
  type SalesProposalState,
} from './sales-proposal.reducer';
import { DeveloperError } from '@models/error-models';

function buildArray<T extends { id: Id }>(obj: Record<string, T & Mutable>, includeHidden?: boolean): T[] {
  const objArray = Object.values(obj);

  if (includeHidden) {
    return objArray;
  }

  return objArray.filter(m => !m.deleted);
}

export const SALES_PROPOSAL_STORE_KEY = 'salesProposal';

export const salesProposalStateSelector = createFeatureSelector<SalesProposalState>(SALES_PROPOSAL_STORE_KEY);

export const mode = createSelector(salesProposalStateSelector, (state: SalesProposalState) => state.edit);
export const salesProposal$ = createSelector(salesProposalStateSelector, state => state.entities.salesProposal);
export const entitiesSnapshot$ = createSelector(salesProposalStateSelector, state => state.entitiesSnapshot);
export const salesProposalLoanApplications$ = createSelector(
  salesProposalStateSelector,
  state => state.entities.salesProposalLoanApplications
);
export const salesProposalPackages$ = createSelector(salesProposalStateSelector, state => state.entities.salesProposalPackages);
export const salesProposalDetails$ = createSelector(salesProposalStateSelector, state => state.entities.salesProposalDetails);
export const salesProposalDiscounts$ = createSelector(salesProposalStateSelector, state => state.entities.salesProposalDiscounts);
export const salesProposalRebates$ = createSelector(salesProposalStateSelector, state => state.entities.salesProposalRebates);
export const salesProposalPayments$ = createSelector(salesProposalStateSelector, state => state.entities.salesProposalPayments);
export const fileInformations$ = createSelector(salesProposalStateSelector, state => state.entities.fileInformations);
export const taxItemSections$ = createSelector(salesProposalStateSelector, state => state.taxItemSections);
export const internalTaxItemEntry$ = createSelector(salesProposalStateSelector, state => state.entities.internalTaxItemEntry);
export const workOrderId$ = createSelector(salesProposalStateSelector, state => state.workOrderId);
export const selectingPackage$ = createSelector(salesProposalStateSelector, state => state.selectingPackage);

export const cacheCorrelationId$ = createSelector(salesProposalStateSelector, state => state._cacheCorrelationId);
export const saveCorrelationId$ = createSelector(salesProposalStateSelector, state => state._correlationId);
export const error$ = createSelector(salesProposalStateSelector, state => state._error);

export const salesProposalPackageArray$ = createSelector(salesProposalPackages$, salesProposalPackages =>
  buildArray(salesProposalPackages).sort(normalOrder())
);
export const salesProposalPaymentArray$ = createSelector(salesProposalPayments$, salesProposalPayments =>
  buildArray(salesProposalPayments).sort(normalOrder())
);
export const salesProposalDetailByPackageId$ = createSelector(salesProposalDetails$, salesProposalDetails =>
  groupBy(buildArray(salesProposalDetails).sort(normalOrder()), 'salesProposalPackageId')
);
export const salesProposalDiscountByPackageId$ = createSelector(salesProposalDiscounts$, salesProposalDiscounts =>
  groupBy(buildArray(salesProposalDiscounts), 'salesProposalPackageId')
);
export const salesProposalRebateByPackageId$ = createSelector(salesProposalRebates$, salesProposalRebates =>
  groupBy(buildArray(salesProposalRebates), 'salesProposalPackageId')
);
export const fileInformationArray$ = createSelector(fileInformations$, fileInformations =>
  buildArray(fileInformations).sort(normalOrder())
);

export const taxItemEntry$ = createSelector(
  salesProposal$,
  taxItemSections$,
  internalTaxItemEntry$,
  (salesProposal, taxItemSections, internalTaxItemEntry) => {
    if (internalTaxItemEntry && typeof internalTaxItemEntry.taxItem !== 'undefined') {
      return internalTaxItemEntry;
    }

    if (salesProposal && taxItemSections) {
      let taxItem: TaxItem | null = null;
      if (salesProposal.taxItemId) {
        for (const taxItemSection of taxItemSections) {
          taxItem = taxItemSection.items.find(m => m.id === salesProposal.taxItemId) ?? null;
          if (taxItem) {
            break;
          }
        }
      }

      return {
        taxItem,
        taxItemManual: salesProposal.salesTaxPercentage && new Decimal(salesProposal.salesTaxPercentage).times(100).toNumber(),
        taxOverride: salesProposal.taxOverride,
      } as TaxItemEntry;
    }

    return null;
  }
);

export const salesProposalLoanApplicationsById$ = createSelector(salesProposalLoanApplications$, salesProposalLoanApplications =>
  indexBy(salesProposalLoanApplications, 'id')
);

export const salesProposalDiscountsByPackage$ = (packageId: Id) =>
  createSelector(salesProposalDiscounts$, (discounts: SalesProposalDiscountState) =>
    indexBy(
      Object.values(discounts).filter(m => m.salesProposalPackageId === packageId),
      'id'
    )
  );

export const salesProposalRebatesByPackage$ = (packageId: Id) =>
  createSelector(salesProposalRebates$, (rebates: SalesProposalRebateState) =>
    indexBy(
      Object.values(rebates).filter(m => m.salesProposalPackageId === packageId),
      'id'
    )
  );

export const salesProposalDetailsByPackage$ = (packageId: Id) =>
  createSelector(salesProposalDetails$, (details: SalesProposalDetailState) =>
    indexBy(
      Object.values(details).filter(m => m.salesProposalPackageId === packageId),
      'id'
    )
  );

export const salesProposalAndTaxItemEntry = createSelector(salesProposal$, internalTaxItemEntry$, (salesProposal, taxItemEntry) => ({
  salesProposal,
  taxItemEntry,
}));

export const salesProposalPaymentWithParentId$ = (id: Id) =>
  createSelector(
    salesProposal$,
    salesProposalPayments$,
    (salesProposal: SalesProposalInformation, salesProposalPayments: SalesProposalPaymentState) => ({
      salesProposalId: salesProposal.id,
      resource: salesProposalPayments[id],
    })
  );

export const salesProposalFileInformationWithParentId$ = (id: Id) =>
  createSelector(salesProposal$, fileInformations$, (salesProposal: SalesProposalInformation, fileInformations: FileInformationState) => ({
    salesProposalId: salesProposal.id,
    resource: fileInformations[id],
  }));

export const salesProposalFileInformationsToDeleteWithParentId$ = createSelector(
  salesProposal$,
  fileInformations$,
  (salesProposal: SalesProposalInformation, fileInformationState: FileInformationState) => ({
    salesProposalId: salesProposal.id,
    resources: Object.values(fileInformationState).filter(x => x.id > 0 && x.deleted),
  })
);

export const salesProposalLoanWithParentId$ = (id: Id) =>
  createSelector(
    salesProposal$,
    salesProposalLoanApplications$,
    (salesProposal: SalesProposalInformation, salesProposalLoanApplications: SalesProposalLoanApplicationState) => {
      const resource = salesProposalLoanApplications.find(m => m.id === id);
      if (!resource) {
        throw new DeveloperError();
      }
      return {
        salesProposalId: salesProposal.id,
        resource,
      };
    }
  );

export const salesProposalPackageWithParentId$ = (id: Id) =>
  createSelector(
    salesProposal$,
    salesProposalPackages$,
    (salesProposal: SalesProposalInformation, salesProposalPackages: SalesProposalPackageState) => ({
      salesProposalId: salesProposal.id,
      resource: salesProposalPackages[id],
    })
  );

export const salesProposalDiscountWithParentId$ = (id: Id) =>
  createSelector(
    salesProposal$,
    salesProposalDiscounts$,
    (salesProposal: SalesProposalInformation, salesProposalDiscounts: SalesProposalDiscountState) => ({
      salesProposalId: salesProposal.id,
      packageId: salesProposalDiscounts[id].salesProposalPackageId,
      resource: salesProposalDiscounts[id],
    })
  );

export const salesProposalRebateWithParentId$ = (id: Id) =>
  createSelector(
    salesProposal$,
    salesProposalRebates$,
    (salesProposal: SalesProposalInformation, salesProposalRebates: SalesProposalRebateState) => ({
      salesProposalId: salesProposal.id,
      packageId: salesProposalRebates[id].salesProposalPackageId,
      resource: salesProposalRebates[id],
    })
  );

export const salesProposalDetailWithParentId$ = (id: Id) =>
  createSelector(
    salesProposal$,
    salesProposalDetails$,
    (salesProposal: SalesProposalInformation, salesProposalDetails: SalesProposalDetailState) => ({
      salesProposalId: salesProposal.id,
      packageId: salesProposalDetails[id].salesProposalPackageId,
      resource: salesProposalDetails[id],
    })
  );
