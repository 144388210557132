import { AppRole } from '@models/app-role';
import { isDashboardCallEvent, type ScheduleEventInformation } from '@models/dashboard-event';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { unique } from '@utility/array';
import { endOfDay, isWithinInterval, startOfDay } from 'date-fns';
import { type DashboardState } from './dashboard.reducer';
export const DASHBOARD_STORE_KEY = 'dashboard';
export const DASHBOARD_EVICT_MINUTES = 5;

export const dashboardStateSelector = createFeatureSelector<DashboardState>(DASHBOARD_STORE_KEY);

export const roles$ = createSelector(dashboardStateSelector, state => state.roles);
export const loadedDates$ = createSelector(dashboardStateSelector, state => state.loadedDates);
export const requestedDate$ = createSelector(dashboardStateSelector, state => state.requestedDate);
export const rawScheduleEvents$ = createSelector(dashboardStateSelector, state => state.scheduleEvents);
export const active$ = createSelector(dashboardStateSelector, state => state.active);
export const connected$ = createSelector(dashboardStateSelector, state => state.connected);
export const liveUpdateEnabled$ = createSelector(dashboardStateSelector, state => state.liveUpdateEnabled);

export const canLiveUpdate$ = createSelector(active$, connected$, liveUpdateEnabled$, (active, connected, liveUpdateEnabled) => {
  return active && connected && liveUpdateEnabled;
});

export const isConsideredActive$ = createSelector(active$, liveUpdateEnabled$, (active, liveUpdateEnabled) => {
  return !liveUpdateEnabled || active;
});

export const canSendToIPad$ = createSelector(roles$, roles => {
  return roles.includes(AppRole.ContractorDispatcher);
});

export const scheduleEvents$ = createSelector(rawScheduleEvents$, canSendToIPad$, (rawScheduleEvents, canSendToIPad) => {
  return rawScheduleEvents.map(rawScheduleEvent => {
    let callDetails = rawScheduleEvent.callDetails;
    if (isDashboardCallEvent(rawScheduleEvent)) {
      callDetails = {
        ...callDetails,
        canSendToIPad,
      } as ANY;
    }

    return {
      ...rawScheduleEvent,
      callDetails,
    } as ScheduleEventInformation;
  });
});

export const visibleScheduleEvents$ = createSelector(scheduleEvents$, requestedDate$, (scheduleEvents, requestedDate) => {
  const requestedMoment = requestedDate as ANY;
  return scheduleEvents.filter(m =>
    isWithinInterval(requestedMoment, {
      start: startOfDay(m.start),
      end: endOfDay(m.end),
    })
  );
});

export const scheduleEventIdsByCustomerId$ = (id: Id) =>
  createSelector(scheduleEvents$, (scheduleEvents: ScheduleEventInformation[]) => {
    return unique(scheduleEvents.filter(m => m.callDetails && m.callDetails.customerId === id).map(m => m.id));
  });

export const scheduleEventIdsBySiteId$ = (id: Id) =>
  createSelector(scheduleEvents$, (scheduleEvents: ScheduleEventInformation[]) => {
    return unique(scheduleEvents.filter(m => m.callDetails && m.callDetails.siteId === id).map(m => m.id));
  });

export const scheduleEventIdsByWorkOrderId$ = (id: Id) =>
  createSelector(scheduleEvents$, (scheduleEvents: ScheduleEventInformation[]) => {
    return unique(scheduleEvents.filter(m => m.callDetails && m.callDetails.workOrderId === id).map(m => m.id));
  });
