import { type QuickBooksSynchronizationStatus } from './quick-books';
import { type RequiredTextResource } from './resource';

export interface LinkText {
  link: string;
  text: string;
}

export enum NotificationStatus {
  Sent = 0,
  Received = 1,
  Acknowledged = 2,
}

export interface Alert extends RequiredTextResource {
  icon: string;
  actionText?: string;
  createdDate: Date;
  status: NotificationStatus;
  notificationMessage?: NotificationMessage;
}

export interface ActionSmsReceived {
  phone: string;
  message: string;
}

export interface WorkOrderSynchronized {
  workOrderId: Id;
  quickBooksSynchronizationStatus: QuickBooksSynchronizationStatus | null;
}

export enum ActionType {
  UpdateScheduleEvent = 1, // extraJson: ScheduleEventInformation
  SmsReceived = 2, // extraJson: ActionSmsReceived
  SmsError = 3,
  TechnicianHasArrived = 4, // extraJson: ActionTechnicianHasArrived
  QuickBooksOnlineSettingsUnlocked = 5,
  QuickBooksOnlineAuthorizationFailed = 6,
  WorkOrderInformation = 7, // extraJson: WorkOrderSynchronized
  WorkOrderSynchronized = 8, // extraJson: WorkOrderSynchronized
  BulkCustomerImport = 9,
  DistributorProductUpload = 10,
  PaymentReceived = 11, // extraJson: ActionPaymentReceived
  AdminMessage = 12,
}

export interface NotificationMessage {
  notificationId: Id;
  contractorId: Id;
  text: string;
  actionType: ActionType;
  extraJson: any;
  createdBy: string;
  createdDate: Date;
  userStatus: NotificationStatus;
}

export interface ActionPaymentReceived {
  workOrderId: Id;
}
