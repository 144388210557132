import { Injectable, inject } from '@angular/core';
import { type DistributorInformation, type DistributorLocationInformation } from '@models/cards/distributor-information';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { HttpClientService } from '@services/http-client.service';
import { UrlService } from '@services/url.service';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';

@Injectable()
export class DistributorsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(): Observable<DistributorInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.Distributors, this.url.distributorsList);
  }

  save(
    distributorInformation: DistributorInformation,
    distributorLocation: DistributorLocationInformation
  ): Observable<{ distributorId: Id; distributorLocationId: Id }> {
    return this.http
      .post<{ distributorId: Id; distributorLocationId: Id }>(
        this.url.distributorsCreate,
        {
          distributor: distributorInformation,
          distributorLocation,
        },
        skipBubbleError()
      )
      .pipe(
        tap(_ => {
          this.clearCache(UpdateCategory.Distributors);
        }),
        share()
      );
  }

  patch(id: Id, distributorInformation: DistributorInformation): Observable<{ id: Id }> {
    return this.http
      .patch<{ id: Id }>(this.url.distributorsPatch.replace('$0', id.toString()), distributorInformation, skipBubbleError())
      .pipe(
        tap(_ => {
          this.clearCache(UpdateCategory.Distributors);
        }),
        share()
      );
  }

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.distributorsDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache(UpdateCategory.Distributors);
      }),
      tap(_ => {
        this.clearCache(UpdateCategory.PartItems);
      }),
      share()
    );
  }

  saveLocation(distributorLocation: DistributorLocationInformation): Observable<{ id: Id }> {
    const obj = this.http.setEmptyStringToNull(distributorLocation);
    this.http.trimStringProperties(obj);

    return this.http
      .post<{
        id: Id;
      }>(this.url.distributorsSaveLocation.replace('$0', distributorLocation.distributorId.toString()), obj, skipBubbleError())
      .pipe(
        tap(_ => {
          this.clearCache(UpdateCategory.Distributors);
        }),
        share()
      );
  }

  deleteLocation(id: Id, locationId: Id): Observable<void> {
    return this.http
      .delete<void>(this.url.distributorsDeleteLocation.replace('$0', id.toString()).replace('$1', locationId.toString()))
      .pipe(
        tap(_ => {
          this.clearCache(UpdateCategory.Distributors);
        }),
        share()
      );
  }

  setDefaultLocation(id: Id, locationId: Id): Observable<void> {
    return this.http
      .post<void>(this.url.distributorsSetDefaultLocation.replace('$0', id.toString()).replace('$1', locationId.toString()), null)
      .pipe(
        tap(_ => {
          this.clearCache(UpdateCategory.Distributors);
        }),
        share()
      );
  }

  private clearCache(_updateCategory: UpdateCategory) {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.Distributors);
    }
  }
}
