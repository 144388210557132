<div class="popover">
  <div
    #arrow
    class="arrow"
    [style.left.px]="arrowLeftPosition"
    [style.top.px]="arrowTopPosition"
    [style.right.px]="arrowRightPosition"
    [style.bottom.px]="arrowBottomPosition"
    [hidden]="!arrowVisible"
  ></div>
  <div class="content">
    <ng-template #portal="cdkPortalOutlet" [cdkPortalOutlet]="popoverPortal"></ng-template>
  </div>
</div>
