<label class="wm-checkbox-layout" [class.check-only]="text.childNodes.length < 1">
  <div class="wm-checkbox-inner-container">
    <input
      #input
      class="wm-checkbox-input wm-visually-hidden"
      type="checkbox"
      [id]="inputId"
      [required]="required"
      [checked]="checked"
      [disabled]="disabled"
      [name]="name"
      [tabIndex]="tabIndex"
      [indeterminate]="indeterminate"
      [attr.aria-label]="ariaLabel"
      [attr.aria-labelledby]="ariaLabelledby"
      (focus)="_onInputFocus()"
      (blur)="_onInputBlur()"
      (change)="_onInteractionEvent($event)"
      (click)="_onInputClick($event)"
    />
    <div class="wm-checkbox-frame"></div>

    <div class="wm-checkbox-background" #background>
      <svg version="1.1" class="wm-checkbox-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve">
        <path class="wm-checkbox-checkmark-path" fill="none" stroke="white" d="M4.1,12.7 9,17.6 20.3,6.3" />
      </svg>
      <!-- Element for rendering the indeterminate state checkbox. -->
      <div class="wm-checkbox-mixedmark"></div>
    </div>
  </div>
  <span class="wm-checkbox-label" #text>
    <ng-content></ng-content>
  </span>
</label>
