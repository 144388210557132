@if (showDate()) {
  <div class="date">
    <ul>
      <li class="today">
        <button wm-button type="button" (click)="todayClick()" [imageUrl]="'/assets/images/moment-picker-today.svg'">Today</button>
      </li>
      <li class="tomorrow">
        <button wm-button type="button" (click)="tomorrowClick()" [imageUrl]="'/assets/images/moment-picker-tomorrow.svg'">Tomorrow</button>
      </li>
    </ul>
    <wm-datepicker #datepicker [(ngModel)]="value" [minDate]="minDate()" [maxDate]="maxDate()"></wm-datepicker>
  </div>
}

@if (showTime()) {
  <div class="time">
    <wm-timepicker [(ngModel)]="value"></wm-timepicker>
  </div>
}
