import { type Address } from './address';
import { type CustomerInformationLastVisit } from './cards/customer-information';
import { type DutyCustomerEntity } from './entity';
import { type OptionalTextStateResource, type RequiredTextOrderedResource } from './resource';
import { type TagEntityInformation } from './tag-models';

export interface DutyInformation extends OptionalTextStateResource {
  localId: Id;
  categoryId: Id;
  dueDate: Date | null;
  withTime: boolean;
  priority: Priority;
  status: Status;
  notes: string | null;
  technicianId: Id | null;
  agreementSiteSystemId: Id | null;
  callId: Id | null;
  customerId: Id | null;
  siteId: Id | null;
  workOrderId: Id | null;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;

  // Read only, used when creating from duty
  agreementSiteSystemDeadDate?: Date | null;
  tagEntityInformations: TagEntityInformation[] | null;
}

export interface DutyCategoryInformation extends RequiredTextOrderedResource {
  custom: string | null;
}

export enum Priority {
  Blocker = 1,
  High = 2,
  Normal = 3,
  Low = 4,
}

export enum Status {
  Open = 1,
  InProgress = 2,
  Completed = 3,
}

export interface DutyInformationForList {
  data: DutyInformation;
  customerId: Id | null;
  siteId: Id | null;
  customerEntity: DutyCustomerEntity | null;
  siteAddress: Address;
  lastVisit: Date | null;
  lastVisits: CustomerInformationLastVisit[] | null;
}

export function getPriorityText(priority: Priority): string {
  switch (priority) {
    case Priority.Blocker:
      return 'End of Day';
    case Priority.High:
      return 'High';
    case Priority.Normal:
      return 'Normal';
    case Priority.Low:
      return 'Low';
  }

  return 'None';
}

export function getStatusText(status: Status): string {
  switch (status) {
    case Status.Open:
      return 'Open';
    case Status.InProgress:
      return 'In Progress';
    case Status.Completed:
      return 'Completed';
  }

  return 'None';
}

export function createEmpty(): DutyInformation {
  return {
    id: 0,
    localId: 0,
    categoryId: 0,
    dueDate: null,
    withTime: false,
    priority: Priority.Normal,
    status: Status.Open,
    notes: null,
    technicianId: null,
    agreementSiteSystemId: null,
    customerId: null,
    siteId: null,
    workOrderId: null,
    callId: null,
    createdBy: '',
    createdDate: new Date(),
    updatedBy: '',
    updatedDate: new Date(),
    tagEntityInformations: [],
  };
}

export interface DutySavedInfo {
  id: Id;
  localId: Id;
  updatedBy?: string;
  updatedDate?: Date;
  createdNewDuties: boolean;
}

