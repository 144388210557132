export interface Address {
  street?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
}

export function createEmpty(): Address {
  return {
    city: undefined,
    country: undefined,
    state: undefined,
    street: undefined,
    zip: undefined,
  };
}
