<div class="flex">
  <div class="section wm-card2">
    <wm-radio-group class="wm-description dates" [formControl]="dateFormControl">
      <label>Dates</label>
      <div class="read">
        <div>
          <wm-radio-button [value]="0">None</wm-radio-button>
        </div>
        <div>
          <wm-radio-button [value]="1">Last 7 Days</wm-radio-button>
        </div>
        <div>
          <wm-radio-button [value]="2">Last 30 Days</wm-radio-button>
        </div>
        <div>
          <wm-radio-button [value]="3">Month to Date</wm-radio-button>
        </div>
        <div>
          <wm-radio-button [value]="4">Year to Date</wm-radio-button>
        </div>
        <div>
          <wm-radio-button [value]="5">Range</wm-radio-button>
        </div>
        <div class="range">
          <div>
            <label>From</label>
            <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateFrom"></wm-input-moment-picker>
          </div>
          <div>
            <label>End</label>
            <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateEnd"></wm-input-moment-picker>
          </div>
        </div>
      </div>
    </wm-radio-group>
  </div>
  <div class="section wm-card2">
    <div class="wm-description expiration">
      <label>Comparison</label>
      <div class="read">
        <wm-checkbox [(ngModel)]="compare">Compare to the previous period</wm-checkbox>
      </div>
    </div>
  </div>
</div>
