<div class="wm-card2">
  <wm-radio-group [formControl]="option">
    <div class="vertically-spaced">
      @if ((data.segmentId ?? 0) > 0) {
        <div>
          <wm-radio-button [value]="existingId">
            Save changes to the list "<strong>{{ data.segmentName }}</strong
            >"</wm-radio-button
          >
        </div>
      }
      <div class="horizontally-spaced">
        <wm-radio-button [value]="createNewId">
          Create new list
          <wm-input-text
            placeholder="Enter the new list name"
            [ngModel]="segmentName()"
            (ngModelChange)="segmentName.set($event); option.patchValue(createNewId)"
          />
        </wm-radio-button>
      </div>
    </div>
  </wm-radio-group>
</div>
