import { Injectable, inject } from '@angular/core';
import { type FileInformation } from '@models/cards/invoice-information';
import {
  type FileDownloader,
  type FileUploader,
  type PreparedFile,
  type UploadHelper,
  type UploadStatus,
  type UploadingFile,
} from '@models/upload';
import { WindowRefService } from '@services/window-ref.service';
import { skipBubbleError } from '@utility/angular';
import { newGuid } from '@utility/string';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, finalize, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { HttpClientBaseService } from './http-client-base.service';
import { FilesService } from './live/files.service';

interface AzureUploadStatus {
  type: 'progress' | 'onload' | 'onerror' | 'onabort';
  event: Event;
}

// If you change this, there is text associated with an error.
const MAX_25MB = 1024 * 1024 * 25;

let counter = 0;

@Injectable()
export class AzureStorageService implements FileUploader, FileDownloader {
  private readonly filesService = inject(FilesService);
  private readonly windowRefService = inject(WindowRefService);
  private readonly http = inject(HttpClientBaseService);

  private fileUploadedCache: Record<string, UploadHelper> = {};

  public downloadFile(file: FileInformation): void {
    // If our file doesn't have the ID, we don't allow to download.
    if (file.id > 0) {
      this.filesService
        .get(file.id)
        .pipe(
          filter(uploadedFile => !!uploadedFile?.url),
          mergeMap(uploadedFile =>
            this.http.get(uploadedFile.url as ANY, { responseType: 'blob' }).pipe(
              map(blob => ({
                uploadedFile,
                blob,
              }))
            )
          )
        )
        .subscribe(data => {
          const fileName = data.uploadedFile.text;
          this.windowRefService.download(data.blob, fileName as ANY);
        });
    }
  }

  public getUploadingFile$(guid: string): Observable<UploadingFile> | undefined {
    return this.fileUploadedCache[guid]?.uploadingFile$;
  }

  public validateFile(guid: string, fileInformation: Partial<FileInformation>): Observable<FileInformation> {
    const uploadHelper = this.fileUploadedCache[guid];
    if (uploadHelper) {
      return uploadHelper.validate(fileInformation);
    }

    return throwError('This guid does not exist.');
  }

  public removeFile(guid: string): void {
    delete this.fileUploadedCache[guid];
  }

  public uploadFile(file: File): UploadHelper {
    const guid = newGuid();

    const statusBehavior = new BehaviorSubject<UploadStatus>({
      progress: 0,
      loaded: 0,
      total: 0,
    });

    let preparedFile: Observable<string> | null = null;
    let validatedFile: Observable<FileInformation> | null = null;
    let abortImmediately = false;
    const abort = (): void => {
      abortImmediately = true;
    };
    const uploadingFile$ = new BehaviorSubject({
      id: --counter,
      text: file.name,
      sizeInBytes: file.size,
      abort,
      valid: true,
      percentage: 0,
      readUrl: null,
    } as UploadingFile);
    const uploadHelper = {
      guid,
      uploadingFile$,
      onStatus: (fnc: (uploadStatus: UploadStatus) => void) => {
        statusBehavior.asObservable().subscribe(fnc);
        return uploadHelper;
      },
      abort,
      prepare: (prepared?: PreparedFile) => {
        if (preparedFile) {
          return preparedFile;
        }

        if (file.size && file.size > MAX_25MB) {
          return throwError({ error: 'The file is too large, 25MB maximum.' });
        }

        const x = (prepared ? of(prepared) : this.filesService.prepare(skipBubbleError())).pipe(
          map(m => {
            if (abortImmediately) {
              throw new Error('Request Abort');
            }

            uploadingFile$.next({
              ...uploadingFile$.getValue(),
              readUrl: m.readUrl,
            });

            // TODO3 slight chance abort is called before we assign here.
            const obj = this.uploadFileToBlob(file, m.writeUrl);
            uploadHelper.abort = obj.abort;

            return { serverResponse: m, status: obj.status };
          }),
          switchMap(m => {
            return new Observable<string>(observer => {
              m.status.subscribe(
                n => {
                  switch (n.type) {
                    case 'progress':
                      {
                        const loaded = (n.event as ProgressEvent).loaded;
                        const total = (n.event as ProgressEvent).total;
                        const progress = total > 0 ? Math.round((loaded / total) * 100) / 100 : 0;
                        const s1 = statusBehavior.value;
                        statusBehavior.next(
                          Object.assign({}, s1, {
                            progress,
                            total,
                            loaded,
                          })
                        );

                        uploadingFile$.next({
                          ...uploadingFile$.getValue(),
                          percentage: progress,
                        });
                      }
                      break;
                  }
                },
                (n: AzureUploadStatus) => {
                  let error: string | undefined;
                  const exception: any = n.event;
                  switch (n.type) {
                    case 'onload':
                      error = 'An error occured while uploading the end of the file.';
                      break;
                    case 'onerror':
                      error = 'An error occured while uploading.';
                      break;
                    case 'onabort':
                      error = 'The upload has been aborted.';
                      break;
                  }

                  observer.error({
                    error,
                    exception,
                  });

                  uploadingFile$.next({
                    ...uploadingFile$.getValue(),
                    percentage: null,
                    error,
                    valid: false,
                  });
                },
                () => {
                  uploadingFile$.next({
                    ...uploadingFile$.getValue(),
                    percentage: null,
                  });

                  observer.next(m.serverResponse.url);
                  observer.complete();
                }
              );
            }).pipe(
              finalize(() => {
                statusBehavior.complete();
              })
            );
          })
        );

        preparedFile = x.pipe(shareReplay(1));
        return preparedFile;
      },
      validate: (addToFileInformation?: Partial<FileInformation>): Observable<FileInformation> => {
        if (validatedFile) {
          return validatedFile;
        }

        validatedFile = uploadHelper.prepare().pipe(
          switchMap(url =>
            this.filesService.validate(
              Object.assign({}, { text: file.name, createdDate: new Date() }, addToFileInformation || {}, {
                url,
              }) as any as FileInformation,
              skipBubbleError()
            )
          ),
          tap({ error: () => (validatedFile = null) }),
          shareReplay(1)
        );

        return validatedFile;
      },
    } as UploadHelper;

    this.fileUploadedCache[guid] = uploadHelper;

    return uploadHelper;
  }

  private uploadFileToBlob(file: File, blobUrl: string): { abort: () => void; status: Observable<AzureUploadStatus> } {
    const xhr = new XMLHttpRequest();
    const obs = new Observable<AzureUploadStatus>(observer => {
      const loadEvent = (event: ProgressEvent<XMLHttpRequestEventTarget>): void => {
        if (xhr.status > 0) {
          if (xhr.status >= 200 && xhr.status < 300) {
            observer.complete();
          } else {
            observer.error({ type: 'onload', event });
          }
        } else {
          // We get the preflight message
        }
      };
      xhr.open('PUT', blobUrl, true);
      xhr.upload.addEventListener('progress', event => {
        observer.next({ type: 'progress', event });
      });

      xhr.upload.addEventListener('load', loadEvent); // This event does not fire the status 201 online. Fallback on xhr
      xhr.addEventListener('load', loadEvent);

      xhr.upload.addEventListener('error', event => {
        observer.error({ type: 'onerror', event });
      });
      xhr.upload.addEventListener('abort', event => {
        observer.error({ type: 'onabort', event });
      });
      xhr.addEventListener('readystatechange', xhr.upload.onload as ANY);
      xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
      xhr.send(file);
    });

    return {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      abort: xhr.abort,
      status: obs,
    };
  }
}
