<div class="flex">
  <div class="section wm-card2">
    <div class="wm-description dates">
      <label>Date Range</label>
      <div class="read range">
        <div>
          <label>From</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateFrom"></wm-input-moment-picker>
        </div>
        <div>
          <label>End</label>
          <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateEnd"></wm-input-moment-picker>
        </div>
      </div>
    </div>
  </div>
</div>
