import { Injectable, inject } from '@angular/core';
import { type AvailableBilling, type Billing, type BillingRequest, type StripeAccountSession } from '@models/billing';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import { FeaturesService } from './features.service';

@Injectable()
export class BillingsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getAvailables(): Observable<AvailableBilling[]> {
    return this.http.get<AvailableBilling[]>(this.url.billingAvailables).pipe(
      map(availableBillings => {
        return [
          ...availableBillings.map(availableBilling => ({
            ...availableBilling,
            features: availableBilling.features.map(feature => FeaturesService.normalizeEnum(feature)),
            conciseFeatures: availableBilling.conciseFeatures.map(feature => FeaturesService.normalizeConciseEnum(feature)),
          })),
        ];
      })
    );
  }

  getCurrent(): Observable<Billing | null> {
    return this.http.get<Billing | null>(this.url.billingCurrent).pipe(
      map(billing => {
        if (billing) {
          return {
            ...billing,
            features: billing.features.map(feature => FeaturesService.normalizeEnum(feature)) ?? [],
          };
        }

        return null;
      })
    );
  }

  update(billingRequest: BillingRequest): Observable<StripeAccountSession> {
    return this.http.post<StripeAccountSession>(this.url.billingUpdate, { ...billingRequest }, skipBubbleError());
  }

  requested(): Observable<void> {
    return this.http.post<void>(this.url.billingRequested, skipBubbleError());
  }
}
