import { DatePipe } from '@angular/common';
import { inject, type Type } from '@angular/core';
import { FilterDynamicCheckboxComponent } from '@controls/filter-dynamic/filter-dynamic-checkbox.component';
import { FilterDynamicDropdownComponent } from '@controls/filter-dynamic/filter-dynamic-dropdown.component';
import { FilterDynamicMagicDateComponent } from '@controls/filter-dynamic/filter-dynamic-magicdate.component';
import { FilterDynamicTextComponent } from '@controls/filter-dynamic/filter-dynamic-text.component';
import { TableDynamicNodeDateComponent } from '@controls/table-dynamic/table-dynamic-node-date.component';
import { TableDynamicNodeDeletedComponent } from '@controls/table-dynamic/table-dynamic-node-deleted.component';
import { TableDynamicNodeLinkComponent } from '@controls/table-dynamic/table-dynamic-node-link.component';
import { TableDynamicNodeNotesComponent } from '@controls/table-dynamic/table-dynamic-node-notes.component';
import { TableDynamicNodeTextComponent } from '@controls/table-dynamic/table-dynamic-node-text.component';
import { AddressPipe } from '@pipes/address.pipe';
import { EmailPipe } from '@pipes/email.pipe';
import { EntityPipe } from '@pipes/entity.pipe';
import { PhonePipe } from '@pipes/phone.pipe';
import { CallWorkOrderTypesService } from '@services/live/call-work-order-types.service';
import { DutyCategoriesService } from '@services/live/duty-categories.service';
import { StaticDataService } from '@services/static-data.service';
import { notEmpty, unique } from '@utility/array';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { firstValueFrom } from 'rxjs';
import { type CallWorkOrderTypeInformation } from './call-work-order-type-models';
import {
  getPriorityText,
  getStatusText,
  type DutyCategoryInformation,
  type DutyInformation,
  type DutyInformationForList,
  type Priority,
} from './duty-models';
import {
  combineData,
  getReversedFilterConfig,
  LogicalOperator,
  magicDateTransform,
  type FancyFilterConfig,
  type FancyFilterImpl,
  type FancyFilterMagicDateRange,
  type LogicalOperatorData,
  type TableColumnDefinition,
} from './filter-models';
import { type RequiredTextExtraResource, type RequiredTextExtraResource1 } from './resource';
import { type TechnicianInformation } from './technician';

export enum FilterDutyDeletedData {
  NotSet = 0,
  DeletedOnly = 1,
  IncludesDeleted = 2,
}

interface FilterDutyDatesData {
  date?: FancyFilterMagicDateRange;
  overdue?: boolean;
  entityStatus: FilterDutyDeletedData | null;
}

export class FilterDutyDates implements FancyFilterImpl<FilterDutyDatesData> {
  private readonly datePipe = inject(DatePipe);

  readonly code = 'Duty';

  getUIConfig(): FancyFilterConfig<FilterDutyDatesData, any>[] {
    const config1: FancyFilterConfig<FilterDutyDatesData, FancyFilterMagicDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value, context);
      },
      ui: {
        type: FilterDynamicMagicDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Date',
          code: this.code,
          name: `${this.code}-date`,
        },
      },
    };

    const config2: FancyFilterConfig<FilterDutyDatesData, [boolean?]> = {
      get: controlValue => {
        return controlValue?.overdue ? [true] : [];
      },
      set: (value, context) => {
        return combineData('overdue', value?.[0] || null, context);
      },
      ui: {
        type: FilterDynamicCheckboxComponent,
        closeAfterSelection: false,
        inputs: {
          code: this.code,
          name: `${this.code}-overdue`,
          options: [
            {
              id: true,
              text: 'Overdue',
            },
          ],
        },
      },
    };

    const config3: FancyFilterConfig<FilterDutyDatesData, FilterDutyDeletedData> = {
      get: controlValue => {
        return controlValue?.entityStatus ?? null;
      },
      set: (value, context) => {
        return combineData('entityStatus', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Status',
          code: this.code,
          name: `${this.code}-status`,
          options: [
            { id: 0, text: 'Active Tasks Only' },
            { id: 1, text: 'Deleted Tasks Only' },
            { id: 2, text: 'Includes Deleted Tasks' },
          ],
        },
      },
    };
    return [config1, config2, config3];
  }

  getUISummary(value: FilterDutyDatesData | null): string {
    let dueMoment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        dueMoment = `due beween ${magicDateTransform(value.date, 'from', false, this.datePipe)} and ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      } else if (value?.date.from) {
        dueMoment = `due after ${magicDateTransform(value.date, 'from', false, this.datePipe)}`;
      } else if (value?.date.to) {
        dueMoment = `due before ${magicDateTransform(value.date, 'to', false, this.datePipe)}`;
      }
    }

    let entityStatus = '';
    switch (value?.entityStatus) {
      case FilterDutyDeletedData.DeletedOnly:
        entityStatus = 'Tasks are deleted';
        break;
      case FilterDutyDeletedData.IncludesDeleted:
        entityStatus = 'Tasks are active or deleted';
        break;
    }

    if (!entityStatus) {
      entityStatus = 'Tasks are active';
    }

    const withOverdue = value?.overdue ? 'including overdue' : null;
    return [entityStatus, dueMoment, withOverdue].filter(notEmpty).join(', ');
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Due Date',
    };
  }

  getIcon() {
    return { matIcon: 'calendar_clock' };
  }
}

export enum FilterDutyStatusDataType {
  All = 0,
  Open = 1,
  InProgress = 2,
  Completed = 3,
}

interface FilterDutyStatusData extends LogicalOperatorData {
  value: FilterDutyStatusDataType;
}

export class FilterDutyStatus implements FancyFilterImpl<FilterDutyStatusData> {
  readonly code = 'Status';

  getUIConfig(): FancyFilterConfig<FilterDutyStatusData, any>[] {
    const config1: FancyFilterConfig<FilterDutyStatusData, FilterDutyStatusDataType> = {
      get: controlValue => {
        return controlValue?.value ?? null;
      },
      set: (value, context) => {
        return combineData('value', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Status',
          code: this.code,
          name: `${this.code}`,
          options: [
            { id: FilterDutyStatusDataType.All, text: 'All' },
            { id: FilterDutyStatusDataType.Open, text: 'Open' },
            { id: FilterDutyStatusDataType.InProgress, text: 'In Progress' },
            { id: FilterDutyStatusDataType.Completed, text: 'Completed' },
          ],
        },
      },
    };
    return [config1, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterDutyStatusData | null): string {
    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    let entityStatus = '';
    switch (value?.value) {
      case FilterDutyStatusDataType.Open:
        entityStatus = `Tasks that are ${notWord}open`;
        break;
      case FilterDutyStatusDataType.InProgress:
        entityStatus = `Tasks that are ${notWord}in progress`;
        break;
      case FilterDutyStatusDataType.Completed:
        entityStatus = `Tasks that are ${notWord}completed`;
        break;
    }

    if (!entityStatus) {
      entityStatus = 'Tasks with any status';
    }

    return entityStatus;
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Status',
    };
  }

  getIcon() {
    return { matIcon: 'check_circle' };
  }
}

interface FilterDutyPriorityData extends LogicalOperatorData {
  items: Priority[];
}

export class FilterDutyPriority implements FancyFilterImpl<FilterDutyPriorityData, any> {
  readonly code = 'Priority';

  getUIConfig(): FancyFilterConfig<FilterDutyPriorityData, any>[] {
    const config1: FancyFilterConfig<FilterDutyPriorityData, Priority[]> = {
      get: controlValue => {
        return controlValue?.items ?? null;
      },
      set: (value, context) => {
        return combineData('items', value?.length ? value : null, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          code: this.code,
          name: `${this.code}`,
          clearable: true,
          multiselect: true,
          options: [
            { id: 4, text: 'Low' },
            { id: 3, text: 'Normal' },
            { id: 2, text: 'High' },
            { id: 1, text: 'End of Day' },
          ],
        },
      },
    };
    return [config1, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterDutyPriorityData | null): string {
    const results: string[] = [];

    if (value) {
      for (const priority of value.items) {
        results.push(getPriorityText(priority));
      }
    }

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    if (results.length === 1) {
      return `Task priority is ${notWord}${results[0]}`;
    } else if (results.length) {
      return `Task priority is ${notWord}one of ${results.join(', ')}`;
    }

    return 'Tasks has any proprity';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Priority',
    };
  }

  getIcon() {
    return { matIcon: 'priority_high' };
  }
}

interface FilterDutyTaskTypeData extends LogicalOperatorData {
  value: Id;
}

export class FilterDutyTaskType implements FancyFilterImpl<FilterDutyTaskTypeData> {
  readonly dutyCategoriesService = inject(DutyCategoriesService);

  readonly code = 'TaskType';

  private options: DutyCategoryInformation[] = [];

  async load(): Promise<void> {
    const dutyCategories = await firstValueFrom(this.dutyCategoriesService.list());
    this.options = dutyCategories;
  }

  getUIConfig(): FancyFilterConfig<FilterDutyTaskTypeData, any>[] {
    const config1: FancyFilterConfig<FilterDutyTaskTypeData, Id> = {
      get: controlValue => {
        return controlValue?.value ?? null;
      },
      set: (value, context) => {
        return combineData('value', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: true,
        inputs: {
          code: this.code,
          name: `${this.code}`,
          clearable: true,
          options: this.options.map(option => {
            return {
              id: option.id,
              text: option.text,
            };
          }),
        },
      },
    };
    return [config1, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterDutyTaskTypeData | null): string {
    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';
    if (value?.value) {
      return `Task type is ${notWord}${this.options.find(m => m.id === value.value)?.text ?? ''}`;
    }

    return 'Task type is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Task Type',
    };
  }

  getIcon() {
    return { matIcon: 'task' };
  }
}

interface FilterAssigneeData extends LogicalOperatorData {
  value: Id;
}

export class FilterDutyAssignee implements FancyFilterImpl<FilterAssigneeData> {
  readonly staticDataService = inject(StaticDataService);

  readonly code = 'Technician';

  private options: TechnicianInformation[] = [];

  async load(): Promise<void> {
    const technicians = await firstValueFrom(this.staticDataService.getTechnicians());
    this.options = technicians;
  }

  getUIConfig(): FancyFilterConfig<FilterAssigneeData, any>[] {
    const options: RequiredTextExtraResource[] = this.options.map(option => {
      return {
        id: option.id,
        text: option.text,
        image: option.image,
      };
    });
    const noAssignee: RequiredTextExtraResource = {
      id: -1,
      text: 'No Assignee',
    };
    const finalOptions = [noAssignee].concat(...options);

    const config1: FancyFilterConfig<FilterAssigneeData, Id> = {
      get: controlValue => {
        return controlValue?.value ?? null;
      },
      set: (value, context) => {
        return combineData('value', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: true,
        inputs: {
          code: this.code,
          name: `${this.code}`,
          clearable: true,
          options: finalOptions,
        },
      },
    };
    return [config1, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterAssigneeData | null): string {
    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    if (value?.value === -1) {
      return `Task has no assignee`;
    } else if (value?.value) {
      return `Task assignee is ${notWord}${this.options.find(m => m.id === value.value)?.text ?? ''}`;
    }

    return 'Task assignee is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Assignee',
    };
  }

  getIcon() {
    return { matIcon: 'person' };
  }
}

interface FilterDutyZipData {
  text: string;
}

export class FilterDutyZip implements FancyFilterImpl<FilterDutyZipData, string> {
  readonly code = 'Zip';

  getUIConfig(): FancyFilterConfig<FilterDutyZipData, string>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.text ?? null;
        },
        set: (value, context) => {
          return combineData('text', value == null || value === '' ? null : value, context);
        },
        ui: {
          type: FilterDynamicTextComponent,
          closeAfterSelection: false,
          inputs: {
            /// /label: 'Brand',
            code: this.code,
            name: this.code,
          },
        },
      },
    ];
  }

  getUISummary(value: FilterDutyZipData | null): string {
    if (value?.text) {
      return `Zip is ${value.text}`;
    }

    return 'Zip is any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Zip',
    };
  }

  getIcon() {
    return { matIcon: 'home' };
  }
}

export class ColumnDutyDue implements TableColumnDefinition<DutyInformationForList> {
  private readonly datePipe = inject(DatePipe);

  readonly id = 'due';

  readonly text = 'Due';

  readonly dataTestId = 'due';

  readonly required = true;

  readonly order = Number.NEGATIVE_INFINITY;

  readonly classes = ['col-due', 'col-date'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        routerLink: ['/tasks', item.data.id],
        text: item.data.dueDate ? this.datePipe.transform(item.data.dueDate, this.getDateFormat(item.data)) : 'Unspecified Date',
        className: {
          important: this.isDue(item.data),
        },
      },
    };
  }

  private getDateFormat(line: DutyInformation): string {
    if (line.withTime) {
      return 'short';
    }

    return 'shortDate';
  }

  private isDue(line: DutyInformation): boolean {
    if (!line.dueDate) {
      return true;
    }

    const currentDate = new Date();
    const lineDate = line.dueDate;

    return differenceInSeconds(lineDate, currentDate) < 0;
  }
}

export class ColumnDutyTask implements TableColumnDefinition<DutyInformationForList> {
  readonly id = 'task-number';

  readonly text = 'Task #';

  readonly classes = ['col-task-number'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: `#${item.data.localId}`,
      },
    };
  }
}

export class ColumnDutyNotes implements TableColumnDefinition<DutyInformationForList> {
  readonly id = 'notes';

  readonly text = 'Notes';

  readonly classes = ['col-notes'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeNotesComponent,
      inputs: {
        text: item.data.notes,
      },
    };
  }
}

export class ColumnDutyCompletion implements TableColumnDefinition<DutyInformationForList> {
  readonly id = 'completion';

  readonly text = 'Completion';

  readonly classes = ['col-completion'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: getStatusText(item.data.status),
      },
    };
  }
}

export class ColumnDutyAssignee implements TableColumnDefinition<DutyInformationForList> {
  private readonly staticDataService = inject(StaticDataService);

  readonly id = 'assignee';

  readonly text = 'Assignee';

  readonly classes = ['col-assignee'];

  private technicians: TechnicianInformation[] = [];

  async load(): Promise<void> {
    this.technicians = await firstValueFrom(this.staticDataService.getTechnicians());
  }

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: item.data.technicianId ? this.technicians.find(m => m.id === item.data.technicianId)?.text : 'Unassigned',
      },
    };
  }
}

export class ColumnDutyTaskType implements TableColumnDefinition<DutyInformationForList> {
  private readonly dutyCategoriesService = inject(DutyCategoriesService);

  readonly id = 'task-type';

  readonly text = 'Task Type';

  readonly classes = ['col-task-type'];

  private dutyCategories: DutyCategoryInformation[] = [];

  async load(): Promise<void> {
    this.dutyCategories = await firstValueFrom(this.dutyCategoriesService.list());
  }

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: this.dutyCategories.find(m => m.id === item.data.categoryId)?.text ?? 'Unknown',
      },
    };
  }
}

export class ColumnDutyPriority implements TableColumnDefinition<DutyInformationForList> {
  readonly id = 'priority';

  readonly text = 'Priority';

  readonly classes = ['col-priority'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: getPriorityText(item.data.priority),
      },
    };
  }
}

export class ColumnDutyCustomer implements TableColumnDefinition<DutyInformationForList> {
  private readonly entity = inject(EntityPipe);

  readonly id = 'customer';

  readonly text = 'Customer';

  readonly classes = ['col-customer', 'col-200'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        text: this.entity.transform(item.customerEntity),
        routerLink: ['/customers', item.customerId],
      },
    };
  }
}

export class ColumnDutyPhone implements TableColumnDefinition<DutyInformationForList> {
  private readonly phone = inject(PhonePipe);

  readonly id = 'phone';

  readonly text = 'Phone';

  readonly classes = ['col-phone'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: this.phone.transform(item.customerEntity?.phone),
      },
    };
  }
}

export class ColumnDutyEmail implements TableColumnDefinition<DutyInformationForList> {
  private readonly email = inject(EmailPipe);

  readonly id = 'email';

  readonly text = 'Email';

  readonly classes = ['col-email'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: this.email.transform(item.customerEntity?.email),
      },
    };
  }
}

export class ColumnDutySite implements TableColumnDefinition<DutyInformationForList> {
  private readonly address = inject(AddressPipe);

  readonly id = 'site';

  readonly text = 'Site';

  readonly classes = ['col-site'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        text: item.siteAddress ? this.address.transform(item.siteAddress) : 'No Address',
        routerLink: item.siteAddress ? ['/customers', item.customerId, 'sites', item.siteId] : [],
      },
    };
  }
}

export class ColumnDutyZip implements TableColumnDefinition<DutyInformationForList> {
  readonly id = 'zip';

  readonly text = 'Zip';

  readonly classes = ['col-zip'];

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: item.siteAddress?.zip,
      },
    };
  }
}

const idSeparator = '/';
export class ColumnDutyLastVisit implements TableColumnDefinition<DutyInformationForList> {
  readonly callWorkOrderTypesService = inject(CallWorkOrderTypesService);

  readonly id = 'last-visit';

  readonly text = 'Last Visit';

  readonly classes = ['col-short-date'];

  private callWorkOrderTypes: CallWorkOrderTypeInformation[] = [];

  async load(): Promise<void> {
    this.callWorkOrderTypes = await firstValueFrom(this.callWorkOrderTypesService.list());
  }

  getColumns(items: DutyInformationForList[]) {
    const callWorkOrderTypeIds = unique(items.flatMap(item => item.lastVisits?.map(lastVisit => lastVisit.callTypeId) ?? []));

    return [{ id: `${this.id}${idSeparator}0`, text: 'Last Visit' }].concat(
      callWorkOrderTypeIds.map(callWorkOrderTypeId => ({
        id: `${this.id}${idSeparator}${callWorkOrderTypeId}`,
        text: `${this.callWorkOrderTypes.find(callWorkOrderType => callWorkOrderType.id === callWorkOrderTypeId)?.text ?? ''} Last Visit`,
      }))
    );
  }

  renderComponent(item: DutyInformationForList, id: string) {
    const [_, callWorkOrderTypeIdStr] = id.split(idSeparator);
    const callWorkOrderTypeId = parseInt(callWorkOrderTypeIdStr, 10);

    return {
      component: TableDynamicNodeDateComponent,
      inputs: {
        date: callWorkOrderTypeId
          ? item.lastVisits?.find(lastVisit => lastVisit.callTypeId === callWorkOrderTypeId)?.lastVisit
          : item.lastVisit,
        format: 'shortDate',
      },
    };
  }
}

export class ColumnDutyDeleted implements TableColumnDefinition<DutyInformationForList> {
  readonly id = 'deleted';

  readonly text = 'Status';

  readonly classes = ['col-status'];

  readonly required = true;

  readonly order = Number.POSITIVE_INFINITY;

  renderComponent(item: DutyInformationForList) {
    return {
      component: TableDynamicNodeDeletedComponent,
      inputs: {
        show: item.data.hidden,
      },
    };
  }
}

export const supportedDutyFilterTypes: readonly Type<FancyFilterImpl<any>>[] = [
  FilterDutyDates,
  FilterDutyStatus,
  FilterDutyPriority,
  FilterDutyTaskType,
  FilterDutyAssignee,
  FilterDutyZip,
  // FilterDeleted,
] as const;

export const allSupportedDutyColumns: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnDutyDue,
  ColumnDutyTask,
  ColumnDutyNotes,
  ColumnDutyCompletion,
  ColumnDutyAssignee,
  ColumnDutyTaskType,
  ColumnDutyPriority,
  ColumnDutyCustomer,
  ColumnDutyPhone,
  ColumnDutyEmail,
  ColumnDutySite,
  ColumnDutyZip,
  ColumnDutyLastVisit,
  ColumnDutyDeleted,
] as const;

export const supportedDutyColumnsForCustomer: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnDutyDue,
  ColumnDutyTask,
  ColumnDutyNotes,
  ColumnDutyCompletion,
  ColumnDutyAssignee,
  ColumnDutyTaskType,
  ColumnDutyPriority,
  ColumnDutySite,
  ColumnDutyZip,
  ColumnDutyLastVisit,
  ColumnDutyDeleted,
] as const;

export const supportedDutyColumnsForSite: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnDutyDue,
  ColumnDutyTask,
  ColumnDutyNotes,
  ColumnDutyCompletion,
  ColumnDutyAssignee,
  ColumnDutyTaskType,
  ColumnDutyPriority,
  ColumnDutyDeleted,
] as const;

export const providers = [...supportedDutyFilterTypes, ...allSupportedDutyColumns];
