import { Component, effect, forwardRef, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { RwCheckbox2Component } from '@controls/rw-checkbox/rw-checkbox2.component';
import { Mode } from '@models/form';
import { type RequiredTextResource1 } from '@models/resource';
import { DynamicFilterService2 } from '@services/dynamic-filter.service';
import { combineLatest } from 'rxjs';
import { concatMap, filter } from 'rxjs/operators';

@Component({
  template: `<div class="wm-description">
    <label>{{ label() }}</label>
    <div class="read">
      @for (option of options(); track option.id) {
        <wm-checkbox [ngModel]="getCheckbox(option.id)" (ngModelChange)="setCheckbox(option.id, $event)">{{ option.text }}</wm-checkbox>
      }
    </div>
  </div>`,
  styleUrl: 'filter-dynamic-checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDynamicCheckboxComponent),
      multi: true,
    },
  ],
  imports: [RwCheckbox2Component, FormsModule, CheckboxComponent],
  standalone: true,
})
export class FilterDynamicCheckboxComponent<T> extends ElementBaseWithFormControl<T[]> {
  readonly Mode = Mode;

  private readonly dynamicFilterService = inject(DynamicFilterService2);

  readonly filterId = input<string>('');
  readonly label = input<string>('');
  readonly code = input<string>(''); // required
  readonly name = input<string>(''); // required
  readonly options = input.required<RequiredTextResource1<T>[]>();

  private readonly valueChanges = toSignal(this.control.valueChanges);
  private readonly serviceChanges = toSignal(
    combineLatest([toObservable(this.code), toObservable(this.name)]).pipe(
      filter(([code, name]) => !!code && !!name),
      concatMap(([code, name]) => this.dynamicFilterService.changes<T[]>(this.filterId(), code, name))
    ),
    { initialValue: [] }
  );

  _ = effect(() => {
    this.dynamicFilterService.patch(this.filterId(), this.code(), this.name(), this.valueChanges());
  });

  __ = effect(
    () => {
      this.control.patchValue(this.serviceChanges() ?? []);
    },
    { allowSignalWrites: true }
  );

  constructor() {
    super(() => []);
  }

  getCheckbox(id: T): boolean {
    const raw = this.control.getRawValue();
    return raw.includes(id);
  }

  setCheckbox(id: T, value: boolean): void {
    const filtered = this.control.getRawValue().filter(m => m !== id);
    if (value) {
      filtered.push(id);
    }

    this.control.patchValue(filtered);
  }
}
