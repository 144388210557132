import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  inject,
  input,
  output,
  type ElementRef,
  type OnChanges,
  type OnDestroy,
  type SimpleChanges,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ButtonComponent } from '@controls/button/button.component';
import { FilterButtonDeprecatedComponent } from '@controls/filter-button/filter-button-deprecated.component';
import { FilterComponent } from '@controls/filter/filter.component';
import { FilterDynamicComponent } from '@dialogviews/filters/filter-dynamic.component';
import { HelpDialogComponent } from '@dialogviews/help-dialog.component';
import { InitDirective } from '@directives/init.directive';
import { TemplateRefDirective } from '@directives/template-ref.directive';
import { DIALOG_SERVICE_IMPL, type PopoverButton } from '@models/dialog';
import { type DynamicFilterHelper, type FilterByRule } from '@models/filter-models';
import { HELP_JSON_IMPL } from '@models/help';
import { type SelfClickableResource } from '@models/resource';
import { AsyncSafePipe } from '@pipes/async-safe.pipe';
import { AppInsightsService } from '@services/app-insights.service';
import { newGuid } from '@utility/string';
import { type Observable, type Subscription } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { type FilterHelper, type FilterInfo } from '../../services/pager.service';
import { TimerService } from '../../services/timer.service';
import * as dom from '../../utility/dom';
import { WindowRefService } from './../../services/window-ref.service';

@Component({
  selector: 'wm-table-action',
  templateUrl: 'table-action.component.html',
  styleUrls: ['table-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TemplateRefDirective,
    FilterComponent,
    AsyncSafePipe,
    ButtonComponent,
    FilterButtonDeprecatedComponent,
    InitDirective,
    FilterDynamicComponent,
  ],
})
export class TableActionComponent implements OnDestroy, OnChanges {
  private readonly cd = inject(ChangeDetectorRef);
  private readonly timerService = inject(TimerService);
  private readonly helpJsonService = inject(HELP_JSON_IMPL);
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  private readonly windowRefService = inject(WindowRefService);
  private readonly appInsightsService = inject(AppInsightsService);

  readonly actions = input<SelfClickableResource[]>();

  /** @deprecated */
  @Input()
  filterHelper: FilterHelper<any> | null = null;

  /** @deprecated */
  private _dynamicFilterHelper?: DynamicFilterHelper<any, any>;

  /** @deprecated */
  @Input()
  public get dynamicFilterHelper(): DynamicFilterHelper<any, any> | undefined {
    return this._dynamicFilterHelper;
  }

  public set dynamicFilterHelper(value: DynamicFilterHelper<any, any> | undefined) {
    this._dynamicFilterHelper = value;

    if (value) {
      this.activeFilters$ = value.currentListParameters$.pipe(
        map(currentListParameters => {
          // For now we only support first level and.
          return currentListParameters.filterBys?.rules?.map(rule => (rule as FilterByRule<any>)?.text ?? '') ?? [];
        })
      );
    }
  }

  readonly printPage = input<string>();
  readonly printExternal = input(false);
  readonly deleteSelectedGroups = input(false);
  readonly settingsCogEnabled = input(true);
  readonly showDeleted = input(false);
  readonly showDeletedTitle = input('Show Deleted');
  readonly helpPage = input<string>();
  readonly helpSection = input<string>();

  readonly deleteSelectedClick = output<void>();
  readonly printExternalClick = output<void>();

  // Someone is reaching for this as Observable.
  // We should change this.
  @Output()
  showDeletedClick = new EventEmitter<void>();

  readonly clearAllFilters = output<void>();
  readonly openChange = output<boolean>();
  readonly editClick = output<void>();

  readonly helpId = computed(() => {
    const page = this.helpPage();
    const section = this.helpSection();
    if (page && section) {
      return this.helpJsonService.getHelpId(page, section);
    }
  });

  private readonly helpData = toSignal(toObservable(this.helpId).pipe(concatMap(helpId => this.helpJsonService.getHelpData$(helpId))));
  private readonly helpModel = toSignal(this.helpJsonService.getJson$());
  readonly shouldDisplayLocation = computed(() => {
    const helpId = this.helpId();
    const helpModel = this.helpModel();
    if (helpId && helpModel) {
      return helpModel.displayLocations;
    }
  });

  readonly shouldDisplayCog = computed(() => !!this.helpData());
  readonly shouldDisplayActions = computed(() => this.shouldDisplayLocation() || this.shouldDisplayCog());
  readonly showSettingsCog = computed(
    () =>
      !!this.printPage() || this.printExternal() || this.deleteSelectedGroups() || this.showDeleted() || (this.actions()?.length ?? 0) > 0
  );

  readonly dropdownElements = computed(() => {
    const dropdownElements: PopoverButton[] = [];

    if (this.printPage()) {
      dropdownElements.push({
        id: 1,
        text: 'Print',
        click: () => {
          this.windowRefService.print(true);
          this.appInsightsService.trackEvent('print', {
            page: this.printPage,
          });
        },
      });
    }

    if (this.deleteSelectedGroups()) {
      dropdownElements.push({
        id: 2,
        text: 'Delete Selected Tax Items',
        click: () => {
          this.deleteSelectedClick.emit();
        },
      });
    }

    if (this.showDeleted()) {
      dropdownElements.push({
        id: 3,
        text: this.showDeletedTitle(),
        click: () => {
          this.showDeletedClick.emit();
        },
      });
    }

    if (this.printExternal()) {
      dropdownElements.push({
        id: 4,
        text: 'Save as PDF',
        click: () => {
          this.printExternalClick.emit();
        },
      });
    }

    const actions = this.actions();
    if (actions) {
      for (const action of actions) {
        dropdownElements.push({
          id: newGuid(),
          text: action.text,
          click: action.click,
        });
      }
    }

    return dropdownElements;
  });

  /** @deprecated */
  activeFilters$?: Observable<string[]>;

  /** @deprecated */
  private filters: FilterInfo[] = [];

  /** @deprecated */
  pointerAt?: number;

  private settingCog: ElementRef<HTMLButtonElement> | null = null;

  private inputSubscriptions: Subscription[] = [];

  /** @deprecated */
  initInputs(): void {
    this.deinitInputs();
    if (this.filterHelper?.context?.filters) {
      this.inputSubscriptions.push(
        this.filterHelper.context.filters.subscribe(filters => {
          this.filters = filters;
          this.refresh();
        })
      );
    }
  }

  deinitInputs(): void {
    for (const inputSubscription of this.inputSubscriptions) {
      inputSubscription.unsubscribe();
    }

    this.inputSubscriptions = [];
  }

  initSettingCog(settingCog: ElementRef<HTMLButtonElement>): void {
    this.settingCog = settingCog;
  }

  ngOnDestroy(): void {
    this.deinitInputs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const change in changes) {
      if (change === 'filterHelper') {
        this.initInputs();
      }
    }
  }

  /** @deprecated */
  refresh(): void {
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  /** @deprecated */
  positionFilter(filterButton: ElementRef): void {
    this.timerService.setTimeout(() => {
      const filterButtonEl = filterButton.nativeElement;
      const parent = dom.closest(filterButtonEl, '.top-content');
      const parentWidth: number = parent?.offsetWidth ?? 0;
      const filterPosition: number = filterButtonEl.offsetLeft;

      this.pointerAt = parentWidth - filterPosition - filterButtonEl.offsetWidth / 2 - 20 / 2; // Triangle Size
      this.refresh();
    }, 0); // Images need to appear
  }

  /** @deprecated */
  internalEditClick(): void {
    this.filterHelper?.openDialog();
    this.dynamicFilterHelper?.openDialog();
    this.editClick.emit();
  }

  /** @deprecated */
  internalClearAllClick(): void {
    this.filterHelper?.clearAllFilters();
    this.dynamicFilterHelper?.clearAllFilters();
    this.clearAllFilters.emit();
  }

  /** @deprecated */
  internalOpenChange(open: boolean): void {
    this.filterHelper?.openChange(open);
    this.dynamicFilterHelper?.openChange(open);
    this.openChange.emit(open);
  }

  /** @deprecated */
  get activeFilters(): string[] {
    return this.filters
      .filter(m => !!m.text)
      .map(m => {
        return m.text ?? '';
      });
  }

  settingClick(): void {
    if (this.settingCog) {
      this.dialog2.menu(this.dropdownElements(), this.settingCog.nativeElement, 'below');
    }
  }

  helpClick(): void {
    HelpDialogComponent.open(this.dialog2, this.helpData() as ANY);
  }
}
