<div class="wm-card2">
  <div class="icon">
    <img src="/assets/images/filter-active.svg" alt="" />
  </div>
  <div class="main">
    <div class="top">
      <div class="text">
        {{ filterText }}
      </div>
      <div class="actions">
        <button type="button" class="clear" (click)="clearAll()" [class.active]="clearActive">Clear All</button>
        <button type="button" class="edit" (click)="edit()">Edit Filter</button>
      </div>
    </div>
    <ul class="filters">
      <li class="filter" *ngFor="let activeFilter of activeFilters">
        {{ activeFilter }}
      </li>
    </ul>
  </div>
</div>
<div class="arrow" *ngIf="pointerAt" [style.right.px]="pointerAt"></div>
