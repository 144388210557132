<div class="flex">
  <div class="section wm-card2">
    <wm-radio-group class="wm-description dates" [formControl]="dateControl">
      <label>Dates</label>
      <div class="read">
        <div>
          <wm-radio-button [value]="0">None</wm-radio-button>
        </div>
        <div>
          <wm-radio-button [value]="1">Next 7 Days</wm-radio-button>
        </div>
        <div>
          <wm-radio-button [value]="2">Next 30 Days</wm-radio-button>
        </div>
        <div>
          <wm-radio-button [value]="3">Range</wm-radio-button>
        </div>
        <div class="range">
          <div>
            <label>From</label>
            <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateFrom"></wm-input-moment-picker>
          </div>
          <div>
            <label>End</label>
            <wm-input-moment-picker [showDate]="true" [(ngModel)]="dateEnd"></wm-input-moment-picker>
          </div>
        </div>
        <wm-checkbox class="overdue" [(ngModel)]="overdue" (click)="changeStatus()">Overdue</wm-checkbox>
      </div>
    </wm-radio-group>
    <div class="wm-description status">
      <label>Status</label>
      <div class="read">
        <wm-input-dropdown [(ngModel)]="status" [options]="statuses" dropdownParent="body"></wm-input-dropdown>
      </div>
    </div>
  </div>
  <div class="section wm-card2">
    <div class="wm-description priority">
      <label>Priority</label>
      <div class="read">
        <wm-checkbox [(ngModel)]="priorityLow">Low</wm-checkbox>
        <wm-checkbox [(ngModel)]="priorityNormal">Normal</wm-checkbox>
        <wm-checkbox [(ngModel)]="priorityHigh">High</wm-checkbox>
        <wm-checkbox [(ngModel)]="priorityBlocker">End of Day</wm-checkbox>
      </div>
    </div>
    <div class="wm-description task-type">
      <label>Task Type</label>
      <div class="read">
        <wm-input-dropdown
          [(ngModel)]="dutyCategoryId"
          [options]="dutyCategories"
          dropdownParent="body"
          [clearable]="true"
        ></wm-input-dropdown>
      </div>
    </div>
    <div class="wm-description assignee">
      <label>Assignee</label>
      <div class="read">
        <wm-input-image [(ngModel)]="assignee" [options]="technicians" dropdownParent="body"> </wm-input-image>
      </div>
    </div>
    <div class="wm-description zip">
      <label>Zip</label>
      <div class="read">
        <wm-input-text [(ngModel)]="zip"></wm-input-text>
      </div>
    </div>
    <div class="wm-description deleted">
      <label>Deleted</label>
      <div class="read">
        <wm-checkbox [(ngModel)]="deleted">Show Deleted</wm-checkbox>
      </div>
    </div>
  </div>
</div>
