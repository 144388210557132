<div class="wm-pager-bubble" [class.active]="active()" [class.initialized]="initialized()">
  <span class="bubble" [style.width.px]="width()"></span>
  <span class="text">
    <button class="arrow previous" href="javascript:;" (click)="internalPreviousClick()">
      <img src="/assets/images/arrow-paging-left.svg" title="Previous Page" alt="" [hidden]="!showPreviousArrow()" />
    </button>
    <span class="page"
      >Page
      <span class="value">{{ value() | number }}</span>
    </span>
    <button class="arrow next" href="javascript:;" (click)="internalNextClick()">
      <img src="/assets/images/arrow-paging-right.svg" title="Next Page" alt="" [hidden]="!showNextArrow()" />
    </button>
  </span>
</div>
