import { DatePipe } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { getAgreementBadgeTitle, getAgreementBadgeUrl, getVisitLeft } from '@cards/agreements/agreement-site-system-list.component';
import { type AgreementInformation } from '@models/cards/agreement-information';
import { type AgreementSiteSystemInformationForList } from '@models/cards/agreement-site-system-information';
import { AgreementsService } from '@services/live/agreements.service';
import { notEmpty } from '@utility/array';
import { addMonths } from 'date-fns';
import { combineLatest, concatMap, filter, map } from 'rxjs';

@Component({
  template: `@if (line(); as line) {
    @if (isRecurring()) {
      <div>Recurring</div>
    } @else {
      {{ endDate() | date: 'shortDate' }}
      @if (line.data.endDate !== null) {
        <img class="small-icon" src="/assets/images/agreement-edited.svg" alt="" title="The badge expires has been manually changed." />
      }
    }
    @if (line.data.deadDate) {
      <div class="benefits">Badge Expires: {{ line.data.deadDate | date: 'shortDate' }}</div>
    }
  }`,
  styles: [
    `
      img.small-icon {
        margin-left: 8px;
        width: 10px;
        height: 10px;
      }
    `,
  ],
  imports: [DatePipe],
  standalone: true,
})
export class TableDynamicNodeAgreementExpirationComponent {
  readonly getVisitLeft = getVisitLeft;
  readonly getAgreementBadgeUrl = getAgreementBadgeUrl;
  readonly getAgreementBadgeTitle = getAgreementBadgeTitle;

  readonly agreementsService = inject(AgreementsService);

  readonly line = input<AgreementSiteSystemInformationForList>();

  readonly agreements = toSignal(
    toObservable(this.line).pipe(
      filter(notEmpty),
      concatMap(line => this.agreementsService.list(line.data.agreementId))
    )
  );

  private readonly data = toSignal(
    combineLatest([toObservable(this.line), toObservable(this.agreements)]).pipe(map(([line, agreements]) => ({ line, agreements }))),
    { initialValue: { line: undefined, agreements: undefined } }
  );

  readonly isRecurring = toSignal(
    toObservable(this.data).pipe(
      map(
        ({ line, agreements }) =>
          !!(agreements as ANY as AgreementInformation[]).find(m => m.id === (line as ANY).data.agreementId)?.interval
      )
    )
  );

  readonly endDate = toSignal(
    toObservable(this.data).pipe(
      map(({ line, agreements }) => {
        if ((line as ANY).data.endDate) {
          return (line as ANY).data.endDate;
        }

        const agreement = (agreements as ANY as AgreementInformation[]).find(m => m.id === (line as ANY).data.agreementId);
        if (agreement) {
          return addMonths((line as ANY).data.createdDate, agreement.monthsValidFor);
        }

        return null;
      })
    )
  );
}
