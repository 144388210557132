<wm-rw2 [name]="name()" [formControl]="control" [mode]="mode()">
  @if (helperTemplate2(); as helperTemplate2) {
    <ng-template #helperTemplate let-control>
      <ng-container [ngTemplateOutlet]="helperTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    </ng-template>
  }
  <ng-template #writeTemplate let-control>
    @if (writeTemplate2(); as writeTemplate2) {
      <ng-container [ngTemplateOutlet]="writeTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    } @else {
      <wm-input-tag
        [formControl]="control"
        [options]="options()"
        [closeOnSelection]="true"
        [optgroups]="optgroups()"
        [optgroupField]="optgroupField()"
      />
    }
  </ng-template>
  <ng-template #readTemplate let-control>
    @if (readTemplate2(); as readTemplate2) {
      <ng-container [ngTemplateOutlet]="readTemplate2" [ngTemplateOutletContext]="{ $implicit: control, control: control }" />
    } @else {
      @for (tag of selectedOptions(); track $index) {
        <span class="tag-look">
          <wm-tag [editable]="false">{{ tag.text }}</wm-tag>
        </span>
      }
    }
  </ng-template>
</wm-rw2>

