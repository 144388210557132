<div class="wm-pager" [class.with-slider]="showSlider()" [class.hidden]="isHidden()" [class.initialized]="initialized()">
  <div class="wm-pager-data">
    <div class="wm-results">
      <span class="value">{{ results() | number }}</span>
      <span class="label">
        {results(), plural, =1 {Result} other {Results}}
      </span>
    </div>
    @if (showSlider()) {
      <div class="wm-connector">on</div>
      <div class="wm-pages">
        <span class="value">{{ lastPage() | number }}</span>
        <span class="label">Pages</span>
      </div>
    }
  </div>
  <div class="wm-pager-slider" [class.hidden]="!showSlider()" (mousemove)="_mousemove($event)" (click)="click($event)">
    <div class="start">
      Page
      <span class="value">{{ 1 | number }}</span>
    </div>
    <div #slider class="wm-slider">
      <wm-pager-bubble
        #highlightObj
        class="current highlight"
        [value]="highlightPage()"
        [active]="false"
        [width]="bubbleWidth()"
        [showPreviousArrow]="false"
        [showNextArrow]="false"
      />
      <wm-pager-bubble
        #currentPageObj
        class="current"
        [initialized]="true"
        [value]="currentPage()"
        [active]="true"
        [style.left]="leftPosition()"
        [width]="bubbleWidth()"
        [showPreviousArrow]="currentPage() > 1"
        [showNextArrow]="currentPage() < lastPage()"
        (previousClick)="previousClick()"
        (nextClick)="nextClick()"
      />
    </div>
    <div class="end">
      Page
      <span class="value">{{ lastPage() | number }}</span>
    </div>
  </div>
</div>
