import { AppRole } from './app-role';
import { type RequiredTextExtraResource } from './resource';

export interface TechnicianInformation extends RequiredTextExtraResource {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string | null;
  workPhone: string | null;
  notes: string | null;
  truckNumber: string | null;
  contractorId: Id;
  avatarId: Id | null;
  photoId: Id | null;
  lockedOut: boolean;
  roles: AppRole[];
  useAvatar: boolean;
  showOnMap: boolean;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  quickBooksOnlineEmployeeId: string | null;
}

export function createEmpty(): TechnicianInformation {
  return {
    id: 0,
    text: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: null,
    workPhone: null,
    notes: null,
    truckNumber: null,
    contractorId: 0,
    avatarId: null,
    photoId: null,
    lockedOut: false,
    roles: [],
    useAvatar: true,
    showOnMap: true,
    createdBy: '',
    createdDate: new Date(),
    updatedBy: '',
    updatedDate: new Date(),
    quickBooksOnlineEmployeeId: null,
  };
}

export const TechnicianRoleLabels: Record<number, string> = {};
TechnicianRoleLabels[AppRole.Administrator] = 'Administrator';
TechnicianRoleLabels[AppRole.ContractorOwner] = 'Owner';
TechnicianRoleLabels[AppRole.ContractorAdmin] = 'Contractor Admin';
TechnicianRoleLabels[AppRole.ContractorDispatcher] = 'Appointments';
TechnicianRoleLabels[AppRole.DashboardView] = 'Dashboard';
TechnicianRoleLabels[AppRole.ReportView] = 'Reports';
TechnicianRoleLabels[AppRole.ReplenishmentView] = 'Replenishments';
TechnicianRoleLabels[AppRole.AgreementSetup] = 'Agreements';
TechnicianRoleLabels[AppRole.Location] = 'Locations';
TechnicianRoleLabels[AppRole.Leaderboard] = 'Leaderboards';

export const TechnicianRoleImages: Record<number, string> = {};
TechnicianRoleImages[AppRole.ContractorOwner] = '/assets/images/role-owner.svg';
TechnicianRoleImages[AppRole.ContractorAdmin] = '/assets/images/role-admin.svg';
TechnicianRoleImages[AppRole.ContractorDispatcher] = '/assets/images/role-dispatcher.svg';
TechnicianRoleImages[AppRole.DashboardView] = '/assets/images/role-viewer.svg';
TechnicianRoleImages[AppRole.ReportView] = '/assets/images/role-reports.svg';
TechnicianRoleImages[AppRole.ReplenishmentView] = '/assets/images/role-replenishments.svg';
TechnicianRoleImages[AppRole.AgreementSetup] = '/assets/images/role-agreements.svg';
TechnicianRoleImages[AppRole.Location] = '/assets/images/role-maps.svg';
TechnicianRoleImages[AppRole.Leaderboard] = '/assets/images/role-leaderboard.svg';

export enum TechnicianStatus {
  Active,
  Locked,
  Deleted,
}
