import { Injectable, inject } from '@angular/core';
import { type TagEntityType, type TagInformation } from '@models/tag-models';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationCacheService, UpdateCategory } from '../application-cache.service';
import { UrlService } from '../url.service';

@Injectable()
export class TagsService {
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(...entityTypes: TagEntityType[]): Observable<TagInformation[]> {
    return this.listAll().pipe(map(tags => tags.filter(x => entityTypes.includes(x.entityType))));
  }

  listAll(): Observable<TagInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.Tags, this.url.tagList);
  }
}

