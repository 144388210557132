import { inject } from '@angular/core';
import { FilterDynamicDropdownComponent } from '@controls/filter-dynamic/filter-dynamic-dropdown.component';
import { Feature } from '@models/feature';
import {
  combineData,
  type FancyFilterConfig,
  type FancyFilterImpl,
  getReversedFilterConfig,
  LogicalOperator,
  type LogicalOperatorData,
} from '@models/filter-models';
import { type RequiredTextExtraResource1 } from '@models/resource';
import { type TagInformation, type TagEntityType, getTagEntityTypeName } from '@models/tag-models';
import { TagsService } from '@services/live/tags.service';
import { chain } from 'lodash';
import { firstValueFrom } from 'rxjs';

interface FilterTagData extends LogicalOperatorData {
  items?: Id[];
}

export class FilterTag implements FancyFilterImpl<FilterTagData, Id[]> {
  constructor(
    public code: string,
    private readonly entityTypes: TagEntityType[]
  ) {}

  private readonly tagsService = inject(TagsService);
  readonly multiple = true;
  readonly requiresFeatures = [Feature.Tag];

  private options: TagInformation[] = [];

  async load(): Promise<void> {
    this.options = await firstValueFrom(this.tagsService.list(...this.entityTypes));
  }

  getUIConfig(): FancyFilterConfig<FilterTagData, any>[] {
    return [
      {
        get: controlValue => {
          return controlValue?.items ?? [];
        },
        set: (value: number[], context) => {
          return combineData('items', value?.length ? value : null, context);
        },
        ui: {
          type: FilterDynamicDropdownComponent,
          closeAfterSelection: false,
          inputs: {
            code: this.code,
            name: this.code,
            clearable: true,
            multiselect: true,
            options: chain(this.options)
              .sortBy(x => `${getTagEntityTypeName(x.entityType)}${x.text.toLowerCase()}`)
              .value(),
            optgroupField: 'entityType',
            optgroups: chain(this.options)
              .uniqBy(x => x.entityType)
              .map(x => ({
                value: x.entityType,
                label: getTagEntityTypeName(x.entityType),
              }))
              .orderBy(x => x.label)
              .value(),
          },
        },
      },
      getReversedFilterConfig(this.code),
    ];
  }

  getUISummary(value: FilterTagData | null): string {
    const tags =
      value?.items
        ?.map(id => this.options.find(x => x.id === id))
        .filter(x => !!x)
        .map(x => (x ? `${getTagEntityTypeName(x.entityType)} : ${x.text}` : '')) ?? [];

    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    if (tags.length === 1) {
      return `Tags are ${notWord}${tags[0]}`;
    } else if (tags.length) {
      return `Tags are ${notWord}one of ${tags.join(', ')}`;
    }

    return 'Tags are any';
  }

  getSimpleName(): RequiredTextExtraResource1<string> {
    return {
      id: this.code,
      text: 'Tags',
    };
  }

  getIcon() {
    return { matIcon: 'sell' };
  }
}

