<div class="content">
  @if (popoverData.title) {
    <div class="title">{{ popoverData.title }}</div>
  }

  @if (popoverData.html) {
    <div class="text" [innerHTML]="popoverData.html"></div>
  } @else {
    <div class="text">{{ popoverData.text }}</div>
  }

  @if (popoverData.button) {
    <div class="button">
      <button wm-button type="button" (click)="buttonClick(popoverData.button)">
        <span class="text">{{ popoverData.button.text }}</span>
      </button>
    </div>
  }
</div>
