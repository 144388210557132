import { Injectable, type OnDestroy, inject } from '@angular/core';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import { type Observable, of, Subject } from 'rxjs';
import { ConciseFeature, Feature } from '@models/feature';
import { PubSubService } from '@services/pub-sub.service';

@Injectable()
export class FeaturesService implements OnDestroy {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly pubSubService = inject(PubSubService);

  private _featureObservable?: Observable<Feature[]>;
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.pubSubService
      .getEventEmitter('LOGIN')
      .pipe(takeUntil(this.destroy$))
      .pipe(
        tap(() => {
          console.log('Clearing FeaturesService.');
        })
      )
      .subscribe(() => {
        this.clear();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clear(): void {
    this._featureObservable = undefined;
  }

  getFeatures$(): Observable<Feature[]> {
    if (!this._featureObservable) {
      this._featureObservable = this.http.get<Feature[]>(this.url.features).pipe(
        map(features => features.map(feature => FeaturesService.normalizeEnum(feature))),
        shareReplay(1)
      );
    }

    return this._featureObservable;
  }

  hasFeature$(...requestedFeatures: Feature[]): Observable<boolean> {
    if (requestedFeatures.length === 0) {
      return of(false);
    }

    return this.getFeatures$().pipe(map(features => requestedFeatures.some(x => features.includes(x))));
  }

  static normalizeEnum(feature: Feature): Feature {
    if (typeof feature === 'number') {
      return feature;
    }

    return Feature[feature] as unknown as Feature;
  }

  static normalizeConciseEnum(conciseFeature: ConciseFeature): ConciseFeature {
    if (typeof conciseFeature === 'number') {
      return conciseFeature;
    }

    return ConciseFeature[conciseFeature] as unknown as ConciseFeature;
  }
}

