<span [class.image-only]="text.childNodes.length === 0" [class.with-ellipsis]="!!textMaxWidth()">
  @if (imageUrl()) {
    <img class="image" src="{{ imageUrl() }}" alt="" [style.width.px]="imageWidth()" [style.height.px]="imageHeight()" />
  }
  @if (matIcon()) {
    <mat-icon [style.font-size.px]="imageWidth()" [inline]="true" [fontSet]="fontSet()">{{ matIcon() }}</mat-icon>
  }
  <span class="text" [style.maxWidth.px]="textMaxWidth()" #text><ng-content></ng-content></span>
  @if (loading()) {
    <span class="spinner"></span>
  }
</span>
