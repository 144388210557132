import { type Mutable } from '@models/mutable';
import { BookType } from '@models/price-book-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { flattenArray, flattenResource, unique } from '@utility/array';
import { type PriceBookDetailState } from './price-book-detail.reducer';

function buildArray<T extends { id: Id }>(obj: Record<string, T & Mutable>, includeHidden?: boolean): T[] {
  const objArray = Object.values(obj);

  if (includeHidden) {
    return objArray;
  }

  return objArray.filter(m => !m.deleted);
}

export const PRICE_BOOK_DETAIL_STORE_KEY = 'priceBookDetail';

export const priceBookDetailStateSelector = createFeatureSelector<PriceBookDetailState>(PRICE_BOOK_DETAIL_STORE_KEY);

// price book detail
export const mode = createSelector(priceBookDetailStateSelector, state => state.edit);
export const priceBookId$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.priceBookId;
  });
export const priceBooks$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.entities.priceBooks;
  });
export const filter$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.filter || { text: null, propertyTypeId: 1, rateTypeId: 1, agreementId: null };
  });
export const partItems$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.entities.partItems;
  });
export const serviceRepairs$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.entities.serviceRepairs;
  });
export const priceBookCategoryPartItemIds$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.entities.priceBookCategoryPartItemIds;
  });
export const priceBookCategoryServiceRepairIds$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.entities.priceBookCategoryServiceRepairIds;
  });
export const priceBookCategories$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.entities.priceBookCategories;
  });
export const selections$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.selections;
  });
export const partItemIdSelections$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return state.group[group]?.partItemIdSelections;
  });
export const priceBookLoaded$ = (group: string) =>
  createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState) => {
    return !!state.group[group]?.entities;
  });

export const cacheCorrelationId$ = createSelector(priceBookDetailStateSelector, state => state._cacheCorrelationId);
export const saveCorrelationId$ = createSelector(priceBookDetailStateSelector, state => state._correlationId);
export const error$ = createSelector(priceBookDetailStateSelector, state => state._error);

export const priceBookWithCategories$ = (group: string) =>
  createSelector(priceBookCategories$(group), priceBookCategories => ({
    priceBookCategories: buildArray(priceBookCategories || {}),
  }));

export const selectedPriceBook$ = (group: string) =>
  createSelector(priceBookId$(group), priceBooks$(group), (priceBookId, priceBooks) => priceBooks?.[priceBookId as ANY] || null);

export const flattenPriceBookCategoriesForCurrentPriceBook$ = (group: string) =>
  createSelector(priceBookId$(group), priceBookWithCategories$(group), (priceBookId, priceBookCategories) =>
    flattenResource(priceBookCategories.priceBookCategories).filter(m => m.priceBookId === priceBookId && !m.hidden)
  );

export const priceBookInformations$ = (group: string) => createSelector(priceBooks$(group), priceBooks => buildArray(priceBooks || {}));

export const priceBooksWithServiceRepairsAllowingPartItems$ = (group: string) =>
  createSelector(priceBookInformations$(group), priceBooks => priceBooks.filter(m => m.type === BookType.Service));

export const serviceRepairInformations$ = (group: string) =>
  createSelector(serviceRepairs$(group), serviceRepairs => buildArray(serviceRepairs || {}));

export const partItemInformations$ = (group: string) => createSelector(partItems$(group), partItems => buildArray(partItems || {}));

export const serviceRepairsForCurrentPriceBook$ = (group: string) =>
  createSelector(
    flattenPriceBookCategoriesForCurrentPriceBook$(group),
    serviceRepairInformations$(group),
    (priceBookWithCategories, serviceRepairs) => {
      const flatten = flattenArray(priceBookWithCategories.map(m => m.serviceRepairIds));
      const serviceRepairIds = unique(flatten);
      return serviceRepairs.filter(m => serviceRepairIds.some(n => n.id === m.id));
    }
  );

export const partItemsForCurrentPriceBook$ = (group: string) =>
  createSelector(
    selectedPriceBook$(group),
    flattenPriceBookCategoriesForCurrentPriceBook$(group),
    serviceRepairInformations$(group),
    partItemInformations$(group),
    (selectedPriceBook, priceBookWithCategories, serviceRepairInformations, partItems) => {
      if (selectedPriceBook && selectedPriceBook.type === BookType.Distributors) {
        return partItems.filter(m => m.distributorId === selectedPriceBook.distributorId);
      }

      const flatten = flattenArray(priceBookWithCategories.map(m => m.partItemIds)).map(m => m.id);
      const partItemIdsInServiceRepairs = [].concat(...serviceRepairInformations.map(m => m.partItemIds as ANY)) as number[];
      const partItemIds = unique(flatten.concat(partItemIdsInServiceRepairs));
      return partItems.filter(m => partItemIds.includes(m.id));
    }
  );

export const GROUP_PAGE = 'page';
export const GROUP_DIALOG = 'dialog';
