import { Injectable, type ChangeDetectorRef } from '@angular/core';
import { type AbstractControl, type ValidationErrors, type ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, type Observable } from 'rxjs';

@Injectable()
export abstract class ValueAccessorBase3<T> implements ControlValueAccessor {
  protected readonly innerValue = new BehaviorSubject<T>(null as any);

  private readonly changed = new Array<(value: T) => void>();
  private readonly touched = new Array<() => void>();
  private readonly validatorChange = new Array<() => void>();

  get value$(): Observable<T | null> {
    return this.innerValue;
  }

  get value(): T {
    return this.innerValue.getValue();
  }

  set value(value: T) {
    if (this.innerValue.getValue() !== value) {
      this.innerValue.next(value);
      this.changed.forEach(f => {
        f(value);
      });
    }
  }

  constructor(protected createEmpty?: () => T) {}

  writeValue(value: T | null): void {
    // ValueAccessorBase2 writeValue
    this.innerValue.next(value ?? this.createEmpty?.() ?? (null as any));
  }

  registerOnChange(fn: (value: T) => void): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.touched.push(fn);
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    return {};
  }

  touch(): void {
    this.touched.forEach(f => {
      f();
    });
  }
}

/**
 * @deprecated Use ValueAccessorBase3
 */
export abstract class ValueAccessorBase2<T> implements ControlValueAccessor {
  protected readonly innerValue = new BehaviorSubject<T>(null as ANY);

  private readonly changed = new Array<(value: T) => void>();
  private readonly touched = new Array<() => void>();

  get value$(): Observable<T | null> {
    return this.innerValue;
  }

  get value(): T {
    return this.innerValue.getValue();
  }

  set value(value: T) {
    if (this.innerValue.getValue() !== value) {
      this.innerValue.next(value);
      this.changed.forEach(f => {
        f(value);
      });
    }
  }

  writeValue(value: T): void {
    // ValueAccessorBase2 writeValue
    this.innerValue.next(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.touched.push(fn);
  }

  touch(): void {
    this.touched.forEach(f => {
      f();
    });
  }
}

/**
 * @deprecated Use ValueAccessorBase3
 */
export abstract class ValueAccessorBase<T> implements ControlValueAccessor {
  private innerValue: T | undefined;

  private readonly changed = new Array<(value: T) => void>();
  private readonly touched = new Array<() => void>();

  get value(): T {
    return this.innerValue as ANY;
  }

  set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach(f => {
        f(value);
      });
    }
  }

  constructor(protected cd: ChangeDetectorRef) {}

  writeValue(value: T): void {
    this.innerValue = value;

    // This is mandatory to get the UI to update again after we do a quick patchValue()
    // Do not call detectChanges as it would potentially call viewDestroyedError
    // We are at our lowest level, we are not updating our children but ourselves
    // Hence, we call markForCheck(). NOT detectChanges()
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: T) => void): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.touched.push(fn);
  }

  touch(): void {
    this.touched.forEach(f => {
      f();
    });
  }
}
