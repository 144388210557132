/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type WorkOrderInformation } from '@models/cards/work-order-information';
import { type Creatable, type Deletable, type Mutable, type Updatable } from '@models/mutable';
import { type TaxItemEntry } from '@models/pricing-models';
import { type TaxItem } from '@models/tax-item';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { indexBy } from '@utility/array';
import { getObjectValues } from '@utility/object';
import { normalOrder } from '@utility/sort-fnc';
import { Decimal } from 'decimal.js';
import { isPlainObject } from 'lodash';
import {
  type AgreementSiteSystemState,
  type DebriefAnswerState,
  type RecommendationPhotoState,
  type RecommendationState,
  type SiteSystemState,
  type TagEntityState,
  type WorkOrderAgreementState,
  type WorkOrderDiscountState,
  type WorkOrderInformationState,
  type WorkOrderLoanApplicationState,
  type WorkOrderPaymentState,
  type WorkOrderRebateState,
  type WorkOrderRepairPartState,
  type WorkOrderState,
} from './work-order.reducer';
import { DeveloperError } from '@models/error-models';

function buildArray<T extends { id: Id }>(obj: Record<string, T & Mutable>, includeHidden?: boolean): T[] {
  const objArray = Object.values(obj);

  if (includeHidden) {
    return objArray;
  }

  return objArray.filter(m => !m.deleted);
}

export const WORK_ORDER_STORE_KEY = 'workOrder';

export const workOrderStateSelector = createFeatureSelector<WorkOrderState>(WORK_ORDER_STORE_KEY);

export const mode = createSelector(workOrderStateSelector, (state: WorkOrderState) => state.edit);
export const workOrder$ = createSelector(workOrderStateSelector, state => state.entities.workOrder);
export const entitiesSnapshot$ = createSelector(workOrderStateSelector, state => state.entitiesSnapshot);
export const workOrderLoanApplications$ = createSelector(workOrderStateSelector, state => state.entities.workOrderLoanApplications);
export const workOrderDetails$ = createSelector(workOrderStateSelector, state => state.entities.workOrderDetails);
export const workOrderRepairParts$ = createSelector(workOrderStateSelector, state => state.entities.workOrderRepairParts);
export const nonStockItems$ = createSelector(workOrderStateSelector, state => state.entities.nonStockItems);
export const workOrderDiscounts$ = createSelector(workOrderStateSelector, state => state.entities.workOrderDiscounts);
export const workOrderRebates$ = createSelector(workOrderStateSelector, state => state.entities.workOrderRebates);
export const workOrderAgreements$ = createSelector(workOrderStateSelector, state => state.entities.workOrderAgreements);
export const workOrderPayments$ = createSelector(workOrderStateSelector, state => state.entities.workOrderPayments);
export const recommendations$ = createSelector(workOrderStateSelector, state => state.entities.recommendations);
export const recommendationPhotos$ = createSelector(workOrderStateSelector, state => state.entities.recommendationPhotos);
export const debriefAnswers$ = createSelector(workOrderStateSelector, state => state.entities.debriefAnswers);
export const tagEntities$ = createSelector(workOrderStateSelector, state => state.entities.tagEntities);
export const inspectionAnswers$ = createSelector(workOrderStateSelector, state => state.entities.inspectionAnswers);
export const siteSystems$ = createSelector(workOrderStateSelector, state => state.entities.siteSystems);
export const workOrderSiteSystems$ = createSelector(workOrderStateSelector, state => state.entities.workOrderSiteSystems);
export const call$ = createSelector(workOrderStateSelector, state => state.entities.call);
export const siteInformation$ = createSelector(workOrderStateSelector, state => state.entities.siteInformation);
export const customerInformation$ = createSelector(workOrderStateSelector, state => state.entities.customerInformation);
export const siteSystemInformations$ = createSelector(workOrderStateSelector, state => state.entities.siteSystemInformations);
export const workOrderBaseViewData$ = createSelector(workOrderStateSelector, state => state.workOrderBaseViewData);
export const salesProposal$ = createSelector(workOrderStateSelector, state => state.entities.salesProposal);
export const salesProposalPackage$ = createSelector(workOrderStateSelector, state => state.entities.salesProposalPackage);
export const agreementSiteSystems$ = createSelector(workOrderStateSelector, state => state.entities.agreementSiteSystems);
export const internalTaxItemEntry$ = createSelector(workOrderStateSelector, state => state.entities.internalTaxItemEntry);

export const workOrderDetailArray$ = createSelector(workOrderDetails$, workOrderDetails =>
  buildArray(workOrderDetails).sort(normalOrder())
);
export const workOrderRepairPartArray$ = createSelector(workOrderRepairParts$, workOrderRepairParts => buildArray(workOrderRepairParts));
export const nonStockItemArray$ = createSelector(nonStockItems$, nonStockItems => buildArray(nonStockItems));
export const workOrderDiscountArray$ = createSelector(workOrderDiscounts$, workOrderDiscounts => buildArray(workOrderDiscounts));
export const workOrderRebateArray$ = createSelector(workOrderRebates$, workOrderRebates => buildArray(workOrderRebates));
export const workOrderAgreementArray$ = createSelector(workOrderAgreements$, workOrderAgreements => buildArray(workOrderAgreements));
export const workOrderPaymentArray$ = createSelector(workOrderPayments$, workOrderPayments => buildArray(workOrderPayments));
export const recommendationArray$ = createSelector(recommendations$, recommendations => buildArray(recommendations));
export const recommendationPhotoArray$ = createSelector(recommendationPhotos$, recommendationPhotos => buildArray(recommendationPhotos));
export const debriefAnswerArray$ = createSelector(debriefAnswers$, debriefAnswers => buildArray(debriefAnswers, true));
export const tagEntityArray$ = createSelector(tagEntities$, tagEntities => buildArray(tagEntities, false));
export const inspectionAnswersArray$ = createSelector(inspectionAnswers$, inspectionAnswers =>
  buildArray(inspectionAnswers).sort(normalOrder())
);
export const siteSystemsArray$ = createSelector(siteSystems$, siteSystems => buildArray(siteSystems));
export const workOrderSiteSystemsArray$ = createSelector(workOrderSiteSystems$, workOrderSiteSystems => buildArray(workOrderSiteSystems));
export const agreementSiteSystemArray$ = createSelector(agreementSiteSystems$, agreementSiteSystems => buildArray(agreementSiteSystems));
export const workOrderLoanApplicationArray$ = createSelector(workOrderLoanApplications$, workOrderLoanApplications =>
  buildArray(workOrderLoanApplications)
);

export const cacheCorrelationId$ = createSelector(workOrderStateSelector, state => state._cacheCorrelationId);
export const saveCorrelationId$ = createSelector(workOrderStateSelector, state => state._correlationId);
export const error$ = createSelector(workOrderStateSelector, state => state._error);
export const submitCounter$ = createSelector(workOrderStateSelector, state => state._submitCounter);
export const entities$ = createSelector(workOrderStateSelector, state => state.entities);

export const taxItemEntry$ = createSelector(
  workOrder$,
  workOrderBaseViewData$,
  internalTaxItemEntry$,
  (workOrder, workOrderBaseViewData, internalTaxItemEntry) => {
    if (internalTaxItemEntry && typeof internalTaxItemEntry.taxItem !== 'undefined') {
      return internalTaxItemEntry;
    }

    let taxItem: TaxItem | null = null;
    if (workOrder.taxItemId) {
      for (const taxItemSection of workOrderBaseViewData.taxItemSections) {
        taxItem = taxItemSection.items.find(m => m.id === workOrder.taxItemId) ?? null;
        if (taxItem) {
          break;
        }
      }
    }

    return {
      taxItem,
      taxItemManual: workOrder.salesTaxPercentage && new Decimal(workOrder.salesTaxPercentage).times(100).toNumber(),
      taxOverride: workOrder.taxOverride,
    } satisfies TaxItemEntry;
  }
);

export const workOrderAndTaxItemEntry = createSelector(workOrder$, internalTaxItemEntry$, (workOrder, taxItemEntry) => ({
  workOrder,
  taxItemEntry,
}));

export const workOrderRepairPartsByDetail$ = (props: { workOrderDetailId: Id }) =>
  createSelector(workOrderRepairParts$, (repairParts: WorkOrderRepairPartState) =>
    indexBy(
      Object.values(repairParts).filter(m => m.parentId === props.workOrderDetailId),
      'id'
    )
  );

export const workOrderPaymentWithParentId$ = (id: Id) =>
  createSelector(workOrder$, workOrderPayments$, (workOrder: WorkOrderInformation, workOrderPayments: WorkOrderPaymentState) => ({
    workOrderId: workOrder.id,
    resource: workOrderPayments[id],
  }));

export const recommendationWithParentId$ = (id: Id) =>
  createSelector(workOrder$, recommendations$, (workOrder: WorkOrderInformation, recommendations: RecommendationState) => ({
    workOrderId: workOrder.id,
    resource: recommendations[id],
  }));

export const recommendationPhotoWithParentId$ = (id: Id) =>
  createSelector(workOrder$, recommendationPhotos$, (workOrder: WorkOrderInformation, recommendationPhotos: RecommendationPhotoState) => ({
    workOrderId: workOrder.id,
    recommendationId: recommendationPhotos[id].recommendationId,
    resource: recommendationPhotos[id],
  }));

export const workOrderDebriefAnswerWithParentId$ = (id: Id) =>
  createSelector(workOrder$, debriefAnswers$, (workOrder: WorkOrderInformation, debriefAnswers: DebriefAnswerState) => ({
    workOrderId: workOrder.id,
    resource: debriefAnswers[id],
  }));

export const workOrderTagEntityWithParentId$ = (id: Id) =>
  createSelector(workOrder$, tagEntities$, (workOrder: WorkOrderInformation, tagEntities: TagEntityState) => ({
    workOrderId: workOrder.id,
    resource: tagEntities[id],
  }));

export const siteSystem$ = (id: Id) => createSelector(siteSystems$, (siteSystems: SiteSystemState) => siteSystems[id]);

export const workOrderDiscountsithParentId$ = (id: Id) =>
  createSelector(workOrder$, workOrderDiscounts$, (workOrder: WorkOrderInformation, workOrderDiscounts: WorkOrderDiscountState) => ({
    workOrderId: workOrder.id,
    resource: workOrderDiscounts[id],
  }));

export const workOrderRebateWithParentId$ = (id: Id) =>
  createSelector(workOrder$, workOrderRebates$, (workOrder: WorkOrderInformation, workOrderRebates: WorkOrderRebateState) => ({
    workOrderId: workOrder.id,
    resource: workOrderRebates[id],
  }));

export const workOrderDetailWithParentId$ = (props: { workOrderDetailId: Id }) =>
  createSelector(workOrder$, workOrderDetails$, nonStockItems$, (workOrder: WorkOrderInformationState, workOrderDetails, nonStockItems) => {
    const resource = workOrderDetails[props.workOrderDetailId];
    const nonStockItem = resource?.nonStockItemId ? nonStockItems[resource.nonStockItemId] : null;

    return {
      workOrderId: workOrder.id,
      resource,
      nonStockItem,
    };
  });

export const workOrderLoanApplicationWithParentId$ = (id: Id) =>
  createSelector(
    workOrder$,
    workOrderLoanApplications$,
    (workOrder: WorkOrderInformation, workOrderLoanApplications: WorkOrderLoanApplicationState) => ({
      workOrderId: workOrder.id,
      resource: workOrderLoanApplications[id],
    })
  );

export const agreementSiteSystemWithParentId$ = (id: Id) =>
  createSelector(workOrder$, agreementSiteSystems$, (workOrder: WorkOrderInformation, agreementSiteSystems: AgreementSiteSystemState) => ({
    workOrderId: workOrder.id,
    resource: agreementSiteSystems[id],
  }));

export const workOrderRepairPartWithParentId$ = (id: Id) =>
  createSelector(workOrder$, workOrderRepairParts$, (workOrder: WorkOrderInformation, workOrderRepairParts: WorkOrderRepairPartState) => {
    const parentId = workOrderRepairParts[id].parentId;
    if (!parentId) {
      throw new DeveloperError();
    }

    return {
      workOrderId: workOrder.id,
      detailId: parentId,
      resource: workOrderRepairParts[id],
    };
  });

export const workOrderAgreementsByAgreementSiteSystemId$ = (agreementSiteSystemId: Id) =>
  createSelector(workOrderAgreements$, (workOrderAgreements: WorkOrderAgreementState) => ({
    workOrderAgreements: Object.values(workOrderAgreements).filter(wOA => wOA.agreementSiteSystemId === agreementSiteSystemId),
  }));

export const workOrderAgreementByIdWithParentId$ = (id: Id) =>
  createSelector(
    workOrder$,
    workOrderAgreements$,
    (workOrder: WorkOrderInformationState, workOrderAgreements: WorkOrderAgreementState) => ({
      workOrderId: workOrder.id,
      resource: workOrderAgreements[id],
    })
  );

export const hasVisibleData$ = createSelector(
  workOrderDetails$,
  workOrderAgreements$,
  workOrderDiscounts$,
  workOrderRebates$,
  (workOrderDetails, workOrderAgreements, workOrderDiscounts, workOrderRebates) => {
    return (
      Object.values(workOrderDetails).some(m => !m.hidden && !m.deleted) ||
      Object.values(workOrderAgreements).some(m => !m.hidden && !m.deleted) ||
      Object.values(workOrderDiscounts).some(m => !m.hidden && !m.deleted) ||
      Object.values(workOrderRebates).some(m => !m.hidden && !m.deleted)
    );
  }
);

export const hasFinishedUpdate$ = createSelector(entities$, submitCounter$, (entities, submitCounter) => {
  const isPending = (entity: unknown): boolean =>
    !!entity &&
    (!!(entity as Creatable).created ||
      (!!(entity as Updatable).updated && (entity as { id: Id }).id > 0) ||
      (!!(entity as Deletable).deleted && (entity as { id: Id }).id > 0));

  return (
    submitCounter === 0 &&
    !getObjectValues(entities).some(
      x => isPending(x) || (isPlainObject(x) && typeof x === 'object' && x !== null && getObjectValues(x).some(isPending))
    )
  );
});
